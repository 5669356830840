// React
import { useEffect, useRef, useState } from "react";

// Context
import { useAuth } from "../../../contexts/UserContext";

// Services
import api from "../../../services/api";

// Icons
import { IconClose, IconDownload, IconFilter, IconSearch } from "../../icons";

// Styles
import { DownloadFile } from "../ProfessionalsFilter/styles";
import { Container } from "./styles";

interface FilterProps {
    filters?: any,
    setFilters?: any,
    setSearchProfiles?: any,
    setClean?: any,
    requestedPayload?: any,
}

export default function ProposalsFilter(
    {
        filters,
        setFilters,
        setSearchProfiles,
        setClean,
        requestedPayload,

    }: FilterProps) {

    const { pageName, user } = useAuth();
    const filterRef = useRef<any>();
    const [ DTOSearch, setDTOSearch ] = useState<any>();
    const [ openFiltersMenu, setOpenFiltersMenu ] = useState<boolean>(false);

    function handleOnChanges(value: any) {
        setDTOSearch(value)
    }

    function clearSearch() {
        setDTOSearch('')
        setSearchProfiles('')
        setFilters([])
    }

    function searchKeyPressed(event: any) {

        if (event.key === 'Enter') {
            setSearchProfiles(DTOSearch)
        }

    }

    async function downloadProposal() {
        try {

            const response = await api.get(`proposta-csv?${requestedPayload}`);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'propostas.csv');
            document.body.appendChild(link);
            link.click();

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            
            if (openFiltersMenu && filterRef.current && !filterRef.current.contains(e.target)) {
                setOpenFiltersMenu(false);
            }

        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openFiltersMenu]);

    return (
        <Container filter={filters}>
            <div className="search">
                <div
                    className="buscar"
                    onClick={() => setSearchProfiles(DTOSearch)}
                >
                    <IconSearch />
                </div>
                <input
                    placeholder="Pesquisar"
                    onChange={(e) => handleOnChanges(e.target.value)}
                    onKeyDown={(e) => searchKeyPressed(e)}
                    value={DTOSearch}
                />
                {
                    DTOSearch &&
                    <div
                        className="clean-search"
                        onClick={() => clearSearch()}
                    >
                        <IconClose />
                    </div>
                }
            </div>

            {
                pageName === 'Propostas' &&
                <div
                    className="button"
                    ref={filterRef}
                    onClick={() => setOpenFiltersMenu(!openFiltersMenu)}
                >
                    {
                        filters?.length < 1
                            ? <div className="button-filter">
                                <IconFilter />
                                Filtrar
                            </div>
                            : <div className="button-filter">
                                <div className="icon-filter">
                                    <IconFilter />
                                </div>
                                <div>
                                    {filters?.length}
                                </div>
                                <div
                                    className="clear-icon"
                                    onClick={() => {setFilters([]); setClean(); }}
                                >
                                    <IconClose />
                                </div>
                            </div>
                    }

                    
                    <div className={openFiltersMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                        <div className="filter">
                            Filtrar por...
                            <div className="button-search">
                                <IconSearch />
                            </div>
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'comarca'])}
                        >
                            Comarca
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'service'])}
                        >
                            Tipo de serviço
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'subtype_service'])}
                        >
                            Subtipos de serviço
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'subtype'])}
                        >
                            Subtipos de audiência
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'modality'])}
                        >
                            Modalidade
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'value'])}
                        >
                            Valor
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'starts'])}
                        >
                            Solicitada em
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'accept'])}
                        >
                            Prazo de aceite
                        </div>
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'conclusion'])}
                        >
                            Prazo de conclusão
                        </div>
                    </div>
                                    

                </div>
            }

            {
                user.profile !== 'comum' &&
                <DownloadFile
                    onClick={() => downloadProposal()}
                >
                    <IconDownload />
                </DownloadFile>
            }                 
            
            
        </Container>
    )
}