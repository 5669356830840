import { Container } from "./styles";

interface PropTabs {
    selected?: any,
    setSelected?: any,
    progress?: any,
    urgent?: any,
    finished?: any,
}

export default function DemandsTab({ selected, setSelected, urgent, progress, finished }: PropTabs) {
    return (
        <Container>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '1' ? 'selected tab' : 'tab'}
                id='1'
            >
                Urgentes
                <div
                    className={selected === '1' ? 'selected notification' : 'notification'}
                >
                    {
                        urgent
                        ? urgent
                        : 0
                    }
                </div>
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '2' ? 'selected tab' : 'tab'}
                id='2'
            >
                Em andamento
                <div
                    className={selected === '2' ? 'selected notification' : 'notification'}
                >
                    {
                        progress
                        ? progress
                        : 0
                    }
                </div>
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '3' ? 'selected tab' : 'tab'}
                id='3'
            >
                Finalizadas
                <div
                    className={selected === '3' ? 'selected notification' : 'notification'}
                >
                    {
                        finished
                        ? finished
                        : 0
                    }
                </div>
            </div>
        </Container>
    )
}