/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useState } from "react";
import { useHistory } from "react-router-dom";

// Icons //
import { 
    IconActiveProfile, 
    IconAddProfile, 
    IconEditProfile, 
    IconInactiveProfile, 
    IconProfileButtonDown, 
    IconProfileButtonUp 
} from "../../icons";

// Styles //
import { 
    Container, 
    DivArrow, 
    DivCode, 
    DivCounty, 
    DivDate, 
    DivIcon, 
    DivMail, 
    DivName, 
    DivOab, 
    DivPlan, 
    DivProfile, 
    DivWhats, 
    ProfileBottom, 
    ProfileTop, 
    ProfileWrapper 
} from "./styles";

export default function ProfessionalCard({profile }: any) {
    const history = useHistory();

    const celNumberClear = profile.whatsapp.replace(/\D/g, '');
    let celNumberCorrect = celNumberClear.match(/^(\d{2})(\d{5})(\d{4})$/);
    
    const [ profileOpen, setProfileOpen ] = useState<any>(false);
    const [ selected, setSelected ] = useState<any>('')

    function handleOpenMenu(item: any) {
        setSelected(item)
        setProfileOpen(!profileOpen)
    }   

    function editProfile(value: any) {
        history.push(`/profissionais/${value}`)
    }

    return (
        <Container>   
            
            <ProfileWrapper
                menuOpen={profileOpen}
                key={profile.id_usuario}
            >
                <ProfileTop>
                    <DivIcon> 
                        {
                            profile.status === 'em analise' ? <IconAddProfile /> 
                            : profile.status === 'ativo' ? <IconActiveProfile />
                            : profile.status === 'inativo' && <IconInactiveProfile />
                        }
                    </DivIcon>
                    
                    <DivCode>
                        <div className="title">Código:</div>
                        <div className="subtitle">D{profile.id_usuario}</div>
                    </DivCode>

                    <DivName>
                        <div className="title">Nome:</div>
                        <div className="subtitle">{profile?.nome?.toLowerCase().replace('>', '')}</div>
                    </DivName>

                    <DivPlan>
                        <div className="title">Plano:</div>
                        <div className="subtitle">
                            {
                                profile.id_plano === 1 ? '123JUS Free' : '' ||
                                profile.id_plano === 2 ? '123JUS Pro' : '' ||
                                profile.id_plano === 3 ? '123JUS Premium' : '' 
                            }
                        </div>
                    </DivPlan>

                    <DivCounty>
                        <div className="title">Comarca:</div>
                        <div className="subtitle">
                            {profile.estado_comarca} - {profile.cidade_comarca} 
                            {
                                profile.qtd_comarcas > 1 
                                    ? `, + ${profile.qtd_comarcas - 1}`
                                    : ''

                            }                            
                        </div>
                    </DivCounty>

                    <DivProfile
                        onClick={() => editProfile(profile.id_usuario)}
                    >
                        <IconEditProfile />
                    </DivProfile>

                    <DivArrow
                        onClick={() => handleOpenMenu(profile.id_usuario)}
                    >
                        {
                            profileOpen
                            ?   <IconProfileButtonUp />
                            :   <IconProfileButtonDown />
                        }
                    </DivArrow>
                </ProfileTop>

                <ProfileBottom
                    menuOpen={profileOpen}
                >
                    <DivIcon>                        
                    </DivIcon>

                    <DivDate>
                        <div className="title">Cadastrado em:</div>
                        <div className="subtitle">{(new Date(profile.criado)).toLocaleString('pt-BR', { dateStyle: 'short', timeZone: 'America/Sao_Paulo', timeStyle: 'short' })}</div>
                    </DivDate>

                    <DivMail>
                        <div className="title">E-mail:</div>
                        <div className="subtitle mail">{profile.email}</div>
                    </DivMail>

                    <DivWhats>
                        <div className="title">Whatsapp:</div>
                        {
                            celNumberCorrect !== null
                            ?   <div className="subtitle">{`(${celNumberCorrect[1]}) ${celNumberCorrect[2]}-${celNumberCorrect[3]}`}</div> 
                            :   <div className="subtitle">------------</div> 
                        }
                        
                    </DivWhats>

                    <DivOab>
                        <div className="title">OAB:</div>
                        <div className="subtitle">{profile.inscricao_oab}</div>
                    </DivOab>

                    <DivProfile className="fake">                        
                    </DivProfile>

                    <DivArrow className="fake">                        
                    </DivArrow>
                </ProfileBottom>
            </ProfileWrapper>            

        </Container>
    )
}