import styled from "styled-components";

export const Container = styled.div`
    width: 1040px;
    max-width: 90%;
    height: auto;
    /* min-height: 100vh; */
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0}; 
    padding-bottom: 100px;
       
    
    .toasted {
        z-index: 99999;        
    }  
    
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 200px;
        transform: translateX(-50%);
    }
`

export const Header = styled.div`
    width: 100%;
    margin-bottom: 74px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;       
`

export const Main = styled.div`
    padding-bottom: 60px;

    .top {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 40px;

        .register {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            height: 80px;
            max-width: 680px;
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 36px; 
                color: ${({ theme }) => theme.colors.interfaceText400};               
            }            
        }

        .subtitle, p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: ${({ theme }) => theme.colors.interfaceText500};                
        }
    }

    form {
        padding-bottom: 140px;
        .name,
        .email {
            display: flex;
            flex-wrap: wrap;
            gap: 24px 16px;
            margin-bottom: 24px;
            justify-content: space-between;
            .big {
                width: 65%;
                &:hover {
                    border-color: red;
                }
            }           

            .small {
                width: 33%;
                &:last-child {
                    width: calc(35% - 24px);
                }
            }            
        }

        .pro {
            max-width: 675px;
            margin-bottom: 24px;
        }

        .state {
            display: flex;
            gap: 16px;
            max-width: 675px;
            margin-bottom: 24px;

            .label-state {
                width: 50%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }
        }

        .fone {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .small {
                width: 32%;
            }

            .big {
                width: 65%;
            }
        }

        .address {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .label {
                width: 24%;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }                
            }

            .small {
                width: 23.5%;
            }
            .big {
                width: 49%;
            }
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }

    .cards {
        display: flex;
        gap: 16px;
    }

` 

export const DemandCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 337px;
    height: fit-content;
    border: 2px solid ${({ theme }) => theme.colors.secondaryPool200};
    border-radius: 8px;
    padding: 24px;

    .title {
        width: 100%;
        height: 48px;
        font-size: 24px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        border-bottom: 2px solid ${({ theme }) => theme.colors.secondaryPool100};
        padding-bottom: 16px;
    }

    .info {
        height: 56px;
        width: 100%;
        height: fit-content;

        span{
            height: 16px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .show-info {
            height: fit-content;
            padding: 8px;
            background-color: ${({ theme }) => theme.colors.secondaryPool100};
            border-radius: 8px;
            margin-top: 8px;
            word-wrap: break-word;
        }
    }

    .address {
        
        span{
            height: 16px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .wrap {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .address-info {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                padding: 8px;
                width: fit-content;
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
                border-radius: 8px;
                margin-top: 8px;    
            }
        }

    }
`

export const FormCard = styled.div`
    display: flex;
    flex-direction: column;
    
    .form-line {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 688px;

        .field {
            display: flex;
            gap: 16px;
            width: 100%;   
            position: relative;         

            .big {
                width: 100%;               
            }

            .name {
                min-width: 425px;
            }            
        }

        .texts {
            display: flex;
            flex-direction: column;

            span {
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-size: 14px;
            }

            .text {
                width: 100%;
                height: auto;
                border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
                border-radius: 8px;
                padding: 16px;
                margin-top: 8px;                
                &:focus-within {
                    border: 1px solid ${({ theme }) => theme.colors.secondaryGreen300};                        
                }                

                &.agreement {
                    height: 96px;

                    &.error {
                        border-color: ${({ theme }) => theme.colors.primaryOrange300};
                    }
                }

                &.require {
                    height: 128px;

                    &.error {
                        border-color: ${({ theme }) => theme.colors.primaryOrange300};
                    }
                }
    
                .text-area {
                    width: 100%;
                    height: 100%;
                    border: none;
                    resize: none;
                    color: ${({ theme }) => theme.colors.interfaceText500};
                    font-weight: 400;
                    outline: none;    
                }

                &.error {
                    border-color: ${({ theme }) => theme.colors.primaryOrange300};
                }

                /* .list {
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        color: ${({ theme }) => theme.colors.interfaceText400};
                        
                        &::before {
                            content: "•";
                            color: ${({ theme }) => theme.colors.interfaceText400};
                            font-weight: bold;
                            display: inline-block; 
                            width: 1em;
                            margin-left: 0;
                        }
                    }
                } */
            }
        }

        .add {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 56px;
            padding: 16px;
            border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.primaryBlue500};
            font-weight: 500;
            cursor: pointer;
            position: relative;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue500};
                }
            }

            .file-input {
                width: 100%;
                height: 100%;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input::before {
                content: 'Anexar arquivos';
                color: ${({ theme }) => theme.colors.primaryBlue500};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input:active {
                outline: 0;
            }
        }

    }

    .file {
        display: flex;
        gap: 8px;
        width: 100%;
        height: 56px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;
        margin-top: 8px;

        .file-details{
            display: flex;
            justify-content: space-between;
            width: 100%;
            border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
            border-radius: 8px;
            padding: 16px;

            &.highlight {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
            }

            .icon-title {
                display: flex;
                align-items: center;
                gap: 18px;
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.primaryBlue500};
            }

            .date-trash {
                display: flex;
                align-items: center;
                gap: 40px;

                .date {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText300};
                }

                .icon-trash {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    cursor: pointer;
                    svg {
                        width: 100%;
                        height: 100%;
                        path {
                            fill: ${({ theme }) => theme.colors.primaryBlue500};
                        }
                    }
                }
            }

        }

        .download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border-radius: 8px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: ${({ theme }) => theme.colors.interfaceText100};
            }
        }
    }

    .files {
        span {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-size: 14px;
            font-weight: 500;
        }
    }
`

export const Footer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    /* display: none; */
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;  
    background: ${({ theme }) => theme.colors.interfacePure0};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    z-index: 0;
    .container {
        width: 1040px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;
    }
    .buttons {
        display: flex;
        gap: 16px;
        button {
            width: 160px;
        }
        .continue {
            background: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;

        .progressbar {
            height: 8px;
            width: 170px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};
            position: relative;
            .percent {
                transition: all .2s;
                width: 34px;
                height: 8px;
                position: absolute;
                border-radius: 8px;
                top: 0;
                left: 0;
                background: ${({ theme }) => theme.colors.primaryBlue400};
                &.two {
                    width: 85px;
                }
                &.three {
                    width: 135px;
                }                
            }
        }
    }
`