import { IconPlanFree, IconPlanPremium, IconPlanPro } from "../icons";
import Card from "../PlanCard";
import { WrapperCards } from "./styles";

interface CardIdProps {
    setCard?: any;
    selected?: any;
    value?: any,
    hover?: any,
}

export default function RegisterPage5({setCard, selected, value, hover}: CardIdProps) {

    return(
        <WrapperCards>            
            <Card 
                id={1}
                className={'free'}
                iconTop={<IconPlanFree />}
                title={'123 JUS Free'}
                subtitle={<p>Você poderá <span>visualizar</ span> oportunidades.</p>}
                value={'R$ 0,00'}
                onClick={() => setCard(1)}
                selected={selected}
                page={hover}
            />
            <Card 
                id={2}
                className={'pro'}
                iconTop={<IconPlanPro />}
                title={'123 JUS Pro'}
                subtitle={<p>Você poderá <span>responder</span> oportunidades!</p>}
                value={'R$ 19,90'}
                onClick={() => setCard(2)}
                selected={selected}
                page={hover}
            />
            <Card 
                id={3}
                className={'premium'}
                iconTop={<IconPlanPremium />}
                title={'123 JUS Premium'}
                subtitle={'Em breve, um plano para você se destacar no mercado.'}
                value={value}
                onClick={() => ('')}
                selected={selected}  
                page={hover}              
            />
        </WrapperCards>
    )
}