import styled from "styled-components"

export const DivCheckbox = styled.div`
    margin-right: 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    .label{
        color: ${(props) => props.theme.colors.interfaceText400};
        font-size: 16px;
        line-height: 24px;
        margin-left: 10px;
    }

    .checkbox {
        display: flex;
        justify-content: left;
        cursor: pointer;
        .square{
            box-sizing: border-box;
            vertical-align: middle;
            display: inline-block;
            width: 22px;
            height: 22px;
            border: 2px solid ${(props) => props.theme.colors.secondaryGreen500};
            border-radius: 3px;
            &:before{
                display: block;
                content: "✔";
                text-align: center;
                color: white;
                transition: .2s;
                opacity: 0;
            }
        }
        .text{
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;
            line-height: 22px;
        }
        &.active{
            .square{
                border: ${(props) => props.theme.colors.secondaryGreen500};
                background-color: ${(props) => props.theme.colors.secondaryGreen500};
                &:before{
                    opacity: 1;
                    color: white;
                }
            }
        }
    }

    @media (max-width: 800px) {
        margin: 0 auto;
        .label {
            width: 250px;
        }
    }
`;