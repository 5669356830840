import styled from "styled-components";

interface ProfileProps {
    profile?: any,
}

export const Container = styled.div<ProfileProps>`
    display: flex;
    gap: 16px;
    width: ${({ profile }) => (profile === 'admin' ? '400px' : profile === 'operador' ? '400px' : '') };
    justify-content: flex-end;
    background: transparent;

    .cancel {
        width: 105px;
        height: 56px;
        border: 2px solid ${({ theme }) => theme.colors.interfaceText100} !important;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-weight: 600;
    }

    .button-save {
        
        .save {
            width: fit-content;
            padding: 16px;
        }
    }

    .activity {
        width: 240px;

        /* .save {
            width: fit-content;
            padding: 16px;
        } */
    }
    .activity-small {
        width: 180px;
    } 
`
export const DropDownMenu = styled.div<ProfileProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    transition: all .3s;
    position: relative;
    &:hover {
        background: ${({ theme }) => theme.colors.interfacePure100};
    }

    .hidden-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 32px;
        width: 220px;
        /* height: ${({ profile }) => profile === 'operador' ? '145px' : '216px'}; */
        height: fit-content;
        border-radius: 8px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        cursor: default;
        position: absolute;
        top: 72px;
        right: 0;
        z-index: 99;
        filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
        &.no-show {
            display: none;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 176px;
            height: 40px;
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.interfaceText400};
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            transition: all .2s;
            padding: 11px;
            &:hover {
                background: ${({ theme }) => theme.colors.interfacePure100};
            }
            svg {
                path {
                    fill: ${({ profile }) => profile === 'operador' || profile === 'admin' ? '#F18F00' : ''};
                }
            }
        }
    }
`