// import { IconLoading } from '../../icons';
import { IconLoading } from '../../icons';
import { Container } from './styles';

interface ButtonProps {
    loading?: boolean;
    type?: 'submit' | 'button';
    children: string;
    className?: 'access' | 'back' | 'cancel' | 'save' | 'danger' | 'continue';
    disabled?: boolean;
    onClick?: () => void;
}

const ButtonDefault: React.FC<ButtonProps> = ({
    loading = false,
    children,
    type = 'submit',
    className = 'access',
    disabled,
    onClick,
}) => {
    return (
        <Container type={type} className={className} disabled={disabled} onClick={onClick}>
            {loading ? <IconLoading /> : <span>{children}</span>}
        </Container>
    );
};

export default ButtonDefault;