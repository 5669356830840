/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Components
import Header from "../../../components/HeaderSite";
import Footer from "../../../components/Footer";

// Styles
import { 
    AreaHeader, 
    AreaImage2, 
    AreaSite, 
    Container, 
    DemandButton, 
    DemandCard, 
    DemandDescription, 
    DemandInfos, 
    DemandSolicitation, 
    DemandTitle, 
    Description, 
    DescriptionTitle, 
    HeaderWrapper, 
    Image, 
    InfoWrapper, 
    IslandCard, 
    IslandQuality, 
    IslandSection, 
    IslandSystem, 
    ServiceCard, 
    StructureCardWrapper, 
    VectorCard, 
    VectorColumn, 
    WideCard, 
    WrapperIslandCards, 
    WrapperServiceCards 
} from "./styles";

// Images
import BlueCircle from "../../../assets/images/WomanBlue.png";
import IslandFrame from "../../../assets/images/IslandFrame.svg";
import SearchDemand from "../../../assets/images/Search.svg";
import PcDemand from "../../../assets/images/Pc.svg";
import TriPeople from "../../../assets/images/Trio.svg";
import MapPeople from "../../../assets/images/MapPeople.svg";
import PeopleJJ from "../../../assets/images/PeopleJJ.svg";
import Bill from "../../../assets/images/Bill.svg";

// Icons
import { 
    IconClockShadow, 
    IconCoconut, 
    IconHelm, 
    IconNoteShadow, 
    IconOneVectorRight, 
    IconOrangeStar, 
    IconPlusShadow, 
    IconRequestDemand, 
    IconSailor, 
    IconVectorDoubleRight, 
    IconVectorRight, 
    IconWhatsShadow 
} from "../../../components/icons";


export default function LegalClaims() {

    const history = useHistory();
    const location = useLocation<any>();

    const benefits = useRef<any>(null);
    const demands = useRef<any>(null);

    const scrollBenefits = () => benefits.current.scrollIntoView();
    const scrollDemands = () => demands.current.scrollIntoView();

    useEffect(() => {
        function scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        scrollTop();        

        if (location.state === 'benefits') {
            scrollBenefits();
        }
    }, [])

    return (
        <Container>
            <AreaHeader>
                <Header />
            </AreaHeader>

            <HeaderWrapper>

                <AreaSite>
                    
                    <AreaImage2>
                        <div className='circle'>
                            <Image src={BlueCircle} />
                            <div className='blue-circle'></div>
                        </div>
                    </AreaImage2>

                    <Description>

                        <DescriptionTitle>
                            <div>
                                100% online <br />
                                <span>e fácil para quem solicita o serviço.</span>
                            </div>

                            <div className='subtitle'>
                                Nossa tecnologia conecta o Universo Jurídico. <br />
                                Experimente a plataforma  mais completa do mercado.
                            </div>

                            <div className='icon'>
                                <IconOrangeStar />
                            </div>

                            <DemandButton 
                                className='first'
                                onClick={scrollDemands}
                            >
                                Solicite um serviço e torne-se cliente!
                            </DemandButton>

                            <DemandButton
                                className='second'
                                onClick={scrollBenefits}
                            >
                                Confira os beneficios
                            </DemandButton>
                        </DescriptionTitle>

                    </Description>

                </AreaSite>

                

            </HeaderWrapper>
            
            <InfoWrapper>
                <DemandInfos ref={benefits}>

                    <DemandTitle className="first">
                        As melhores soluções para a sua rotina jurídica <span>num só lugar e sem custo!</span>
                    </DemandTitle>

                    <DemandDescription>
                        Nossa plataforma de contratação automatiza as demandas jurídicas, como 
                        prazos de diligências, controle financeiro entre outras necessidades do 
                        cotidiano da logística jurídica.
                    </DemandDescription>

                    <StructureCardWrapper>
                        <DemandCard>
                            <IconPlusShadow />
                            <div className='info'>Solicitação e acompanhamento de diversos tipos de demandas</div>
                        </DemandCard>
                        <DemandCard>
                            <IconClockShadow />
                            <div className='info'>
                                Agilidade por meio de <br /> valores fixos de serviços
                            </div>
                        </DemandCard>
                        <DemandCard>
                            <IconWhatsShadow />
                            <div className='info'>
                                Qualidade e transparência <br /> de atendimento e resultados
                            </div>
                        </DemandCard>
                        <DemandCard>
                            <IconNoteShadow />
                            <div className='info'>
                                Controle financeiro com <br /> fatura única mensal
                            </div>
                        </DemandCard>
                    </StructureCardWrapper>

                    <DemandTitle className='light'>
                        <span>Deixe com a gente ;)</span> <br />
                        Organizaremos todo o fluxo das suas demandas.
                    </DemandTitle>

                    <DemandDescription>
                        Você pode escolher enviar suas solicitações de serviços direto para <br />
                        a gente, encontraremos o profissional para realizá-las, <br />
                        acompanharemos o andamento e retornaremos para você com o <br />
                        serviço executado. Você não precisa se preocupar, nossos <br />
                        operadores cuidarão do seu serviço.
                    </DemandDescription>

                    <WideCard>
                        <VectorColumn>
                            <div className='vector'>
                                <div className='icon'>
                                    <Image src={SearchDemand} />
                                </div>
                                <VectorCard className='blue'>
                                    Suas <br /> demandas
                                </VectorCard>
                            </div>
                            <div className='vector-icon'>
                                <IconVectorRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector'>
                                <div className='icon'>
                                    <Image src={PcDemand} />
                                </div>
                                <VectorCard className='orange'>
                                    Nossa <br /> plataforma
                                </VectorCard>
                            </div>
                            <div className="vector-double">
                                <IconVectorDoubleRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='no-card'>
                                <Image src={TriPeople} />
                                <Image src={MapPeople} className='bottom' />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector cards'>
                                <VectorCard className='orange'>
                                    Ilhas de <br /> atendimento
                                </VectorCard>
                                <VectorCard className='orange'>
                                    Representante <br /> regional
                                </VectorCard>
                            </div>
                            <div className='one'>
                                <IconOneVectorRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn>
                            <div className='vector people'>
                                <div className='icon people'>
                                    <Image src={PeopleJJ} />
                                </div>
                                <VectorCard className='orange'>
                                    Profissionais <br /> jurídicos
                                </VectorCard>
                            </div>
                            <div className='vector-icon'>
                                <IconVectorRight />
                            </div>
                        </VectorColumn>

                        <VectorColumn >
                            <div className='vector'>
                                <div className='icon'>
                                    <Image src={Bill} />
                                </div>
                                <VectorCard className='blue' ref={demands}>
                                    Fatura única <br /> no fim do mês
                                </VectorCard>
                            </div>
                        </VectorColumn>

                    </WideCard>

                </DemandInfos>
            </InfoWrapper>

            <DemandSolicitation>
                <DemandTitle className="mobile">
                    <span>Solicite</span> todas as suas demandas em um só lugar:
                </DemandTitle>

                <div className='subtitle'>
                    Temos a solução para todas as suas demandas! <span>Clique para solicitar:</span>
                </div>

                <WrapperServiceCards>
                    <ServiceCard>
                        <IconRequestDemand />
                        <div className='title'>Audiências</div>
                        <DemandButton 
                            className='button'
                            onClick={() => history.push('/solicitarservico')}
                        >
                            Solicitar
                        </DemandButton>
                    </ServiceCard>
                    <ServiceCard>
                        <IconRequestDemand />
                        <div className='title'>Diligências</div>
                        <DemandButton 
                            className='button'
                            onClick={() => history.push('/solicitarservico')}
                        >
                            Solicitar
                        </DemandButton>
                    </ServiceCard>
                    <ServiceCard>
                        <IconRequestDemand />
                        <div className='title'>Protocolos</div>
                        <DemandButton 
                            className='button'
                            onClick={() => history.push('/solicitarservico')}
                        >
                            Solicitar
                        </DemandButton>
                    </ServiceCard>
                    <ServiceCard>
                        <IconRequestDemand />
                        <div className='title'>Outros</div>
                        <DemandButton 
                            className='button'
                            onClick={() => history.push('/solicitarservico')}
                        >
                            Solicitar
                        </DemandButton>
                    </ServiceCard>
                </WrapperServiceCards>

            </DemandSolicitation>

            <IslandSection>
                <IslandQuality>
                    <div className='island'>
                        <div className='title'>
                            Qualidade através <br /> das ilhas de atendimento
                        </div>
                        
                        <div className='subtitle'>
                            Cada ilha conta com um time de <br />
                            <span>5 profissionais dedicados</span> às suas <br />
                            demandas! Mais pessoalidade no <br />
                            atendimento, melhores resultados! <br />
                        </div>

                        <div className='image'>
                            <Image src={IslandFrame} />
                        </div>
                    </div>
                </IslandQuality>

                <IslandSystem>
                    <div className='left'>
                        <div className='title'>
                            Nosso <span>Sistema de Ilhas</span> <br />
                            está pronto para simplificar sua vida!
                        </div>

                        <div className='fact'>
                            <span>Curiosidade: cada Ilha possui um nome de praia :)</span><br /> Queremos com isso, transmitir toda a tranquilidade que você terá trabalhando com o 123JUS!
                        </div>

                        <div className='subtitle'>
                            Sabemos que a logística jurídica em âmbito nacional e um número elevado de demandas trazem dificuldades no contrato entre o solicitante e quem está cuidando de suas demandas. <br />
                            <p>Conheça nosso método:</p>
                        </div>
                    </div>

                    <div className='right'>
                        <span>Curiosidade: cada Ilha possui um nome de praia :)</span> Queremos com isso, transmitir toda a tranquilidade que você terá trabalhando com o 123JUS!
                    </div>
                </IslandSystem>

                <WrapperIslandCards>
                    <IslandCard>
                        <IconCoconut />
                        <div className='info'>
                            <span>Cada cliente do 123JUS conta com um Ilha específica,</span> que lida com um número limitado de processos, evitando sobrecarga e atraso.
                        </div>
                    </IslandCard>

                    <IslandCard>
                        <IconSailor />
                        <div className='info'>
                            <span>Cada Ilha é composta por 5 profissionais jurídicos,</span> dedicados a audiências, diligências, protocolos, cadastros e controladoria
                        </div>
                    </IslandCard>

                    <IslandCard>
                        <IconHelm />
                        <div className='info'>
                            <span>Estes profissionais são selecionados com o perfil ideal para suas demandas,</span> e se especializarão na sua linha de defesa e forma de trabalhar
                        </div>
                    </IslandCard>
                </WrapperIslandCards>
            </IslandSection>
            
            <Footer />
        </Container>
    )
}