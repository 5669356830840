import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 24px;
  gap: 16px;

  background-color: ${({theme}) => theme.colors.interfacePure0};
  box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
  border-radius: 16px;

`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  .iconCardService {
    display: grid;
    place-items: center;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: #F2F2F2;
  }

  .cardServiceButtonExternal {
    display: grid;
    place-items: center;
    border: none;
    background-color: transparent;
  }
`;

export const SectionGrid = styled.div`
  display: flex;
  
`;

export const SectionInfo = styled.div`
  margin-top: 8px;

  h1 {
    width: 200px;
  }

  .Title {
    &.delayed {
      width: 160px;
    }
  }
`;

export const TotalsCardServices = styled.h2`
  margin-top: 6px;
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.primaryBlue400};

  .dark-blue {
    max-width: 200px;
    color: ${({ theme }) => theme.colors.primaryBlue400};
    span {
      color: ${({ theme }) => theme.colors.primaryBlue400};
    }
  }

  .green {
    color: #03AD8F;
    max-width: 248px;
  }

  .light-blue {
    color: ${({ theme }) => theme.colors.secondaryPool600};
  }

  .orange {
    color: ${({ theme }) => theme.colors.primaryOrange600};
  }

  .red {
    color: ${({ theme }) => theme.colors.interfaceDanger400};
  }

  .success {
    color: ${({ theme }) => theme.colors.interfaceSuccess400};
  }

  span {

  }
`;

export const SectionGrafic = styled.div`
  display: flex;
  width: 770px;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
`;


