import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 1040px;

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 300px;
        transform: translateX(-50%);
    }
`;

export const SectionCardDashInfo = styled.div`
    display: grid;
    grid-template-columns: 776px 1fr;
    gap: 16px;
`;

export const SectionAllCardsDashInfo = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(248px, 1fr));
    gap: 16px;
`;

export const OperatorDemands = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
`

export const DemandCardColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export const WrapperLittleCards = styled.div`
    display: flex;
    gap: 16px;
`

export const CardInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 248px;
    height: 200px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.interfacePure0};
    padding: 24px;
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);

    .icons {
        display: flex;
        height: 48px;
        align-items: flex-start;
        justify-content: space-between;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 8px;

            &.active {
                background-color: ${({ theme }) => theme.colors.secondaryGreen050};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.secondaryGreen600};
                    }
                }
            }

            &.ongoing {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.secondaryPool600};
                    }
                }
            }

            &.canceled {
                background-color: ${({ theme }) => theme.colors.background};
                svg {
                    width: 24px;
                    height: 24px;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }

            &.accept {
                background-color: ${({ theme }) => theme.colors.interfaceSuccess100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }
        }

        .button {
            cursor: pointer;
        }
    }

    .title {
        height: 56px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 16px;
        font-weight: 600;    
        padding-top: 4px;
        line-height: 24px;
    }

    .numbers {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 24px;
        font-weight: 500;
        
        span {
            color: ${({ theme }) => theme.colors.interfaceText300};
            font-size: 12px;
        }

        &.active {
            color: ${({ theme }) => theme.colors.secondaryGreen600};
        }

        &.ongoing {
            color: ${({ theme }) => theme.colors.secondaryPool600};
        }

        &.canceled {
            color: ${({ theme }) => theme.colors.interfaceText500};
        }
        
        &.accept {
            color: ${({ theme }) => theme.colors.interfaceSuccess400};
        }
    }
`

export const WideCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 512px;
    height: 200px;
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 16px;
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
    /* filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3)); */

    .title {
        display: flex;
        justify-content: space-between;

        p {
            font-size: 1rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

    }

    .info {
        display: flex;
    }

    .button {
        cursor: pointer;
    }
`

export const ColumnInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;

    .line {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
            width: 14px;
            max-width: 32px;
            text-align: end;
            color: ${({ theme }) => theme.colors.primaryBlue400};
        }

        p {
            color: ${({ theme }) => theme.colors.interfaceText300};
        }

        span, 
        p {
            font-size: 14px;
            font-weight: 500;
        }

        .status {
            &.proposal {
                width: 4px;
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.secondaryGreen600};
                    }
                }
            }
            &.progress {
                width: 4px;
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.secondaryPool600};
                    }
                }
            }
            &.orientation {
                width: 4px;
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.primaryOrange700};
                    }
                }
            }
            &.finish {
                width: 4px;
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }
            &.delayed {
                width: 4px;
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceDanger400};
                    }
                }
            }
        }
    }
`