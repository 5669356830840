import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1040px;
    height: auto;
    min-height: 100vh;
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0}; 
       
    
    .toasted {
        z-index: 99999;        
    }    
`

export const Header = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 56px;
    
`

export const DeadlineAccept = styled.div`
    width: 50%;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.interfaceText400};

    .calendar-time {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 510px;
        margin-top: 8px;
    }

    .calendar {
        width: 335px;
        
        .react-datetime-picker {
            width: 100%;
            height: 48px;
            display: inline-flex;
            position: relative;
            }
            .react-datetime-picker,
            .react-datetime-picker *,
            .react-datetime-picker *:before,
            .react-datetime-picker *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            }
            .react-datetime-picker--disabled {
                background-color: #f0f0f0;
                color: #6d6d6d;
            }
            .react-datetime-picker__wrapper {
                display: flex;
                flex-grow: 1;
                flex-shrink: 0;
                border: thin solid gray;
                border-radius: 8px;
                padding: 8px 16px;

            }
            .react-datetime-picker__inputGroup {
                min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                flex-grow: 1;
                padding: 0 2px;
            }
            .react-datetime-picker__inputGroup__divider {
                padding: 8px 0;
                white-space: pre;
                    &:nth-child(7) {
                        padding: 8px;
                        &::before {
                            content: '|';
                        }
                    }
            }
            .react-datetime-picker__inputGroup__input {
                min-width: 0.54em;
                height: calc(100% - 3px);
                position: relative;
                padding: 1px;
                border: 0;
                background: none;
                font: inherit;
                font-size: 16px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
                box-sizing: content-box;
                -moz-appearance: textfield;
            }
            .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
            .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .react-datetime-picker__inputGroup__input:invalid {
                background: rgba(255, 0, 0, 0.1);
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero {
                margin-left: -0.54em;
                padding-left: calc(1px +  0.54em);
                font: inherit;
                font-size: 16px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
            .react-datetime-picker__inputGroup__leadingZero {
                margin-left: -0.44em;
                padding-left: calc(1px +  0.54em);
                font: inherit;
                font-size: 16px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
            .react-datetime-picker__inputGroup__amPm {
            font: inherit;
                -moz-appearance: menulist;
            }
            .react-datetime-picker__button {
                border: 0;
                background: transparent;
                padding: 4px 6px;
            }
            .react-datetime-picker__button:enabled {
            cursor: pointer;
            }
            .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
            .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                stroke: #0078d7;
            }
            .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                stroke: #6d6d6d;
            }
            .react-datetime-picker__button svg {
                display: inherit;
            }
            .react-datetime-picker__calendar,
            .react-datetime-picker__clock {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
            }
            .react-datetime-picker__calendar--closed,
            .react-datetime-picker__clock--closed {
                display: none;
            }
            .react-datetime-picker__calendar {
                width: 350px;
                max-width: 100vw;
            }
            .react-datetime-picker__calendar .react-calendar {
                border-width: thin;
            }
            .react-datetime-picker__clock {
                width: 200px;
                height: 200px;
                max-width: 100vw;
                padding: 25px;
                background-color: white;
                border: thin solid #a0a096;
            }
    }

    .time {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        width: 160px;
        height: 48px;
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.primaryOrange50};
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }
`

export const DeadlineConclusion = styled.div`
    width: 50%;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.interfaceText400};

    .calendar-time {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 510px;
        margin-top: 8px;
    }

    .calendar {
        width: 335px;

        .react-datetime-picker {
            width: 100%;
            height: 48px;
            display: inline-flex;
            position: relative;
            }
            .react-datetime-picker,
            .react-datetime-picker *,
            .react-datetime-picker *:before,
            .react-datetime-picker *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            }
            .react-datetime-picker--disabled {
            background-color: #f0f0f0;
            color: #6d6d6d;
            }
            .react-datetime-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border: thin solid gray;
            border-radius: 8px;
            padding: 8px 16px;

            }
            .react-datetime-picker__inputGroup {
            min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
            flex-grow: 1;
            padding: 0 2px;
            }
            .react-datetime-picker__inputGroup__divider {
                padding: 8px 0;
                white-space: pre;
                    &:nth-child(7) {
                        padding: 8px;
                        &::before {
                            content: '|';
                        }
                    }
            }
            .react-datetime-picker__inputGroup__input {
            min-width: 0.54em;
            height: calc(100% - 3px);
            position: relative;
            padding: 1px;
            border: 0;
            background: none;
            font: inherit;
            font-size: 16px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText500};
            box-sizing: content-box;
            -moz-appearance: textfield;
            }
            .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
            .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
            .react-datetime-picker__inputGroup__input:invalid {
            background: rgba(255, 0, 0, 0.1);
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero {
            margin-left: -0.54em;
            padding-left: calc(1px +  0.54em);
            }
            .react-datetime-picker__inputGroup__leadingZero {
                margin-left: -0.44em;
                padding-left: calc(1px +  0.54em);
                font: inherit;
                font-size: 16px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
            .react-datetime-picker__inputGroup__amPm {
            font: inherit;
            -moz-appearance: menulist;
            }
            .react-datetime-picker__button {
            border: 0;
            background: transparent;
            padding: 4px 6px;
            }
            .react-datetime-picker__button:enabled {
            cursor: pointer;
            }
            .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
            .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
            stroke: #0078d7;
            }
            .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
            stroke: #6d6d6d;
            }
            .react-datetime-picker__button svg {
            display: inherit;
            }
            .react-datetime-picker__calendar,
            .react-datetime-picker__clock {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            }
            .react-datetime-picker__calendar--closed,
            .react-datetime-picker__clock--closed {
            display: none;
            }
            .react-datetime-picker__calendar {
            width: 350px;
            max-width: 100vw;
            }
            .react-datetime-picker__calendar .react-calendar {
            border-width: thin;
            }
            .react-datetime-picker__clock {
            width: 200px;
            height: 200px;
            max-width: 100vw;
            padding: 25px;
            background-color: white;
            border: thin solid #a0a096;
        }
    }

    .time {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        width: 160px;
        height: 48px;
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.primaryOrange50};
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }
`

export const Main = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-size: 24px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
        margin-bottom: 32px;
    }

    .subtitle {
        max-width: 600px;
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
        margin-bottom: 32px;
    }

    .upload-orientation {
        display: flex;
        gap: 16px;
        width: 100%;        
    }
`

export const Orientations = styled.div`
    width: 512px;

    .text {
        width: 100%;
        height: 300px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        padding: 16px;
        margin-top: 8px;

        .text-area {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 8px;
            resize: none;
            padding: 16px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;
            outline: none;
        }
    }
        
`

export const Upload = styled.div`
    width: 512px;

    .archives {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        height: 300px;
        border: 1px dashed ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        padding: 16px;
        margin-top: 8px;
        overflow-y: scroll;

        .file-uploaded {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 56px;
            border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
            border-radius: 8px;
            padding: 16px;

            .icon-title {
                display: flex;
                align-items: center;
                gap: 16px;

            }

            .icon-trash {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                cursor: pointer;
                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        fill: ${({ theme }) => theme.colors.primaryOrange700};
                    }
                }
            }
        }


        .button {
            display: flex;
            align-items: center;
            gap: 18px;
            width: 100%;
            height: 56px;
            border: 2px dashed ${({ theme }) => theme.colors.primaryOrange600};
            border-radius: 8px;
            padding: 16px;
            color: ${({ theme }) => theme.colors.primaryOrange600};
            cursor: pointer;            
            position: relative;

            .file-input {
                width: 100%;
                height: 56px;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input::before {
                content: 'Adicionar arquivos';
                color: ${({ theme }) => theme.colors.primaryOrange600};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input:active {
                outline: 0;
            }
            
        }
    }
       
`