import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 522px;
    height: 56px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.interfacePure0};

    .tab {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0 16px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        cursor: pointer;
        border-bottom: 2px solid transparent;
        transition: all .2s;
        &.selected {
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 700;
            border-bottom: 2px solid ${({ theme }) => theme.colors.primaryBlue400};
        }
    }

    @media (max-width: 1000px) {
        min-width: 600px;        
    }
`