import { Container } from './styles';


interface ButtonProps {
    children?: any;
    styles?: 'save' | 'new' | 'help' | 'filter' | 'pro' | 'op' | 'activate' | 'reject';
    type?: 'button' | 'submit';
    Icon?: any;
    onClick?:() => void;
}

const ButtonIcon: React.FC<ButtonProps> = ({
    children,
    styles,
    type = 'button',
    Icon = false,
    onClick = () => { },
}) => {
    
    return (
        <Container type={type} onClick={onClick} className={styles}>
            {Icon && (
                <span className="icon">
                    {Icon}
                </span>
            )}
            {
                children && (               
                    <span
                        className="children"
                    >
                        {children}
                    </span>
                )
            }
        </Container>
    );
};

export default ButtonIcon;