/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Icons 
import { 
    IconEditProfile, 
    IconProfileButtonDown, 
    IconProfileButtonUp, 
    IconSquarePlus, 
} from "../../icons";

// Styles
import { 
    Container, 
    DivArrow, 
    DivCode, 
    DivProfit, 
    DivIcon, 
    DivServices, 
    DivProfile, 
    ProposalBottom, 
    ProposalTop, 
    ProposalWrapper, 
    DivProcess,
    DivConclusion, 
    DivProfessional,
    DivValue
} from "./styles";

// Libraries
import Moment from 'react-moment';
import 'moment/locale/pt-br';

// Context
import { useAuth } from "../../../contexts/UserContext";


export default function ProposalCard({proposal}: any) {
    const history = useHistory();
    const { user } = useAuth();
    const [ proposalOpen, setProposalOpen] = useState<any>(false);
    const [ selected, setSelected ] = useState<any>('');
    const [ filteredSectors, setFilteredSectors ] = useState<any>('');

    function handleOpenMenu(item: any) {
        setSelected(item);
        setProposalOpen(!proposalOpen);
    }

    function editProposal(value: any) {
        history.push(`/propostas/${value}`)
    }

    useEffect(() => {

        const sectors: string[] = []

        setFilteredSectors(sectors);

    }, [])

    return (
        <Container>

            <ProposalWrapper
                menuOpen={proposalOpen}
                key={proposal.id_proposta}
            >
                {
                    user.profile === 'comum' &&   
                    <>
                        <ProposalTop>
                            <DivIcon>
                                <IconSquarePlus />
                            </DivIcon>

                            <DivServices>
                                <div className="title">Serviço:</div>
                                <div className="subtitle">{proposal.descricao_servico_completo}</div>
                            </DivServices>

                            <DivConclusion>
                                <div className="title">Prazo de conclusão:</div>
                                <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{proposal.prazo_para_conclusao}</Moment></div>
                            </DivConclusion>  

                            <DivValue>
                                <div className="title">Valor:</div>
                                <div className="subtitle">
                                    {   
                                        proposal?.valor_profissional !== null 
                                        ?
                                            proposal?.valor_profissional.toString().includes('.')
                                                ? <div className="value">R$ {proposal?.valor_profissional.toString().replace('.', ',')}</div>
                                                : <div className="value">R$ {proposal?.valor_profissional},00</div>
                                        : 'R$ 0,00'
                                    }  
                                </div>
                            </DivValue>                      

                            <DivCode>
                                <div className="title">Código:</div>
                                <div className="subtitle">#{proposal.id_proposta}</div>
                            </DivCode>

                            <DivProfile
                                onClick={() => editProposal(proposal.id_proposta)}
                            >
                                <IconEditProfile />
                            </DivProfile>

                            <DivArrow
                                onClick={() => handleOpenMenu(proposal.id_proposta)}
                            >
                                {
                                    proposalOpen
                                        ? <IconProfileButtonUp />
                                        : <IconProfileButtonDown />
                                }
                            </DivArrow>
                        </ProposalTop>
                
                        <ProposalBottom
                            menuOpen={proposalOpen}
                        >
                            <DivIcon>
                            </DivIcon>

                            <DivProcess>
                                <div className="title">Nº do processo:</div>
                                <div className="subtitle">{proposal.numero_processo}</div>
                            </DivProcess>
                            {
                                proposal.status === 'Pendente' &&
                                <DivConclusion>
                                    <div className="title">Prazo de aceite:</div>
                                    <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{proposal.prazo_aceite}</Moment></div>
                                </DivConclusion>
                            }

                            {
                                proposal.status !== 'Pendente' &&
                                <DivConclusion>
                                    <div className="title">Encerrada em:</div>
                                    <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{proposal.modificado}</Moment></div>
                                </DivConclusion>
                            }

                            <DivValue>
                                <div className="title">Comarca:</div>
                                <div className="subtitle">{proposal.uf_comarca} - {proposal.cidade_comarca}</div>
                            </DivValue>

                            <DivCode>                            
                            </DivCode>

                            <DivProfile
                                className="off"
                            >
                            </DivProfile>

                            <DivArrow className="fake">
                            </DivArrow>
                        </ProposalBottom>
                    </>                 
                }

                {
                    user.profile !== 'comum' &&
                    <ProposalTop>
                        <DivIcon>
                            <IconSquarePlus />
                        </DivIcon>

                        <DivServices>
                            <div className="title">Serviço:</div>
                            <div className="subtitle">{proposal.descricao_servico_completo}</div>
                        </DivServices>

                        <DivProfessional>
                            <div className="title">Comarca:</div>
                            <div className="subtitle">{proposal.uf_comarca} - {proposal.cidade_comarca}</div>
                        </DivProfessional>

                        <DivConclusion>
                            <div className="title">Profissional:</div>
                            <div className="subtitle">
                                {
                                    proposal?.nome_profissional 
                                    ? proposal?.nome_profissional.split(' ')[0].replace('>', '').toLowerCase()
                                    : 'Nome'
                                } - D
                                {
                                    proposal.id_usuario
                                }
                            </div>       
                            <div className="show-name">{proposal?.nome_profissional?.replace('>', '')}</div>                     
                        </DivConclusion>

                        <DivProfessional>
                            <div className="title">Prazo de conclusão:</div>
                            <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{proposal.prazo_para_conclusao}</Moment></div>
                        </DivProfessional>

                        <DivCode>
                            <div className="title">Código:</div>
                            <div className="subtitle">#{proposal.id_proposta}</div>
                        </DivCode>                    

                        <DivProfile
                            onClick={() => editProposal(proposal.id_proposta)}
                        >
                            <IconEditProfile />
                        </DivProfile>

                        <DivArrow
                            onClick={() => handleOpenMenu(proposal.id_proposta)}
                        >
                            {
                                proposalOpen
                                    ? <IconProfileButtonUp />
                                    : <IconProfileButtonDown />
                            }
                        </DivArrow>
                    </ProposalTop>
                }

                {
                    user.profile !== 'comum' &&
                    <ProposalBottom
                        menuOpen={proposalOpen}
                    >
                        <DivIcon>
                        </DivIcon>

                        <DivServices>
                            <div className="title">Nº do processo:</div>
                            <div className="subtitle">{proposal.numero_processo}</div>
                        </DivServices>

                        <DivProfessional>
                            <div className="title">Prazo de aceite:</div>
                            <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{proposal.prazo_aceite}</Moment></div>
                            {/* <div className="title">Prazo de conclusão:</div>
                            <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{proposal.prazo_para_conclusao}</Moment></div> */}
                        </DivProfessional>

                        <DivProfessional>                            
                        </DivProfessional>

                        <DivConclusion>
                        </DivConclusion>                    
                        
                        <DivProfit>
                            <div className="title">Lucro:</div>
                            {   
                                proposal?.lucro_porcentagem.includes('-')
                                ?   <div className="subtitle negative">
                                        {proposal.lucro_porcentagem.split('.')[0]}%
                                    </div>
                                :   <div className="subtitle">
                                        {proposal.lucro_porcentagem.split('.')[0]}%
                                    </div>
                            }
                        </DivProfit>

                        <DivProfile 
                            className="off"
                        >                        
                        </DivProfile>

                        <DivArrow className="fake">                        
                        </DivArrow>
                    </ProposalBottom>
                }

            </ProposalWrapper>

        </Container>
    )
}