import styled from "styled-components";

interface PageProps {
  pageType: any,
}

export const Container = styled.div<PageProps>`
  margin-bottom: 2rem;
  position: relative;

  .back {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    svg {
      path {
        fill: ${({ theme }) => theme.colors.primaryBlue500};
      }
    }
  }

  h1 {
    font-size: ${({ pageType }) => pageType === 'special' ? '2.5rem' : '1.5rem'};
    font-weight: ${({ pageType }) => pageType === 'special' ? '400' : '500'};
    color: ${({ theme }) => theme.colors.interfaceText400};
    text-transform: capitalize;

    &::after {
      content: "";
      width: 20px;
      height: 2px;
      border-radius: 4px;
      background-color: #4361CD;
      position: absolute;
      bottom: 5px;
    }
  }

  @media (max-width: 1000px) {
      width: 1200px;
  }
`;
