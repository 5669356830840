import styled from "styled-components";

interface SelectedProps {
    className?: any,
}

export const Container = styled.div<SelectedProps>`
    display: flex;
    align-items: center;
    justify-content: center;    
    gap: 16px;
`

export const ButtonCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 248px;
    height: 112px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 16px;
    border: 4px solid #FFF;
    color: ${({ theme }) => theme.colors.interfaceText400};
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    &.selected {
        border: 4px solid #FFA41F;
    }    
`




