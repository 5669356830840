import styled from "styled-components";

interface MenuProps {
    display?: boolean,
    userType?: any,
    menuSidebar?: boolean,
}

export const Container = styled.div<MenuProps>`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 176px;
    height: fit-content;
    background-color: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 16px;
    position: absolute;
    right: ${({ menuSidebar }) => (!menuSidebar ? "-185px" : "-170px")};
    top: ${({ menuSidebar }) => (!menuSidebar ? "-150px" : "-140px")};
    z-index: 99;
    filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
`

export const Button = styled.button<MenuProps>`
    display: flex;
    align-items: center;        
    gap: 10px;
    width: 144px;
    height: 40px;
    color: ${({ theme }) => theme.colors.interfaceText400};
    background: transparent;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px;
    &:hover {
        background: ${({ theme }) => theme.colors.background};
    }
`

export const Divider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
`