// React
import { useEffect, useState } from "react";

// Components
import Header from "../../../components/HeaderSite";
import InputDefault from "../../../components/form/InputDefault";
import Footer from "../../../components/Footer";

// Styles
import { AreaHeader, AreaImageContact, AreaSite, ContactForm, Container, Description, FormButton, HeaderWrapper, Image } from "./styles";

// Images
import ContactImage from "../../../assets/images/ContactSite.svg";

// Libraries
import ReCAPTCHA from "react-google-recaptcha";
import api from "../../../services/api";
import toast, { Toaster } from 'react-hot-toast';

interface ContactFormProps {
    nome?: string,
    email?: string,
    telefone?: string,
    mensagem?: string,
}

export default function Contact() {

    const [ token, setToken ] = useState<any>();
    const [ disable, setDisable ] = useState<boolean>();
    const [ contactSent, setContactSent] = useState<boolean>(false);
    const [ DTOContact, setDTOContact ] = useState<ContactFormProps>({
        nome: '',
        email: '',
        telefone: '',
        mensagem: '',
    });

    useEffect(() => {
        function scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        scrollTop();
        
    }, []);

    useEffect(() => {

        if (token !== undefined) {

            setDisable(false);

        } else {
            
            setDisable(true);
            
        }

    }, [token])


    async function handleSubmit(e: any) {
        e.preventDefault();

        try {

            const response = await api.post('site/contato/enviar', DTOContact);

            if (response.data.status !== 'success') {

                throw new Error(response.data.message);

            } else {
                toast.success('Contato enviado com sucesso.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                setContactSent(true);                
            }            
            
        } catch (error: any) {

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }

    function handleOnChange(name: any, value: any) {
        
        const newDTO: any = DTOContact
        newDTO[name] = value
        setDTOContact({ ...newDTO })

    }

    return (
        <Container>
            <AreaHeader>
                <Header />
            </AreaHeader>

            <HeaderWrapper>
                <AreaSite>
                    <AreaImageContact>
                        <Image src={ContactImage} />
                        <div className='square'></div>
                    </AreaImageContact>

                    <Description>
                        {
                            !contactSent &&
                            <ContactForm onSubmit={handleSubmit}>
                                <div className='first'>
                                    <InputDefault
                                        title='Nome completo:'
                                        name='nome'
                                        value={DTOContact?.nome}
                                        onChangeText={(value) => handleOnChange('nome', value)}
                                        placeholder='Digite seu nome'
                                    />
                                    <InputDefault
                                        title='Telefone para contato:'
                                        name='telefone'
                                        mask="(99) 99999-9999"
                                        value={DTOContact?.telefone}
                                        onChangeText={(value) => handleOnChange('telefone', value)}
                                        placeholder='(00) 00000-0000'
                                    />
                                </div>

                                <div className='mail'>
                                    <InputDefault
                                        title='Email:'
                                        name='email'
                                        value={DTOContact?.email}
                                        onChangeText={(value) => handleOnChange('email', value)}
                                        placeholder='digiteseuemail@mail.com'
                                    />
                                </div>

                                <div className='message'>
                                    <p className='title'>Sua mensagem:</p>
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        placeholder="Digite sua mensagem"
                                        defaultValue={DTOContact?.mensagem}
                                        onChange={(e: any) => handleOnChange('mensagem', e.target.value)}
                                    />
                                </div>

                                <div className='buttons'>
                                    <div className='captcha'>
                                        <ReCAPTCHA 
                                            sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                                            onChange={(value: any) => setToken(value)}                                       
                                        />
                                    </div>
                                    <FormButton
                                        disabled={disable}
                                    >
                                        Enviar
                                    </FormButton>
                                </div>
                            </ContactForm>
                        }

                        {
                            contactSent &&
                            <div className="sent">
                                <div className="title">
                                    Agradecemos <br />
                                    pela mensagem!
                                </div>
                                <div className="subtitle">
                                    Nossa equipe entrará em contato <br />
                                    assim que possível! :)
                                </div>
                            </div>
                        }

                    </Description>
                </AreaSite>
            </HeaderWrapper>

            <Footer />
            
            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}