
import { PropsDate } from '../../../pages/Dashboard';
import { IconExternalLink } from '../../icons';
import GraficCard from '../GraficCard';

import { 
  Container,
  ContentInfo,
  HeaderInfo,
  SectionInfo,
  SectionGrafic,
  TotalsCardServices,
  SectionGrid,
} from './styles';

interface Props {
  data?: PropsDate;
  graphic?: any,
}

export default function CardServices({data, graphic}: Props) {

  return (
    <Container>
      <ContentInfo>
        <HeaderInfo>
          <div className="iconCardService">
            {data?.icon}
          </div>

          <button type='button' className='cardServiceButtonExternal'>
            <IconExternalLink />
          </button>
        </HeaderInfo>

      <SectionGrid>
        <SectionInfo>
          {
            data?.id !== 5 &&
            <>
              <h1 className='Title'>{data?.title}</h1>
              <span className="Paragraph">{data?.subtitle}</span>
            </>
          }

          {
            data?.id === 5 &&
            <>
              <h1 className='Title delayed'>{data?.title}</h1>
              <span className="Paragraph">{data?.subtitle}</span>
            </>
          }

          <TotalsCardServices>
            {
              data?.id === 1 &&
              <div className='dark-blue'>
                {data?.total} <span className='Paragraph'>{data?.description}</span>
              </div>
            }

            {
              data?.id === 2 &&
              <div className='green'>
                {data?.total} <span className='Paragraph'>{data?.description}</span>
              </div>
            }

            {
              data?.id === 3 &&
              <div className='light-blue'>
                {data?.total} <span className='Paragraph'>{data?.description}</span>
              </div>
            }

            {
              data?.id === 4 &&
              <div className='orange'>
                {data?.total} <span className='Paragraph'>{data?.description}</span>
              </div>
            }

            {
              data?.id === 5 &&
              <div className='red'>
                {data?.total} <span className='Paragraph'>{data?.description}</span>
              </div>
            }

            {
              data?.id === 6 &&
              <div className='success'>
                {data?.total?.replace('.',',')}
              </div>
            }
         
          </TotalsCardServices>
        </SectionInfo>

        {data?.isgrafic && (
          <SectionGrafic>
            <GraficCard info={graphic} />            
          </SectionGrafic>
        )}
      </SectionGrid>

      </ContentInfo>

    </Container>
  )
}
