/* eslint-disable react-hooks/exhaustive-deps */

// React //
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components //
import OperatorCard from "../../components/Ui/OperatorCard";
import ProfessionalsFilter from "../../components/Ui/ProfessionalsFilter";
import OperatorsTab from "../../components/Ui/OperatorTabs";
import ButtonIcon from "../../components/form/ButtonIcon";

// Services //
import api from "../../services/api";
import { useAuth } from "../../contexts/UserContext";

// Icons //
import { 
    IconAdvancePagination, 
    IconBackPagination, 
    IconButtonArrowDown, 
    IconClose, 
    IconOperator, 
    IconSearch 
} from "../../components/icons";

// Libraries //
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import { Oval } from 'react-loader-spinner';

// Styles //
import { 
    Container, 
    Filter, 
    Menu, 
    Paginate 
} from "./styles";

interface Pagination {
    currentPage: string,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number,
}

export default function Operators(props: any) {
    const history = useHistory();
    const { setPageName, setPageType } = useAuth();
    const [ loading, setLoading ] = useState<any>(false);
    const [ selected, setSelected ] = useState('1');
    const [ activeTotal, setActiveTotal ] = useState<any>();
    const [ inactiveTotal, setInactiveTotal ] = useState<any>();
    const [ operatorActives, setOperatorsActives ] = useState<any>();
    const [ profilesInactives, setOperatorsInactives ] = useState<any>();
    const [ filterProfiles, setFilterProfiles ] = useState<any>([]);
    const [ searchProfiles, setSearchProfiles ] = useState<any>();
    const [ openIslands, setOpenIslands ] = useState<boolean>(false);
    const [ dataIslands, setIslands ] = useState<any>();
    const [ islandSelected, setIslandSelected ] = useState<any>();
    const [ islandNameSelected, setIslandNameSelected ] = useState<any>();
    const [ openSector, setOpenSector ] = useState<boolean>(false);
    const [ sectorSelected, setSectorSelected ] = useState<any>();
    const [ paginateProfileActive, setPaginateOperatorsActive ] = useState<Pagination>()
    const [ paginateProfileInactive, setPaginateOperatorsInactive ] = useState<Pagination>();
    
    
    async function getOperatorsActives(page: any) {
        try {

            if (searchProfiles === undefined) {
                setSearchProfiles('')
            } 
            if (islandSelected === undefined) {
                setIslandSelected('')
            }
            if (sectorSelected === undefined) {
                setSectorSelected('')
            }

            setLoading(true)
            const response = await api.get(`operador?id_ilha=${islandSelected}&limit=10&ordem=&nome=${searchProfiles}&status=ativo&setor=${sectorSelected}&page=${page}`)
            setActiveTotal(response.data.result.pagination.total);
            setOperatorsActives(response.data.result.data);
            setPaginateOperatorsActive(response.data.result.pagination);

            if (response.data.status !== 'success') {
                throw new Error('Não foi possível encontrar perfis ativos.')
            }

            setLoading(false)

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            setLoading(false)
        }
    };

    async function getOperatorsInactive(page: any) {
        try {

            if (searchProfiles === undefined) {
                setSearchProfiles('')
            }
            if (islandSelected === undefined) {
                setIslandSelected('')
            }
            if (sectorSelected === undefined) {
                setSectorSelected('')
            }

            setLoading(true)
            const response = await api.get(`operador?id_ilha=${islandSelected}&limit=10&ordem=&nome=${searchProfiles}&status=inativo&setor=${sectorSelected}&page=${page}`)
            setInactiveTotal(response.data.result.data.length);
            setOperatorsInactives(response.data.result.data);
            setPaginateOperatorsInactive(response.data.result.pagination);

            if (response.data.status !== 'success') {
                throw new Error('Não foi possivel encontrar perfis inativos.')
            }

            setLoading(false)

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            setLoading(false);
        }

    };
    
    useEffect(() => {

        setPageName('Operadores');
        setPageType('normal');

        if(searchProfiles === undefined) {
            setSearchProfiles('')
        } else if (islandSelected === undefined) {
            setIslandSelected('')
        } else if (sectorSelected === undefined) {
            setSectorSelected('')
        } else {

            getOperatorsActives(1);
            getOperatorsInactive(1);

        }

    }, [selected, searchProfiles, sectorSelected, islandSelected])

    useEffect(() => {

        async function getIslands() {
            try {

                setLoading(true)
                const response = await api.get('/ilha')
                setIslands(response.data.result)
                
                if (response.data.status !== 'success') {
                    throw new Error('Não foi possível encontrar ilhas.')
                }

                setLoading(false)

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                setLoading(false)
            }
        }

        getIslands();

    }, [filterProfiles])   
    
    function setIslandFilter(value: any, name: any) {
        setIslandSelected(value)
        setIslandNameSelected(name)
    }

    const onClickPage = (name: any, selectedItem: { selected: number; }) => {

        let pageNumber = selectedItem.selected + 1

        if (name === '1') {
            getOperatorsActives(pageNumber)
        }
        if (name === '2') {
            getOperatorsInactive(pageNumber)
        }

    }

    function clearAllFilters() {
        setFilterProfiles([]);
        setSectorSelected('');
        setIslandSelected('');
    }

    function removeEspecificValueFromFilters(value: string) {
        const arrayFiltered = filterProfiles;
        const index = arrayFiltered.indexOf(value);
        const newArray = arrayFiltered.splice(index, 1);
        console.log('log do novo array', newArray, arrayFiltered)
        setFilterProfiles(arrayFiltered)        
    }

    return (
        <Container>

            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            <ButtonIcon
                Icon={<IconOperator />}
                children={'Novo operador'}
                styles="op"
                onClick={() => history.push('/cadastrar/operador')}
            />
            
            <Menu>
                <div className="initial">
                    <OperatorsTab
                        selected={selected}
                        setSelected={setSelected}                    
                        active={activeTotal}
                        inactive={inactiveTotal}
                    />  
                </div>

                <ProfessionalsFilter
                    filters={filterProfiles}
                    setFilters={setFilterProfiles}
                    setSearchProfiles={setSearchProfiles}
                    setClean={clearAllFilters}
                />
            </Menu>

            <Filter>
                {
                    filterProfiles.length > 0 &&
                    <>
                        <div className="results">
                            {
                                filterProfiles !== undefined && selected === '1' &&
                                `${ operatorActives?.length} resultados:`
                            }
                            {
                                filterProfiles !== undefined && selected === '2' &&
                                `${profilesInactives?.length} resultados:`
                            }
                        </div>
                    </>

                }

                <div className="buttons">
                    {   
                        filterProfiles.includes('ilhas') &&
                        <div
                            className="island"
                            onClick={() => setOpenIslands(!openIslands)}
                        >
                            Ilhas <IconButtonArrowDown />
                            <div className={openIslands ? 'island-map' : "island-map no-show"}>
                                <div className="filter">
                                    Filtrar por...
                                    <div className="button-filter">
                                        <IconSearch />
                                    </div>
                                </div>

                                {
                                    dataIslands.map((row: any, key: any) => (
                                        <div
                                            className="filters"
                                            key={key}
                                            onClick={() => setIslandFilter(row.id_ilha, row.descricao)}
                                        >
                                            {row.descricao}
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }
                    {
                        islandSelected &&
                        <div className="island-selected">
                            <div>Ilhas:</div>
                            <div>{islandNameSelected}</div>
                            <div
                                className="close-icon"
                                onClick={() => {
                                    setIslandSelected('');
                                    removeEspecificValueFromFilters('ilhas')
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }
                    {
                        filterProfiles.includes('setor') &&
                        <div
                            className="sector"
                            onClick={() => setOpenSector(!openSector)}
                        >
                            Setor <IconButtonArrowDown />
                            <div className={openSector ? 'sector-map' : 'sector-map no-show'}>
                                <div
                                    className="filters"
                                    onClick={() => setSectorSelected('audiencias')}
                                >
                                    Audiências
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSectorSelected('diligencias')}
                                >
                                    Diligências
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSectorSelected('protocolos')}
                                >
                                    Protocolos
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSectorSelected('peticoes')}
                                >
                                    Petições
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSectorSelected('acautelamentos')}
                                >
                                    Acautelamentos
                                </div>
                            </div>

                        </div>
                    }
                    {
                        sectorSelected &&
                        <div className="sector-selected">
                            <div>Setor: </div>
                            <div>{sectorSelected}</div>
                            <div
                                className="close-icon"
                                onClick={() => {
                                    setSectorSelected('');
                                    removeEspecificValueFromFilters('setor')
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }
                    
                </div>
            </Filter>

            {
                selected === '1' &&  operatorActives?.length > 0 && loading === false &&
                 operatorActives?.map((row: any) => (
                    <OperatorCard
                        profile={row}
                        key={row.id_operador}
                    />
                ))

            }

            {
                selected === '2' && profilesInactives?.length > 0 && loading === false &&
                profilesInactives?.map((row: any) => (
                    <OperatorCard
                        profile={row}
                        key={row.id_operador}
                    />

                ))

            }

            {
                selected === '1' && paginateProfileActive &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('1', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={paginateProfileActive.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {
                selected === '2' && paginateProfileInactive &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('2', value)}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={1}
                        pageCount={paginateProfileInactive.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}