import styled from "styled-components";

export const Wrapper = styled.div`

    .services-form {
        .services-top {
            display: flex;
            align-items: center;
            height: 72px;
            gap: 16px;
            margin-bottom: 32px;

            .big {
                width: 100%;
            }

            button {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 10px;
                padding-bottom: 5px;
                background: none;
                border: none;
                width: 320px;
                height: 48px;
                font-weight: 400;
                font-size: 16px;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .line.services {
            border-top: 1px dashed rgba(0,0,0,0.1);
            padding-top: 20px;
        }
        .services-hearing {
            margin-bottom: 32px;
            .hearing-title {
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 15px;
            }

            .hearing-fields {
                display: flex;
                gap: 16px;

                .small {
                    width: 32%;
                }
                .mid {
                    width: 100%;
                }
            }
        }

        .dot-border {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 48px;
            border: 1px dashed ${({ theme }) => theme.colors.primaryOrange200};
            border-radius: 8px;
            margin-bottom: 56px;
            padding: 12px;
            cursor: pointer;      
        }

        .footer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;
            font-size: 18px;
        }
    }

    @media (max-width: 600px) {
        max-width: 600px;        
    }
`

export const ModalPrecification = styled.div`
    background: ${({ theme }) => theme.colors.interfacePure0};
    width: 520px;
    padding: 8px;
    
    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
        padding-bottom: 25px;

        p {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-weight: 700;
            font-size: 20px;
        }

        .close {
            cursor: pointer;
        }
    }

    .main {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        margin-top: 25px;

        
    }
`