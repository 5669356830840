import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh; 
    background: ${({ theme }) => theme.colors.primaryBlue600};
`

export const Header = styled.div`
    width: 1040px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;     
`

export const Main = styled.div`
    width: 1040px;
    min-height: 550px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;

    .image {
        width: 445px;
    }

    .status {
        height: 336px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 48px;        

        .status-title {
            width: 100%;
            color: ${({ theme }) => theme.colors.interfacePure0};
            font-size: 40px;
            font-weight: 400;
            line-height: 150%;
            margin-bottom: 16px;
            text-transform: capitalize;

            span {
                font-size: 40px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.primaryOrange600};
            }
        }

        .status-subtitle {
            max-width: 500px;
            color: ${({ theme }) => theme.colors.interfacePure0};
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin-bottom: 32px;
            span {
                font-weight: 700;                
            }
        }

        .buttons {
            display: flex;
            width: 424px;
            gap: 16px;
            .new {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 248px;
                height: 40px;
                border: 2px solid ${({ theme }) => theme.colors.primaryBlue100};
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfacePure0};
                transition: all .3s;
                cursor: pointer;
                &:hover {
                    transform: scale(1.05);
                }
            }

            .continue {
                max-width: 160px;
                height: 40px;
            }
        }
    }

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;
        position: absolute;
        top: 100%;

        .progressbar {
            height: 8px;
            width: 172px;
            border-radius: 8px;
            background: ${({theme}) => theme.colors.secondaryGreen400};
            position: relative;
        }

        .bar-title {
            color: ${({theme}) => theme.colors.white};
            font-size: 14px;
            font-weight: 400;

            span {
                font-weight: 700;
            }
        }
    }
`