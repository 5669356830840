/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AuthProvider, useAuth } from "./contexts/UserContext";
import Routes from "./routes";
import { GlobalStyles } from "./styles/Global";
import { comum, admin, operador } from "./styles/Themes/light";

function App() {
  const [tema, setTema] = useState<any>("comum");
  

  useEffect( () => {
    getUser()
   
  }, [tema, comum, admin]);

  async function getUser() {
    var storedArray = await localStorage.getItem("@Jj:user");
    if (storedArray === null) {
      return setTema("comum");
    }

    const ourArray = JSON.parse(storedArray as any);
    setTema(ourArray?.profile);
    
  }

  // console.log(tema, 'tema')


  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <ThemeProvider
            theme={
              (tema === "comum" && comum) || (tema === "admin" && admin) || (tema === 'operador' && operador)
            }
          >
            <Routes setTema={setTema}/>
          </ThemeProvider>
        </AuthProvider>
      </BrowserRouter>
      <GlobalStyles />
    </>
  );
}

export default App;
