/* eslint-disable react-hooks/exhaustive-deps */

// React
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Libraries
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";
import moment from "moment";
import 'moment/locale/pt-br';
import DateTimePicker from 'react-datetime-picker';

// Components
import InputDefault from "../../components/form/InputDefault";
import ButtonDefault from "../../components/form/ButtonDefault";
import SearchProfessionalProposal from "../../components/SearchProfessionalProposal";
import DeadlineProposal from "../../components/DeadlineProposal";
import InputMoney from "../../components/form/InputMoney";
import ServicesTags from "../../components/ServicesTags";

// Icons
import { 
    IconAsteristik, 
    IconDetails, 
    IconLogoJuridico, 
    IconProfessional, 
    IconServices,
    IconSquarePlus,  
} from "../../components/icons";

// Styles
import { Container, Footer, Header, Hr1, Main } from "./styles";

// Data
import { ModalityAudience } from '../../components/Data/ModalityAudience'
import { EstadosBrasileiros } from "../../components/Data/StatesData";
import { CivilHearing } from "../../components/Data/CivilHearing";
import { CriminalHearing } from "../../components/Data/CriminalHearing";
import { LaborHearing } from "../../components/Data/LaborHearing";
import { AnotherHearing } from "../../components/Data/AnotherHearing";
import { ProfessionalTypeNecessary } from "../../components/Data/ProfessionalTypeNecessary";
import { NotariesTypes } from "../../components/Data/Notaries";

// Services
import api from "../../services/api";
import axios from "axios";

interface Proposal {
    id_proposta: any,
    id_usuario: any,
    id_servico: any,
    id_comarca: any,
    tags: any,
    arquivos: any,
    numero_processo: string,
    nome_cliente: string,
    valor_cliente: string,
    codigo_cliente: string,
    prazo_aceite: string,
    prazo_para_conclusao: string,
    dias_uteis_para_conclusao: string,
    orientacoes: any,
    tipo_audiencia: string,
    data: string,
    profissional_necessario: string,
    modalidade: string,
    endereco_virtual: string,
    rua: string,
    bairro: string,
    cep: string,
    numero: string,
    complemento: string,
    tipo_cartorio: string,
    pagina_inicial: string,
    pagina_final: string,
    valor_profissional?: string,    
    cidade_comarca?: string,
}

export interface LocationParams {
    title: string,
    type: string,
    pathname: string,
    state: any,
    search: string,
    hash: string,
    key: string,
    name: string,
    proposal: any,
}

export default function HearingForms() {
    const location = useLocation<LocationParams>();
    
    const history = useHistory();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ activePage, setActivePage ] = useState<number>(1);
    const [ advancePageError, setAdvancePageError ] = useState<any>();
    const [ dateError, setDateError ] = useState<any>();
    const [ DTOProposal, setDTOProposal ] = useState<Proposal>({
        id_proposta: '',
        id_usuario: '',
        id_servico: '',
        id_comarca: '',
        tags: '',
        arquivos: '',
        numero_processo: '',
        nome_cliente: '',
        valor_cliente: '',
        codigo_cliente: '',
        prazo_aceite: '',
        prazo_para_conclusao: '',
        dias_uteis_para_conclusao: "",
        orientacoes: '',
        tipo_audiencia: '',
        data: '',
        profissional_necessario: '',
        modalidade: '',
        endereco_virtual: '',
        rua: '',
        bairro: '',
        cep: '',
        numero: '',
        complemento: '',
        tipo_cartorio: '',
        pagina_inicial: '',
        pagina_final: '',
        valor_profissional: '',
    });
    const [ formTitle, setFormTitle ] = useState<any>();
    const [ proposalType, setProposalType ] = useState<any>();
    const [ dateHearing, setDateHearing ] = useState(new Date());
    const [ counties, setCounties ] = useState<any>();
    const [ tagsSelecteds, setTagSelecteds ] = useState<any>();
    const [ countieProps, setCountieProps ] = useState<any>();
    const [ nameProfessional, setNameProfessional ] = useState<any>();
    const [ deadline, setDeadline ] = useState<any>();
    const [ reuseProposal, setReuseProposal ] = useState<any>();
    const [ oldProposalDate, setOldProposalDate ] = useState(new Date());

    let cepData = {
        uf: '',
        localidade: '',
        bairro: '',
        logradouro: '',
    }

    const states = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.uf ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.id_comarca ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    const hearing = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.tipo_audiencia ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    const modalities = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.modalidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    const professional = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError?.profissional_necessario ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  
    
    useEffect(() => {   
        // console.log('log da proposta duplicada', location.state.proposal);

        setFormTitle(location.state.title);
        setProposalType(location.state.type);        

        if (location.state.title === 'Comparecimento em cartório') {
            handleOnChange('id_servico', 6)
        }

        if (location.state.title === 'Cópia parcial') {
            handleOnChange('id_servico', 9)
        }

        if (location.state.title === 'Cópia integral') {
            handleOnChange('id_servico', 8)
        }

        if (location.state.title === 'Acautelamento') {
            handleOnChange('id_servico', 13)
        }

        if (location.state.title === 'Acompanhamento de atos oficiais') {
            handleOnChange('id_servico', 5)
        }

        if (location.state.title === 'Despacho') {
            handleOnChange('id_servico', 10)
        }

        if (location.state.title === 'Retirada de ofício e alvará') {
            handleOnChange('id_servico', 11)
        }

        if (location.state.title === 'Diligência') {
            handleOnChange('id_servico', 12)
        }

        if (location.state.title === 'Protocolo eletrônico') {
            handleOnChange('id_servico', 14)
        }

        if (location.state.title === 'Protocolo físico') {
            handleOnChange('id_servico', 15)
        }

        if (location.state.title === 'Protocolo postal') {
            handleOnChange('id_servico', 16)
        }

        if (location.state.title === 'Petições') {
            handleOnChange('id_servico', 22)
        }

        if (location.state.title === 'Outro Serviço') {
            handleOnChange('id_servico', 21)
        }

        if (location.state.name === 'Reuse') {
            setDTOProposal({
                id_proposta: location.state.proposal.id_proposta,
                id_usuario: location.state.proposal.id_usuario,
                id_servico: location.state.proposal.id_servico,
                id_comarca: location.state.proposal.id_comarca,
                tags: location.state.proposal.tags,
                arquivos: location.state.proposal.arquivos,
                numero_processo: location.state.proposal.numero_processo,
                nome_cliente: location.state.proposal.nome_cliente,
                valor_cliente: location.state.proposal.valor_cliente.toFixed(2),
                codigo_cliente: location.state.proposal.codigo_cliente,
                prazo_aceite: location.state.proposal.prazo_aceite,
                prazo_para_conclusao: location.state.proposal.prazo_para_conclusao,
                dias_uteis_para_conclusao: location.state.proposal.dias_uteis_para_conclusao,
                orientacoes: location.state.proposal.orientacoes[0],
                tipo_audiencia: location.state.proposal.tipo_audiencia,
                data: location.state.proposal.data,
                profissional_necessario: location.state.proposal.profissional_necessario,
                modalidade: location.state.proposal.modalidade,
                endereco_virtual: location.state.proposal.endereco_virtual,
                rua: location.state.proposal.rua,
                bairro: location.state.proposal.bairro,
                cep: location.state.proposal.cep,
                numero: location.state.proposal.numero,
                complemento: location.state.proposal.complemento,
                tipo_cartorio: location.state.proposal.tipo_cartorio,
                pagina_inicial: location.state.proposal.pagina_inicial,
                pagina_final: location.state.proposal.pagina_final,
                valor_profissional: location.state.proposal.valor_profissional.toFixed(2),
            });
            setTagSelecteds(location.state.proposal.tags)

            getCountiesById();
            setReuseProposal(true);           

            if (location.state.proposal.descricao_servico_completo.includes('Audiências Trabalhista')) {
                setFormTitle('Audiência Trabalhista');
                setProposalType('Audiência');
            }

            if (location.state.proposal.descricao_servico_completo.includes('Audiências Cível')) {
                setFormTitle('Audiência Cível');
                setProposalType('Audiência');
            }

            if (location.state.proposal.descricao_servico_completo.includes('Audiências Penal')) {
                setFormTitle('Audiência Penal');
                setProposalType('Audiência');
            }

            if (location.state.proposal.descricao_servico_completo.includes('Audiências Outros')) {
                setFormTitle('Audiências');
                setProposalType('Audiência');
            }

            if (location.state.proposal.descricao_servico_completo.includes('Protocolos')) {
                setFormTitle('Protocolos');
            }

            if (location.state.proposal.descricao_servico_completo.includes('Diligências')) {
                setFormTitle('Diligencia');
            }

            setOldProposalDate(new Date(location.state.proposal.data));
        }
        
    }, [formTitle, proposalType])

    useEffect(() => {
        if (DTOProposal.id_comarca !== '') {
            getCountiesById();
            // console.log('log do countie Id', DTOProposal.id_comarca)
        }        
    }, [DTOProposal.id_comarca])

    useEffect(() => {
        
        if (formTitle === 'Audiência Cível' && DTOProposal.modalidade === 'presencial') {

            handleOnChange('id_servico', 1);
            
        }

        if (formTitle === 'Audiência Cível' && DTOProposal.modalidade === 'online') {

            handleOnChange('id_servico', 17)
        }

        if (formTitle === 'Audiência Penal' && DTOProposal.modalidade === 'presencial') {
            handleOnChange('id_servico', 3)
        }

        if (formTitle === 'Audiência Penal' && DTOProposal.modalidade === 'online') {
            handleOnChange('id_servico', 19)
            
        }

        if (formTitle === 'Audiência Trabalhista' && DTOProposal.modalidade === 'presencial') {
            handleOnChange('id_servico', 2)
        }

        if (formTitle === 'Audiência Trabalhista' && DTOProposal.modalidade === 'online') {
            handleOnChange('id_servico', 18)
        }

        if (formTitle === 'Audiências' && DTOProposal.modalidade === 'presencial') {
            handleOnChange('id_servico', 4)
        }

        if (formTitle === 'Audiências' && DTOProposal.modalidade === 'online') {
            handleOnChange('id_servico', 20)       
        }  

    }, [DTOProposal.modalidade])

    function handleOnChangeMoney(name: any, value: any) {

        let newValue = value.replace(/\D/g, '');

        if (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1)           
        } else {

            let txtArray = newValue.split('')
            txtArray.splice(txtArray.length - 2, 0, ".");
            var correctedValue = txtArray.join('')

            const newDTO: any = DTOProposal
            newDTO[name] = correctedValue
            setDTOProposal({ ...newDTO })

        }        

    }

    function handleOnChange(name: any, value: any) {
        
        const newDTO: any = DTOProposal
        newDTO[name] = value
        setDTOProposal({ ...newDTO })

    }

    async function getCounties(selected: any) {
        
        try {

            const countieArray: {value: any, label: any}[] = []

            // axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selected.value}/municipios`).then(response => {
            //     response.data.map((row: any) => {
            //         countieArray.push({
            //             value: row.nome,
            //             label: row.nome
            //         })
            //     })
            //     setCounties(countieArray)
            // });
            
            const response = await api.get(`comarca?uf=${selected.value}`)
            
            if(response.data.status !== 'success') {
                throw new Error();
            }

            response.data.result.map((row: any) => (
                countieArray.push({
                    label: row.cidade,
                    value: row.id_comarca
                })
            ))            
            
            setCounties(countieArray)

        } catch (error: any) {

            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }

    }

    async function getCountiesById() {
        
        try {
            
            if (location.state.name === 'Reuse') {
                setLoading(true);

                const response = await api.get(`/comarca/${location.state.proposal.id_comarca}`)
                setCountieProps(response.data.result);
                
                if (response.data.status !== 'success') {
                    throw new Error();
                }

                setLoading(false);

            } else {
                setLoading(true);

                const response = await api.get(`/comarca/${DTOProposal.id_comarca}`)
                setCountieProps(response.data.result)            
                
                if(response.data.status !== 'success') {
                    throw new Error();
                }

                setLoading(false);
            }

            

        } catch (error: any) {

            setLoading(false);
           
            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }

    }

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    async function advancePage() {

        try {

            if (activePage === 1) {             

                const actualDate = new Date();
                
                if (
                    formTitle === 'Audiência Cível' || 
                    formTitle === 'Audiência Penal' ||
                    formTitle === 'Audiência Trabalhista' ||
                    formTitle === 'Audiências'
                ) {
                    if (!reuseProposal) {       
                        
                        if (dateHearing < actualDate) {
                            throw new Error('A data e hora da audiência precisam ser maiores que a data atual')
                        }
                        setDateError(false);
                        const date = moment(dateHearing).format("YYYY-MM-DD HH:mm:ss")
                        handleOnChange('data', date);

                    } else {

                        if (moment(oldProposalDate).format("YYYY-MM-DD HH:mm:ss") < moment(actualDate).format("YYYY-MM-DD HH:mm:ss")) {
                            throw new Error('A data e hora da audiência precisam ser maiores que a data atual')
                        }
                        setDateError(false);

                        const date = moment(oldProposalDate).format("YYYY-MM-DD HH:mm:ss")
                        handleOnChange('data', date);

                    }
    
                    const schema = yup.object().shape({
                        id_comarca: yup.string().required('Cidade é obrigatório'),
                        numero_processo: yup.string().required('Número do processo é obrigatório'),
                        valor_cliente: yup.string().required('Valor do cliente é obrigatório'),
                        codigo_cliente: yup.string().required('Código do cliente é obrigatório'),
                        nome_cliente: yup.string().required('Nome é obrigatório'),
                        tipo_audiencia: yup.string().required('Tipo de audiência é obrigatório'),
                        modalidade: yup.string().required('Modalidade é obrigatório'),
                        profissional_necessario: yup.string().required('Profissional necessário é obrigatório'),                                        
                    });
                    await schema.validate(DTOProposal, {
                        abortEarly: false,
                    });

                }      
                
                if (formTitle === 'Cópia parcial') {

                    const schema = yup.object().shape({
                        id_comarca: yup.string().required('Cidade é obrigatório'),
                        numero_processo: yup.string().required('Número do processo é obrigatório'),
                        valor_cliente: yup.string().required('Valor do cliente é obrigatório'),
                        codigo_cliente: yup.string().required('Código do cliente é obrigatório'),
                        nome_cliente: yup.string().required('Nome é obrigatório'),
                        pagina_inicial: yup.string().required('Página inicial é obrigatória'),
                        pagina_final: yup.string().required('Página final é obrigatória'),
                    });
                    await schema.validate(DTOProposal, {
                        abortEarly: false,
                    });

                }

                // const date = moment(dateHearing).format("YYYY-MM-DD HH:mm:ss")
                // handleOnChange('data', date)

                const schema = yup.object().shape({
                    id_comarca: yup.string().required('Cidade é obrigatório'),
                    numero_processo: yup.string(),
                    valor_cliente: yup.string().required('Valor do cliente é obrigatório'),
                    codigo_cliente: yup.string().required('Código do cliente é obrigatório'),
                    nome_cliente: yup.string().required('Nome é obrigatório'),                    
                });

                await schema.validate(DTOProposal, {
                    abortEarly: false,
                });

                setActivePage(activePage + 1);
            }

            if (activePage === 2) {

                handleOnChange('tags', tagsSelecteds);

                if (DTOProposal.valor_profissional === '.') {
                    handleOnChange('valor_profissional', 0)
                }
                // if (reuseProposal !== true) {
                //     handleOnChange('tags', tagsSelecteds);
                // }
                
                // Removi verificação das tags 03/07/2023
                // if (DTOProposal.tags.length < 1) {

                //     throw new Error('Não é permitido avançar sem selecionar tags')

                // }
                    
                setActivePage(activePage + 1);
            }

            if (activePage === 3) {
                
                if(DTOProposal.id_usuario === '') {
                    throw new Error('Selecione um profissional antes de avançar')
                }

                setActivePage(activePage + 1);

            }

            if (activePage === 4) {
                // Caso complete o envio de formulário e de OK
                if (DTOProposal.prazo_aceite === DTOProposal.prazo_para_conclusao) {

                    throw new Error('Os prazos não podem ser iguais');

                }

                handleSubmit();
                
            }

            scrollTop();
            setAdvancePageError({})


        } catch (err: any) {

            if (activePage === 1) {

                if (err instanceof yup.ValidationError) {
                    const errors = getValidationErrors(err);
                    console.log('log do erro cópia parcial', errors)

                    setAdvancePageError(errors)

                    toast.error('Preencha os campos corretamente.', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else {

                    setDateError(true);

                    toast.error(err.message, {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });

                }
            }

            if (activePage === 2) {
                
                toast.error(err.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }

            if (activePage === 3) {

                toast.error(err.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }

            if (activePage === 4) {

                toast.error(err.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }


        }
    }

    useEffect(() => {
        if (DTOProposal.id_usuario) {
            getUserName();
        }
    }, [DTOProposal])

    async function getUserName() {
        try {
            setLoading(true);
            const response = await api.get(`/usuario/${DTOProposal.id_usuario}`);
            setNameProfessional(response.data.result.nome);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('log error user name', error);
        }
    }

    async function handleSubmit() {

        try {
            // console.log('log do DTOProposal', DTOProposal);

            setLoading(true);

            const response = await api.post('proposta', DTOProposal)
            
            if (response.data.status !== 'success') {

                if (response.data.result.length > 0) {
                    
                    throw (response.data.result)
                    
                } else {

                    throw new Error(response.data.message);
                }
            }

            if (response.data.status === 'success') {
                history.push({
                    pathname: '/proposta/concluir',
                    state: {
                        userName: nameProfessional,
                        id: DTOProposal.id_usuario,
                        prazo: deadline,
                        proposalId: response.data.result
                    }
                })
                setLoading(false);
            }

        } catch (error: any) {            

            setLoading(false);

            if (error.length > 0) {
                error.map((row: any) => (
                    toast.error(row.message, {
                        duration: 5000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    })
                ))

            } else {
                toast.error(error.message, {
                    duration: 5000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }


        }


    }       

    async function consultCep(value: any) {
        
        try {

            if (value.replace(/[^\d]/g, '').length > 7) {
                
                handleOnChange('cep', value);
                const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
                const data = response.data
                cepData = {
                    uf: data.uf,
                    localidade: data.localidade,
                    bairro: data.bairro,
                    logradouro: data.logradouro,
                }      
                handleOnChange('rua', data.logradouro);
                handleOnChange('bairro', data.bairro);

                if (response.data.erro) throw new Error('CEP inválido, tente novamente')                

            }
            

        } catch (err: any) {

            toast.error(err.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            })
                        
        }

    }

    // useEffect(() => {
    //     if (oldProposalDate) {

    //         const date = moment(oldProposalDate).format("YYYY-MM-DD HH:mm:ss")
    //         handleOnChange('data', date)            

    //     }

    //     // if (dateHearing) {

    //     //     const date = moment(dateHearing).format("YYYY-MM-DD HH:mm:ss")
    //     //     handleOnChange('data', date)            

    //     // }

    // }, [oldProposalDate, dateHearing]);

    return (
        <Container>

            <Header>
                <div className="logo"><IconLogoJuridico /></div>

                <div className="status">
                    <Hr1
                        className={
                            activePage === 1 ? "first" : '' ||
                                activePage === 2 ? "second" : '' ||
                                    activePage === 3 ? "third" : '' ||
                                        activePage === 4 ? 'fourth' : ''
                        }
                    />
                    <div
                        className="status-square first"
                    >
                        <div className={"square active"}>
                            <IconServices />
                        </div>
                        <p className="first">Serviço</p>
                    </div>
                    <div
                        className="status-square"
                    >
                        <div className={activePage > 1 ? "square active" : 'square'}>
                            <IconDetails />
                        </div>
                        <p className={activePage > 1 ? 'on second' : 'second'} >Detalhes</p>
                    </div>
                    <div
                        className="status-square"
                    >
                        <div className={activePage > 2 ? "square active" : 'square'}>
                            <IconProfessional />
                        </div>
                        <p className={activePage > 2 ? 'on third' : 'third'} >Profissional</p>
                    </div>
                    <div
                        className="status-square"
                    >
                        <div className={activePage > 3 ? "square active" : 'square'}>
                            <IconSquarePlus />
                        </div>
                        <p className={activePage > 3 ? 'on fourth' : 'fourth'} >Proposta</p>
                    </div>
                </div>
            </Header>

            <Main>
                <div className="top">
                    {
                        activePage === 1 &&
                        <div className="one" >
                            <div className="register operator">
                                <h3>{formTitle}</h3>
                                <p>Informe os dados para localizarmos o profissional apropriado.</p>
                            </div>
                        </div>
                    }

                    {
                        activePage === 2 &&
                        <div className="two">
                            <div className="register services">
                                <h3>Descreva o serviço para o profissional!</h3>                                
                            </div>
                        </div>
                    }

                    {
                        activePage === 3 &&
                        <div className="three">
                            <div className="register permission">
                                <h3>Encontre o profissional ideal para o serviço!</h3>
                                <div className="subtitle">
                                    Selecione um profissional de acordo com a comarca, disponibilidade e valor tabelado.
                                </div>
                            </div>
                        </div>
                    }

                    {
                        activePage === 4 &&
                        <div className="four">
                            <div className="register deadlines">
                                <h3>Defina prazos para o atendimento!</h3>
                            </div>
                        </div>
                    }

                    {advancePageError && activePage === 1 &&
                        Object.keys(advancePageError).length > 0
                        ? <div>
                            <div className="attention">
                                <div className="star"><IconAsteristik /></div>
                                <span>Atenção:</span>
                                <p>Existem campos obrigatórios a serem preenchidos</p>
                            </div>
                        </div>
                        : ''
                    }

                    {advancePageError && activePage === 2 &&
                        Object.keys(advancePageError).length > 0
                        ? <div>
                            <div className="attention">
                                <div className="star"><IconAsteristik /></div>
                                <span>Atenção:</span>
                                <p>{advancePageError.ilhas}</p>
                            </div>
                        </div>
                        : ''
                    }

                    {advancePageError && activePage === 3 &&
                        Object.keys(advancePageError).length > 0
                        ? <div>
                            <div className="attention">
                                <div className="star"><IconAsteristik /></div>
                                <span>Atenção:</span>
                                <p>Selecione no mínimo 1 setor.</p>
                            </div>
                        </div>
                        : ''
                    }

                </div>

                {
                    activePage === 1 &&                    
                    <form className="register-form">
                        <div className="state">
                            {
                                !loading &&
                                <>
                                    <div className="label-state">
                                        <p>Estado:</p>
                                        <Select
                                            name="estados"
                                            styles={states}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            placeholder='Selecionar'
                                            defaultValue={countieProps ? { value: countieProps?.uf, label: countieProps?.uf } : ''}
                                            onChange={getCounties}
                                            options={EstadosBrasileiros}
                                        />
                                        <span className="error">{advancePageError?.uf ? '*' : ''}</span>
                                    </div>

                                    <div className="label-state">
                                        <p>Comarca:</p>
                                        <Select
                                            styles={city}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            name='id_comarca'
                                            placeholder="Selecionar"
                                            defaultValue={countieProps ? { value: countieProps?.cidade, label: countieProps?.cidade } : ''}
                                            options={counties}
                                            onChange={(e: any) => handleOnChange('id_comarca', e.value)}
                                            // onChange={(selected: any) => handleOnChange('cidade_comarca', selected.value)}
                                        />
                                        <span className="error">{advancePageError?.id_comarca ? '*' : ''}</span>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="process">
                            <InputDefault
                                title="Número do processo:"
                                name="numero_processo"
                                value={DTOProposal.numero_processo}
                                mask={'9999999-99.9999.9.99.9999'}
                                onChangeText={(value) => handleOnChange('numero_processo', value)}
                                className={advancePageError?.numero_processo ? 'big error' : 'big'}
                                error={advancePageError?.numero_processo ? '*' : ''}
                            />

                            <InputMoney
                                title="Valor do cliente:"
                                name="valor_cliente"
                                mask="R$ 999.999,99"
                                placeholder="R$ 0,00"
                                value={DTOProposal?.valor_cliente}
                                onChangeText={(value) => handleOnChangeMoney('valor_cliente', value)}
                                className={advancePageError?.valor_cliente ? 'small error' : 'small'}
                                error={advancePageError?.valor_cliente ? '*' : ''}
                            />
                            {
                                formTitle === 'Outro Serviço' &&
                                <InputMoney
                                    title="Valor do serviço:"
                                    name="valor_profissional"
                                    mask="R$ 999.999,99"
                                    placeholder="R$ 0,00"
                                    value={DTOProposal?.valor_profissional}
                                    onChangeText={(value) => handleOnChangeMoney('valor_profissional', value)}
                                    className={advancePageError?.valor_cliente ? 'small error' : 'small'}
                                    error={advancePageError?.valor_profissional ? '*' : ''}
                                />
                            }
                            {
                                formTitle === 'Audiências' &&
                                <InputMoney
                                    title="Valor do serviço:"
                                    name="valor_profissional"
                                    mask="R$ 999.999,99"
                                    placeholder="R$ 0,00"
                                    value={DTOProposal?.valor_profissional}
                                    onChangeText={(value) => handleOnChangeMoney('valor_profissional', value)}
                                    className={advancePageError?.valor_profissional ? 'small error' : 'small'}
                                    error={advancePageError?.valor_profissional ? '*' : ''}
                                />
                            }
                        </div>

                        <div className="name">
                            <InputDefault
                                title="Nome:"
                                name='nome'
                                value={DTOProposal.nome_cliente}
                                onChangeText={(value) => handleOnChange('nome_cliente', value)}
                                className={advancePageError?.nome_cliente ? 'big error' : 'big'}
                                error={advancePageError?.nome_cliente ? '*' : ''}
                            />

                            <InputDefault
                                title="Código do cliente:"
                                name="codigo_cliente"
                                placeholder="C0000"
                                value={DTOProposal.codigo_cliente.toLocaleUpperCase()}
                                onChangeText={(value) => handleOnChange('codigo_cliente', value)}
                                className={advancePageError?.codigo_cliente ? 'small error' : 'small'}
                                error={advancePageError?.codigo_cliente ? '*' : ''}
                            />
                        </div>
                        
                        {
                            proposalType === 'Audiência' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>audiências</span>, informe também:
                                </div>

                                <div className="subtype">
                                    <div className="label-state">
                                        <p>Subtipo:</p>
                                        {
                                            formTitle === 'Audiência Cível' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal.tipo_audiencia !== '' ? { value: DTOProposal.tipo_audiencia, label: DTOProposal.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={CivilHearing}
                                                />
                                                {/* <span className="error">{advancePageError?.tipo_audiencia ? '*' : ''}</span> */}
                                            </>
                                        }
                                        {
                                            formTitle === 'Audiência Penal' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal.tipo_audiencia !== '' ? { value: DTOProposal.tipo_audiencia, label: DTOProposal.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={CriminalHearing}
                                                />
                                                {/* <span className="error">{advancePageError?.tipo_audiencia ? '*' : ''}</span> */}
                                            </>
                                        }
                                        {
                                            formTitle === 'Audiência Trabalhista' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal.tipo_audiencia !== '' ? { value: DTOProposal.tipo_audiencia, label: DTOProposal.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={LaborHearing}
                                                />
                                                {/* <span className="error">{advancePageError?.tipo_audiencia ? '*' : ''}</span> */}
                                            </>
                                        }
                                        {
                                            formTitle === 'Audiências' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal.tipo_audiencia !== '' ? { value: DTOProposal?.tipo_audiencia, label: DTOProposal?.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={AnotherHearing}
                                                />
                                                {/* <span className="error">{advancePageError?.tipo_audiencia ? '*' : ''}</span> */}
                                            </>
                                        }

                                        <span className="error">{advancePageError?.tipo_audiencia ? '*' : ''}</span>
                                    </div>

                                    <div className="label-state">
                                        <p>Modalidade:</p>
                                        <Select
                                            name="modalidade"
                                            styles={modalities}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            placeholder='Selecionar'
                                            defaultValue={DTOProposal.modalidade !== '' ? { value: DTOProposal.modalidade, label: DTOProposal.modalidade } : ''}
                                            onChange={(selected: any) => handleOnChange('modalidade', selected.value)}
                                            options={ModalityAudience}
                                        />
                                        <span className="error">{advancePageError?.modalidade ? '*' : ''}</span>
                                    </div>
                                </div>

                                <div className="professional">
                                    <div className="label-state">
                                        <p>Profissional necessário:</p>
                                        <Select
                                            name="profissional_necessario"
                                            styles={professional}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            placeholder='Selecionar'
                                            defaultValue={DTOProposal.profissional_necessario !== '' ? { value: DTOProposal.profissional_necessario, label: DTOProposal.profissional_necessario } : ''}
                                            onChange={(selected: any) => handleOnChange('profissional_necessario', selected.value)}
                                            options={ProfessionalTypeNecessary}
                                        />
                                        <span className="error">{advancePageError?.profissional_necessario ? '*' : ''}</span>
                                    </div>
                                    {
                                        reuseProposal &&
                                        <div className="label-state">
                                            <p>Data e hora:</p>
                                            <DateTimePicker
                                                onChange={setOldProposalDate}
                                                value={oldProposalDate}
                                                calendarIcon={null}
                                                disableClock={true}
                                                className={dateError ? 'date-error' : 'date'}
                                                name="data"
                                                required={true}
                                            />
                                            <span className="error">{dateError ? '*' : ''}</span>
                                        </div>
                                    }
                                    {
                                        !reuseProposal &&
                                        <div className="label-state">
                                            <p>Data e hora:</p>
                                            <DateTimePicker 
                                                onChange={setDateHearing}                                             
                                                value={dateHearing}                                             
                                                calendarIcon={null} 
                                                disableClock={true} 
                                                className={dateError ? 'date-error' : 'date'}
                                                name="data" 
                                                required={true}                                           
                                            />
                                            <span className="error">{dateError ? '*' : ''}</span>
                                        </div>
                                    }

                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        value={cepData.logradouro || DTOProposal.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className={advancePageError?.email_profissional ? 'big error' : 'big'}
                                        error={advancePageError?.email_profissional ? '*' : ''}
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={cepData.bairro || DTOProposal.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className={advancePageError?.email_profissional ? 'mid error' : 'mid'}
                                        error={advancePageError?.email_profissional ? '*' : ''}
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        value={DTOProposal.cep}
                                        mask="99999-999"
                                        onChangeText={(value) => consultCep(value)}
                                        className={advancePageError?.email_profissional ? 'small error' : 'small'}
                                        error={advancePageError?.email_profissional ? '*' : ''}
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className={advancePageError?.email_profissional ? 'num error' : 'num'}
                                        error={advancePageError?.email_profissional ? '*' : ''}
                                    />
                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className={advancePageError?.email_profissional ? 'comp error' : 'comp'}
                                        error={advancePageError?.email_profissional ? '*' : ''}
                                    />
                                    {
                                        DTOProposal.modalidade === 'online' &&
                                        <InputDefault
                                            title="Endereço virtual:"
                                            name="link"
                                            value={DTOProposal.endereco_virtual}
                                            onChangeText={(value) => handleOnChange('endereco_virtual', value)}
                                            className={advancePageError?.email_profissional ? 'big error' : 'big'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                    }
                                </div>                          
                                
                            </div>
                        }

                        {
                            proposalType === 'Diligencia' && formTitle === 'Comparecimento em cartório' &&
                                <div className="bottom">

                                    <div className="subtitle">
                                        Para <span>comparecimento em cartório</span>, informe também:
                                    </div>

                                    <div className="address">
                                        <InputDefault
                                            title="Rua:"
                                            name="rua"
                                            value={cepData.logradouro || DTOProposal.rua}
                                            onChangeText={(value) => handleOnChange('rua', value)}
                                            className={advancePageError?.email_profissional ? 'big error' : 'big'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Bairro:"
                                            name="bairro"
                                            value={cepData.bairro || DTOProposal.bairro}
                                            onChangeText={(value) => handleOnChange('bairro', value)}
                                            className={advancePageError?.email_profissional ? 'mid error' : 'mid'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="CEP:"
                                            name="Cep"
                                            mask="99999-999"
                                            value={DTOProposal.cep}
                                            onChangeText={(value) => consultCep(value)}
                                            className={advancePageError?.email_profissional ? 'small error' : 'small'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Número:"
                                            name="numero"
                                            value={DTOProposal.numero}
                                            onChangeText={(value) => handleOnChange('numero', value)}
                                            className={advancePageError?.email_profissional ? 'num error' : 'num'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Complemento:"
                                            name="complemento"
                                            value={DTOProposal.complemento}
                                            onChangeText={(value) => handleOnChange('complemento', value)}
                                            className={advancePageError?.email_profissional ? 'comp error' : 'comp'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <div className="label-state">
                                            <p>Tipo de cartório:</p>
                                            <Select
                                                name="estado_oab"
                                                styles={states}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                placeholder='Selecionar'
                                                defaultValue={DTOProposal.tipo_cartorio !== '' ? { value: DTOProposal.tipo_cartorio, label: DTOProposal.tipo_cartorio } : ''}
                                                onChange={(selected: any) => handleOnChange('tipo_cartorio', selected.value)}
                                                options={NotariesTypes}
                                            />
                                            <span className="error">{advancePageError?.uf ? '*' : ''}</span>
                                        </div>                                        
                                    </div>
                                </div>
                        }

                        {
                            proposalType === 'Diligencia' && formTitle === 'Cópia parcial' &&
                                <div className="bottom">

                                    <div className="subtitle">
                                        Para <span>cópia parcial</span>, informe também:
                                    </div>

                                    <div className="address">
                                        <InputDefault
                                            title="Rua:"
                                            name="rua"
                                            placeholder="Nome da rua"
                                            value={cepData.logradouro || DTOProposal.rua}
                                            onChangeText={(value) => handleOnChange('rua', value)}
                                            className={advancePageError?.rua ? 'big error' : 'big'}
                                            error={advancePageError?.rua ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Bairro:"
                                            name="bairro"
                                            value={cepData.bairro || DTOProposal.bairro}
                                            onChangeText={(value) => handleOnChange('bairro', value)}
                                            className={advancePageError?.email_profissional ? 'mid error' : 'mid'}
                                            error={advancePageError?.bairro ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="CEP:"
                                            name="Cep"
                                            mask="99999-999"
                                            value={DTOProposal.cep}
                                            onChangeText={(value) => consultCep(value)}
                                            className={advancePageError?.cep ? 'small error' : 'small'}
                                            error={advancePageError?.cep ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Número:"
                                            name="numero"
                                            value={DTOProposal.numero}
                                            onChangeText={(value) => handleOnChange('numero', value)}
                                            className={advancePageError?.numero ? 'num error' : 'num'}
                                            error={advancePageError?.numero ? '*' : ''}
                                        />

                                        <InputDefault
                                            title="Complemento:"
                                            name="complemento"
                                            value={DTOProposal.complemento}
                                            onChangeText={(value) => handleOnChange('complemento', value)}
                                            className={'comp'}
                                            // error={advancePageError?.email_profissional ? '*' : ''}
                                        />                                                                              

                                        <div className="pages-title">
                                            <div className="title-error">
                                                <p>Intervalo de páginas:</p>
                                                {
                                                    advancePageError?.pagina_final ||
                                                    advancePageError?.pagina_inicial
                                                    ?   <span>*</span>
                                                    :   ''
                                                }                                                
                                            </div>
                                            <div className="page-input">
                                                <InputDefault
                                                    title=""
                                                    name="Página inicial"
                                                    value={DTOProposal.pagina_inicial}
                                                    placeholder="Pg. Inicial"
                                                    onChangeText={(value) => handleOnChange('pagina_inicial', value)}
                                                    className={advancePageError?.pagina_inicial ? 'page error' : 'page'}
                                                    // error={advancePageError?.pagina_inicial ? '*' : ''}
                                                />                                                                              
                                                - 
                                                <InputDefault
                                                    title=""
                                                    name="Página final"
                                                    placeholder="Pg. Final"
                                                    value={DTOProposal.pagina_final}
                                                    onChangeText={(value) => handleOnChange('pagina_final', value)}
                                                    className={advancePageError?.pagina_final ? 'page error' : 'page'}
                                                    // error={advancePageError?.pagina_final ? '*' : ''}
                                                />                                                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        
                        {
                            proposalType === 'Diligencia' && formTitle === 'Cópia integral' &&
                                <div className="bottom">

                                    <div className="subtitle">
                                        Para <span>cópia integral</span>, informe também:
                                    </div>

                                    <div className="address">
                                        <InputDefault
                                            title="Rua:"
                                            name="rua"
                                            value={cepData.logradouro || DTOProposal.rua}
                                            onChangeText={(value) => handleOnChange('rua', value)}
                                            className={advancePageError?.email_profissional ? 'big error' : 'big'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Bairro:"
                                            name="bairro"
                                            value={cepData.bairro || DTOProposal.bairro}
                                            onChangeText={(value) => handleOnChange('bairro', value)}
                                            className={advancePageError?.email_profissional ? 'mid error' : 'mid'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="CEP:"
                                            name="Cep"
                                            mask="99999-999"
                                            value={DTOProposal.cep}
                                            onChangeText={(value) => consultCep(value)}
                                            className={advancePageError?.email_profissional ? 'small error' : 'small'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Número:"
                                            name="numero"
                                            value={DTOProposal.numero}
                                            onChangeText={(value) => handleOnChange('numero', value)}
                                            className={advancePageError?.email_profissional ? 'num error' : 'num'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Complemento:"
                                            name="complemento"
                                            value={DTOProposal.complemento}
                                            onChangeText={(value) => handleOnChange('complemento', value)}
                                            className={advancePageError?.email_profissional ? 'comp error' : 'comp'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />                                                                              
                                    </div>
                                </div>
                        }

                        {
                            proposalType === 'Protocolo' && formTitle === 'Acautelamento' &&
                                <div className="bottom">

                                    <div className="subtitle">
                                        Para <span>acautelamento</span>, informe também:
                                    </div>

                                    <div className="address">
                                        <InputDefault
                                            title="Rua:"
                                            name="rua"
                                            value={cepData.logradouro || DTOProposal.rua}
                                            onChangeText={(value) => handleOnChange('rua', value)}
                                            className={advancePageError?.email_profissional ? 'big error' : 'big'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Bairro:"
                                            name="bairro"
                                            value={cepData.bairro || DTOProposal.bairro}
                                            onChangeText={(value) => handleOnChange('bairro', value)}
                                            className={advancePageError?.email_profissional ? 'mid error' : 'mid'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="CEP:"
                                            name="Cep"
                                            mask="99999-999"
                                            value={DTOProposal.cep}
                                            onChangeText={(value) => consultCep(value)}
                                            className={advancePageError?.email_profissional ? 'small error' : 'small'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Número:"
                                            name="numero"
                                            value={DTOProposal.numero}
                                            onChangeText={(value) => handleOnChange('numero', value)}
                                            className={advancePageError?.email_profissional ? 'num error' : 'num'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Complemento:"
                                            name="complemento"
                                            value={DTOProposal.complemento}
                                            onChangeText={(value) => handleOnChange('complemento', value)}
                                            className={advancePageError?.email_profissional ? 'comp error' : 'comp'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />                                                                              
                                    </div>
                                </div>
                        }

                        {
                            proposalType === 'Protocolo' && formTitle === 'Protocolo postal' &&
                                <div className="bottom">

                                    <div className="subtitle">
                                        Para <span>protocolo postal</span>, informe também:
                                    </div>

                                    <div className="address">
                                        <InputDefault
                                            title="Rua:"
                                            name="rua"
                                            value={cepData.logradouro || DTOProposal.rua}
                                            onChangeText={(value) => handleOnChange('rua', value)}
                                            className={advancePageError?.email_profissional ? 'big error' : 'big'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Bairro:"
                                            name="bairro"
                                            value={cepData.bairro || DTOProposal.bairro}
                                            onChangeText={(value) => handleOnChange('bairro', value)}
                                            className={advancePageError?.email_profissional ? 'mid error' : 'mid'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="CEP:"
                                            name="Cep"
                                            mask="99999-999"
                                            value={DTOProposal.cep}
                                            onChangeText={(value) => consultCep(value)}
                                            className={advancePageError?.email_profissional ? 'small error' : 'small'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Número:"
                                            name="numero"
                                            value={DTOProposal.numero}
                                            onChangeText={(value) => handleOnChange('numero', value)}
                                            className={advancePageError?.email_profissional ? 'num error' : 'num'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />
                                        <InputDefault
                                            title="Complemento:"
                                            name="complemento"
                                            value={DTOProposal.complemento}
                                            onChangeText={(value) => handleOnChange('complemento', value)}
                                            className={advancePageError?.email_profissional ? 'comp error' : 'comp'}
                                            error={advancePageError?.email_profissional ? '*' : ''}
                                        />                                                                              
                                    </div>
                                </div>
                        }

                    </form>
                }

                {
                    activePage === 2 &&
                    <ServicesTags 
                        tagSelected={tagsSelecteds} 
                        setTagSelected={setTagSelecteds} 
                        reuseTags={DTOProposal.tags}
                    />
                }

                {
                    activePage === 3 &&
                    <SearchProfessionalProposal 
                        uf={countieProps.uf} 
                        cidade={countieProps.cidade}
                        proposalData={DTOProposal}  
                        proposalType={proposalType} 
                        userSelected={handleOnChange}
                        selected={DTOProposal.id_usuario}   
                        nameUser={setNameProfessional}   
                        reuseProfessional={DTOProposal.id_usuario}               
                        reuseProposal={reuseProposal}
                    />
                }

                {
                    activePage === 4 &&
                    <DeadlineProposal 
                        handleOnChange={handleOnChange}
                        deadline={setDeadline}
                        reuseDeadline={location.state.proposal?.prazo_aceite}
                        reuseConclusionDate={location.state.proposal?.prazo_para_conclusao}
                        reuseFiles={location.state.proposal?.arquivos}
                        reuseOrientations={location.state.proposal?.orientacoes[0]}
                        reuseProposal={reuseProposal}
                    />
                }
            </Main>

            <Footer>
                <div className="container">
                    {
                        activePage !== 1 &&
                        <div className="back">
                            <ButtonDefault
                                className="cancel"
                                onClick={() => history.push('/propostas')}
                            >
                                Cancelar
                            </ButtonDefault>
                        </div>
                    }
                    <div className="buttons">
                        {
                            activePage === 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => history.goBack()}
                            >
                                Cancelar
                            </ButtonDefault>
                        }
                        {
                            activePage > 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Voltar
                            </ButtonDefault>
                        }

                        <ButtonDefault
                            className="continue"
                            type="submit"
                            onClick={() => advancePage()}
                            disabled={loading}
                        >
                            Continuar
                        </ButtonDefault>
                    </div>                    
                </div>
            </Footer>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}