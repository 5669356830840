import { Switch } from 'react-router-dom'
import PrivateRoutes from './PrivateRoutes';
import Login from '../pages/Login';
import Register from '../pages/Register';
import Recover from '../pages/Login/recover';
import Layout from '../components/Layout';
import Dashboard from '../pages/Dashboard';
import Professionals from '../pages/Professionals';
import ProfessionalProfile from '../pages/ProfileProfessional';
import RegisterOperator from '../pages/RegisterOperator';
import CompleteRegisterOperator from '../pages/RegisterOperatorComplete';
import Operators from '../pages/Operators';
import OperatorProfile from '../pages/OperatorProfile';
import Proposals from '../pages/Proposals';
import NewProposal from '../pages/NewProposal';
import HearingForms from '../pages/HearingForms';
import CreateProposalComplete from '../pages/CreateProposalComplete';
import CreateUserComplete from '../pages/CreateUserComplete';
import Proposal from '../pages/ProposalEdit';
import Demands from '../pages/Demands';
import DemandInfo from '../pages/DemandDetails';
import Orientations from '../pages/Orientations';
import Financial from '../pages/Financial';
import WhoWeARe from '../pages/Site/WhoWeAre';
import LegalClaims from '../pages/Site/RequestLegalClaims';
import ReceiveOpportunities from '../pages/Site/ReceiveOpportunities';
import Contact from '../pages/Site/Contact';
import Cookies from '../pages/Site/CookiesPolicies';
import Home from '../pages/Site/Home';
import RequestService from '../pages/RequestService';
import CreateServiceComplete from '../pages/CreateServiceComplete';

function Routes({props}: any) {
    return (
        <Switch>
            {/* Free access */}
            <PrivateRoutes path="/" exact component={Home} />
            <PrivateRoutes path="/quemsomos" exact component={WhoWeARe} />
            <PrivateRoutes path="/solicitardemanda" exact component={LegalClaims} />
            <PrivateRoutes path="/receberoportunidades" exact component={ReceiveOpportunities} />
            <PrivateRoutes path="/contato" exact component={Contact} />
            <PrivateRoutes path="/politicas-cookies" exact component={Cookies} />
            <PrivateRoutes path="/login" exact component={Login} />
            <PrivateRoutes path="/cadastrar" exact component={Register} />
            <PrivateRoutes path="/cadastro-completo" exact component={CreateUserComplete} />
            <PrivateRoutes path="/solicitarservico" exact component={RequestService} />
            <PrivateRoutes path="/servico-completo" exact component={CreateServiceComplete} />
            <PrivateRoutes path="/recuperar-senha/:id" exact component={Recover} />

            {/* admin/operator access */}
            <PrivateRoutes path="/cadastrar/operador" exact component={RegisterOperator} isPrivate />
            <PrivateRoutes path="/formulario-proposta" exact component={HearingForms} isPrivate />            
            <PrivateRoutes path="/operador/concluir" exact component={CompleteRegisterOperator} isPrivate />            
            <PrivateRoutes path="/proposta/concluir" exact component={CreateProposalComplete} isPrivate />    
            <PrivateRoutes path="/demanda/orientacoes" exact component={Orientations} isPrivate />                

            <Layout {...props}>
                <PrivateRoutes path="/dashboard" exact component={Dashboard} isPrivate />
                <PrivateRoutes path="/demandas" exact component={Demands} isPrivate />
                <PrivateRoutes path="/demandas/:id" exact component={DemandInfo} isPrivate />
                <PrivateRoutes path="/operadores" exact component={Operators} isPrivate />
                <PrivateRoutes path="/operadores/:id" exact component={OperatorProfile} isPrivate />
                <PrivateRoutes path="/nova-proposta" exact component={NewProposal} isPrivate />
                <PrivateRoutes path="/propostas" exact component={Proposals} isPrivate />
                <PrivateRoutes path="/propostas/:id" exact component={Proposal} isPrivate />                
                <PrivateRoutes path="/profissionais" exact component={Professionals} isPrivate />
                <PrivateRoutes path="/profissionais/:id" exact component={ProfessionalProfile} isPrivate />                
                <PrivateRoutes path="/financeiro" exact component={Financial} isPrivate />                
            </Layout>
        </Switch>
    )
}

export default Routes;