import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 265px 1fr;
  padding: 24px;
  max-width: 1040px;

  background-color: ${({theme}) => theme.colors.interfacePure0};
  box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
  border-radius: 16px;  
`;

export const ImageWellcome = styled.div`
  position: relative;
  height: 100%;
  img {
    position: absolute;
    bottom: -24px;
  }

  .virtual {
    position: absolute;
  }
`;

export const SectionMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wellcomeTitle {
    font-size: 1rem;
    font-weight: 600;
    color: ${({theme}) => theme.colors.interfaceText400};
    margin-bottom: 1rem;
    text-transform: capitalize;

    span {
      font-size: 1rem;
      font-weight: 600;
      color: ${({theme}) => theme.colors.secondaryGreen600};
    }
  }

  .wellcomeParagraph {
    font-size: 14px;
    line-height: 180%;
    font-weight: 500;
    color: ${({theme}) => theme.colors.interfaceText400};
  }

`;

export const SectionCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 224px;  
  justify-content: flex-end;
  gap: 16px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  height: 96px;
  border: 2px solid #EAEEF3;
  border-radius: 8px;
  cursor: pointer;
  
  .wellcomeCardTitle {
    font-size: 14px;
    font-weight: 600;
    color: ${({theme}) => theme.colors.interfaceText400};
    /* white-space: nowrap; */
  }

  &.help {
    width: 160px;
    justify-self: flex-end;
  }

  &.invite {
    width: 224px;
    justify-self: flex-end;
  }
`;

export const WrapperModal = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 8px;
`

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.interfaceText400};

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;

    path {
      width: 100%;
      height: 100%;
      fill: ${({ theme }) => theme.colors.interfacePure400};
    }
  }
`

export const ModalContacts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .first-line,
  .second-line {
    display: flex;
    gap: 24px;
  }

  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    height: 120px;
    border-radius: 16px;
    font-size: 1rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.interfaceText400};
    cursor: pointer;

    span {
      font-weight: 500;
    }

    &.zap {
      width: 236px;
      border: 2px solid ${({ theme }) => theme.colors.interfaceSuccess200};
    }

    &.mail {
      width: 236px;
      border: 2px solid ${({ theme }) => theme.colors.secondaryPool200};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.secondaryPool600};
        }
      }
    }

    &.phone {
      width: 236px;
      border: 2px solid ${({ theme }) => theme.colors.primaryOrange200};
    }

    &.tutorial {
      width: 236px;
      border: 2px solid ${({ theme }) => theme.colors.interfaceText100};
    }
  }

  .name,
  .mail {

    span {
      color: ${({ theme }) => theme.colors.interfaceText400};
      font-size: 16px;
      font-weight: 400;
    }

  }

  .buttons {
    display: flex;
    gap: 24px;

    .cancel {
      border: 2px solid ${({ theme }) => theme.colors.background};
    }

    .continue {
      background-color: ${({ theme }) => theme.colors.primaryBlue300};
    }
  }
`

export const Divider = styled.div`
    width: 100%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
`

