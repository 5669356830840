import axios from 'axios';

var baseUrl

if (process.env.NODE_ENV === 'development') {
    baseUrl =  'http://192.168.15.14:33331/'
    // baseUrl = 'https://backend.juridicoja.com/'
} else {
    baseUrl = 'https://backend.juridicoja.com/'
}

const api = axios.create({
    baseURL: baseUrl,
})

export default api;
