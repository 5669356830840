// React
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

// Components
import ProposalButtons from "../../components/Ui/ProposalButton";

// Context
import { useAuth } from "../../contexts/UserContext";

// Styles
import { Container, ProposalBottom, ProposalTop, ServicesOptions } from "./styles";


export default function NewProposal() {
    const { setPageName } = useAuth();
    const [selected, setSelected] = useState<any>('Audiência');

    useEffect(() => {
        setPageName('Nova proposta');
        
    })

    return(
        <Container>

            <ProposalTop>

                <ProposalButtons
                    selected={selected}
                    setSelected={setSelected}                                            
                />                
                
            </ProposalTop>

            <ProposalBottom>
                {
                    selected === 'Audiência' &&
                    <>
                        <ServicesOptions>
                            Cível
                            <div 
                                className="select"
                            >
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Audiência Cível',
                                            type: 'Audiência',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Penal
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Audiência Penal',
                                            type: 'Audiência',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Trabalhista
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Audiência Trabalhista',
                                            type: 'Audiência',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Outros
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Audiências',
                                            type: 'Audiência',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>
                    </>
                }

                {
                    selected === 'Diligência' &&
                    <>
                        <ServicesOptions>
                            Acompanhamento de atos oficiais
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Acompanhamento de atos oficiais',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Comparecimento em cartório
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Comparecimento em cartório',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Cópia integral
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Cópia integral',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Cópia parcial
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Cópia parcial',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Despacho
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Despacho',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Retirada de ofício e alvará
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Retirada de ofício e alvará',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Petições
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Petições',
                                            type: 'Diligencia',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>

                        <ServicesOptions>
                            Outros
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Diligência',
                                            type: 'Diligência',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>
                    </>
                }

                {
                    selected === 'Protocolo' &&
                    <>
                        <ServicesOptions>
                            Acautelamento
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Acautelamento',
                                            type: 'Protocolo',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>
                        <ServicesOptions>
                            Protocolo eletrônico
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Protocolo eletrônico',
                                            type: 'Protocolo',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>
                        <ServicesOptions>
                            Protocolo físico
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Protocolo físico',
                                            type: 'Protocolo',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>
                        <ServicesOptions>
                            Protocolo postal
                            <div className="select">
                                <Link
                                    to={{
                                        pathname: '/formulario-proposta',
                                        state: {
                                            title: 'Protocolo postal',
                                            type: 'Protocolo',
                                        }
                                    }}
                                >
                                    Selecionar
                                </Link>
                            </div>
                        </ServicesOptions>
                    </>
                }

                {
                    selected === 'Outros' &&
                    <ServicesOptions>
                        Outro serviço
                        <div className="select">
                            <Link
                                to={{
                                    pathname: '/formulario-proposta',
                                    state: {
                                        title: 'Outro Serviço',
                                        type: 'Outros',
                                    }
                                }}
                            >
                                Selecionar
                            </Link>
                        </div>
                    </ServicesOptions>
                }

            </ProposalBottom>

        </Container>
    )
}