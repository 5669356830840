/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// React //
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Components //
import OperatorProfileTabs from "../../components/Ui/OperatorProfileTabs";
import ButtonsCard from "../../components/Ui/ButtonsCard";
import InputDefault from "../../components/form/InputDefault";
import ModalDefault from "../../components/ModalDefault";
import ButtonDefault from "../../components/form/ButtonDefault";
import OperatorPermissions from "../../components/OperatorPermissions";
import Checkbox from "../../components/form/InputCheckbox";

// Styles //
import { Archipelago, Island } from "../../components/OperatorIslands/styles";
import { Container, Menu, ModalMain, ModalPlanMain } from "./styles";

// Icons //
import { IconClose } from "../../components/icons";

// Libraries //
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';
import { Oval } from 'react-loader-spinner';

// Services //
import api from "../../services/api";
import axios from "axios";
import { useAuth } from "../../contexts/UserContext";

// Data //
import { EstadosBrasileiros } from '../../components/Data/StatesData';
import ChangePassword from "../../components/ChangePassword";

interface Operator {
    nome?: string,
    telefone?: string,
    email_pessoal?: string,
    whatsapp?: string,
    email_profissional?: string,
    uf?: string,
    cidade?: string,
    audiencias?: string,
    protocolos?: string,
    diligencias?: string,
    peticoes?: string,
    outros?: string,
    acautelamentos?: string,
    ilhas?: any,
    status?: any,
}

export default function OperatorProfile() {
    const params = useParams<any>()
    const profileId = params.id;
    const history = useHistory();
    const { setPageName, setPageType, user } = useAuth();
    const [ loading, setLoading ] = useState<any>(false);
    const [ selected, setSelected ] = useState('1');
    const [ updateUser, setUpdateUser ] = useState<any>({});
    const [ editProfile, setEditProfile ] = useState<any>();
    const [ profile, setProfile ] = useState<Operator>();
    const [ openModalDelete, setOpenModalDelete ] = useState<any>();
    const [ operatorDelete, setOperatorDelete ] = useState<any>();
    const [ inactivateModal, setInactivateModal ] = useState<any>();
    const [ inactiveOperator, setInactiveOperator ] = useState<any>(false);
    const [ activeOperatorModal, setActiveOperatorModal ] = useState<any>(false);
    const [ activeOperator, setActiveOperator ] = useState<any>(false);
    const [ updateData, setUpdateData ] = useState<any>();
    const [ cities, setCities ] = useState<any>([]);
    const [ islands, setIslands ] = useState<any>();
    const [ checkedAll, setCheckedAll ] = useState<any>(false);

    useEffect(() => {
        async function getIslands() {

            try {
                setLoading(true)
                const response = await api.get('ilha')
                setIslands(response.data.result);                
                
                if (response.data.status !== 'success') {
                    throw new Error()
                }
                setLoading(false)
            } catch (error) {

                console.log('log dos erros recebendo as ilhas', error)
                setLoading(false)
            }

        }

        getIslands();

    }, [])

    const handleSelectAll = (e: any) => {

        const newProfile: any = profile
        if ( profile?.ilhas.length === islands.length ){
            newProfile.ilhas = []
            setCheckedAll(false)
        } else {
            newProfile.ilhas = islands
            setCheckedAll(true)
        }
        setProfile({ ...newProfile })
        const allIslands: any[] = [];
        newProfile.ilhas.map((row: any) => (
            allIslands.push(row.id_ilha)
        ))
        handleOnChange('ilhas', allIslands)
    };

    const handleClick = (ilha: any) => {
        
        if ( profile?.ilhas.filter((obj:any) => obj.id_ilha === ilha.id_ilha).length > 0 ){
            const newProfile:any = profile
            newProfile.ilhas = profile?.ilhas.filter((obj: any) => obj.id_ilha !== ilha.id_ilha)
            setProfile({...newProfile})
            const allIslands: any[] = [];
            newProfile.ilhas.map((row: any) => (
                allIslands.push(row.id_ilha)
            ))
            handleOnChange('ilhas', allIslands)
        } else {
            profile?.ilhas.push(ilha)
            setProfile({...profile})
            const allIslands: any[] = [];
            profile?.ilhas.map((row: any) => (
                allIslands.push(row.id_ilha)
            ))
            handleOnChange('ilhas', allIslands)
        }
        
    };

    const selectStyles = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    useEffect(() => {
        getProfile();        
    }, [updateData])
        
    async function getProfile() {
        try {

            setLoading(true);

            const response = await api.get('operador/' + profileId)
            setProfile(response.data.result)
            
            setLoading(false);

        } catch (error) {
            console.log('log dos erros recebendo o profile', error)
            setLoading(false);
        }
    }

    async function handleUpdateUser() {
               
        try {
            if (DTOoperator?.ilhas?.length === 0) {
                throw new Error('Não é possível remover todas as ilhas do perfil.');
            }

            // if (DTOoperator?.acautelamentos === '' && DTOoperator?.diligencias === '' && DTOoperator?.outros === '' && DTOoperator?.peticoes === '' && DTOoperator?.protocolos === '' ) {
            //     throw new Error('É obrigatório escolher no mínimo uma permissão')
            // }
            
            const response = await api.put('operador/' + profileId, DTOoperator);
            
            if (response.data.status !== 'success') {
                throw new Error('Não foi possível realizar a solicitação, tente novamente mais tarde');
            }

            if (response.data.status === 'success') {
                setUpdateData(response.data);                
                setEditProfile(false)   
                // window.location.reload();             
            }
            
            toast.success('Alterações salvas com sucesso', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                    background: '#E7F8EB',
                    border: '2px solid #9EE4AF',
                }
            });
            
            
            

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            console.log('Log do erro', error)
        }
    };

    const [DTOoperator, setDTOoperator] = useState<Operator>({
        nome: profile?.nome,
        telefone: profile?.telefone,
        email_pessoal: profile?.email_pessoal,
        whatsapp: profile?.whatsapp,
        email_profissional: profile?.email_profissional,
        uf: profile?.uf,
        cidade: profile?.cidade,
        audiencias: profile?.audiencias,
        protocolos: profile?.protocolos,
        diligencias: profile?.diligencias,
        peticoes: profile?.peticoes,
        outros: profile?.outros,
        acautelamentos: profile?.acautelamentos,
        ilhas: profile?.ilhas,
        status: profile?.status,
    });

    function handleOnChange(name: any, value: any) {
         
        const newDTO: any = DTOoperator
        newDTO[name] = value
        setDTOoperator({ ...newDTO })

        setUpdateUser(DTOoperator);
       
    };    

    useEffect(() => {

        handleInactiveOperator()

    }, [inactiveOperator])

    async function handleInactiveOperator() {
        try {
            setLoading(true)
            if (inactiveOperator === true) {

                const response = await api.put('operador/' + profileId, {
                    status: 'inativo'
                })

                if (response.data.status !== 'success') {
                    throw new Error()
                }

                if (response.data.status === 'success') {
                    toast.success('Perfil alterado com sucesso.', {
                        style: {
                            padding: '16px',
                            color: '#FA9604',
                        },
                        iconTheme: {
                            primary: '#02E8BE',
                            secondary: '#FFFAEE',
                        },
                    });
                }

                setLoading(false)
                setInactivateModal(false)                
            }


        } catch (error: any) {

            console.log('log do err delete', error);
            setLoading(false);


        }
    };   

    useEffect(() => {
        handleDeleteOperator()
    }, [operatorDelete])

    async function handleDeleteOperator() {
        try {
            setLoading(true)
            if (operatorDelete === true) {

                const response = await api.delete('operador/' + profileId)

                if (response.data.status !== 'success') {
                    throw new Error()
                }

                if (response.data.status === 'success') {
                    toast.success('Perfil excluído com sucesso.', {
                        style: {
                            padding: '16px',
                            color: '#FA9604',
                        },
                        iconTheme: {
                            primary: '#02E8BE',
                            secondary: '#FFFAEE',
                        },
                    });
                    setTimeout(() => {
                        history.push('/operadores')
                    }, 500)
                }

                setLoading(false)
                setOpenModalDelete(false)
            }


        } catch (error: any) {

            console.log('log do err delete', error);
            setLoading(false);


        }
    };        

    function handleSelect(selected: any) {

        if (selected.value.length < 3) {
            handleOnChange('uf', selected.value);
            getCitiesForUF(selected.value);
        } else {
            handleOnChange('cidade', selected.value);
        }

    }

    async function getCitiesForUF(uf: any) {
        const newCities: any = []

        await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`).then(response => {
            response.data.map((row: any) => (

                newCities.push({
                    value: row.nome,
                    label: row.nome
                })
            ))
            setCities([...newCities])
        });

    }

    useEffect(() => {
        activateOperator()
    }, [activeOperator])

    async function activateOperator() {

        if (activeOperator === true) {
            try {
                const response = await api.put('operador/' + profileId, {
                    status: 'ativo'
                });
    
                if (response.data.status !== 'success') {
                    throw new Error('Não foi possível realizar a solicitação, tente novamente mais tarde');
                }
    
                toast.success('Operador ativado com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });
    
                if (response.data.status === 'success') {
                    setUpdateData('update please');
                }

                setActiveOperatorModal(false)                
    
    
            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                console.log('Log do erro', error)
            }
        }

        
    }

    useEffect(() => {

        const fullName = profile?.nome;
        const splitOnSpace = fullName?.split(' ');
        const first = splitOnSpace ? splitOnSpace[0] : '';
        
        setPageName(`${first} P${profileId}`);
        setPageType('normal');

        if (user.profile === 'admin' && profileId === '1') {
            setPageName(`${first} J${profileId}`);
        }


    }, [profile]);

    return (
        <Container editProfile={editProfile}>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }
            
            <Menu>

                <OperatorProfileTabs
                    selected={selected}
                    setSelected={setSelected}
                />

                <ButtonsCard
                    setSaveData={() => handleUpdateUser()}
                    setEditProfile={() => setEditProfile(!editProfile)}
                    editProfile={editProfile}
                    setDelete={() => setOpenModalDelete(true)}
                    setConfigActivity={() => setInactivateModal(true)}
                    profileStatus={profile?.status}
                    activeOperator={() => setActiveOperatorModal(true)}
                    setSelected={setSelected}
                />

            </Menu>           

            {
                selected === '1' && 
                profile &&
                !loading &&
                <div className="profile">
                    <form className="register-form" >
                            <div className="name">
                                <InputDefault
                                    title="Nome:"
                                    name='nome'
                                    value={profile.nome}
                                    disabled={editProfile ? false : true}
                                    onChangeText={(value) => handleOnChange('nome', value)}
                                    className='big'
                                />

                                <InputDefault
                                    title="Telefone:"
                                    name="telefone"
                                    mask="(99) 99999-9999"
                                    disabled={editProfile ? false : true}
                                    value={profile.telefone}
                                    onChangeText={(value) => handleOnChange('telefone', value)}
                                    className='small'
                                />
                            </div>

                            <div className="email">
                                <InputDefault
                                    title="E-mail pessoal:"
                                    name="email_pessoal"
                                    disabled={editProfile ? false : true}
                                    value={profile.email_pessoal}
                                    onChangeText={(value) => handleOnChange('email_pessoal', value)}
                                    className='big'
                                />

                                <InputDefault
                                    title="Whatsapp:"
                                    name="whatsapp"
                                    disabled={editProfile ? false : true}
                                    mask="(99) 99999-9999"
                                    value={profile.whatsapp}
                                    onChangeText={(value) => handleOnChange('whatsapp', value)}
                                    className='small'
                                />
                            </div>

                            <div className="pro">
                                <InputDefault
                                    title="E-mail profissional:"
                                    name="email_profissional"
                                    disabled={editProfile ? false : true}
                                    value={profile.email_profissional}
                                    onChangeText={(value) => handleOnChange('email_profissional', value)}
                                    className='big'
                                />
                            </div>

                            <div className="state">
                                <div className="label-state">
                                    <p>Estado:</p>
                                    <Select
                                        name="estado_oab"
                                        styles={selectStyles}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DDFAF4',
                                                primary50: '#DDFAF4',
                                                primary: '#02E8BE',
                                            },
                                        })}
                                        placeholder='Selecionar'
                                        defaultValue={profile.uf !== '' ? { value: profile.uf, label: profile.uf } : ''}
                                        onChange={handleSelect}
                                        options={EstadosBrasileiros}
                                        isDisabled={editProfile ? false : true}
                                    />                                    
                                </div>

                                <div className="label-state">
                                    <p>Cidade da comarca:</p>
                                    <Select
                                        styles={selectStyles}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DDFAF4',
                                                primary50: '#DDFAF4',
                                                primary: '#02E8BE',
                                            },
                                        })}
                                        name='cidade'
                                        placeholder="Selecionar"
                                        defaultValue={profile.cidade !== '' ? { value: profile.cidade, label: profile.cidade } : ''}
                                        options={cities}
                                        onChange={(e: any) => handleOnChange('cidade', e.value)}
                                        isDisabled={editProfile ? false : true}
                                    />
                                </div>
                            </div>

                  
                    </form >
                </div>
            }

            {
                selected === '2' &&
                !loading &&
                <div className="islands">
                    {/* Se for para editar o perfil renderiza com o checkbox laranja */}
                        {
                            editProfile &&
                            <>
                                <div className="select-all">
                                    <Checkbox
                                        name='selectAll'
                                        label="Selecionar todas"
                                        value={checkedAll}
                                        onClick={handleSelectAll}
                                    />                                    
                                </div>

                                <Archipelago>
                                    {
                                        islands && !loading && profile &&
                                        islands.map((row: any) => (
                                            <Island key={row.id_ilha}>
                                                <div>
                                                    <Checkbox
                                                        name={row.descricao}
                                                        value={profile.ilhas.filter((obj:any) => obj.id_ilha === row.id_ilha).length > 0 ? true : false}
                                                        onClick={() => handleClick(row)}   
                                                    />
                                                </div>
                                                <div className="tag">{row.descricao}</div>
                                            </Island>
                                        ))
                                    }
                                </Archipelago>
                            </>
                        }

                    {/* Se for para visualizar o perfil renderiza com o checkbox azul */}
                        {
                            !editProfile &&
                                <>
                                    <div className="select-all">
                                        <Checkbox
                                            name='true'
                                            
                                            value={checkedAll}
                                            // onClick={() => selectAllIslands()}
                                            onClick={() => ('')}
                                        />
                                    </div>

                                    <Archipelago>
                                        {
                                            islands && !loading && profile &&
                                            islands.map((row: any) => (
                                                <Island key={row.id_ilha}>
                                                    <div className="no-edit">
                                                        <Checkbox
                                                            name={row.descricao}
                                                            value={
                                                                row.descricao === profile.ilhas[0]?.descricao
                                                                ? true 
                                                                : row.descricao === profile.ilhas[1]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[2]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[3]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[4]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[5]?.descricao  
                                                                ? true
                                                                : row.descricao === profile.ilhas[6]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[7]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[8]?.descricao 
                                                                ? true
                                                                : row.descricao === profile.ilhas[9]?.descricao 
                                                                ? true
                                                                : false
                                                            }
                                                            onClick={() => ('')}                                                            
                                                        />
                                                    </div>
                                                    <div className="tag">{row.descricao}</div>
                                                </Island>
                                            ))
                                        }
                                    </Archipelago>
                                </>
                        }                    
                    
                </div>
            }

            {
                selected === '3' &&
                !loading &&
                <div className="permissions">
                    <OperatorPermissions 
                        handleOnChange={handleOnChange} 
                        editProfile={editProfile} 
                        profile={profile}                         
                    />
                </div>
            }

            {
                selected === 'pass' &&
                !loading &&
                <ChangePassword id_profile={profileId} setSelected={setSelected} />
            }
            
            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>

            {/* Modal para excluir operador */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalDelete((openModalDelete: any) => !openModalDelete)}
                openModal={openModalDelete}
                width="45%"
            >
                <ModalMain>
                    <div className="title">
                        <div>Deseja excluir a conta?</div>
                        <div
                            className="close"
                            onClick={() => setOpenModalDelete(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalDelete(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setOperatorDelete(true)}
                        >
                            Excluir conta
                        </ButtonDefault>
                    </div>
                </ModalMain>

            </ModalDefault>

            {/* Modal para inativar operador */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setInactivateModal((inactivateModal: any) => !inactivateModal)}
                openModal={inactivateModal}
                width="34.5%"
            >
                <ModalPlanMain>
                    <div className="title">
                        <div>Deseja inativar esta conta?</div>
                        <div
                            className="close"
                            onClick={() => setInactivateModal(false)}
                        >
                            <IconClose />
                        </div>
                    </div>

                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setInactivateModal(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="save"
                            onClick={() => setInactiveOperator(true)}
                        >
                            Inativar conta
                        </ButtonDefault>
                    </div> 
                </ModalPlanMain>

            </ModalDefault>

            {/* Modal para ativar operador */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setActiveOperatorModal((activeOperatorModal: any) => !activeOperatorModal)}
                openModal={activeOperatorModal}
                width="34.5%"
            >
                <ModalPlanMain>
                    <div className="title">
                        <div>Deseja ativar esta conta?</div>
                        <div
                            className="close"
                            onClick={() => setActiveOperatorModal(false)}
                        >
                            <IconClose />
                        </div>
                    </div>

                    <div className="buttons">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setActiveOperatorModal(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="save"
                            onClick={() => setActiveOperator(true)}
                        >
                            Ativar conta
                        </ButtonDefault>
                    </div> 
                </ModalPlanMain>

            </ModalDefault>

        </Container>
    )
}