import styled from "styled-components";

export const Container = styled.button`
   display: flex;
   gap: 16px;
   width: 100%;
   height: 56px;
   border-radius: 8px;
   border: none;
   justify-content: center;
   font-size: 16px;
   line-height: 24px;
   align-items: center;
   cursor: pointer;
   transition: all .3s;
            &:hover {
                transform: scale(1.05);
            }
   &.new {
       background: ${({ theme }) => theme.colors.secondaryPool600};
       color: ${({theme}) => theme.colors.interfacePure0};
   }
   &.pro {
       background: ${({ theme }) => theme.colors.secondaryGreen600};
       color: ${({theme}) => theme.colors.interfacePure0};
   }
   &.save {
        background: ${({ theme }) => theme.colors.primaryBlue500};        
        color: ${({theme}) => theme.colors.interfacePure0};
        /* width: 200px; */
    }
   &.help {
        background: ${({ theme }) => theme.colors.interfaceDanger600};
        color: ${({theme}) => theme.colors.interfacePure0};
   } 
   &.filter {
        background: ${({ theme }) => theme.colors.primaryBlue500};
        color: ${({ theme }) => theme.colors.interfacePure0};        
    } 
   &.op {
        background: ${({ theme }) => theme.colors.primaryOrange700};
        color: ${({ theme }) => theme.colors.interfacePure0};
   } 
   &.activate {
        background: ${({ theme }) => theme.colors.interfaceSuccess};
        color: ${({ theme }) => theme.colors.interfacePure0};
   }
   &.reject {
        background: ${({ theme }) => theme.colors.interfacePure0};
        color: ${({ theme }) => theme.colors.primaryBlue500};
        border: 2px solid ${({ theme }) => theme.colors.primaryBlue500};
        width: 135px;
   }
`