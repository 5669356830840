/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import CardWelcomeOperator from "../../components/Ui/CardWelcomeOperator";
import ModalDefault from "../../components/ModalDefault";
import ButtonDefault from "../../components/form/ButtonDefault";

// Context
import { useAuth } from "../../contexts/UserContext";

// Styles
import { BottomContainer, CloseModal, Container, FinancialReports, InfoCard, MensalButton, ModalMain, ModalTitle, ModalTitleHeader, PersonalizeButton, TopContaineir } from "./styles";

// Libraries
import { Oval } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';

// Icons
import { IconArrowRigthDown, IconArrowRigthUp, IconCalendar, IconClose, IconExternalLink, IconFilter, IconFinishedAccept } from "../../components/icons";

// Images
import Reports from '../../assets/images/FinancialReport.svg';

// Services
import api from "../../services/api";

// Libraries
import DatePicker from 'react-date-picker';
import moment from "moment";

interface FinancialProps {
    custoTotalProfissionais: number,
    demandasFinalizadas: number,
    lucroTotal: number,
    receberClientes: number,
    valorAdiantadoDuranteAndamento: number,
    valorRestantePagarProfissionais: number,
}

export default function Financial() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const { setPageName, setPageType } = useAuth();
    const [ financialData, setFinancialData] = useState<FinancialProps>()
    const [ openModal, setOpenModal ] = useState<boolean>(false);
    const date = new Date();
    const month = date.toLocaleString('pt-BR', { month: 'long' });
    const year = date.toLocaleString('pt-BR', { year: 'numeric' });
    const [starterDate, setStarterDate] = useState<any>(moment(new Date()).startOf('month').add(1, 'day').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState<any>(moment(new Date()).endOf('month').add(1, 'day').format('YYYY-MM-DD'));
    

    useEffect(() => {
        setLoading(true);

        setPageName('Financeiro');
        setPageType('normal');        

        setLoading(false);

        getFinancialData();
    }, [])
    
    async function getFinancialData() {
        try {
            setLoading(true);
            
            const response = await api.get('/financeiro/dashboard');

            if (response.data.status !== 'success') {

                throw new Error(response.data.message);

            } else {

                setFinancialData(response.data.result);

            }

            setLoading(false);
            
        } catch (error: any) {

            console.log('log do erro financeiro', error);

            setLoading(false);

        }
        
    }

    async function downloadFinancial() {
        try {
            setLoading(true);
            
            const response = await api.get(`/financeiro/exportar`);
            const url = window.URL.createObjectURL(new Blob([s2ab(response.data)]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `financeiro/${month}-${year}.csv`);
            document.body.appendChild(link);
            link.click();

            setLoading(false);

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }

    async function downloadFinancialPersonalized() {
        try {
            setLoading(true);
            // const startDate = moment(starterDate).startOf('month').format('YYYY-MM-DD');
            // const finalDate = moment(endDate).endOf('month').format('YYYY-MM-DD');            
            
            const response = await api.get(`/financeiro/exportar?dataInicio=${starterDate}&dataFim=${endDate}`);
            const url = window.URL.createObjectURL(new Blob([s2ab(response.data)]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `financeiro/${month}-${year}.csv`);
            document.body.appendChild(link);
            link.click();

            setLoading(false);

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }

    function s2ab(s:any) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    const handleClearDates = () => {
        setStarterDate(new Date());
        setEndDate(new Date());
    }
    
    return (
        <Container>

            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                !loading &&
                <>
                    <TopContaineir>
                        <CardWelcomeOperator month={month} year={year} financial={true} />
                        <FinancialReports>
                            <div className="left">
                                <div className="circle">
                                    <img src={Reports} alt="icon" />
                                </div>
                            </div>
                            <div className="infos">
                                <div className="title">Relatórios financeiros</div>
                                <div className="subtitle">
                                    Você pode <span>exportar um relatório geral</span> <br />
                                    <span>do mês atual</span> ou <span>personalizá-lo com filtros</span>.
                                </div>
                                <div className="buttons">
                                    <MensalButton
                                        onClick={downloadFinancial}
                                    >
                                        <IconCalendar />
                                        Mensal
                                    </MensalButton>
                                    
                                    {/* <Link
                                        to={{
                                            pathname: '/demandas',
                                            state: {
                                                filter: 'month'
                                            }
                                        }}
                                    >
                                        <PersonalizeButton>
                                            <IconFilter />
                                            Personalizar
                                        </PersonalizeButton>
                                    </Link> */}
                                    <PersonalizeButton onClick={() => setOpenModal(true)}>
                                        <IconFilter />
                                        Personalizar
                                    </PersonalizeButton>
                                </div>
                            </div>
                        </FinancialReports>
                    </TopContaineir>  

                    <BottomContainer>
                        <InfoCard>
                            <div className="icons">
                                <div className="icon accept">
                                    <IconFinishedAccept />
                                </div>
                                <Link
                                    to={{
                                        pathname: '/demandas',
                                        state: {
                                            selected: '3',
                                            demands: 'accepted',
                                        }
                                    }}
                                >
                                    <div className="button">
                                        <IconExternalLink />
                                    </div>
                                </Link>
                            </div>

                            <div className="title">
                                Demandas finalizadas <br /> 
                                com aceite
                            </div>
                            <div className="numbers accept">
                                {financialData?.demandasFinalizadas} <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon receive">
                                    <IconArrowRigthDown />
                                </div>                        
                            </div>

                            <div className="title">
                                A receber <br />
                                dos clientes
                            </div>
                            <div className="numbers receive">
                                {/* <span>R$</span>  */}
                                {
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(financialData?.receberClientes ?? 0)
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon success">
                                    <IconArrowRigthDown />
                                </div>                        
                            </div>

                            <div className="title">
                                Lucro total
                            </div>
                            <div className="numbers sucess">
                            {/* <span>R$</span>  */}
                            {
                                new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                }).format(financialData?.lucroTotal ?? 0)
                            } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon prof">
                                    <IconArrowRigthUp />
                                </div>                        
                            </div>

                            <div className="title">
                                Custo total <br />
                                dos profissionais
                            </div>
                            <div className="numbers prof">
                                {/* <span>R$</span>  */}
                                {
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(financialData?.custoTotalProfissionais ?? 0)
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon accept">
                                    <IconArrowRigthUp />
                                </div>
                            </div>

                            <div className="title">
                                Valor adiantado <br />
                                durante o andamento
                            </div>
                            <div className="numbers ongoing">
                                {/* <span>R$</span>  */}
                                {   
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(financialData?.valorAdiantadoDuranteAndamento ?? 0)
                                } <span>total</span>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <div className="icons">
                                <div className="icon to-pay">
                                    <IconArrowRigthUp />
                                </div>
                            </div>

                            <div className="title">
                                Valor restante <br />
                                a pagar aos profissionais
                            </div>
                            <div className="numbers to-pay">
                                {/* <span>R$</span>  */}
                                {
                                    new Intl.NumberFormat('pt-BR', {
                                        style: 'currency',
                                        currency: 'BRL'
                                    }).format(financialData?.valorRestantePagarProfissionais ?? 0)
                                    // .replace('R$', '')
                                } <span>total</span>
                            </div>
                        </InfoCard>
                    </BottomContainer>          
                </>
            }         

            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModal((openModal: any) => !openModal)}
                openModal={openModal}
                width="400px"
            >
                <ModalMain>
                    <ModalTitleHeader>
                        <ModalTitle>Personalizar data do relátorio</ModalTitle>
                        <CloseModal
                            onClick={() => {
                                setOpenModal(false);
                                handleClearDates();
                            }}
                        >
                            <IconClose />
                        </CloseModal>
                    </ModalTitleHeader>
                    <div className="select-date">
                        <div className="start-date">
                            <div className="filters">
                                Data inicial:
                            </div>

                            <div className="date-field">
                                <DatePicker 
                                    onChange={setStarterDate} 
                                    value={starterDate} 
                                    calendarIcon={null} 
                                    disableCalendar={true} 
                                    clearIcon={null}
                                />
                            </div>
                        </div>
                        <div className="start-date">
                            <div className="filters">
                                Data final:
                            </div>

                            <div className="date-field">
                                <DatePicker onChange={setEndDate} value={endDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <ButtonDefault
                            className="save"
                            onClick={() => {
                                downloadFinancialPersonalized();
                                setOpenModal(false);
                                handleClearDates();
                            }}
                        >
                            Baixar relatório personalizado
                        </ButtonDefault>
                    </div>
                </ModalMain>
            </ModalDefault>   

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}