//  React
import { useEffect, useRef, useState } from "react";

// Styles
import { Container, Footer, Header, HearingCard, LeftSide, Main, RightStatus, ToTheTop, Whatsapp } from "./styles";

// Assets
import OrientationsImages from '../../assets/images/orientação.png';

// Icons
import { IconAccept, IconLogoJuridico, IconToTheTop, IconWhatsapp } from "../../components/icons";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";
import DemandFiles from "../../components/Ui/DemandFiles";

// Libraries
import Moment from 'react-moment';
import 'moment/locale/pt-br';

interface OrientationCardProps {
    cidade_comarca?: any,
    uf_comarca?: any,
    numero_processo?: any,
    modalidade?: any,
    tipo_audiencia?: any,
    profissional_necessario?: any,
    data?: any,
    rua?: any,
    numero?: any,
    bairro?: any,
    complemento?: any,
    cep?: any,
    endereco_virtual?: any,
    descricao_categoria_servico?: any,
    titulo_servico?: any,
    orientacoes?: any,
    arquivos?: any,
    id_operador?: any,
    nomeOperador?: any,
}

export default function ProfessionalOrientations(orientations: any) {
    
    const [ orientationsCard, setOrientationsCard ] = useState<OrientationCardProps>();
    const [ activePage, setActivePage ] = useState<number>(1);

    const ModalTop = useRef<any>(null);

    const scrollToTop = () => ModalTop.current.scrollIntoView();     

    useEffect(() => {

        setOrientationsCard(orientations.orientacoes);     
        
    }, [orientations])      

    return (
        <Container>

            <Header ref={ModalTop}>
                <div className="logo"><IconLogoJuridico /></div>
            </Header>

            <Main>
                <LeftSide active={activePage}>
                    <img src={OrientationsImages} alt="Imagem de cadastro concluído" />
                </LeftSide>

                <RightStatus>

                    <div className="progress">
                        <div className="progressbar">
                            <div 
                                className={
                                    activePage === 1 ? "percent" : '' ||
                                        activePage === 2 ? "percent two" : '' ||
                                            activePage === 3 ? "percent three" : '' ||
                                                activePage === 4 ? "percent four" : '' ||
                                                    activePage === 5 ? "percent five" : '' ||
                                                        activePage === 6 ? "percent six" : '' ||
                                                            activePage === 7 ? "percent seven" : '' ||
                                                                activePage === 8 ? "percent eight" : '' ||
                                                                    activePage === 9 ? "percent nine" : '' 
                                }>
                            </div>
                        </div>                        
                        <div className="percentage">
                            {
                                activePage === 1 ? "0%" : '' ||
                                    activePage === 2 ? "10%" : '' ||
                                        activePage === 3 ? "15%" : '' ||
                                            activePage === 4 ? "25%" : '' ||
                                                activePage === 5 ? "35%" : '' ||
                                                    activePage === 6 ? "45%" : '' ||
                                                        activePage === 7 ? "55%" : '' ||
                                                            activePage === 8 ? "75%" : '' ||
                                                                activePage === 9 ? "100%" : ''
                            }
                        </div>                        
                    </div>

                    <div className="status">
                        {
                            activePage === 1 &&
                            <>
                                <div className="status-title">Orientações para audiência</div>

                                <div className="status-subtitle"> 
                                    <span>{orientationsCard?.nomeOperador.split(' ')[0]} {orientationsCard?.id_operador}</span> acaba de explicar o que se espera da sua atuação na seguinte audiência:
                                </div>
                            </>
                        }

                        {
                            activePage === 2 &&
                            <>
                                <div className="status-title">Fique por dentro</div>

                                <div className="stay-in">
                                    <div>
                                        Seu conhecimento sobre as instruções a seguir é <span>pré-requisito</span> para a realização da audiência!
                                    </div>
                                    <div>
                                        Você tem <span>2 horas</span> para ler todos os itens contidos nesta orientação.
                                    </div>
                                    <div className="link">
                                        Em caso de dúvidas, <span>
                                            <a href="https://api.whatsapp.com/send?phone=5531983474387&text=Ol%C3%A1!%20Tudo%20bem%3F" target={'_blank'} rel="noreferrer">
                                                entre em contato    
                                            </a>
                                        </span> com nossos operadores.
                                    </div>
                                </div>
                            </>
                        }

                        {
                            activePage === 3 &&
                            <>
                                <div className="status-title">Qual parte representamos?</div>
                                {
                                  orientationsCard?.orientacoes.length > 1
                                    ?   <div className="small-info">
                                            Informação atualizada em <Moment locale='pt-br' format="DD/MM/YYYY">{orientationsCard?.orientacoes[1].modificado}</Moment> por {orientationsCard?.nomeOperador.split(' ')[0]} {orientationsCard?.id_operador}.
                                        </div>
                                    :   <div className="small-info">
                                            Informação atualizada em <Moment locale='pt-br' format="DD/MM/YYYY">{orientationsCard?.orientacoes[0].modificado}</Moment> por {orientationsCard?.nomeOperador.split(' ')[0]} {orientationsCard?.id_operador}.
                                        </div>
                                    
                                }
                                {
                                    orientationsCard?.orientacoes.length > 1
                                        ?   <div className="status-subtitle">
                                                {orientationsCard?.orientacoes[1]?.parte_representada}
                                            </div>
                                        :   <div className="status-subtitle">
                                                {orientationsCard?.orientacoes[0]?.parte_representada}
                                            </div>
                                }                                
                            </>

                        }

                        {
                            activePage === 4 &&
                            <>
                                <div className="status-title">Identificação do advogado</div>
                                {
                                    orientationsCard?.orientacoes.length > 1
                                        ? <div className="small-info">
                                            Informação atualizada em <Moment locale='pt-br' format="DD/MM/YYYY">{orientationsCard?.orientacoes[1].modificado}</Moment> por {orientationsCard?.nomeOperador.split(' ')[0]} {orientationsCard?.id_operador}.
                                        </div>
                                        : <div className="small-info">
                                            Informação atualizada em <Moment locale='pt-br' format="DD/MM/YYYY">{orientationsCard?.orientacoes[0].modificado}</Moment> por {orientationsCard?.nomeOperador.split(' ')[0]} {orientationsCard?.id_operador}.
                                        </div>

                                }
                                {
                                    orientationsCard?.orientacoes.length > 1
                                        ?   <div className="status-subtitle">
                                                {orientationsCard?.orientacoes[1].nome_advogado}

                                                <p>
                                                    {
                                                        orientationsCard?.orientacoes[1].oab_advogado
                                                            ? orientationsCard?.orientacoes[1].oab_advogado
                                                            : 'MG 000.00'
                                                    }
                                                </p>
                                            </div>
                                        :   <div className="status-subtitle">
                                                {orientationsCard?.orientacoes[0].nome_advogado}

                                                <p>
                                                    {
                                                        orientationsCard?.orientacoes[0].oab_advogado
                                                            ? orientationsCard?.orientacoes[0].oab_advogado
                                                            : 'MG 000.00'
                                                    }
                                                </p>
                                            </div>
                                }

                                
                            </>

                        }

                        {
                            activePage === 5 &&
                            <>
                                <div className="status-title">Identificação do preposto</div>

                                {/* <div className="small-info">Informação atualizada em 21/09/2022 por Sara J000.</div> */}

                                <div className="status-subtitle">
                                    {
                                        orientationsCard?.orientacoes.length > 1
                                        ?   orientationsCard?.orientacoes[1].nome_preposto
                                        :   orientationsCard?.orientacoes[0].nome_preposto
                                    }
                                </div>
                            </>

                        }

                        {
                            activePage === 6 &&
                            <>
                                <div className="status-title">Proposta de acordo</div>

                                <div className="status-subtitle">
                                    {
                                        orientationsCard?.orientacoes.length > 1
                                        ?   orientationsCard?.orientacoes[1].proposta_acordo
                                        :   orientationsCard?.orientacoes[0].proposta_acordo
                                    }
                                </div>
                            </>
                        }

                        {
                            activePage === 7 &&
                            <>
                                <div className="status-title">Requerimentos</div>

                                {/* <div className="status-subtitle">
                                    {
                                        orientationsCard?.orientacoes.length > 1
                                        ?   orientationsCard?.orientacoes[1].requerimentos
                                        :   orientationsCard?.orientacoes[0].requerimentos
                                    }
                                </div> */}

                                <div className="bullet">
                                    <ul>
                                        {   
                                            orientationsCard?.orientacoes.length > 1
                                            ? 
                                                orientationsCard?.orientacoes[1].requerimentos &&
                                                orientationsCard?.orientacoes[1]?.requerimentos?.split('\n').map((row: any, key: any) => (
                                                    <li key={key}>{row.replace('•', '')}</li>
                                                ))
                                            :  
                                                orientationsCard?.orientacoes[0].requerimentos &&
                                                orientationsCard?.orientacoes[0]?.requerimentos?.split('\n').map((row: any, key: any) => (
                                                    <li key={key}>{row.replace('•', '')}</li>
                                                ))
                                        }
                                    </ul>
                                </div>
                            </>
                        }
                        
                        {
                            activePage === 8 &&
                            <>
                                <div className="status-title">Documentos a juntar</div>

                                {/* <div className="small-info">Informação atualizada em 21/09/2022 por Sara J000.</div> */}

                                    {
                                        orientationsCard?.orientacoes.length > 1
                                            ?   
                                                <div className="bullet">
                                                    <ul>
                                                        {
                                                            orientationsCard?.orientacoes[1]?.documentos?.split('\n').map((row: any, key: any) => (
                                                                <li key={key}>{row.replace('•', '')}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>                                                
                                            :   
                                                <div className="bullet">
                                                    <ul>
                                                        {
                                                            orientationsCard?.orientacoes[0]?.documentos?.split('\n').map((row: any, key: any) => (
                                                                <li key={key}>{row.replace('•', '')}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>      
                                    }                                        
              

                                <div className="files">
                                    <DemandFiles 
                                        files={orientationsCard?.orientacoes[1]?.arquivos}
                                        hideDate={true}
                                    />
                                </div>
                            </>

                        }

                        {
                            activePage === 9 &&
                            <>
                                <div className="status-title">Agora sim, tudo pronto!</div>

                                <div className="contact">
                                    <div>
                                        Siga as instruções descritas aqui para realizar a demanda conforme o esperado pelo cliente. Você pode consultar as orientações dentro da demanda. 
                                    </div>
                                    <div>
                                        <span>
                                            Esperamos pelo envio do documento da ata ao fim da audiência, 
                                        </span> para concluir a demanda com sucesso.
                                    </div>
                                    <div className="link">
                                        Em caso de dúvida, não hesite em nos contatar:
                                        <div className="phones">
                                            <span>(31)  98347-4387</span> ou <span>(31) 99192-7691</span>
                                        </div>
                                    </div>
                                </div>

                                <a href="https://api.whatsapp.com/send?phone=5531983474387&text=Ol%C3%A1!%20Tudo%20bem%3F" target={'_blank'} rel="noreferrer">
                                    <Whatsapp>
                                        Iniciar conversa no whatsapp
                                        <div className="icon">
                                            <IconWhatsapp />
                                        </div>
                                    </Whatsapp>
                                </a>
                            </>
                        }
                        
                        
                    </div>
                    {
                        activePage === 1 &&
                        <>
                            <HearingCard>
                                <div className="title">
                                    {
                                        orientationsCard?.descricao_categoria_servico.split(' ')[0] === 'Audiências' ? 'Audiência' : '' ||
                                        orientationsCard?.descricao_categoria_servico.split(' ')[0] === 'Diligências' ? 'Diligência' : '' ||
                                        orientationsCard?.descricao_categoria_servico.split(' ')[0] === 'Protocolos' ? orientationsCard?.titulo_servico : ''
                                    } {orientationsCard?.titulo_servico.split(' ')[0] === 'Protocolo' ? '' : orientationsCard?.titulo_servico.split(' ')[0]}                       
                                </div>

                                <div className="info">
                                    <span>Comarca:</span>
                                    <div className="show-info">{orientationsCard?.uf_comarca} | {orientationsCard?.cidade_comarca}</div>
                                </div>

                                <div className="info">
                                    <span>Número do processo:</span>
                                    <div className="show-info">{orientationsCard?.numero_processo}</div>
                                </div>
                                
                                {
                                    orientationsCard?.descricao_categoria_servico === 'Audiências' &&
                                    <>
                                        <div className="info">
                                            <span>Modalidade:</span>
                                            <div className="show-info">
                                                {
                                                    orientationsCard?.modalidade === 'online'
                                                        ? 'Virtual'
                                                        : "Presencial"
                                                }
                                            </div>
                                        </div>

                                        <div className="info">
                                            <span>Subtipo:</span>
                                            <div className="show-info">{orientationsCard?.tipo_audiencia}</div>
                                        </div>

                                        <div className="info">
                                            <span>Profissional necessário:</span>
                                            <div className="show-info">
                                                {
                                                    orientationsCard?.profissional_necessario === 'Advogado_Preposto'
                                                        ? 'Advogado + Preposto'
                                                        : orientationsCard?.profissional_necessario
                                                }
                                            </div>
                                        </div>

                                        <div className="info">
                                            <span>Data e hora da audiência:</span>
                                            <div className="show-info">{
                                                orientationsCard?.data !== null
                                                    ? <Moment locale='pt-br' format="DD/MM/YYYY | HH:mm">{orientationsCard?.data}</Moment>
                                                    : 'Sem data'
                                            }</div>
                                        </div>
                                    </>
                                }

                                {
                                    orientationsCard?.modalidade !== 'online' &&
                                    <div className="address">
                                        <span>Endereço físico:</span>
                                        <div className="wrap">
                                            <div className="address-info">{orientationsCard?.rua}</div>
                                            <div className="address-info">{orientationsCard?.numero}</div>
                                            <div className="address-info">{orientationsCard?.bairro}</div>
                                            <div className="address-info">{orientationsCard?.complemento}</div>
                                            <div className="address-info">{orientationsCard?.cep}</div>
                                        </div>
                                    </div>
                                }

                                {
                                    orientationsCard?.modalidade === 'online' &&
                                    <div className="info">
                                        <span>Endereço virtual:</span>
                                        <div className="show-info">{orientationsCard?.endereco_virtual}</div>
                                    </div>
                                }


                            </HearingCard>

                            <ToTheTop
                                onClick={scrollToTop}
                            >
                                Voltar ao topo 
                                <div className="icon">
                                    <IconToTheTop />                      
                                </div>
                            </ToTheTop>
                        </>
                    }


                </RightStatus>
            </Main>

            <Footer>
                <div className="container">
                    <div className="progress">
                        <IconAccept />
                        <div>
                            Ao continuar, você se torna <span>ciente</span> das orientações.
                        </div>
                    </div>

                    <div className="buttons">
                        {
                            activePage === 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={orientations.close}
                            >
                                Fechar
                            </ButtonDefault>
                        }

                        {
                            activePage > 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Voltar
                            </ButtonDefault>
                        }

                        {
                            activePage < 9 &&
                            <ButtonDefault
                                className="continue"
                                type="submit"
                                onClick={() => setActivePage(activePage + 1)}
                            >
                                Continuar
                            </ButtonDefault>
                        }

                        {
                            activePage === 9 &&
                            <ButtonDefault
                                className="continue"
                                type="submit"
                                onClick={orientations.finish}                                
                            >
                                Concluir
                            </ButtonDefault>
                        }
                    </div>
                </div>
            </Footer>
        </Container>
    )
}