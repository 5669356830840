import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/UserContext';
import { IconArrowLeft } from '../icons';
import { Container } from './styles';

interface Props {
    title?: string;
    type?: string;
}

export default function Header({ title, type }: Props) {
  const history = useHistory();
  const { pageName } = useAuth();

  return (
    <Container pageType={type}>

      {
        pageName !== 'Propostas' &&
        pageName !== 'Profissionais' &&
        pageName !== 'Operadores' &&
        pageName !== 'Demandas' &&
        pageName !== 'Visão geral' &&
        pageName !== 'Financeiro' &&        

        <div 
          className='back'
          onClick={() => history.goBack()}
        >
          <IconArrowLeft />
          Voltar
        </div>
      }
       <h1>{title ?? 'Visão geral'}</h1>
    </Container>
  )
}
