// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import Header from "../../../components/HeaderSite";
import Footer from "../../../components/Footer";

// Styles
import { 
    AreaImage, 
    AreaHeader, 
    AreaSite, 
    Container, 
    HeaderWrapper, 
    Image, 
    Description, 
    AreaDescription, 
    TextDescription, 
    CardSection, 
    CardWrapper, 
    Card, 
    Text, 
    RevolutionSection, 
    RevolutionCard, 
    RevolutionButton, 
    StructureSection, 
    StructureCardWrapper, 
    StructureCard, 
    RevolutionCarrer, 
    CarrerCard, 
} from "./styles";

// Icons
import { 
    IconDuplicate, 
    IconHand, 
    IconHappyStar, 
    IconMoney, 
    IconRight, 
    IconStar 
} from "../../../components/icons";

// Images
import imageSite from "../../../assets/images/logoJuricoJa.svg";
import imageRevolution from "../../../assets/images/RevolutionFrame.svg";
import National from "../../../assets/images/National.svg";
import Representation from "../../../assets/images/Representation.svg";
import Professional from "../../../assets/images/Professional.svg";
import Clients from "../../../assets/images/Clients.svg";
import imageRevolutionCarrer from "../../../assets/images/Revolution.svg";

export default function WhoWeARe() {

    const history = useHistory();
    const [copyMail, setCopyMail] = useState<boolean>(false);
    const [copiedMail, setCopiedMail] = useState<boolean>();

    useEffect(() => {
        function scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }

        scrollTop();
    }, [])

    return (
        <Container>
            <AreaHeader>
                <Header />
            </AreaHeader>

            <HeaderWrapper>

                <AreaSite>
                    <AreaImage>
                        <div className='image'>
                            <Image src={imageSite} />
                        </div>
                    </AreaImage>

                    <Description>
                        <AreaDescription>
                            <TextDescription>
                                <h1>Quem somos</h1>
                                <div className='description'>
                                    <p>
                                        Idealizado em 2017, 123JUS se tornou uma estrutura sólida, voltada para logística jurídica de alta qualidade, referência em tecnologia e soluções para nossos clientes.<br />
                                    </p>
                                    <p>
                                        Solucionamos suas demandas de forma eficaz, assertiva e responsável, sempre em busca de melhores resultados e menores custos.
                                    </p>
                                    <div className='icon'>
                                        <IconStar />
                                    </div>
                                </div>
                            </TextDescription>   
                        </AreaDescription>
                    </Description>
                </AreaSite>

            </HeaderWrapper>

            <>
                <CardSection>
                    <CardWrapper>
                        <Card>
                            <IconMoney />
                            <p>Valores fixos de serviços</p>
                        </Card>
                        <Card>
                            <IconHappyStar />
                            <p>Agilidade nos processos</p>
                        </Card>
                        <Card>
                            <IconHand />
                            <p>Fatura única mensal</p>
                        </Card>
                    </CardWrapper>
                </CardSection>

                <Text>
                    <div className='text'>
                        Nos destacamos por nossa tecnologia, busca de menores custos, evitando <br />
                        pedidos de reembolso, foco em resultados e métodos únicos para agilizar o <br />
                        envio das diligências. Além disso, 123JUS tem como valores a <span>transparência,</span> <br />
                        <span>ética,</span> <span>inovação</span> e <span>compromisso.</span>
                    </div>
                </Text>

                <RevolutionSection>
                    <RevolutionCard>
                        <div className='text'>
                            <span>Revolucionamos</span>
                            <br /> a contratação de profissionais jurídicos, através da tecnologia
                            <br /> e inovação.
                        </div>

                        <RevolutionButton
                            onClick={() => history.push('/solicitarservico')}
                        >
                            Solicite uma demanda!
                        </RevolutionButton>
                    </RevolutionCard>

                    <div className='image'>
                        <Image src={imageRevolution} />
                    </div>
                </RevolutionSection>

                <StructureSection>
                    <div className='title'>Entenda a nossa <span>estrutura de sucesso!</span></div>

                    <StructureCardWrapper>
                        <StructureCard>
                            <div className='header'>
                                <Image src={National} />
                                Atendimento <br /> Nacional
                            </div>
                            <div className='description'>
                                Atuamos em todo o país com uma rede de advogados correspondentes pronta não só para atender os objetivos dos nossos clientes, mas para suprir necessidades que, porventura, possam ainda ser identificadas.
                            </div>
                        </StructureCard>
                        <StructureCard>
                            <div className='header'>
                                <Image src={Representation} />
                                Representantes <br /> Regionais
                            </div>
                            <div className='description'>
                                Contamos com Representantes Regionais que garantem a superação das expectativas dos nossos clientes. <br />
                                É responsável por gerenciar outros profissionais na região em que atua, controlando prazos e a qualidade das demandas.
                            </div>
                        </StructureCard>
                        <StructureCard>
                            <div className='header'>
                                <Image src={Professional} />
                                Profissionais <br /> Jurídicos
                            </div>
                            <div className='description'>
                                Os correspondentes são treinados e contam com tecnologia para realização de serviços com alto padrão de qualidade. Nosso aplicativo permite realizar demandas de forma ágil, organizada e eficaz.
                            </div>
                        </StructureCard>
                        <StructureCard>
                            <div className='header'>
                                <Image src={Clients} />
                                Clientes <br /> Satisfeitos
                            </div>
                            <div className='description'>
                                Nossos clientes contam com um canal 100% digital para solicitação de demandas. <br />
                                Com fatura única mensal ou pagamento antecipado, os pagamentos podem ser feitos de forma organizada e eficiente.
                            </div>
                        </StructureCard>
                    </StructureCardWrapper>
                </StructureSection>

                <RevolutionCarrer>
                    <CarrerCard>
                        <div className='title'>
                            Revolucione <br /> sua <span>carreira jurídica</span>!
                        </div>

                        <div className='description'>
                            Nossa equipe é composta por profissionais experientes e qualificados, <span>treinados e capacitados tecnologicamente</span>  para entregar <br /> os melhores resultados do mercado.
                        </div>

                        {
                            !copyMail &&
                            <RevolutionButton
                                className='button'
                                onClick={() => setCopyMail(true)}
                            >
                                Faça parte da equipe 123JUS!
                            </RevolutionButton>
                        }

                        {
                            copyMail &&
                            <>
                                <div className='mail'>
                                    <span>Envie seu currículo para:</span> <br />
                                    rh@123jus.com.br
                                </div>
                                {
                                    !copiedMail &&
                                    <RevolutionButton
                                        className='button-icon'
                                        onClick={() => { navigator.clipboard.writeText('rh@123jus.com.br'); setCopiedMail(true) }}
                                    >
                                        Copiar email
                                        <IconDuplicate />
                                    </RevolutionButton>
                                }
                                {
                                    copiedMail &&
                                    <RevolutionButton className='button-icon copied'>
                                        Endereço de e-mail copiado!
                                        <IconRight />
                                    </RevolutionButton>
                                }
                            </>
                        }
                    </CarrerCard>

                    <div className='image'>
                        <Image className='carrer' src={imageRevolutionCarrer} />
                    </div>
                </RevolutionCarrer>
            </>

            <Footer />

        </Container>
    )
}