import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh; 
    background: ${({ theme }) => theme.colors.primaryBlue600};
`

export const Header = styled.div`
    width: 1040px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;     

    @media (max-width: 800px) {
        max-width: 100%;
        padding-left: 10%;
    }
`

export const Main = styled.div`
    width: 1040px;
    min-height: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 20px;

    .image {
        width: 595px;
    }

    .status {
        height: 336px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .progress {
            display: flex;
            align-items: center;
            gap: 8px;
            color: ${({ theme }) => theme.colors.interfacePure0};
            margin-bottom: 24px;
            .progress-bar {
                height: 8px;
                width: 172px;
                border-radius: 8px;
                background: ${({ theme }) => theme.colors.primaryOrange600};                        
            }
        }

        .status-title {
            max-width: 245px;
            color: ${({ theme }) => theme.colors.interfacePure0};
            font-size: 24px;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 16px;
        }

        .status-subtitle {
            max-width: 424px;
            color: ${({ theme }) => theme.colors.interfacePure0};
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin-bottom: 32px;
        }

        .buttons {
            display: flex;
            width: 424px;
            gap: 16px;
            .new {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 248px;
                height: 40px;
                background-color: ${({ theme }) => theme.colors.primaryOrange700};
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfacePure0};
                transition: all .3s;
                cursor: pointer;
                &:hover {
                    transform: scale(1.05);
                }
            }

            .continue {
                max-width: 160px;
                height: 40px;
                background-color: transparent;
                border: 2px solid ${({ theme }) => theme.colors.secondaryPool600};

            }
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;
        max-width: 90%;

        .image {
            width: 100%;
            margin-top: 50px;
        }

        .status {
            max-width: 90%;

            .progress {
                .progress-bar {
                    width: 100%;
                }
            }

            .buttons {
                flex-direction: column;
                align-items: center;
                max-width: 100%;

                .new {
                    width: 100%;
                }
            }
        }
    }
`