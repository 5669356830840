/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import api from "../../services/api";
import Checkbox from "../form/InputCheckbox";
import { Archipelago, Container, Island } from "./styles";

interface OperatorIslandProps {
    handleOnChange?: any,
    profile?: any,    
}


export default function OperatorIslands({handleOnChange, profile}: OperatorIslandProps) {

    const [ loading, setLoading ] = useState<any>(false);
    const [ islands, setIslands ] = useState<any>();
    const [ checkedAll, setCheckedAll ] = useState<any>(false);
    const [ checkedBox, setCheckedBox ] = useState<any>([]);

    useEffect(() => {
        async function getIslands() {

            try {
                
                setLoading(true)

                const response = await api.get('ilha')
                setIslands(response.data.result)
                
                if (response.data.status !== 'success') {
                    throw new Error()
                }

                setLoading(false)
                
            } catch (error) {

                console.log('log dos erros recebendo as ilhas', error)
                setLoading(false)
            }
            
        }

        getIslands();
        
    }, [])    

    const handleSelectAll = (e: any) => {
        setCheckedAll(!checkedAll);
        setCheckedBox(islands.map((obj: any) => obj.id_ilha));
        if (checkedAll) {
            setCheckedBox([]);
        }
    };

    const handleClick = (e: any) => {
        setCheckedBox([...checkedBox, e]);
        if (checkedBox.includes(e)) {
            setCheckedBox(checkedBox.filter((item: any) => item !== e));
        }        
    };

    useEffect(() => {
        handleOnChange('ilhas', checkedBox)
    }, [checkedBox])

    return (
        <Container>
            <div className="select-all">
                <Checkbox 
                    name='true'
                    label="Selecionar todas"
                    value={checkedAll}
                    onClick={handleSelectAll}
                />
            </div>

            <Archipelago>
                {   
                    islands && !loading &&
                    islands.map((row: any) => (
                        <Island key={row.id_ilha}>
                            <div>
                                <Checkbox
                                    name={row.id_ilha}
                                    value={checkedBox?.includes(row.id_ilha)}
                                    onClick={handleClick}
                                />
                            </div>
                            <div className="tag">{row.descricao}</div>
                        </Island>
                    )) 
                }               
            </Archipelago>
        </Container>
    )
}