/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from 'react';

// Libraries
import Select from 'react-select';
import { Oval } from 'react-loader-spinner';

// Data
import { brazilianBanks } from '../Data/BrazilianBanks';
import { PixKeyData } from '../Data/PixKeyData';

// Services
import api from '../../services/api';

// Components
import InputDefault from "../form/InputDefault";

// Context
import { useAuth } from '../../contexts/UserContext';

// Styles
import { Wrapper } from './styles';

interface UpdateAccountProps {
    setUpdateBankAccount?: any,
    updateBankAccount?: any,
    userId?: any,
    editProfile?: any,
}

export default function BankData({setUpdateBankAccount, updateBankAccount, userId, editProfile}: UpdateAccountProps ) {

    const { user } = useAuth();
    const [ loading, setLoading ] = useState<any>(false);
    const [ accountData, setAccountData ] = useState<any>();
    const [ DTOAccount, setDTOAccount ] = useState<any>({
        nome_titular: '',
        tipo: '',
        cnpj_cpf: '',
        banco: '',
        agencia: '',
        conta: '',
        digito: '',
        tipo_chave_pix: '',
        chave_pix: '',
    });

    const accountSelect = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            }
        }),
    };

    const accounts = [
        { value: 'Conta PJ', label: 'Conta PJ' },
        { value: 'Conta Corrente', label: 'Conta Corrente' },
        { value: 'Conta Poupança', label: 'Conta Poupança' },
        { value: 'Conta Pessoa Física', label: 'Conta Pessoa Física' },
    ]; 

    const pixKey = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
    }

    const bankSelect = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
    }

    function handleOnChange(name: any, value: any) {       
        
        const newDTO: any = DTOAccount
        newDTO[name] = value
        setDTOAccount({ ...newDTO })

        setUpdateBankAccount(DTOAccount)
        
    };

    useEffect(() => {
        
        async function getBankData() {
            try {
                
                if (user.profile !== 'comum') {
                    
                    setLoading(true);
    
                    const response = await api.get('conta-usuario/' + userId);
                    setAccountData(response.data.result); 

                    setLoading(false);                                  
    
                } else {
                    setLoading(true);
    
                    const response = await api.get('conta-usuario/' + user.id);
                    setAccountData(response.data.result);   
                                   
                    setLoading(false);  
                }
                
    
            } catch (error) {
    
                console.log('erro do account data', error);
                setLoading(false);  
    
            }
        }

        getBankData()       
        
    }, [userId])


    return (
        
        <Wrapper>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }
            {
                accountData && !loading
                ?   <form className='bank-account-form' >
                    <div className="account-data">
                        <InputDefault
                            title="Nome do titular:"
                            placeholder="Nome"
                            name="nome_titular"
                            value={accountData[0]?.nome_titular}
                            onChangeText={(value) => handleOnChange('nome_titular', value)}
                            className='big'
                            disabled={editProfile ? false : true}
                        />

                        
                        <div className="label">
                            <p>Tipo de conta:</p>
                            <Select
                                styles={accountSelect}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 8,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#DDFAF4',
                                        primary50: '#DDFAF4',
                                        primary: '#02E8BE',
                                    },
                                })}
                                name='tipo'
                                placeholder="Selecionar"
                                defaultValue={{ value: accountData[0]?.tipo, label: accountData[0]?.tipo }}
                                onChange={(selected: any) => handleOnChange('tipo', selected.value)}
                                options={accounts}
                                isDisabled={editProfile ? false : true}
                            />                        
                        </div>
                        

                        {
                            accountData[0]?.cnpj_cpf.length > 14
                                ?   <InputDefault
                                        title="CNPJ:"
                                        mask="99.999.999/9999-99"
                                        placeholder="CPF / CNPJ"
                                        name="cnpj"
                                        value={accountData[0]?.cnpj_cpf}
                                        onChangeText={(value) => handleOnChange('cnpj_cpf', value)}
                                        className='big'
                                        disabled={editProfile ? false : true}
                                    />
                                :   <InputDefault
                                        title="CPF:"
                                        mask="999.999.999-99"
                                        placeholder="CPF / CNPJ"
                                        name="cnpj"
                                        value={accountData[0]?.cnpj_cpf}
                                        onChangeText={(value) => handleOnChange('cnpj_cpf', value)}
                                        className='big'
                                        disabled={editProfile ? false : true}
                                    />
                        }

                        
                    </div>

                    <div className="bank-data">
                        <div className="label">
                            <p>Banco:</p>
                            <Select
                                styles={bankSelect}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 8,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#DDFAF4',
                                        primary50: '#DDFAF4',
                                        primary: '#02E8BE',
                                    },
                                })}
                                name='banco'
                                placeholder="Selecionar"
                                defaultValue={{ value: accountData[0]?.banco, label: accountData[0]?.banco }}
                                onChange={(selected: any) => handleOnChange('banco', selected.label)} 
                                options={brazilianBanks}
                                isDisabled={editProfile ? false : true}
                            />
                        </div>

                        <InputDefault
                            title="Agência:"
                            placeholder="0000"
                            name="agencia"
                            value={accountData[0]?.agencia}
                            onChangeText={(value) => handleOnChange('agencia', value)}
                            className='big'
                            disabled={editProfile ? false : true}
                        />
                        <InputDefault
                            title="Conta:"
                            placeholder="0000000"
                            name="conta"
                            value={accountData[0]?.conta}
                            onChangeText={(value) => handleOnChange('conta', value)}
                            className='account'  
                            disabled={editProfile ? false : true}                      
                        />
                        <InputDefault
                            title="Dígito:"
                            placeholder="00"
                            name="digito"
                            value={accountData[0]?.digito}
                            onChangeText={(value) => handleOnChange('digito', value)}
                            className='small' 
                            disabled={editProfile ? false : true}                      
                        />
                    </div>

                    <div className="pix-data">
                        <div className="label">
                            <p>Tipo de chave do PIX:</p>
                            <Select
                                styles={pixKey}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 8,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#DDFAF4',
                                        primary50: '#DDFAF4',
                                        primary: '#02E8BE',
                                    },
                                })}
                                name='tipo_chave_pix'
                                placeholder="Selecionar"
                                defaultValue={{ value: accountData[0]?.tipo_chave_pix, label: accountData[0]?.tipo_chave_pix }}
                                onChange={(selected: any) => handleOnChange('tipo_chave_pix', selected.label)}
                                options={PixKeyData}
                                isDisabled={editProfile ? false : true}
                            />                        
                        </div>

                        <InputDefault
                            title="Chave PIX:"
                            placeholder="Chave pix"
                            name="chave_pix"
                            value={accountData[0]?.chave_pix}
                            onChangeText={(value) => handleOnChange('chave_pix', value)} 
                            className='fit-width'
                            disabled={editProfile ? false : true}                       
                        />
                    </div>
                    </form>
                :   'Erro carregando a conta bancária'
            }
        </Wrapper>

    )
}