import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 16px;
    width: 1040px;
    height: auto;
    min-height: 100vh;
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0};     
    
    .toasted {
        z-index: 99999;        
    }

    .warning {
        display: flex;
        gap: 16px;        
    }
`

export const TagLeft = styled.div`
    width: 600px;
    height: 367px;

    .title {
        margin-bottom: 32px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-size: 18px;
        font-weight: 400;
    }

    .search {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 600px;
        height: 56px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        margin-bottom: 40px;
        position: relative;
        input {
            width: 400px;
            border: none;
            outline: none;
            background: ${({ theme }) => theme.colors.background};
        }

        .buscar {
            cursor: pointer;
        }

        .clean-search {
            position: absolute;
            top: 15px;
            right: 16px;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
                &:hover {
                    cursor: pointer;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
        }

        .show-tags {
            display: flex;
            flex-direction: column;
            width: 600px;
            height: 290px;
            background: ${({ theme }) => theme.colors.interfacePure0};
            border: 1px solid #EAEEF3;
            border-radius: 8px;
            padding: 16px;
            position: absolute;
            top: 64px;
            left: 0;
            overflow: auto;

            &.off {
                display: none;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText300};
                margin-bottom: 8px;

                &.empty {
                    margin-bottom: 16px;
                }
            }

            .tag-arrays {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                height: auto;

                .tag {
                    width: 100%;
                    height: 56px;
                    padding: 16px;
                    font-size: 16px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    cursor: pointer;
                    &:hover {
                        background-color: ${({ theme }) => theme.colors.interfacePure100};
                    }
                }
            }

            .span-no-tag {
                margin-top: 8px;
            }

            .no-tags {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: 100%;
                height: auto;

                .tag {
                    width: 100%;
                    height: 56px;
                    padding: 16px;
                    font-size: 16px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    cursor: pointer;
                    &:hover {
                        background-color: ${({ theme }) => theme.colors.interfacePure100};
                    }
                }
            }
        }
    }

    .tags {
        .title {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
        }
        .selected-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            width: 100%;
            height: 188px;
            border: 1px solid #EAEEF3;
            border-radius: 8px;
            padding: 16px;
            overflow: auto;

            .example-tag {
                display: flex;
                align-items: center;
                width: fit-content;
                height: 24px;
                background: ${({ theme }) => theme.colors.background};
                border: 1px dashed ${({ theme }) => theme.colors.primaryBlue500};
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                padding: 0 8px;
            }

            .tag {
                display: flex;
                align-items: center;
                width: fit-content;
                height: 24px;
                background: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px dashed ${({ theme }) => theme.colors.primaryBlue500};
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfacePure0};
                padding: 0 8px;

                .delete {
                    cursor: pointer;
                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
            }
        }
    }
`

export const TagRigth = styled.div`
    width: 424px;
    height: 367px;
    
    .subtitle {
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 32px;

        span {
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }

    .tag-box {
        width: 100%;
        height: 308px;
        background-color: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        padding: 24px;

        ul {
            li {
                list-style-type: disc;
                margin-left: 18px;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
            }
        }
    }
`                                   