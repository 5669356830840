import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0 88px;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.primaryBlue400}; 

    @media (max-width: 800px) {
        padding: 0 44px;
    }
`

export const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    max-width: 1366px;
    height: 75%;
    max-height: 592px;
    border-radius: 16px 0 0 16px;
    position: relative;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        top: 0;
        margin-top: -50px;
        width: 100%;
        left: 10px;
        max-width: 98%;

    }

    .back {
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        color: ${(props) => props.theme.colors.white};
        width: 200px;
        line-height: 10px;
        font-size: 16px;
        cursor: pointer;
        span {
            margin-right: 8px;
        }
    }

    .icons {
        display: flex;
        gap: 25px;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        .card {
            width: 20px;
            height: 20px;
            color: ${(props) => props.theme.colors.white};
            cursor: pointer;
            transition: all .3s;
            &:hover {
                transform: scale(1.3);
            }            
        }
    }

    .password {
        label {
            span {
                font-size: 14px;
            }
        }
    }

    @media (max-width: 800px) {
        .header {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
        }

        .back {
            justify-content: center;
        }

        .icons {
            justify-content: center;
        }
    }
`

export const MainLeft = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4% 6%;
    background: ${(props) => props.theme.colors.white};
    width: 51%;
    border-radius: 16px 0 0 16px;

    h1 {
        color: ${(props) => props.theme.colors.primaryBlue500};
        span {
            color: ${(props) => props.theme.colors.primaryOrange500};
        }
    }

    .forgot {
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 25px;
        
    }

    .forgot-text {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        max-width: 300px;
        
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 24px;  

        animation: ${appearFromLeft} 0.7s;

        .password {
            position: relative;
        }
        .icon {
            position: absolute;
            top: 55%;
            right: 5%;
            cursor: pointer;
        }

        .checkbox {
            display: flex;
            align-items: center;            
        }

        .field {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        button {
            width: 248px;
        }

        .buttons {
            display: flex;
            gap: 15px;
            button {
                &:first-child {
                    width: 160px;
                    background: transparent;
                    border-radius: 8px;
                    border: 1px solid ${(props) => props.theme.colors.primaryOrange500};
                    color: ${(props) => props.theme.colors.primaryOrange500};
                    transition: all .3s;
                    &:hover {
                        background: ${(props) => props.theme.colors.primaryOrange500};
                        color: ${(props) => props.theme.colors.white};
                    }
                }
            }
        }
    }

    .forgot-form {
        height: 65%;


    }

    .confirm {
        position: relative;
        .check {
            position: absolute;
            top: 0;
            right: 1%;
            font-size: 14px;
            font-weight: 500;
            color: ${(props) => props.theme.colors.interfaceDanger};
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .ok {
            display: flex;
            gap: 8px;
            position: absolute;
            top: 0;
            right: 1%;
            font-size: 14px;
            font-weight: 500;
            color: ${(props) => props.theme.colors.interfaceSuccess};
        }
    }

    .danger {
        input {
            border: 1px solid ${(props) => props.theme.colors.interfaceDanger300};
        }
    }

    .strong {
        input {
            border: 1px solid ${(props) => props.theme.colors.interfaceSuccess200};
        }
    }

    @media (max-width: 800px) {
        width: 100%;
        height: fit-content;
        border-radius: 16px;
        margin-top: 24px;
        padding: 24px;

        form {
            button {
                width: 100%;
            }
        }
    }
    
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;  
    justify-content: space-between;
    height: fit-content;
    margin-bottom: 24px;   

    animation: ${appearFromLeft} 0.7s;

    p {
        font-size: 20px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.primaryBlue500};
        padding-top: 16px;

        .blueTitle {
            font-weight: 700;
            font-size: 18px;
        }
        .orangeTitle {
            color: ${(props) => props.theme.colors.primaryOrange500};    
            font-weight: 700;
            font-size: 18px;
        }
    }
`

export const Footer = styled.div`
    display: flex;
    margin-top: 5%;
    animation: ${appearFromLeft} 0.7s;
    gap: 0px 20px;
    button {
        display: flex;
        align-items: center;
        gap: 8px;
        background: transparent;
        border: none;
        color: ${(props) => props.theme.colors.primaryBlue500};
        text-decoration: underline;
    }

    @media (max-height: 700px) {
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: 22px;
        margin-left: 370px;
    }
`

export const MainRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.primaryBlue600};
    width: 49%;
    height: 100%;
    border-radius: 0 16px 16px 0;

    
    img {
        animation: ${appearFromRight} 0.7s;
        width: 75%;
    }

    @media (max-width: 800px) {
        display: none;
    }
`