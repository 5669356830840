import styled from 'styled-components';

interface InputProps {
    type?: string;
}

export const Container = styled.label<InputProps>`
    display: block;
    height: 68px;
    position: relative;    
    .title {
        display: block;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }
    input {
        width: 100%;
        height: 48px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        outline: none;
        padding: 12px 16px;
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        &:focus-visible {
            border: 1px solid ${({ theme }) => theme.colors.secondaryGreen300};
        }
    }
    &.empty {
        input {
            border: 1px solid ${({ theme }) => theme.colors.interfaceDanger};
        }
    }
    &.error {
        input {
            border: 1px solid ${({ theme }) => theme.colors.primaryOrange300};
        }
    }
    span {
        position: absolute;
        top: 0;
        right: 2%;
        color: ${({ theme }) => theme.colors.interfaceDanger};
    }
`;