import styled from "styled-components";

export const Container = styled.div`
    width: 1040px;
    max-width: 90%;
    height: auto;
    min-height: 100vh;
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0};     
    
    .toasted {
        z-index: 99999;        
    }

    .warning {
        display: flex;
        gap: 8px;        
    }
`

export const Header = styled.div`
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;   

    .status {
        display: flex;
        justify-content: space-between;
        width: 340px;
        position: relative;
        .status-square {
            width: 55px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            
            p {
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;  
                &.on {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.primaryBlue600};
                }
            }
            &.first {
                white-space: nowrap;
                p {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.primaryBlue600};
                }
            }
            .second {
                align-self: center;
            }
            .third {
                align-self: flex-end;
            }
        }
        .square {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};   
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;            
        }        
        .active {
            background: ${({ theme }) => theme.colors.primaryOrange50};
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryOrange700};
                }
            }
        }
    }
`

export const Main = styled.div`
    height: fit-content;
    
    .top {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 56px;

        .two {
            height: 140px;
        }
        
        .three {
            .register {
                .subtitle {
                    font-size: 18px;
                    font-weight: 400;
                    span {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }

        .register {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            height: 80px;
            max-width: 680px;
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                span {
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 700;
                    color: ${({ theme }) => theme.colors.primaryOrange700};
                }
            }
            &.underline {
                p {
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                    span {
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        cursor: pointer;
                    }
                }
            }
            &.services {
                .title {
                    margin-bottom: -10px;
                }
                p {
                    span {
                        font-weight: 500;
                    }
                }
            }
            &.permission {
                max-width: 512px;
                margin-bottom: 32px;
            }
        }

        .subtitle, p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: ${({ theme }) => theme.colors.interfaceText400};
                span {
                    font-weight: 500;
                }
        }

        .attention {
            border: 1px solid ${({ theme }) => theme.colors.primaryOrange300};
            width: 333px;
            height: 88px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10%;
            border-radius: 8px;
            position: relative;

            .star {
                position: absolute;
                top: 19%;
                left: 4%;
            }

            span {
                margin-top: 5px;
                color: ${({ theme }) => theme.colors.primaryOrange600};
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
            }

            p {
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
            }

        }
    }

    form {
        padding-bottom: 140px;
        .name,
        .email {
            display: flex;
            flex-wrap: wrap;
            gap: 24px 16px;
            margin-bottom: 24px;
            justify-content: space-between;
            .big {
                width: 65%;
                &:hover {
                    border-color: red;
                }
            }           

            .small {
                width: 33%;
                &:last-child {
                    width: calc(35% - 24px);
                }
            }            
        }

        .pro {
            max-width: 675px;
            margin-bottom: 24px;
        }

        .state {
            display: flex;
            gap: 16px;
            max-width: 675px;
            margin-bottom: 24px;

            .label-state {
                width: 50%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }
        }

        .fone {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .small {
                width: 32%;
            }

            .big {
                width: 65%;
            }
        }

        .address {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .label {
                width: 24%;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }                
            }

            .small {
                width: 23.5%;
            }
            .big {
                width: 49%;
            }
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }

    .islands {
        padding-bottom: 140px;
    }

    .email-pass {
        display: flex;
        gap: 16px;

        .password {
            width: 100%;
            position: relative;
            .icon {
                position: absolute;
                top:55%;
                right: 5%;
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText300};
                    }
                }
            }
        }
    }
` 

export const Hr1 = styled.hr`

    width: 100%;
    max-width: 300px;
    height: 1px;
    border: none;
    background: ${({ theme }) => theme.colors.interfaceText200};
    position: absolute;
    top: 25px;
    left: 20px;
    transform: translateY(-50%);
    z-index: 1;
    &.first {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 35%, #C5CED7 35%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 35%, #C5CED7 35%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 35%,#C5CED7 35%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.second {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 75%, #C5CED7 75%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 75%, #C5CED7 75%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 75%,#C5CED7 75%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.third {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 100%, #C5CED7 100%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 100%, #C5CED7 100%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 100%,#C5CED7 100%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.fourth {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 100%, #C5CED7 100%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 100%, #C5CED7 100%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 100%,#C5CED7 100%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
`

export const Footer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;  
    background: ${({ theme }) => theme.colors.interfacePure0};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    z-index: 0;

    .container {
        width: 1040px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;
    }

    .buttons {
        display: flex;
        gap: 16px;
        button {
            width: 160px;
        }
        .continue {
            background: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;

        .progressbar {
            height: 8px;
            width: 170px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};
            position: relative;
            .percent {
                transition: all .2s;
                width: 34px;
                height: 8px;
                position: absolute;
                border-radius: 8px;
                top: 0;
                left: 0;
                background: ${({ theme }) => theme.colors.primaryBlue400};
                &.two {
                    width: 85px;
                }
                &.three {
                    width: 155px;
                }                
                &.four {
                    width: 160px;
                }                
            }
        }
    }
`