import styled from "styled-components";

interface CardProps {
    selected?: any,
    editProfile?: any,
}

export const Container = styled.div<CardProps>`
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1040px;

    .profile {

        .css-19n8nyf-control {
            background: ${({ theme }) => theme.colors.interfacePure0};
        }
    }

    form {
        padding-bottom: 140px;
        .name,
        .email {
            display: flex;
            flex-wrap: wrap;
            gap: 24px 16px;
            margin-bottom: 24px;
            justify-content: space-between;
            .big {
                width: 65%;
                &:hover {
                    border-color: red;
                }
            }

            .small {
                width: 33%;
                &:last-child {
                    width: calc(35% - 24px);
                }
            }

            .css-1s3jnk5-singleValue {
                font-weight: 400;
            }
        }

        .pro {
            max-width: 65%;
            margin-bottom: 24px;
        }

        .state {
            display: flex;
            gap: 16px;
            max-width: 65%;
            margin-bottom: 24px;

            .label-state {
                width: 50%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }
        }

        .fone {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .small {
                width: 32%;
            }

            .big {
                width: 65%;
            }
        }

        .address {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .label {
                width: 24%;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }
            }

            .small {
                width: 23.5%;
            }
            .big {
                width: 49%;
            }
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }    

    .toasted {
        z-index: 99999;
    }
    .warning {
        display: flex;
        gap: 16px;
        width: 500px;
    }

    .islands {
        max-width: 1040px;
        align-self: flex-start;
        margin-top: ${({ editProfile }) => (editProfile ? '0' : '32px')};
        

        .select-all {
            height: 48px;
            display: ${({ editProfile }) => (editProfile ? 'flex' : 'none')};
            margin-bottom: 16px;
            padding-left: 32px;

            .label {
                color: ${({ theme }) => theme.colors.interfacePure500};
                font-size: 18px;
                font-weight: 600;
            }

            .checkbox {
                .square {
                    border: 1px solid ${(props) => props.theme.colors.interfaceText200};
                    background: white;
                }
                &.active {
                    .square {
                        background-color: ${({ theme }) => theme.colors.primaryOrange700};
                        border: 1px solid ${(props) => props.theme.colors.primaryOrange700};
                    }
                }
            }
        }

        .no-edit {
            .checkbox {
                .square {
                    border: 1px solid ${(props) => props.theme.colors.interfaceText200};
                    background: ${({ theme }) => theme.colors.interfacePure0};
                }
                &.active {
                    .square {
                        background-color: ${({ theme }) => theme.colors.primaryBlue600};
                        border: 1px solid ${(props) => props.theme.colors.primaryBlue600};
                    }
                }
            }
        }
    }

    .permissions {
        margin-top: 96px;
        .select-all {
            display: none;
        }
    }

    @media (max-width: 600px) {
        min-width: 800px;        
    }
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    /* max-width: 1040px; */    
`

export const ModalMain = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 520px;
    height: 140px;
    background: ${({ theme }) => theme.colors.interfacePure0};

    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 20px;
        font-weight: 700;
    }

    .close {
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
        /* &:hover {
            transform: scale(1.2);
        } */
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .cancel,
        .danger {
            font-weight: 600;
        }
        .cancel {
           border: 2px solid ${({ theme }) => theme.colors.interfacePure200};
        }
    }
`

export const ModalPlanMain = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 448px;
    /* height: 140px; */
    background: ${({ theme }) => theme.colors.interfacePure0};

    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 20px;
        font-weight: 700;
        padding-top: 8px;
        padding-bottom: 24px;
        border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
    }

    .close {
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
        /* &:hover {
            transform: scale(1.2);
        } */
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .cancel,
        .save {
            font-weight: 600;
        }
        .cancel {
           border: 2px solid ${({ theme }) => theme.colors.interfacePure200};
        }
        .save {
            color: ${({ theme }) => theme.colors.interfacePure0};
            background: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }
`
