import logo from '../../assets/images/logoJuricoJa.svg'
import {IconBurguerMenu, IconCloseBurguerMenu, IconLogin} from '../icons'
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Container, Logo, AreaLinks, Tab, AreaLogin, BurguerMenu, DropDownMenu, } from "./styles";
import { useState } from 'react';

export default function Header() {
  const location = useLocation();
  const history = useHistory(); 
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  function handlelogin(){
    history.push('/login')
  }
  
  return (
    <Container>

      <Logo 
        src={logo}
        onClick={() => history.push('/')}
      />

      <AreaLinks>
        <Link
          to={'/quemsomos'}
        >
          <Tab
            className={location.pathname === '/quemsomos' ? 'selected one' : 'one'}             
          >
            Quem Somos
          </Tab>
        </Link>
        
        <Link
          to={'/solicitardemanda'}
        >
          <Tab
            className={location.pathname === '/solicitardemanda' ? 'selected two' : 'two'}          
          >
            Solicitar demandas jurídicas
          </Tab>        
        </Link>

        <Link
          to={'/receberoportunidades'}
        >
          <Tab
            className={location.pathname === '/receberoportunidades' ? 'selected three' : 'three'}
          >
            Receber oportunidades
          </Tab>
        </Link>

        <Link
          to={'/contato'}
        >
          <Tab
            className={location.pathname === '/contato' ? 'selected four' : 'four'}
          >
            Contato
          </Tab>
        </Link>

        <AreaLogin onClick={handlelogin}>
            <IconLogin />
            <Tab style={{marginLeft: 10}}>Entrar</Tab>
        </AreaLogin>   

      </AreaLinks>

      <BurguerMenu>
        {
          !openMenu &&
          <div
            className='menu'
            onClick={() => setOpenMenu(true)}
          >
            <IconBurguerMenu />
          </div>
        }

        {
          openMenu &&
          <div
            className='menu'
            onClick={() => setOpenMenu(false)}
          >
            <IconCloseBurguerMenu />
          </div>
        }
      </BurguerMenu>

      {
        openMenu &&
        <DropDownMenu>
          <Link
            to={'/quemsomos'}
          >
            <Tab
              className={location.pathname === '/quemsomos' ? 'selected one' : 'one'}
            >
              Quem Somos
            </Tab>
          </Link>

          <Link
            to={'/solicitardemanda'}
          >
            <Tab
              className={location.pathname === '/solicitardemanda' ? 'selected two' : 'two'}
            >
              Solicitar demandas jurídicas
            </Tab>
          </Link>

          <Link
            to={'/receberoportunidades'}
          >
            <Tab
              className={location.pathname === '/receberoportunidades' ? 'selected three' : 'three'}
            >
              Receber oportunidades
            </Tab>
          </Link>

          <Link
            to={'/contato'}
          >
            <Tab
              className={location.pathname === '/contato' ? 'selected four' : 'four'}
            >
              Contato
            </Tab>
          </Link>

          <AreaLogin onClick={handlelogin}>
            <IconLogin />
            <Tab style={{ marginLeft: 10 }}>Entrar</Tab>
          </AreaLogin> 
        </DropDownMenu>
      }
    </Container>
  );
}
