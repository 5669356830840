import styled from "styled-components";

export const Wrapper = styled.div`

    .county-form {

        .line {
            border-top: 1px dashed rgba(0,0,0,0.1);
            padding-top: 20px;
        }
        .county {
            display: flex;
            justify-content: space-evenly;
            gap: 16px;
            width: 100%;
            margin-bottom: 25px;

            .label {
                width: 100%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                }
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 2%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }

            .big {
                width: 65%;
            }

            .mid {
                width: 45%;
            }

            .small {
                width: 33%;
            }

            .select {
                height: 48px;
                width: 100%;
                border-radius: 8px;
                padding: 10px;

            }

        }

        .pricing {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 16px;
            margin-bottom: 56px;

            .label {
                width: 50%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                }
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 2%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }

            .right {
                display: flex;
                align-items: flex-end;
                width: 50%;
            }

            .radio-input {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 8px;
                width: 160px;
                height: 48px;

                input {
                    margin-bottom: 2px;
                }

                span {
                    font-weight: 400;
                    font-size: 16px;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }

            }

            .delete {
                display: flex;
                align-items: flex-end;
                justify-content: center;
                gap: 10px;
                background: none;
                border: none;
                width: 320px;
                height: 48px;
                font-weight: 400;
                font-size: 16px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                text-decoration: underline;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .services {

        }

        .dot-border {
            display: flex;
            align-items: center;
            gap: 16px;
            height: 48px;
            border: 1px dashed ${({ theme }) => theme.colors.primaryOrange200};
            border-radius: 8px;
            padding: 12px;
            cursor: pointer;
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }

    .warning {
        display: flex;
        gap: 16px;
        width: 500px;
    }
`

export const ModalPrecification = styled.div`
    background: ${({ theme }) => theme.colors.interfacePure0};
    width: 520px;
    padding: 8px;
    
    .header {
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
        padding-bottom: 25px;

        p {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-weight: 700;
            font-size: 20px;
        }

        .close {
            cursor: pointer;
        }
    }

    .main {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        margin-top: 25px;

        
    }
`