import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }


  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clipPath: ellipse(120% 100% at 50.00% 0%); */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 678px;
  padding-top: 5rem;
  padding-bottom: 7rem;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clip-path: ellipse(120% 100% at 50% 0%);
  transform: translateZ(0);  

  @media (max-width: 800px) {
    overflow: hidden;
  }
`;

export const AreaHeader = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  z-index: 2;
  position: fixed;
  top: 0;
`;

export const AreaSite = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 6.25rem;
  max-width: 1400px;
  z-index: 2;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
  }
`;

export const AreaImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-height: 300px;

  animation: ${appearFromLeft} 0.7s;

  .image {
    width: 390px;
  }

  .position {
    width: 100%;
    height: 100%;
    position: relative;

    .home {
      position: absolute;
      width: 800px;
      height: 600px;
      top: 0;
      right: 0;
      margin-top: -100px;
      margin-right: -40px;
    }

    .orange-circle {
      width: 400px;
      height: 400px;
      background-color: ${({ theme }) => theme.colors.primaryOrange700};
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      margin-top: -50px;
      margin-right: 120px;
    }
  }

  @media (max-width: 800px) and (min-width: 501px) {
    display: none;
  }

  @media (max-width: 500px) {
    .position {

      .home {
        width: 135%;
        top: 448px;
        right: 0px;
      }

      .orange-circle {
        width: 200px;
        height: 200px;
        background-color: ${({ theme }) => theme.colors.primaryOrange700};
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: -1;
        margin-top: 340px;
        margin-right: 0;
      }
    }
  }
  
`;

export const Image = styled.img`
  width: 100%;  
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  animation: ${appearFromRight} 0.7s;

  @media (max-width: 800px) {
    width: 85%;
  }

`;

export const DemandButton = styled.button`
  width: 469px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  transition: all .2s;

  &.first {
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
    color: ${({ theme }) => theme.colors.interfacePure0};
  }

  &.first-green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen500};
    color: ${({ theme }) => theme.colors.interfacePure0};
  }
  
  &.second {
    border: 2px solid ${({ theme }) => theme.colors.primaryOrange500};
    color: ${({ theme }) => theme.colors.primaryOrange500};
  }

  &.second-green {
    border: 2px solid ${({ theme }) => theme.colors.secondaryGreen200};
    color: ${({ theme }) => theme.colors.secondaryGreen200};
  }

  &:hover {
    transform: scale(1.05);
  }
`

export const AreaDescription = styled.div`
  
`;

export const TextDescription = styled.div`
  margin-bottom: 32px;
  h1 {
    font-size: 40px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryOrange500};
  }

  .description {
    width: 512px;
    margin-top: 20px;
    position: relative;
    
    p {
      color: ${({ theme }) => theme.colors.interfacePure0};
      font-size: 20px;
      font-weight: 500;
      line-height: 150%;     
      padding-bottom: 8px;
    }

    .icon {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: -25px;
      margin-right: 15px;
    }
  }

  .title {
    max-width: 469px;
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    color: ${({ theme }) => theme.colors.interfacePure0};

    span {
      color: ${({ theme }) => theme.colors.primaryOrange500};
    }
  }

  @media (max-width: 800px) {
    .title {
      max-width: 100%;
      font-size: 22px;
    }
  }
`;

export const BtnDescription = styled.a`
  height: 3.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFA41F;
  color: #FFF;
  font-size: 1.25rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 2.31rem;
`;

export const BtnDescriptionBorder = styled.a`
  height: 3.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: #FFA41F;
  color: #FFA41F;
  font-size: 1.25rem;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  margin-top: 1rem;
`;

export const RoutineSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  gap: 40px;
  margin-top: 112px;
  margin-left: auto;
  margin-right: auto;

  .text {
    color: ${({ theme }) => theme.colors.primaryBlue500};
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;

    span {
      font-weight: 700;
    }
  }

  .structure {
    max-width: 424px;
    background-color: ${({ theme }) => theme.colors.primaryOrange700};

    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.interfacePure0};
  }

  @media (max-width: 800px) {
    max-width: 85%;
    align-items: center;
    justify-content: center;

    .text {
      text-align: center;
    }

    .structure {
      max-width: 95%;
      font-size: 1.2rem;
    }
  }
`

export const RoutineCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 500px) {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;    
  }
`

export const RoutineCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 248px;
  height: 224px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 16px;
  padding: 32px;
  
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primaryBlue500};


  .icon {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 500px) {
    align-items: center;
    width: 160px;
    height: fit-content;
    font-size: 14px;

    .icon {
      width: 60px;
      height: 60px;
    }
  }
`

export const DemandTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.primaryBlue500};

  span {
    color: ${({ theme }) => theme.colors.primaryOrange600};
  }

  &.first {
    max-width: 500px;
  }

  &.green {
    span {
      color: ${({ theme }) => theme.colors.secondaryGreen500};
    }
  }

  &.light {
    max-width: 688px;
    margin-top: 120px;
    
    &.green {
      span {
        color: ${({ theme }) => theme.colors.secondaryGreen500};
      }  
    }

    span {
      color: ${({ theme }) => theme.colors.secondaryPool600};
    }
  }

  @media (max-width: 800px) {
    font-size: 22px; 
    line-height: 32px;
    
    &.light {
      padding-top: 20px;
      line-height: 32px;
    }

    &.text {
      line-height: 32px;
    }
  }
`

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 469px;

  @media (max-width: 800px) {
    width: 100%;
  }  

`

export const HomeButton = styled.button`
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 8px;
  transition: all .3s;

  &:hover {
    transform: scale(1.05);
  }

  &.blue {
    background-color: ${({ theme }) => theme.colors.primaryBlue100};
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 18px;
    font-weight: 600;
  }

  &.border {
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.colors.primaryBlue100};
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 18px;
    font-weight: 600;
  }

  @media (max-width: 500px) {
    &.blue,
    &.border {
      font-size: 16px;
    }
  }
`

export const SolicitationCard = styled.div`
  width: calc(85% - ((100% - 1040px) / 2));
  height: 544px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 0 16px 16px 0;  
  margin-top: 110px;
  padding: 56px 88px;
  
  display: flex;
  flex-direction: column;
  /* align-items: center;  */
  gap: 40px;
  
  position: relative;

  .light {
    margin-left: auto;
    margin-top: 0;
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 424px;
    margin-right: 245px;
    margin-left: auto;

    .title, 
    .subtitle {
      font-size: 20px;
      color: ${({ theme }) => theme.colors.primaryBlue600};
    }

    .title {
      font-weight: 700;
    }
  }

  .easy {
    max-width: 424px;
    background-color: ${({ theme }) => theme.colors.secondaryPool600};
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
    margin-right: 245px;
    margin-left: auto;
  }

  .image {
    position: absolute;
    top: 20%;
    right: 0;
    margin-right: -30%;
  }

  @media (max-width: 800px) {
    width: 85%;
    height: fit-content;
    padding: 16px;
    border-radius: 16px;
    margin: 0 auto;
    margin-top: 110px;

    .text {
      max-width: 90%;
      margin: 0 auto;
    }

    .image {
      display: none;
    }

    .easy {
      width: 100%;
      height: 48px;
      margin: 0 auto;
    }   
  }
`

export const ManagementWrapper = styled.div`
  position: relative;
  height: 496px;
  margin-top: 112px;  
`

export const ManagementCard = styled.div`
  width: calc(85% - ((100% - 1040px) / 2));
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 16px 0 0 16px;  
  margin-top: 110px;
  padding: 56px 0;
  
  display: flex;
  flex-direction: column;
  gap: 40px;
  
  position: absolute;
  bottom: 0;
  right: 0;

  .light {
    width: 510px;
    margin: 0 auto;

    span {
      color: #03AD8F;
    }
  }

  .subtitle {
    max-width: 510px;
    margin: 0 auto;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.primaryBlue600};

    span {
      font-weight: 700;
    }

    .bottom {
      margin-top: 8px;
    }

  }

  .button {
    margin: 0 auto;
    width: 510px;
    background-color: ${({ theme }) => theme.colors.secondaryGreen500};
    color: ${({ theme }) => theme.colors.interfacePure0};
  }

  .image {
    width: 500px;
    height: 410px;
    position: absolute;
    top: 8%;
    right: 0;
    margin-right: 80%;
  }

  @media (max-width: 800px) {
    width: 85%;
    height: fit-content;
    position: relative;
    margin: 0 auto;
    margin-top: 260px;
    border-radius: 16px;
    padding-bottom: 16px;

    .light {
      text-align: center;
      width: 95%;
      font-size: 20px;
    }

    .subtitle {
      width: 90%;
      text-align: center;
      font-size: 18px;
    }

    .button {
      width: 90%;
    }
    
    .image {
      width: 100%;
      top: 0;
      margin: 0 auto;
      margin-top: -240px;
    }
  }
`

export const RevolutionWrapper = styled.div`
  position: relative;
  height: 504px;
  margin-top: 112px;  

  .card {
    max-width: 55%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 0 16px 16px 0;
    padding: 108px 50px;
    
    .text {
      width: 424px;  
      margin-left: auto;
      margin-right: 175px;
    }

    .subtitle {
      height: 108px;
      width: 424px;
      margin-left: auto;
      margin-right: 175px;

      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.primaryBlue600};

      span {
        font-weight: 700;
      }
    }

    .revolution {
      width: 424px;
      margin-left: auto;
      margin-right: 175px;
      background-color: ${({ theme }) => theme.colors.primaryOrange600};
      color: ${({ theme }) => theme.colors.interfacePure0};
    }

    .image {
      width: 600px;
      height: 340px;
      position: absolute;
      top: 17%;
      right: 18%;
    }
  }

  @media (max-width: 1400px) and (min-width: 801px) {
    .card {
      .image {
        right: 10%;
      }
    }
  }

  @media (max-width: 800px) {
    height: fit-content;
    margin: 0 auto;
    margin-top: 112px;
    width: 85%;

    .card {
      max-width: 100%;
      height: fit-content;
      border-radius: 16px;
      padding: 24px;
      padding-top: 108px;

      .text {
        width: 100%;
        text-align: center;
      }
      
      .subtitle {
        text-align: center;
        width: 100%;
        height: fit-content;
      }

      .revolution {
        width: 100%;
        font-size: 16px;
      }

      .image {
        width: 90%;
        top: 0;
        left: 5%;
        margin-top: -90px;
      }
    }
  }
`

export const ModalCookiesMain = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .icon {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -60px;
    z-index: 9999;
  }

  .description {
    width: 300px;
    margin-top: 24px;

    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.interfaceText400};
    text-align: right;

    span {
      color: ${({ theme }) => theme.colors.secondaryPool600};
      cursor: pointer;
    }
  }
`

export const ModalButton = styled.button`
  width: 100%;
  height: 44px;
  background-color: ${({ theme }) => theme.colors.secondaryPool600};
  border: none;
  border-radius: 22px;

  font-weight: 500;
  color: ${({ theme }) => theme.colors.interfacePure0};
  
`


