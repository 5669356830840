import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }


  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clipPath: ellipse(120% 100% at 50.00% 0%); */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 678px;
  padding-top: 5rem;
  padding-bottom: 7rem;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clip-path: ellipse(120% 100% at 50% 0%);
  transform: translateZ(0);
`;

export const AreaHeader = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  z-index: 2;
  position: fixed;
  top: 0;
`;

export const AreaSite = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 6.25rem;
  max-width: 1400px;
  z-index: 2;
`;

export const AreaImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  max-height: 300px;

  animation: ${appearFromLeft} 0.7s;

  .image {
    width: 390px;
  }

  .position {
    width: 700px;
    height: 300px;
    position: relative;

    .home {
      position: absolute;
      width: 800px;
      height: 600px;
      top: 0;
      right: 0;
      margin-top: -100px;
      margin-right: -40px;
    }

    .orange-circle {
      width: 400px;
      height: 400px;
      background-color: ${({ theme }) => theme.colors.primaryOrange700};
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: -1;
      margin-top: -50px;
      margin-right: 120px;
    }
  }

  @media (max-width: 800px) {
    width: 0;
    .image {
      display: none;
    }
  }
`;

export const Image = styled.img`
  width: 100%;  
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  animation: ${appearFromRight} 0.7s;

  @media (max-width: 800px) {
    width: 85%;
  }
`;

export const AreaDescription = styled.div`
  
`;

export const TextDescription = styled.div`
  margin-bottom: 32px;

  h1 {
    font-size: 40px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryOrange500};
  }

  .description {
    width: 512px;
    margin-top: 20px;
    position: relative;
    
    p {
      color: ${({ theme }) => theme.colors.interfacePure0};
      font-size: 20px;
      font-weight: 500;
      line-height: 150%;     
      padding-bottom: 8px;
    }

    .icon {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-bottom: -25px;
      margin-right: 15px;
    }
  }

  .title {
    max-width: 469px;
    font-size: 40px;
    font-weight: 700;
    line-height: 46px;
    color: ${({ theme }) => theme.colors.interfacePure0};

    span {
      color: ${({ theme }) => theme.colors.primaryOrange500};
    }
  }

  @media (max-width: 800px) {
    .description {
      width: 100%;

      p {
        font-size: 18px;
      }
    }
  }
`;

export const CardSection = styled.div`
  position: relative;

  @media (max-width: 800px) {
    height: 250px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: center; */
  gap: 16px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -91.5px;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 336px;
  height: 183px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.background};
  padding: 32px;
  z-index: 99;

  p {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primaryBlue600};
  }

  @media (max-width: 800px) {
    flex-direction: row;
    height: fit-content;
    padding: 24px 32px;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 150px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1040px;
  height: 120px;

  .text {
    max-width: 780px;
    margin-right: auto;
    color: ${({ theme }) => theme.colors.primaryBlue500};
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;

    span {
      font-weight: 700;
    }
  }

  @media (max-width: 800px) {
    max-width: 85%;
  }
`;

export const RevolutionSection = styled.div`
  width: 1040px;
  height: 504px;
  margin-top: 112px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  .image {
    max-width: 550px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 47.5%;
  }

  @media (max-width: 800px) {
    max-width: 85%;

    .image {
      width: 100%;
      bottom: 0;
      left: 0;
      margin-top: 110px;
    } 
  }
`;

export const RevolutionCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  width: 100%;
  height: 100%;
  border-radius: 0 16px 16px 0;
  background-color: ${({ theme }) => theme.colors.primaryOrange500};
  padding: 5% 8% 5% 43%;
  position: absolute;
  left: -440px;

  .text {
    max-width: 470px;
    font-size: 40px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.interfacePure0};    

    span {
      color: ${({ theme }) => theme.colors.primaryBlue600};
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: fit-content;
    left: 0;
    border-radius: 16px;
    padding: 24px;
    padding-bottom: 120px;

    .text {
      font-size: 24px;
    }
  }
`;

export const RevolutionButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 333px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  border-radius: 8px;

  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.interfacePure0};
  transition: all .2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 800px) {
    width: 100%;
    font-size: 16px;
  }
`;

export const StructureSection = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  gap: 32px;
  margin-top: 112px;
  margin-left: auto;
  margin-right: auto;

  .title {
    font-size: 32px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue500};

    span {
      color: ${({ theme }) => theme.colors.primaryOrange500};
    }
  }

  @media (max-width: 800px) {
    .title {
      max-width: 85%;
      font-size: 22px;
      margin: 0 auto;
    }
  }
`;

export const StructureCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StructureCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  height: 344px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 16px;
  padding: 32px;

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 80px;

    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue500};

    img {
      width: 80px;
      height: 80px;
    }
  }

  .description {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: ${({ theme }) => theme.colors.primaryBlue500};
  }

  @media (max-width: 800px) {
    height: fit-content;
    text-align: center;
    align-items: center;
  }
`;

export const RevolutionCarrer = styled.div`
  width: 1040px;
  height: 504px;
  margin-top: 112px;
  margin-left: auto;
  margin-right: auto;

  .image {
    width: 100%;
    position: relative;
    
    .carrer {
      width: 600px;
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 22%;
      transform: translateX(-50%);
      margin-top: 5%;
    }
  }

  @media (max-width: 1400px) and (min-width: 801px) {
    .image {
      .carrer {
        margin-right: 30%;
      }
    }

  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 132px;

    .image {
      width: 80%;
      .carrer {
        margin: 0 auto;
        transform: translateX(0);
        margin-top: -110px;
        left: 40px;
      }
    }
  }
`;

export const CarrerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  height: 504px;
  width: 100%;
  max-width: 1040px;
  border-radius: 16px 0 0 16px;
  background-color: ${({ theme }) => theme.colors.primaryBlue600};
  padding: 5% 8% 0 20%; 
  position: absolute;
  right: 0;

  
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
    color: ${({ theme }) => theme.colors.interfacePure0};

    span {
      color: ${({ theme }) => theme.colors.primaryOrange600}
    }
  }

  .description {
    max-width: 424px;
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    
    span {
      font-weight: 700;
    }
  }
  
  .mail {
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    
    span {
      font-weight: 700;
    }
  }
  
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 425px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;

    &:hover {
      transform: scale(1.05);
    }
    
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-weight: 700;
  }
  
  .button-icon {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 425px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;
    padding: 16px;
    margin-top: -8px;
    
    &.copied {
      background-color: ${({ theme }) => theme.colors.primaryBlue100};
      font-weight: 600;

      svg {
        margin-left: 20%;
        width: 20px;
        height: 20px;
      }
    }
    
    &:hover {
      transform: scale(1.05);
    }

    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 1rem;
    font-weight: 700;

    svg {
      margin-left: 33%;
      path {
        fill: ${({ theme }) => theme.colors.interfacePure0};
      }
    }
  }

  @media (min-width: 1600px) {
    padding: 5% 8% 5% 10%;;
  }

  @media (max-width: 800px) {
    height: fit-content;
    border-radius: 16px;
    padding: 32px 24px;
    padding-top: 128px;


    .button {
      width: 100%;
    }

    .button-icon {
      width: 100%;
    }
  }
`;