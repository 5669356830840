import styled from "styled-components";

interface SelectionProps {
    selected?: any
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1040px;
    height: auto;
    /* min-height: 100vh; */
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0}; 
    padding-bottom: 190px;
    position: relative;
       
    
    .toasted {
        z-index: 99999;        
    }  
    
    .loader {
        margin: 0 auto;
    }
`

export const SearchHeader = styled.div`
    display: flex;
    gap: 16px;
    height: 56px;
    margin-bottom: 16px;
    

    .search {
        width: 511px;
        display: flex;
        align-items: center;
        gap: 8px;
        width: 600px;
        height: 56px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        margin-bottom: 40px;
        input {
            width: 450px;
            border: none;
            outline: none;
            background: ${({ theme }) => theme.colors.background};
        }

        .buscar {
            cursor: pointer;
        }

        .clean-search {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
                &:hover {
                    cursor: pointer;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
        }        
    }

    .countie-status {
        position: relative;
        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
            margin-bottom: 8px;
        }

        .countie-selected {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            left: 0;
            gap: 8px;
            width: max-content;
            height: 32px;
            border-radius: 8px;
            padding: 5px 20px;
            background: ${({ theme }) => theme.colors.primaryBlue500};
            color: ${({ theme }) => theme.colors.interfacePure0};
            cursor: pointer;
            .icon {
                width: 20px;
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.secondaryPool300};
                    }
                }
            }
        }

        .countie-map {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 20px;
            width: 340px;
            height: 350px;
            border-radius: 8px;
            padding: 24px;
            background: ${({ theme }) => theme.colors.interfacePure0};
            cursor: default;
            position: absolute;
            top: 80px;
            left: 0;
            z-index: 99;
            overflow-y: auto;
            &.no-show {
                display: none;
            }

            .filter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 48px;
                border-radius: 8px;
                border: 1px solid ${({ theme }) => theme.colors.interfacePure100};
                padding: 16px;
                color: ${({ theme }) => theme.colors.interfaceText300};
                .button-filter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    background: ${({ theme }) => theme.colors.interfacePure100};
                    border-radius: 8px;
                }
            }

            .filters {
                display: flex;
                align-items: center;
                padding-left: 16px;
                width: 100%;
                height: 48px;
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-size: 16px;
                font-weight: 500;
                padding-top: 5px;
                padding-bottom: 5px;
                cursor: pointer;
                transition: all .2s;
                &:hover {
                    background: ${({ theme }) => theme.colors.interfaceText100};
                }
            }

        }

        .select {
            width: 300px;
        }
    }
`

export const ProfessionalTitles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 864px;
    height: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.background};
    margin-bottom: 8px;    
    padding: 16px;

    .icon-profile {
        width: 32px;
        height: 32px;        
    }

    .name {
        width: 420px;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .service {
        width: 88px;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .profit {
        width: 88px;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .code {
        width: 80px;
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .contact {
        width: 48px;
        height: 48px;
    }
`

export const ProfessionalPrices = styled.div<SelectionProps>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 864px;
    height: 80px;
    padding: 24px;
    border: ${({ selected }) => (selected ? '3px solid #FA9604' : '2px solid #F6F8FA')};
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 2px 10px rgba(0,0,0, 0.15);
    cursor: pointer;
    transition: all .3s;
    transform: ${({selected}) => (selected ? 'scale(1.01)' : 'scale(1)')};

    .icon-profile {
        width: 32px;
        height: 32px;
        svg {
            width: 100%;
            height: 100%;
            path {
                fill: ${({ selected }) => (selected ? '#FA9604' : '#F2F2F2')};
            }
        }

    }
     
    &:hover {
        border: 2px solid #FFF2DE;
        svg {
            path {
                fill: #FA9604;
            }
        }
    }

    .name {
        width: 420px;
        font-size: 18px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText500};
        text-transform: capitalize;
    }

    .service {
        width: 88px;
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }

    .profit {
        width: 88px;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText500};
        &.negative {
            color: ${({ theme }) => theme.colors.interfaceDanger500};
        }
    }

    .code {
        width: 80px;
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }

    .contact {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background: ${({ theme }) => theme.colors.primaryOrange50};
        border-radius: 8px;
        cursor: pointer;
    }
`

export const Paginate = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 1040px;
    width: 100%;
    height: 24px;
    position: absolute;
    bottom: 0;
    right: 185px;
    /* margin-bottom: 0; */
    padding-bottom: 150px;

    .pagination {
        display: flex;
        gap: 8px;
        list-style-type: none;
        .page-link {
            display: flex;
            align-items: center;
            justify-content: center;            
            width: 24px;
            height: 24px;
            background: transparent;
            border: 1px solid ${({ theme }) => theme.colors.interfaceText200};
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
            transition: all .2s;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            &:hover {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
            &.active {
                display: flex;
                align-items: center;
                justify-content: center;            
                width: 24px;
                height: 24px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
        }
        .page-item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }
        .back-page,
        .next-page {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border: 1px solid ${({ theme }) => theme.colors.interfaceText200};
            cursor: pointer;
            &:hover {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
            &.active {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                border: 1px solid ${({ theme }) => theme.colors.primaryBlue500};
            }
        }

        .back-link-page,
        .next-link-page {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
        }

    }
`

export const ModalContactHead = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    min-width: 450px;
    font-size: 20px;
    font-weight: 700;
    border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
    color: ${({ theme }) => theme.colors.interfaceText400};  
    padding: 8px;
    padding-bottom: 24px;

    .close {        
        cursor: pointer;
    }

    .close-pendencie {
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
    }

    .payment {
        display: flex;
        align-items: center;
        gap: 13px;
    }
`

export const ModalcontactMain = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 24px;
    font-size: 18px;
    font-weight: 400;
`