import { Container } from "./styles";

interface PropTabs {
    selected?: any,
    setSelected?: any,
}

export default function OperatorProfileTabs({ selected, setSelected }: PropTabs) {

    return (
        <Container>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '1' ? 'selected tab' : 'tab'}
                id='1'
            >
                Identificação
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '2' ? 'selected tab' : 'tab'}
                id='2'
            >
                Atendimento
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '3' ? 'selected tab' : 'tab'}
                id='3'
            >
                Permissões
            </div>            
        </Container>
    )
} 