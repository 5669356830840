/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import api from "../../services/api";
import { IconClose, IconSearch, IconWarning } from "../icons";
import { Container, TagLeft, TagRigth } from "./styles";
import toast, { Toaster } from 'react-hot-toast';

interface TagsProps {
    tagSelected?: any,
    setTagSelected?: any,
    reuseTags?: any,
}

export default function ServicesTags({ setTagSelected, tagSelected, reuseTags }: TagsProps) {
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ tag, setTag ] = useState<any>();
    const [ filteredTags, setFilteredTags ] = useState<any>();
    const [ selectedTags, setSelectedTags ] = useState<any>([]);

    useEffect(() => {

        setTagSelected(selectedTags);

    }, [filteredTags, selectedTags])

    useEffect(() => {

        setSelectedTags(reuseTags);
        
    }, [reuseTags])

    async function getTags() {
        try {

            if (tag.includes('https://') || tag.includes('http://') || tag.includes('/')) {
                toast(<div className="warning"><span><IconWarning /></span> Não permitido usar endereço URL ou / como TAG!</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '400px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });  
                throw new Error('Não permitido usar endereço URL ou / como TAG!')
            }

            if (!tag) {
                toast(<div className="warning"><span><IconWarning /></span> Digite uma TAG antes de pesquisar.</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '400px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });  
                throw new Error('Digite uma TAG antes de pesquisar')
            }

            setLoading(true);

            const response = await api.get(`tag-find/${tag}?like=true`);
            setFilteredTags(response.data.result);
            
            if (response.data.status !== 'success') {
                throw new Error()
            }

            setLoading(false);

        } catch (error: any) {
            console.log('error log getting tags', error)            
            setLoading(false);
        }
        
    }

    async function createTag() {
        try {

            if (tag.includes('https://') || tag.includes('http://') || tag.includes('/')) {
                toast(<div className="warning"><span><IconWarning /></span> Não permitido usar endereço URL ou / como TAG!</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '400px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });  
                throw new Error('Não permitido usar endereço URL ou / como TAG!')
            }

            if (!tag) {
                toast(<div className="warning"><span><IconWarning /></span> Digite uma TAG antes de pesquisar.</div>,
                {
                    duration: 3000,
                    style: {
                        padding: '16px',
                        minWidth: '400px',
                        color: '#1A2858',
                        background: '#E4F5FF',
                        border: '2px solid #88D3FE',
                    },
                });  
                throw new Error('Digite uma TAG antes de pesquisar')
            }

            setLoading(true);

            const response = await api.get(`tag-find/${tag}`);
            console.log('log do create tag', response.data.result);
            
            if (response.data.status !== 'success') {
                throw new Error()
            }

            setLoading(false);

        } catch (error: any) {
            console.log('error log getting tags', error)            
            setLoading(false);
        }
        
    }

    function searchTags(event: any) {

        if (event.key === 'Enter') {
            getTags()  
        }
    }

    return(
        <Container>
            <TagLeft>
                <div className="title">Selecione ou crie tags para identificar a demanda.</div>
                
                <div className="search">
                    <div
                        className="buscar"
                        onClick={() => getTags()}
                    >
                        <IconSearch />
                    </div>
                    <input
                        placeholder="Pesquisar"
                        onChange={(e) => setTag(e.target.value)}
                        onKeyUp={(e) => searchTags(e)}
                        value={tag}
                    />
                    {
                        filteredTags && !loading &&
                        <div
                            className="clean-search"
                            onClick={() => { setFilteredTags(''); setTag('') }}
                        >
                            <IconClose />
                        </div>
                    }

                    <div className={filteredTags ? 'show-tags' : 'show-tags off'}>
                        {
                            filteredTags?.length > 0 
                                ? <span>Selecione uma das tags:</span>
                                : <span className="empty">0 tags encontradas para a sua pesquisa.</span>
                        }
                        
                        <div className="tag-arrays">
                            {
                                filteredTags &&
                                filteredTags.map((row: any) => (
                                    <div 
                                        className="tag"
                                        onClick={() => { setSelectedTags((selectedTags: any) => [...selectedTags, row.tag]); setTag(''); setFilteredTags(''); }}
                                        key={row.id_tag}
                                    >
                                        {row.tag}
                                    </div>
                                ))
                            }
                        </div>

                        {   
                            filteredTags &&
                            filteredTags?.find((obj: { tag: any; }) => obj.tag === tag)
                            ? ''
                            :   <>
                                    <span className="span-no-tag">Crie uma nova tag para:</span>
                                    <div className="no-tags">
                                        <div
                                            className="tag"
                                            onClick={() => { setSelectedTags((selectedTags: any) => [...selectedTags, tag]); setTag(''); setFilteredTags(''); createTag() }}
                                        >
                                            {tag}
                                        </div>
                                    </div>
                                </>
                        }

                        
                    </div>
                </div>               
                
                <div className="tags">
                    <div className="title">Detalhes da demanda:</div>

                    <div className="selected-tags">
                        {
                            selectedTags.length < 1 && reuseTags.length < 1 &&
                            <div className="example-tag" key={1}>
                                Exemplo de tag: 1ª instância 
                                <div>
                                    <IconClose />
                                </div>
                            </div>
                        }
                        {
                            selectedTags &&
                            selectedTags.map((row: any) => (
                                <div className="tag" key={row}>
                                    {row}
                                    <div
                                        className='delete'
                                        onClick={() => setSelectedTags((obj: any[]) => obj.filter(element => {
                                            return element !== row
                                        }))}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            ))
                        }

                        {/* {
                            reuseProposal && reuseTags &&
                            selectedReuseTags.map((row: any) => (
                                <div className="tag" key={row}>
                                    {row}
                                    <div
                                        className='delete'
                                        onClick={() => setSelectedReuseTags((obj: any[]) => obj.filter(element => {
                                            return element !== row
                                        }))}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            ))
                        } */}
                    </div>

                </div>

            </TagLeft>

            <TagRigth>
                <div className="subtitle">
                    Para <span>audiências</span>, não esqueça de mencionar:                    
                </div>

                <div className="tag-box">
                    <ul>
                        <li>Qual instância?</li>
                        <li>Qual a vara?</li>
                        <li>Qual é a parte representada?</li>
                        <li>Quem é o autor?</li>
                        <li>Quem é o réu?</li>
                        <li>Qual o fórum?</li>
                        <li>Existe testemunha?</li>
                        <li>Existe proposta de acordo?</li>
                        <li>Existe requerimento?</li>
                        <li>Disponibilidade de preposto?</li>
                    </ul>
                </div>
            </TagRigth>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )

}