import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1040px;
    height: auto;
    position: relative;
    /* min-height: 100vh; */
    /* background: ${({ theme }) => theme.colors.interfacePure0};  */
    /* padding-bottom: 190px; */
    /* margin-top: -20px; */
       
    
    .toasted {
        z-index: 99999;        
    }   
    
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 300px;
        transform: translateX(-50%);
    }    
`

export const TopContaineir = styled.div`
    display: flex;
    gap: 16px;
`

export const FinancialReports = styled.div`
    display: flex;
    padding: 24px;
    width: 512px;
    height: 200px;
    overflow: hidden;

    background-color: ${({ theme }) => theme.colors.interfacePure0};
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);
    border-radius: 16px; 

    .left {
        width: 151px;
        position: relative;

        .circle {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            width: 191px;
            height: 191px;
            top: 0;
            left: 0;
            margin-top: -50px;
            margin-left: -60px;
            border-radius: 50%;
            background-color: ${({ theme }) => theme.colors.secondaryPool100};

            img {
                margin-right: 40px;
            }
        }
        
    }

    .infos {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
            font-size: 20px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

        .subtitle {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
            line-height: 160%;

            span {
                font-weight: 600;
            }
        }

        .buttons {
            margin-top: 8px;
            display: flex;
            gap: 16px;            
        }
    }
`

export const MensalButton = styled.button`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 18px;
    width: 126px;
    height: 48px;
    border: none;
    background-color: ${({ theme }) => theme.colors.primaryBlue500};
    border-radius: 8px;
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 1rem;
    font-weight: 700;
    transition: all .3s;
    
    &:hover {
        transform: scale(1.1);
    }
`

export const PersonalizeButton = styled.button`
    display: flex;
    align-items: center;
    gap: 12px;

    padding: 18px;

    width: 170px;
    height: 48px;
    background: transparent;
    border: 2px solid ${({ theme }) => theme.colors.secondaryPool400};
    border-radius: 8px;

    color: ${({ theme }) => theme.colors.primaryBlue500};
    font-weight: 700;

    transition: all .3s;
    
    &:hover {
        transform: scale(1.1);
    }

    svg {
        path {
            fill: ${({ theme }) => theme.colors.secondaryPool400};
        }
    }
`

export const BottomContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
`

export const InfoCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 336px;
    height: 200px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.interfacePure0};
    padding: 24px;
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);

    .icons {
        display: flex;
        height: 48px;
        align-items: flex-start;
        justify-content: space-between;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 8px;

            &.accept {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primaryBlue100};
                    }
                }
            }

            &.receive {
                background-color: ${({ theme }) => theme.colors.secondaryGreen050};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.secondaryGreen600};
                    }
                }
            }
            
            &.success {
                background-color: ${({ theme }) => theme.colors.interfaceSuccess100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }
            
            &.prof {
                background-color: ${({ theme }) => theme.colors.interfacePure100};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
            
            &.to-pay {
                background-color: ${({ theme }) => theme.colors.primaryOrange50};
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primaryOrange700};
                    }
                }
            }
        }

        .button {
            cursor: pointer;
        }
    }

    .title {
        height: 56px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 16px;
        font-weight: 600;    
        padding-top: 4px;
        line-height: 24px;
    }

    .numbers {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 24px;
        font-weight: 500;
        
        span {
            color: ${({ theme }) => theme.colors.interfaceText300};
            font-size: 12px;
        }

        &.accept {
            color: ${({ theme }) => theme.colors.primaryBlue100};
        }

        &.receive {
            color: ${({ theme }) => theme.colors.secondaryGreen600};
        }

        &.sucess {
            color: ${({ theme }) => theme.colors.interfaceSuccess400};
        }

        &.prof {
            color: ${({ theme }) => theme.colors.interfacePure400};
        }

        &.ongoing {
            color: ${({ theme }) => theme.colors.secondaryPool600};
        }
        
        &.to-pay {
            color: ${({ theme }) => theme.colors.primaryOrange700};
        }
    }
`

export const ModalMain = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;

    .select-date {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        .start-date {
            width: 144px;

            .date-field {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                border: 1px solid #EAEEF3;
                border-radius: 8px;
                padding: 12px;
                font-size: 16px;
                margin-top: 8px;
                margin-bottom: 16px;

                .react-date-picker {
                    width: 100%;
                }

                .react-date-picker__wrapper {
                    display: flex;
                    flex-grow: 1;
                    flex-shrink: 0;
                    border: none;
                    /* border: thin solid gray; */
                }

                .react-date-picker__inputGroup__day {
                    min-width: 20px !important;
                }

                .react-date-picker__inputGroup__month {
                    min-width: 12px !important;
                }

                .react-date-picker__inputGroup__year {
                    width: 40px !important;
                }

                input {
                    /* width: 100%; */
                    border: none;
                    outline: none;
                    text-align: center;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }
            }
        }
    }
`

export const ModalTitleHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 320px;
    height: 48px;
`

export const ModalTitle = styled.h2`
    color: ${({ theme }) => theme.colors.interfaceText400};
    font-size: 1rem;
    font-weight: 600;
`

export const CloseModal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;    
    
    svg {
        transition: all .3s;
        path {
            fill: ${({ theme }) => theme.colors.interfaceText500};
        }
    }

    &:hover {
        svg {
            transform: scale(1.2);
        }
    }
`