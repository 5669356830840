/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// Libraries
import toast, { Toaster } from 'react-hot-toast';
import Select from 'react-select';
import Moment from 'react-moment'; 
import moment from "moment";
import 'moment/locale/pt-br';
import DateTimePicker from 'react-datetime-picker';
import { Oval } from 'react-loader-spinner';

// Services
import axios from "axios";
import api from "../../services/api";
import { useAuth } from "../../contexts/UserContext";

// Icons
import { 
    IconClock, 
    IconClose, 
    IconDownload, 
    IconEdit, 
    IconFile,
    IconServiceValue, 
    IconThreeDots, 
    IconThreeDotsH, 
    IconTimer, 
    IconUser, 
    IconStatus, 
    IconSearch, 
    IconDeleteOne, 
    IconDeleteTwo, 
    IconFloppyDisk, 
    IconPlus, 
    IconWarning, 
    IconTrash, 
    IconUpload,
    IconAccept, 
    IconDuplicate
} from "../../components/icons";

//  Components
import ButtonIcon from "../../components/form/ButtonIcon";
import ModalDefault from "../../components/ModalDefault";
import ButtonDefault from "../../components/form/ButtonDefault";
import InputDefault from "../../components/form/InputDefault";
import { ModalityAudience } from "../../components/Data/ModalityAudience";
import { ModalcontactMain } from "../../components/SearchProfessionalProposal/styles";
import InputMoney from "../../components/form/InputMoney";

//  Data
import { LaborHearing } from "../../components/Data/LaborHearing";
import { CivilHearing } from "../../components/Data/CivilHearing";
import { CriminalHearing } from "../../components/Data/CriminalHearing";
import { EstadosBrasileiros } from "../../components/Data/StatesData";
import { ProfessionalTypeNecessary } from "../../components/Data/ProfessionalTypeNecessary";

//  Styles
import { 
    Buttons, 
    Container, 
    DeadlineInfos, 
    DropDownMenu, 
    Files, 
    HistoryTimeLine, 
    HistoryTitle, 
    ModalButtons, 
    ModalChanges, 
    ModalContactHead, 
    ModalJustification, 
    ModalSearch, 
    ModalTitle, 
    ModalWrapper, 
    Orientations, 
    ProposalAdress, 
    ProposalDetails, 
    ProposalInfos, 
    ProposalLeft,
    ServiceTags, 
    ServiceValue, 
    Status, 
    StatusHeader 
} from "./styles";

interface ProposalProps {
    id_usuario: any,
    id_servico: any,
    id_comarca: any,
    tags: any,
    arquivos: any,
    numero_processo: string,
    nome_cliente: any,
    valor_cliente: number,
    codigo_cliente: string,
    prazo_aceite: string,
    prazo_para_conclusao: string,
    dias_uteis_para_conclusao: string,
    orientacoes: string,
    tipo_audiencia: string,
    data: any,
    profissional_necessario: string,
    modalidade: string,
    endereco_virtual: string,
    rua: string,
    bairro: string,
    cep: string,
    numero: string,
    complemento: string,
    tipo_cartorio: string,
    pagina_inicial: string,
    pagina_final: string,
    valor_profissional?: string,
    lucro?: any,
    lucro_porcentagem?: any,
}

export default function Proposal() {
    const { setPageName, user, setPageType } = useAuth();
    const params = useParams<any>();
    const proposalId = params.id;
    const history = useHistory();
    const historyRef = useRef<any>();
    const menuRef = useRef<any>();
    const [ loading, setLoading ] = useState<any>(false);
    const [ proposalInitial, setProposalInitial ] = useState<any>();
    const [ professional, setProfessional ] = useState<any>();
    const [ modalContact, setModalContact ] = useState<any>();
    const [ openHistory, setOpenHistory ] = useState<any>();
    const [ DTOSearch, setDTOSearch ] = useState<any>();
    const [ openMenu, setOpenMenu ] = useState<any>();
    const [ deleteProposal, setDeleteProposal ] = useState<any>();
    const [ justicationText, setJustificationText ] = useState<any>(); 
    const [ secondStage, setSecondStage ] = useState<any>();
    const [ editProposal, setEditProposal ] = useState<any>();
    const [ counties, setCounties ] = useState<any>();
    const [ cepCounty, setCepCounty ] = useState<any>();
    const [ cepAddress, setCepAddress ] = useState<any>();
    const [ DTOProposal, setDTOProposal ] = useState<ProposalProps>({
        id_usuario: '',
        id_servico: '',
        id_comarca: '',
        tags: '',
        arquivos: '',
        numero_processo: '',
        nome_cliente: '',
        valor_cliente: 0,
        codigo_cliente: '',
        prazo_aceite: '',
        prazo_para_conclusao: '',
        dias_uteis_para_conclusao: "",
        orientacoes: '',
        tipo_audiencia: '',
        data: '',
        profissional_necessario: '',
        modalidade: '',
        endereco_virtual: '',
        rua: cepAddress,
        bairro: cepCounty,
        cep: '',
        numero: '',
        complemento: '',
        tipo_cartorio: '',
        pagina_inicial: '',
        pagina_final: '',
        valor_profissional: '',
    });
    const [ dateHearing, setDateHearing ] = useState<any>();  
    const [ modalTags, setModalTags ] = useState<any>();
    const [ tag, setTag ] = useState<any>();
    const [ filteredTags, setFilteredTags ] = useState<any>();
    const [ selectedTags, setSelectedTags ] = useState<any>([]);
    const [ historyProposal, setHistoryProposal ] = useState<any>();
    const [ acceptProposal, setAcceptProposal ] = useState<any>();
    const [ refuseProposal, setRefuseProposal ] = useState<any>();    
    const [ proposalTags, setProposalTags ] = useState<any[]>([]);
    const [ proposalFiles, setProposalFiles ] = useState<any[]>([]);    
    const [ countieInfo, setCountieInfo ] = useState<any>({
        cidade: '',
        uf: '',
    });
    const [ modalPrice, setModalPrice ] = useState<any>(false);
    const [ newPercentage, setNewPercentage ] = useState<any>();
    const reader = new FileReader();

    const states = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            // border: advancePageError?.uf ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            // border: advancePageError?.id_comarca ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    const modalities = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            // border: advancePageError?.modalidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    async function getProposal() {
        try {
            
            setLoading(true);
            const response = await api.get('proposta/' + proposalId);
            setProposalInitial(response.data.result); 
            setDTOProposal(response.data.result);      
            setProposalTags(response.data.result.tags);
            setProposalFiles(response.data.result.arquivos);
            setDateHearing(new Date(response.data.result.data));    

            if (response.data.status !== 'success') {
                throw new Error();
            }

            const id_user = response.data.result.id_usuario

            const responseProfessional = await api.get('usuario/' + id_user);
            setProfessional(responseProfessional.data.result);

            if (responseProfessional.data.status !== 'success') {
                throw new Error();
            }

            const responseHistory = await api.get('historico-proposta/' + proposalId);
            setHistoryProposal(responseHistory.data.result);

            if (responseHistory.data.status !== 'success') {
                throw new Error();
            }

            const countieId = response.data.result.id_comarca
            const responseCountie = await api.get('comarca/' + countieId)
            setCountieInfo(responseCountie.data.result);
            // console.log('log de quando atualiza o getProposal', responseCountie.data.result)

            if (responseCountie.data.status !== 'success') {

                throw new Error(responseCountie.data.message)

            }
            

            setLoading(false);

        } catch (error: any) {  

            console.log('log do erro pegando propostas', error)
            
            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            setLoading(false);
        }
        
    }

    useEffect(() => {
        setPageName(`Proposta #${proposalId}`);

        setPageType('special')

        getProposal();
        
    }, [editProposal])

    function searchKeyPressed(event: any) {

        if (event.key === 'Enter') {
            // setSearchProfiles(DTOSearch)
        }

    }

    function clearSearch() {
        setDTOSearch('')
        // setSearchProfiles('')
    }

    async function duplicateProposal() {
        try {
            
            setLoading(true)

            const response = await api.delete('proposta-reaproveitar/'+ proposalId)
            
            if (response.data.status !== 'success') {

                throw new Error(response.data.result[0].message)

            } else {

                setDeleteProposal(false);
                history.push({
                    pathname: '/formulario-proposta',
                    state: {
                        name: 'Reuse',
                        proposal: response.data.result,
                    }
                })

            }


            setLoading(false);

        } catch (error: any) {

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            setLoading(false);

        }
    }

    async function cancelProposal() {
        try {
            
            setLoading(true)

            if (justicationText === '') {
                throw new Error('Justifique sua decisão antes de cancelar a proposta')
            }

            const response = await api.delete('proposta/'+ proposalId)            

            if (response.data.status !== 'success') {
                
                throw new Error(response.data.result[0].message)

            } else {

                history.push('/propostas');

                toast.success('Proposta cancelada.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                

            }

            setDeleteProposal(false);
            setSecondStage(false);

            setLoading(false);

        } catch (error: any) {

            console.log('log do erro cancelando proposta', error)
            setLoading(false);

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function getCounties(selected: any) {

        try {
            setLoading(true);
            const response = await api.get(`comarca?uf=${selected.value}`)

            if (response.data.status !== 'success') {
                throw new Error();
            }
            const countieArray: { value: any, label: any }[] = []

            response.data.result.map((row: any) => (
                countieArray.push({
                    label: row.cidade,
                    value: row.id_comarca
                })
            ))

            setCounties(countieArray);
            setCountieInfo({
                cidade: '',    
                uf: selected.value,            
            })

            setLoading(false);

        } catch (error: any) {

            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }

    }

    function handleOnChangeMoney(name: any, value: any) {

        let newValue = value.replace(/\D/g, '');

        if (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1)
        }

        if (newValue.length <= 3) {

            newValue = newValue + '.00'

        } else {

            let txtArray = newValue.split('')
            txtArray.splice(txtArray.length - 2, 0, ".");
            var correctedValue = txtArray.join('')

            const newDTO: any = DTOProposal
            newDTO[name] = correctedValue
            setDTOProposal({ ...newDTO })

        }

    }

    function handleOnChange(name: any, value: any) {

        const newDTO: any = DTOProposal
        newDTO[name] = value
        setDTOProposal({ ...newDTO })

    }

    async function consultCep(name: any, value: any) {

        try {

            handleOnChange('cep', value);
            const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
            const data = response.data
            setCepCounty(data.bairro);
            setCepAddress(data.logradouro);

            handleOnChange('bairro', data.bairro);
            handleOnChange('rua', data.logradouro);

        } catch (err: any) {

            console.log('err, cep', err)

        }

    }  

    async function getTags() {
        try {

            if (!tag) {
                toast(<div className="warning"><span><IconWarning /></span> Digite uma TAG antes de pesquisar.</div>,
                    {
                        duration: 3000,
                        style: {
                            padding: '16px',
                            minWidth: '400px',
                            color: '#1A2858',
                            background: '#E4F5FF',
                            border: '2px solid #88D3FE',
                        },
                    });
                throw new Error('Digite uma TAG antes de pesquisar')
            }

            setLoading(true);

            const response = await api.get(`tag-find/${tag}?like=true`);
            setFilteredTags(response.data.result);            

            if (response.data.status !== 'success') {
                throw new Error()
            }

            setLoading(false);

        } catch (error: any) {
            console.log('error log getting tags', error)
            setLoading(false);
        }

    }

    function searchTags(event: any) {

        if (event.key === 'Enter') {
            getTags()
        }
    }

    function setNewTags(tag: any) {

        setSelectedTags((selectedTags: any) => [...selectedTags, tag]);
        
        setTag(''); 
        setFilteredTags('');

        setProposalTags((proposalTags: any) => [...proposalTags, tag])

        setModalTags(false);        
    }

    async function sendFiles(e: any) {
        try {

            setLoading(true)

            const formData = new FormData();
            formData.append('arquivo', e.target.files[0]);

            reader.addEventListener("load", () => {
            });

            reader.readAsDataURL(e.target.files[0]);

            // const fileWithTag = {
            //     formData: formData,
            //     conclusion: false,
            // }

            // const response = await api.post('fileupload', fileWithTag)
            // Tambem mandar o caminho com a flag

            const response = await api.post('fileupload', formData)

            setProposalFiles(proposalFiles => [...proposalFiles, {
                arquivo_final: "não",
                caminho: response.data.name
            }]);
                        
            // if (response.data.status !== 'success') {
            //     throw new Error()
            // }

            setLoading(false)

        } catch (error: any) {

            // toast.error(error, {
            //     duration: 3000,
            //     className: 'toasted',
            //     style: {
            //         padding: '16px',
            //         color: '#1A2858',
            //     },
            //     iconTheme: {
            //         primary: '#F24343',
            //         secondary: '#FFFAEE',
            //     },
            // });

        }
    }

    async function updateProposal() {
        try {

            const newDate = moment(dateHearing).format("YYYY-MM-DD HH:mm:ss")
            const deadline = moment(DTOProposal.prazo_aceite).format("YYYY-MM-DD HH:mm:ss");
            const conclusionDate = moment(DTOProposal.prazo_para_conclusao).format("YYYY-MM-DD HH:mm:ss");

            const proposalUpdated = {
                id_usuario: DTOProposal.id_usuario,
                id_servico: DTOProposal.id_servico,
                id_comarca: DTOProposal.id_comarca,
                tags: proposalTags,
                arquivos: proposalFiles,
                numero_processo: DTOProposal.numero_processo,
                nome_cliente: DTOProposal.nome_cliente,
                valor_cliente: DTOProposal.valor_cliente,
                codigo_cliente: DTOProposal.codigo_cliente,
                prazo_aceite: deadline,
                prazo_para_conclusao: conclusionDate,
                dias_uteis_para_conclusao: DTOProposal.dias_uteis_para_conclusao,
                orientacoes: DTOProposal.orientacoes,
                tipo_audiencia: DTOProposal.tipo_audiencia,
                data: newDate,
                profissional_necessario: DTOProposal.profissional_necessario,
                modalidade: DTOProposal.modalidade,
                endereco_virtual: DTOProposal.endereco_virtual,
                rua: DTOProposal.rua,
                bairro: DTOProposal.bairro,
                cep: DTOProposal.cep,
                numero: DTOProposal.numero,
                complemento: DTOProposal.complemento,
                tipo_cartorio: DTOProposal.tipo_cartorio,
                pagina_inicial: DTOProposal.pagina_inicial,
                pagina_final: DTOProposal.pagina_final,
                valor_profissional: DTOProposal.valor_profissional,
                tipo: proposalInitial.tipo,
            }

            const response = await api.put('proposta/' + proposalId, proposalUpdated)            

            if (response.data.status !== 'success') {

                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].message);
                }

            } else {
                setEditProposal(false);
                setModalPrice(false);

                toast.success('Alterações salvas com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });
            }
            
        } catch (error: any) {

            console.log('log do erro', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function resolveProposal(value: boolean) {
        try {

            setLoading(true);

            const response = await api.put(`proposta-responder/${proposalId}?aceitar=${value}`);

            if (response.data.status !== 'success') {

                if (response.data.message === '1 Erros encontrados') {
                    
                    throw new Error (response.data.result[0].message)
                    
                } else {

                    throw new Error(response.data.message);
                }

            } else {

                setRefuseProposal(false);
                setAcceptProposal(false);                

                toast.success('Proposta aceita com sucesso', {
                    duration: 5000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                history.push('/demandas');

            }
            
        } catch (err: any) {
            console.log('log do erro resolve proposal', err)

            toast.error(err.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            if (err.message === 'É preciso uma assinatura PRO ou superior para responder as propostas') {
                setTimeout(() => {
                    history.push('/propostas')
                }, 3500)
            }
        }
    }

    useEffect(() => {

        getPercentage();        

    }, [DTOProposal.valor_cliente])

    function getPercentage() {
        const total = DTOProposal.valor_cliente
        const partial = DTOProposal.lucro

        setNewPercentage((100 * partial) / total);        

    } 

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            
            if (openHistory && historyRef.current && !historyRef.current.contains(e.target)) {
                setOpenHistory(false);
            }

            if (openMenu && menuRef.current && !menuRef.current.contains(e.target)) {
                setOpenMenu(false);
            }

        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openHistory, openMenu]);

    function beforeCancelProposal() {

        if (justicationText === undefined) {

            toast.error('Justifique sua decisão antes de prosseguir.', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        } else {

            setSecondStage(true);

        }

    };
    

    // async function downloadFile(file: string) {
    //     try {
    //         setLoading(true)

    //         const response = await api.get(`getfile/ + ${file}`);

    //         console.log('log do response downloadImage', response)

    //         setLoading(false);

    //     } catch (error: any) {
    //         console.log('log do error downloadImage', error)

    //         // toast.error(error.message, {
    //         //     duration: 3000,
    //         //     className: 'toasted',
    //         //     style: {
    //         //         padding: '16px',
    //         //         color: '#1A2858',
    //         //     },
    //         //     iconTheme: {
    //         //         primary: '#F24343',
    //         //         secondary: '#FFFAEE',
    //         //     },
    //         // });

    //         setLoading(false);
    //     }
    // }
    
    return (
        <Container>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                loading !== true &&
                <StatusHeader>
                    <Status>
                        {
                            proposalInitial?.status === 'Pendente' &&
                            <p className="waiting"><IconStatus /> Aguardando aceite</p>
                        }
                        {
                            proposalInitial?.status === 'Cancelada' && 
                            <p className="canceled"><IconStatus /> Cancelada por operador</p>
                        }
                        {
                            proposalInitial?.status === 'Recusada' && 
                            <p className="recused"><IconStatus /> Recusada pelo profissional</p>
                        }
                        {
                            proposalInitial?.status === 'Pendente' &&
                            <div className="type">
                                <IconClock />
                                <div>
                                    <Moment locale='pt-br' fromNow ago>{proposalInitial.prazo_aceite}</Moment> restantes para o aceite
                                </div>
                            </div>
                        }
                    </Status>

                    <Buttons>
                        {
                            user.profile === 'comum' && proposalInitial?.status === 'Pendente' &&
                            <>
                                <ButtonIcon
                                    styles="save"
                                    Icon={<IconAccept />}
                                    onClick={() => setAcceptProposal(true)}
                                >
                                    Aceitar
                                </ButtonIcon>
                                <ButtonIcon
                                    styles="reject"
                                    Icon={<IconClose />}
                                    onClick={() => setRefuseProposal(true)}
                                >
                                    Recusar
                                </ButtonIcon>
                            </>
                        }
                        {
                            editProposal !== true && user.profile !== 'comum' &&
                            <>
                            {
                                proposalInitial?.status === 'Cancelada' || proposalInitial?.status === 'Recusada' 
                                ?   <ButtonIcon
                                        styles="save"
                                        Icon={<IconDuplicate />}
                                        onClick={() => duplicateProposal()}
                                    >
                                        Duplicar
                                    </ButtonIcon>
                                :   <ButtonIcon
                                        styles="save"
                                        Icon={<IconEdit />}
                                        onClick={() => setEditProposal(true)}
                                    >
                                        Editar
                                    </ButtonIcon>
                            }                                

                                <DropDownMenu
                                    onClick={() => { setOpenHistory(!openHistory); setOpenMenu(false) }}
                                    ref={historyRef}                         
                                >
                                    <IconTimer />
                                    <div
                                        className={openHistory ? 'hidden-history' : 'hidden-history no-show'}
                                    >
                                            <HistoryTitle>
                                                Histórico:
                                                <div
                                                    onClick={() => setOpenHistory(false)}
                                                >
                                                    <IconClose />
                                                </div>
                                            </HistoryTitle>

                                            {/* <HistorySearch>
                                                <div className="search">
                                                    <div
                                                        className="buscar"
                                                    // onClick={() => setSearchProfiles(DTOSearch)}
                                                    >
                                                        <IconSearch />
                                                    </div>
                                                    <input
                                                        placeholder="Busque uma data, nome ou participante"
                                                        onChange={(e) => setDTOSearch(e.target.value)}
                                                        onKeyPress={(e) => searchKeyPressed(e)}
                                                        value={DTOSearch}
                                                    />
                                                    {
                                                        DTOSearch &&
                                                        <div
                                                            className="clean-search"
                                                            onClick={() => clearSearch()}
                                                        >
                                                            <IconClose />
                                                        </div>
                                                    }
                                                </div>
                                            </HistorySearch> */}

                                            <HistoryTimeLine>
                                                {   
                                                    historyProposal?.length > 0 &&
                                                    historyProposal.map((row: any) => (
                                                        <div className="line-info" key={row.id_historico_proposta}>
                                                            <div className="date"><Moment locale='pt-br' format="DD/MM/YY">{row.criado}</Moment></div>
                                                            <div 
                                                                className={
                                                                    row.acao === 'criou proposta' ? 'bullet start' :  
                                                                    row.acao === 'cancelou' || row.acao.includes('Excedeu') ? "bullet end" : 'bullet' 
                                                                }
                                                            >
                                                                <IconStatus />
                                                            </div>
                                                            <div className="event">
                                                                {
                                                                    row.acao === 'criou proposta' ? 'Proposta criada' : '' ||
                                                                    row.acao === 'editou proposta' ? 'Proposta editada' : '' ||
                                                                    row.acao === 'editou' ? 'Proposta editada' : '' ||
                                                                    row.acao === 'recusou proposta' ? 'Proposta recusada' : '' ||
                                                                    row.acao === 'aceitou proposta' ? 'Proposta aceite' : '' ||
                                                                    row.acao === 'cancelou proposta' ? 'Proposta cancelada' : '' ||
                                                                    row.acao === 'criou rascunho' ? 'Rascunho criado' : '' ||
                                                                    row.acao === 'cancelou' ? 'Proposta cancelada' : '' ||
                                                                    row.acao.includes('Duplicou') ? 'Proposta duplicada' : '' ||
                                                                    row.acao === 'respondeu pendência de marjoracao' ? 'Pendência respondida' : '' ||
                                                                    row.acao === 'respondeu pendência de reembolso' ? 'Pendência respondida' : '' ||
                                                                    row.acao === 'respondeu pendência de adiantamento' ? 'Pendência respondida' : '' ||
                                                                    row.acao === 'respondeu pendência de documentacao' ? 'Pendência respondida' : '' ||
                                                                    row.acao === 'respondeu pendencia de correcao' ? 'Pendência respondida' : '' ||
                                                                    row.acao === 'criou pendencia de marjoracao' ? 'Pendência criada' : '' ||
                                                                    row.acao === 'criou pendencia de reembolso' ? 'Pendência criada' : '' ||
                                                                    row.acao === 'criou pendencia de adiantamento' ? 'Pendência criada' : '' ||
                                                                    row.acao === 'criou pendencia de correcao' ? 'Pendência criada' : '' ||
                                                                    row.acao === 'criou pendencia de documentacao' ? 'Pendência criada' : '' ||
                                                                    row.acao.includes('Excedeu') ? 'Proposta cancelada' : '' ||
                                                                    row.acao === 'Deu ciencia na orientaçao' ? 'Deu ciência' : '' 
                                                                } por {row.nome_responsavel.split(' ')[0]} {
                                                                    row.tipo_responsavel === 'Operador' 
                                                                    ? `P${row.id_responsavel}` 
                                                                    : row.tipo_responsavel === 'Profissional'
                                                                    ? `D${row.id_responsavel}`
                                                                    : row.tipo_responsavel === 'Admin'
                                                                    ? `J${row.id_responsavel}`
                                                                    : row.tipo_responsavel === 'Sistema'
                                                                    ? 'prazo de aceite excedido'
                                                                    : ''
                                                                }
                                                            </div>
                                                        </div>
                                                    )).reverse()
                                                }                                                
                                                
                                            </HistoryTimeLine> 
                                    </div>
                                </DropDownMenu>


                                {
                                    proposalInitial?.status !== 'Cancelada' &&
                                    <DropDownMenu
                                        onClick={() => { setOpenMenu(!openMenu); setOpenHistory(false) }} 
                                        ref={menuRef}                                   
                                    >
                                        <div>
                                            <IconThreeDots />
                                        </div>
                                        <div 
                                            className={openMenu ? 'hidden-menu' : 'hidden-menu no-show'}                                
                                        >
                                            {
                                                proposalInitial?.status === 'Pendente' ||
                                                proposalInitial?.status === 'Em Andamento'
                                                ?   <div
                                                        className="button"
                                                        onClick={() => setDeleteProposal(true)}
                                                    >
                                                        <IconClose />
                                                        Cancelar proposta
                                                    </div>
                                                :   <div
                                                        className="button"
                                                        onClick={() => 
                                                                toast.error("Proposta já cancelada", {
                                                                    duration: 3000,
                                                                    className: 'toasted',
                                                                    style: {
                                                                        padding: '16px',
                                                                        color: '#1A2858',
                                                                    },
                                                                    iconTheme: {
                                                                        primary: '#F24343',
                                                                        secondary: '#FFFAEE',
                                                                    },
                                                                })
                                                        }
                                                    >
                                                        <IconClose />
                                                        Cancelar proposta
                                                    </div>
                                            }
                                            
                                        </div>
                                    </DropDownMenu>
                                }
                            </>
                        }
                        {
                            editProposal === true && user.profile !== 'comum' &&
                            <>
                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => setEditProposal(false)}
                                >
                                    Cancelar
                                </ButtonDefault>

                                {
                                    proposalInitial?.valor_cliente !== DTOProposal?.valor_cliente
                                    ?   <ButtonIcon
                                            styles="filter"
                                            Icon={<IconFloppyDisk />}
                                            onClick={() => setModalPrice(true)}
                                        >
                                            Salvar Alterações
                                        </ButtonIcon>
                                    :   <ButtonIcon
                                            styles="filter"
                                            Icon={<IconFloppyDisk />}
                                            onClick={() => updateProposal()}
                                        >
                                            Salvar Alterações
                                        </ButtonIcon>
                                }
                                
                            </>

                        }
                    </Buttons>
                </StatusHeader>
            }

            {
                loading !== true &&
                <ProposalDetails>
                    
                    <ProposalLeft>

                        {
                            editProposal !== true &&
                            <>
                                <ProposalAdress>
                                    <div className="title-service">
                                        {
                                            proposalInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' ? 'Audiência' : '' ||
                                            proposalInitial?.descricao_categoria_servico.split(' ')[0] === 'Diligências' ? 'Diligência' : '' ||
                                            proposalInitial?.descricao_categoria_servico.split(' ')[0] === 'Protocolos' ? proposalInitial?.titulo_servico : ''
                                        } {
                                            proposalInitial?.titulo_servico.split(' ')[0] === 'Protocolo' 
                                            ? '' 
                                            : proposalInitial?.titulo_servico.split(' ')[0] === 'Acautelamento'
                                            ? ''
                                            : proposalInitial?.titulo_servico.split(' ')[0]
                                        }
                                    </div>

                                    <div className="countie-process">
                                        <div className="title-infos">
                                            <span>Comarca:</span>
                                            <div className="info">
                                                {countieInfo?.uf} | {countieInfo?.cidade}
                                            </div>
                                        </div>
                                        <div className="title-infos">
                                            <span>Número do processo:</span>
                                            <div className="info">
                                                {
                                                    proposalInitial?.numero_processo
                                                    ? proposalInitial.numero_processo
                                                    : 'Número não informado'
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {
                                        proposalInitial?.descricao_categoria_servico === 'Audiências' &&
                                        <div className="countie-process">
                                            <div className="title-infos">
                                                <span>Subtipo:</span>
                                                <div className="info">
                                                    {
                                                        proposalInitial?.tipo_audiencia
                                                        ?   proposalInitial.tipo_audiencia
                                                        :   'Sem subtipo'
                                                    }
                                                </div>
                                            </div>
                                            <div className="title-infos">
                                                <span>Modalidade:</span>
                                                <div className="info">
                                                    {
                                                        proposalInitial?.modalidade
                                                        ? proposalInitial?.modalidade
                                                        : 'Sem modalidade'
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {
                                        proposalInitial?.descricao_categoria_servico === 'Audiências' &&
                                        <div className="countie-process">
                                            <div className="title-infos">
                                                <span>Profissional necessário:</span>
                                                <div className="info">
                                                    {
                                                        proposalInitial?.profissional_necessario
                                                        ?   proposalInitial.profissional_necessario  === 'Advogado_Preposto'
                                                            ?   'Advogado + Preposto'
                                                            :   proposalInitial.profissional_necessario
                                                        :   'Sem profissional necessário'
                                                    }
                                                </div>
                                            </div>
                                                <div className="title-infos">
                                                    <span>Data e hora da audiência:</span>
                                                    <div className="info">
                                                        {
                                                            proposalInitial?.data !== null
                                                            ?   <Moment format="DD/MM/YYYY | HH:mm">{proposalInitial?.data}</Moment>                                        
                                                            :   'Sem data'
                                                        }
                                                    </div>
                                                </div>

                                        </div>
                                    }

                                    {
                                        proposalInitial?.modalidade !== 'online'
                                            ? 
                                                (
                                                    <div className="address">
                                                        <span>Endereço físico:</span>
                                                        <div className="address-info">
                                                            <div className="info">
                                                                {
                                                                    proposalInitial?.rua
                                                                        ? proposalInitial.rua
                                                                        : 'End. não informado'
                                                                }
                                                            </div>
                                                            <div className="info">nº {proposalInitial?.numero}</div>
                                                            <div className="info">{proposalInitial?.bairro}</div>
                                                            <div className="info">{proposalInitial?.cep}</div>
                                                            {
                                                                proposalInitial?.complemento !== ''
                                                                    ? <div className="info">{proposalInitial?.complemento}</div>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            : 
                                                (
                                                    <div className="virtual-address">
                                                        <div className="title-infos">
                                                            <span>Endereço virtual:</span>
                                                            <div className="info">
                                                                {
                                                                    proposalInitial?.endereco_virtual !== ''
                                                                        ? proposalInitial?.endereco_virtual
                                                                        : 'Sem endereço'
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                    }
                                    
                                    {/* {
                                        proposalInitial?.endereco_virtual !== '' &&
                                        <div className="virtual-address">
                                            <div className="title-infos">
                                                <span>Endereço virtual:</span>
                                                {
                                                    proposalInitial?.modalidade === 'presencial' 
                                                        ?   'Apenas presencial'
                                                        :   <div className="info">{proposalInitial?.endereco_virtual}</div>                               
                                                }
                                            </div>                            
                                        </div>
                                    } */}

                                </ProposalAdress>

                                <ServiceTags>
                                    <span>Detalhes do serviço:</span>
                                    <div className="tags">
                                        {
                                            proposalInitial?.tags.map((row: any) => (
                                                <div className="tag" key={row}>{row}</div>
                                            ))
                                        }
                                    </div>
                                </ServiceTags>

                                <Orientations>
                                    <span>Orientações:</span>
                                    {
                                        proposalInitial?.orientacoes.length === 0
                                            ? 
                                                <div className="info">
                                                    Sem orientações iniciais.
                                                </div>
                                            :   <div className="info">{proposalInitial?.orientacoes}</div>
                                    }
                                </Orientations>

                                <Files>
                                    <span>Arquivos:</span>
                                    {
                                        proposalInitial?.arquivos.length === 0 &&
                                        <div className="file">Sem arquivos anexados</div>                                                                              
                                    }
                                    {
                                        proposalInitial?.arquivos.length >= 1 &&
                                        proposalInitial.arquivos.map((row: any) => (
                                            <div className="file" key={row}>
                                                <div className="file-details">
                                                    {
                                                        row.caminho &&
                                                        <div className="icon-title">
                                                            <IconFile />
                                                            {row.caminho}
                                                        </div>
                                                    }                                                    
                                                    <div className="date"><Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.criado}</Moment></div>
                                                </div>
                                                {
                                                    row.caminho &&
                                                    <div className="download">
                                                        <a href={`https://backend.juridicoja.com/getfile/${row.caminho}`} target="_blank" rel="noreferrer">
                                                            <IconDownload />
                                                        </a>  
                                                    </div>
                                                }
                                                {
                                                    !row.caminho &&
                                                    <div className="download">
                                                        <a href={`https://backend.juridicoja.com/getfile/${row}`} target="_blank" rel="noreferrer">
                                                            <IconDownload />
                                                        </a>  
                                                    </div>
                                                }
                                            </div>   
                                        ))
                                    }
                                    

                                </Files>
                            </>
                        }

                        {
                            editProposal === true &&
                            <>
                                <ProposalAdress>
                                    <div className="title-service">
                                        {
                                            proposalInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' ? 'Audiência' : '' ||
                                            proposalInitial?.descricao_categoria_servico.split(' ')[0] === 'Diligências' ? 'Diligência' : '' ||
                                            proposalInitial?.descricao_categoria_servico.split(' ')[0] === 'Protocolos' ? 'Protocolo' : '' 
                                        } {proposalInitial?.titulo_servico.split(' ')[0]}
                                    </div>

                                    <div className="line">
                                        <div className="label-state">
                                            <p>Estado:</p>
                                            <Select
                                                name="estados"
                                                styles={states}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                placeholder='Selecionar'
                                                defaultValue={{ value: countieInfo?.uf, label: countieInfo?.uf }}
                                                onChange={getCounties}
                                                options={EstadosBrasileiros}
                                            />
                                            {/* <span className="error">{advancePageError?.uf ? '*' : ''}</span> */}
                                        </div>

                                        <div className="label-state">
                                            <p>Comarca:</p>
                                            <Select
                                                styles={city}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                name='id_comarca'
                                                placeholder="Selecionar"
                                                defaultValue={{ value: countieInfo?.cidade, label: countieInfo?.cidade }}
                                                options={counties}
                                                onChange={(e: any) => handleOnChange('id_comarca', e.value)}                                                
                                            />                                            
                                        </div>
                                    </div>
                                    
                                    <div className="line">
                                        <InputDefault
                                            title="Número do processo:"
                                            name="numero_processo"
                                            value={DTOProposal?.numero_processo}
                                            mask={'9999999-99.9999.9.99.9999'}
                                            onChangeText={(value) => handleOnChange('numero_processo', value)}
                                            className={proposalInitial?.numero_processo !== DTOProposal?.numero_processo ? 'mid highlight' : 'mid'}
                                        />

                                        <div className="label-state">
                                            <p>Subtipo:</p>
                                            {
                                                proposalInitial?.titulo_servico.split(' ')[0] === "Cível" &&
                                                <>
                                                    <Select
                                                        name="tipo_audiencia"
                                                        styles={states}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 8,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DDFAF4',
                                                                primary50: '#DDFAF4',
                                                                primary: '#02E8BE',
                                                            },
                                                        })}
                                                        placeholder='Selecionar'
                                                        defaultValue={DTOProposal?.tipo_audiencia !== '' ? { value: DTOProposal.tipo_audiencia, label: DTOProposal.tipo_audiencia } : ''}
                                                        onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                        options={CivilHearing}
                                                    />
                                                </>
                                            }
                                            {
                                                proposalInitial?.titulo_servico.split(' ')[0] === "Penal" &&
                                                <>
                                                    <Select
                                                        name="tipo_audiencia"
                                                        styles={states}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 8,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DDFAF4',
                                                                primary50: '#DDFAF4',
                                                                primary: '#02E8BE',
                                                            },
                                                        })}
                                                        placeholder='Selecionar'
                                                        defaultValue={DTOProposal.tipo_audiencia !== '' ? { value: DTOProposal.tipo_audiencia, label: DTOProposal.tipo_audiencia } : ''}
                                                        onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                        options={CriminalHearing}
                                                    />
                                                </>
                                            }
                                            {
                                                proposalInitial?.titulo_servico.split(' ')[0] === "Trabalhista" &&
                                                <>
                                                    <Select
                                                        name="tipo_audiencia"
                                                        styles={states}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 8,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DDFAF4',
                                                                primary50: '#DDFAF4',
                                                                primary: '#02E8BE',
                                                            },
                                                        })}
                                                        placeholder='Selecionar'
                                                        defaultValue={DTOProposal.tipo_audiencia !== '' ? { value: DTOProposal.tipo_audiencia, label: DTOProposal.tipo_audiencia } : ''}
                                                        onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                        options={LaborHearing}
                                                    />
                                                </>
                                            }
                                            
                                        </div>
                                    </div>
                                    
                                    {
                                        proposalInitial?.descricao_categoria_servico === 'Audiências' &&
                                        <div className="line">
                                            <div className="label-state">
                                                <p>Modalidade:</p>
                                                <Select
                                                    name="modalidade"
                                                    styles={modalities}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal?.modalidade !== '' ? { value: DTOProposal.modalidade, label: DTOProposal.modalidade } : ''}
                                                    onChange={(selected: any) => handleOnChange('modalidade', selected.value)}
                                                    options={ModalityAudience}
                                                />
                                                {/* <span className="error">{advancePageError?.modalidade ? '*' : ''}</span> */}
                                            </div>

                                            <div className="label-state">
                                                <p>Profissional necessário:</p>
                                                <Select
                                                    name="profissional_necessario"
                                                    styles={states}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal?.profissional_necessario !== '' ? { value: DTOProposal.profissional_necessario, label: DTOProposal.profissional_necessario } : ''}
                                                    onChange={(selected: any) => handleOnChange('profissional_necessario', selected.value)}
                                                    options={ProfessionalTypeNecessary}
                                                />
                                                {/* <span className="error">{advancePageError?.profissional_necessario ? '*' : ''}</span> */}
                                            </div>
                                        </div>
                                    }

                                    {
                                        proposalInitial?.descricao_categoria_servico === 'Audiências' &&    
                                        <div className="line">
                                            <div className="label-state">
                                                <p>Data e hora:</p>
                                                {
                                                    proposalInitial?.data !== null 
                                                    ?   <DateTimePicker
                                                            onChange={setDateHearing}
                                                            // value={moment(proposalInitial?.data).add(3, 'hours').toDate()}
                                                            value={dateHearing}
                                                            calendarIcon={null}
                                                            disableClock={true}
                                                            className='date'
                                                            name="data"
                                                            required={true}
                                                        />                                            
                                                    :   <div className="no-data">Sem data</div>
                                                    
                                                }
                                            </div>
                                            {
                                                proposalInitial?.modalidade !== 'online' &&
                                                <InputDefault
                                                    title="CEP:"
                                                    name="cep"
                                                    mask="99999-999"
                                                    value={DTOProposal.cep}
                                                    onChangeText={(value) => consultCep('cep', value)}
                                                    className={proposalInitial?.cep !== DTOProposal?.cep ? 'mid highlight' : 'mid'}                                            
                                                />
                                            }

                                            {
                                                proposalInitial?.modalidade === 'online' &&
                                                <InputDefault
                                                    title="Endereço virtual:"
                                                    name="link"
                                                    value={DTOProposal?.endereco_virtual}
                                                    onChangeText={(value) => handleOnChange('endereco_virtual', value)}
                                                    className={proposalInitial?.endereco_virtual !== DTOProposal?.endereco_virtual ? 'big highlight' : 'big'}
                                                />
                                            }
                                        </div>
                                    }

                                    {
                                        proposalInitial.descricao_categoria_servico !== 'Audiências' &&
                                        <div className="line">
                                            <InputDefault
                                                title="CEP:"
                                                name="cep"
                                                mask="99999-999"
                                                value={DTOProposal.cep}
                                                onChangeText={(value) => consultCep('cep', value)}
                                                className={proposalInitial?.cep !== DTOProposal?.cep ? 'small highlight' : 'small'}                                            
                                            />
                                        </div>
                                    }
                                    {
                                        proposalInitial?.modalidade !== 'online' &&
                                        <>
                                            <div className="line">
                                                <InputDefault
                                                    title="Rua:"
                                                    name="rua"
                                                    value={DTOProposal?.rua}
                                                    disabled={cepAddress ? true : false}
                                                    onChangeText={(value) => handleOnChange('rua', value)}
                                                    className={proposalInitial?.rua !== DTOProposal?.rua ? 'mid highlight' : 'mid'}
                                                />
                                                <InputDefault
                                                    title="Número:"
                                                    name="numero"
                                                    value={DTOProposal?.numero}
                                                    onChangeText={(value) => handleOnChange('numero', value)}
                                                    className={proposalInitial?.numero !== DTOProposal?.numero ? 'mid highlight' : 'mid'}
                                                />
                                            </div>

                                            <div className="line">
                                                <InputDefault
                                                    title="Bairro:"
                                                    name="bairro"
                                                    value={DTOProposal?.bairro}
                                                    disabled={cepCounty ? true : false}
                                                    onChangeText={(value) => handleOnChange('bairro', value)}
                                                    className={proposalInitial?.bairro !== DTOProposal?.bairro ? 'mid highlight' : 'mid'}
                                                />
                                                <InputDefault
                                                    title="Complemento:"
                                                    name="complemento"
                                                    value={DTOProposal?.complemento}
                                                    onChangeText={(value) => handleOnChange('complemento', value)}
                                                    className={proposalInitial.complemento !== DTOProposal?.complemento ? 'mid highlight' : 'mid'}
                                                />
                                            </div>
                                        </>
                                    }
                                    
                                    
                                </ProposalAdress>

                                <ServiceTags>
                                    <span>Detalhes do serviço:</span>
                                    <div className="tags">
                                        {
                                            proposalTags?.map((row: any) => (
                                                <div className={proposalInitial?.tags.includes(row) ? 'tag' : 'tag highlight'} key={row}>
                                                    {row} 
                                                    <div
                                                        onClick={() => setProposalTags(proposalTags.filter((obj: any) => obj !== row))}
                                                        className='close'
                                                    >
                                                        <IconClose />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div 
                                        className="add"
                                        onClick={() => setModalTags(!modalTags)}
                                    >
                                        <IconPlus /> Adicionar detalhes
                                    </div>
                                </ServiceTags>

                                <Orientations>
                                    <span>Orientações:</span>
                                    <div 
                                        className="text"                                            
                                    >
                                        <textarea
                                            name=""
                                            id=""
                                            defaultValue={DTOProposal.orientacoes}
                                            className={proposalInitial.orientacoes[0] !== DTOProposal.orientacoes ? 'text-area highlight' : "text-area"}
                                            onBlur={(e) => handleOnChange('orientacoes', e.target.value)}
                                        />
                                    </div>                                    
                                </Orientations>

                                <Files>
                                    <span>Arquivos:</span>
                                    {
                                        proposalFiles?.length === 0 &&
                                        <div className="file">Sem arquivos anexados</div>
                                    }
                                    {
                                        proposalFiles?.length > 0 &&
                                        proposalFiles.map((row: any) => (
                                            <div className="file" key={row}>
                                                <div className={proposalInitial.arquivos.includes(row) ? "file-details" : 'file-details highlight'}>
                                                    {
                                                        row.caminho &&
                                                        <div className="icon-title">
                                                            <IconFile />
                                                            {row.caminho}
                                                        </div>
                                                    }
                                                    {
                                                        !row.caminho &&
                                                        <div className="icon-title">
                                                            <IconFile />
                                                            {row}
                                                        </div>
                                                    }
                                                    <div className="date-trash">
                                                        <div className="date"><Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.criado}</Moment></div>
                                                        <div
                                                            className="icon-trash"
                                                            onClick={() => setProposalFiles(proposalFiles.filter((obj: any) => obj !== row))}
                                                        >
                                                            <IconTrash />
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    row.caminho &&
                                                    <div className="download">
                                                        <a href={`https://backend.juridicoja.com/getfile/${row.caminho}`} target="_blank" rel="noreferrer">
                                                            <IconDownload />
                                                        </a>  
                                                    </div>
                                                }
                                                {
                                                    !row.caminho &&
                                                    <div className="download">
                                                        <a href={`https://backend.juridicoja.com/getfile/${row}`} target="_blank" rel="noreferrer">
                                                            <IconDownload />
                                                        </a>  
                                                    </div>
                                                }
                                            </div>
                                        ))
                                    }
                                    <div
                                        className="add"
                                        onClick={() => ('')}
                                    >
                                        <IconUpload /> 
                                        <input
                                            type="file"
                                            name="Files"
                                            // accept="image/*"
                                            className="file-input"
                                            onChange={sendFiles}
                                        />
                                        
                                    </div>
                                </Files>
                            </>
                        }

                    </ProposalLeft>


                    <ProposalInfos>

                        <ServiceValue>
                            <span>Valor do profissional:</span>
                            {
                                editProposal !== true &&
                                <div className="value-profit">
                                    <div className="icon"><IconServiceValue /></div>
                                    {
                                        proposalInitial?.valor_profissional.toString().includes('.')
                                        ?   <div className="value">R$ {proposalInitial?.valor_profissional.toString().replace('.', ',')}</div>
                                        :   <div className="value">R$ {proposalInitial?.valor_profissional},00</div>
                                    } 
                                    {
                                        user.profile !== 'comum' 
                                        ? proposalInitial?.lucro_porcentagem.includes('-')
                                            ? <div className="profit negative">{proposalInitial?.lucro_porcentagem.split('.')[0]}% de lucro</div>
                                            : <div className="profit">+{proposalInitial?.lucro_porcentagem.split('.')[0]}% de lucro</div>                                                                                                                    
                                        : ''
                                    }
                                    
                                </div>
                            }
                            {
                                editProposal === true &&
                                <InputMoney
                                    name="valor_cliente"
                                    mask="R$ 999.999,99"
                                    placeholder="R$ 0,00"
                                    value={DTOProposal.valor_cliente ? Number(DTOProposal.valor_profissional).toFixed(2) : 0}
                                    onChangeText={(value) => handleOnChangeMoney('valor_cliente', value)}
                                    className='money'
                                />
                            }
                        </ServiceValue>
                        
                        {
                            user.profile !== 'comum' &&
                            <>
                                <ServiceValue>
                                    <span>Cliente:</span>
                                    <div className="value-profit">
                                        <div className="icon"><IconUser /></div>
                                        <div className="value">{DTOProposal?.nome_cliente.split(' ')[0]}</div>
                                        <div className="code">
                                            {
                                                proposalInitial?.codigo_cliente.includes('C')
                                                ? proposalInitial?.codigo_cliente
                                                : `C${proposalInitial?.codigo_cliente.replace('c', '')}`
                                            }
                                        </div>
                                    </div>
                                </ServiceValue>
                            
                                <ServiceValue>
                                    <span>Profissional:</span>
                                    <div className="value-profit">
                                        <div className="icon"><IconUser /></div>
                                            <div className="value">{professional?.nome.split(' ')[0].toLowerCase()}</div>
                                        <div className="code">D{proposalInitial?.id_usuario}</div>
                                        <div 
                                            className="button"
                                            onClick={() => setModalContact(true)}
                                        >
                                            <IconThreeDotsH />
                                        </div>
                                    </div>
                                </ServiceValue>
                            </>
                        }

                        {
                            user.profile === 'comum' &&
                            <ServiceValue>
                                <span>Operador responsável:</span>
                                <div className="value-profit">
                                    <div className="icon"><IconUser /></div>
                                    <div className="value">{proposalInitial?.nomeOperador.split(' ')[0]}</div>
                                    <div className="code">P{proposalInitial?.id_operador}</div>
                                    {/* <div
                                        className="button"
                                        onClick={() => setModalContact(true)}
                                    >
                                        <IconThreeDotsH />
                                    </div> */}
                                </div>
                            </ServiceValue>
                        }

                        <DeadlineInfos>
                            <div className="deadline">
                                <span>Solicitada em:</span>
                                <div className="date-hour">
                                    <div className="info"><Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.criado}</Moment></div>
                                    <div className="info"><Moment  format="HH:mm">{proposalInitial?.criado}</Moment></div>
                                </div>
                            </div>
                            <div className="deadline">
                                <span>Prazo para aceite:</span>
                                <div className="date-hour">
                                    <div className="info"><Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.prazo_aceite}</Moment></div>
                                    <div className="info"><Moment  format="HH:mm">{proposalInitial?.prazo_aceite}</Moment></div>
                                </div>
                            </div>
                            <div className="deadline">
                                <span>Iniciada em:</span>
                                <div className="date-hour">
                                    <div className="info">
                                        {
                                            proposalInitial?.data_inicio
                                            ?   <Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.data_inicio}</Moment>
                                            :   'Aguardando'        
                                        }
                                    </div>
                                    <div className="info">
                                        {
                                            proposalInitial?.data_inicio
                                            ?   <Moment  format="HH:mm">{proposalInitial?.data_inicio}</Moment>
                                            :   'aceite'
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="deadline">
                                <span>Prazo para conclusão:</span>
                                <div className="date-hour">
                                    <div className="info"><Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.prazo_para_conclusao}</Moment></div>
                                    <div className="info"><Moment  format="HH:mm">{proposalInitial?.prazo_para_conclusao}</Moment></div>
                                </div>
                            </div>
                        </DeadlineInfos>

                    </ProposalInfos>
                </ProposalDetails>
            }

            

            {/* Modal contatos do profissional */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalContact((modalContact: any) => !modalContact)}
                openModal={modalContact}
                width="520px"
            >
                <ModalContactHead>
                    <div>
                        Entre em contato
                    </div>

                    <div
                        className="close"
                        onClick={() => setModalContact(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>

                
                <ModalcontactMain>
                    <div>{professional?.email}</div>
                    <div>{professional?.celular.replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '($1) $2')
                        .replace(/(\d{5})(\d)/, '$1-$2')
                        .replace(/(-\d{4})\d+?$/, '$1')}
                    </div>
                    <div>{professional?.tel_fixo 
                        ? professional.tel_fixo.replace(/\D/g, '')
                            .replace(/(\d{2})(\d)/, '($1) $2')
                            .replace(/(\d{4})(\d)/, '$1-$2')
                            .replace(/(-\d{4})\d+?$/, '$1') 
                        : 'sem telefone fixo'}
                    </div>
                    <div>{professional?.tel_recado 
                        ? professional.tel_recado.replace(/\D/g, '')
                            .replace(/(\d{2})(\d)/, '($1) $2')
                            .replace(/(\d{4})(\d)/, '$1-$2')
                            .replace(/(-\d{4})\d+?$/, '$1') 
                        : 'sem telefone de recado'}
                    </div>
                </ModalcontactMain>
            </ModalDefault> 
            
            {/* Modal cancelar proposta */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setDeleteProposal((deleteProposal: any) => !deleteProposal)}
                openModal={deleteProposal}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Deseja cancelar esta proposta?
                            <span>{secondStage ? <IconDeleteTwo /> : <IconDeleteOne />}</span>
                        </div>
                        <div
                            className="close"
                            onClick={() => setDeleteProposal(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>   
                    
                    {
                        secondStage !== true &&
                        <div className="justify">
                            <p>Esta ação não pode ser desfeita.</p>
                            <span>Justifique sua decisão:</span>
                        </div>     
                    }

                    {
                        secondStage === true &&
                        <div className="justify cancel">
                            <p>A proposta {proposalId} será cancelada.</p>
                            <span>Gostaria de reaproveitar as definições desta proposta na criação de uma nova?</span>
                        </div>     
                    }


                    {
                        secondStage !== true &&
                        <ModalJustification>
                            <textarea 
                                name="justify"
                                id="1"
                                className="text-area"
                                onChange={(e) => setJustificationText(e.target.value)}

                            />
                        </ModalJustification>
                    }

                    <ModalButtons>
                        
                        {
                            secondStage
                            ?   <>
                                    <ButtonDefault
                                        className="cancel"
                                        onClick={cancelProposal}
                                    >
                                        Apenas cancelar
                                    </ButtonDefault>
                                    <ButtonDefault
                                        className="continue"
                                        onClick={duplicateProposal}
                                    >
                                        Apenas duplicar
                                    </ButtonDefault>
                                </>  
                            :   <>
                                    <ButtonDefault
                                        className="cancel"
                                        onClick={() => setDeleteProposal(false)}
                                    >
                                        Voltar
                                    </ButtonDefault>
                                    <ButtonDefault
                                        className="continue"
                                        onClick={() => beforeCancelProposal()}
                                    >
                                        Continuar
                                    </ButtonDefault>
                                </> 
                        }
                        

                    </ModalButtons>

                </ModalWrapper>
                
            </ModalDefault>
            
            {/* Modal Tags */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalTags((modalTags: any) => !modalTags)}
                openModal={modalTags}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Adicionar detalhes                            
                        </div>
                        <div
                            className="close"
                            onClick={() => setModalTags(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>
                    
                    <ModalSearch>
                        <div className="title">Selecione ou crie tags para descrever o serviço:</div>

                        <div className="search">
                            <div
                                className="buscar"
                                onClick={() => getTags()}
                            >
                                <IconSearch />
                            </div>
                            <input
                                placeholder="Nome da tag"
                                onChange={(e) => setTag(e.target.value)}
                                onKeyPress={(e) => searchTags(e)}
                                value={tag}
                            />
                            {
                                filteredTags && !loading &&
                                <div
                                    className="clean-search"
                                    onClick={() => { setFilteredTags(''); setTag('') }}
                                >
                                    <IconClose />
                                </div>
                            }

                            <div className={filteredTags ? 'show-tags' : 'show-tags off'}>
                                {
                                    filteredTags?.length > 0
                                        ? <span>Selecione uma das tags:</span>
                                        : <span className="empty">0 tags encontradas para a sua pesquisa.</span>
                                }

                                <div className="tag-arrays">
                                    {
                                        filteredTags &&
                                        filteredTags.map((row: any) => (
                                            <div
                                                className="tag"
                                                onClick={() => setNewTags(row.tag)}
                                                key={row.id_tag}
                                            >
                                                {row.tag}
                                            </div>
                                        ))
                                    }
                                </div>

                                {
                                    filteredTags &&
                                        filteredTags?.find((obj: { tag: any; }) => obj.tag === tag)
                                        ? ''
                                        : <>
                                            <span className="span-no-tag">Crie uma nova tag para:</span>
                                            <div className="no-tags">
                                                <div
                                                    className="tag"
                                                    onClick={() => { setNewTags(tag); setTag(''); setFilteredTags(''); }}
                                                >
                                                    {tag}
                                                </div>
                                            </div>
                                        </>
                                }


                            </div>
                        </div> 
                    </ModalSearch>
                   

                </ModalWrapper>

            </ModalDefault>
            
            {/* Modal para aceitar proposta*/}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setAcceptProposal((acceptProposal: any) => !acceptProposal)}
                openModal={acceptProposal}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        {
                            secondStage 
                            ?   <div className="points">
                                    Proposta aceita com sucesso!
                                    
                                </div>
                            :   <div className="points">
                                    Deseja aceitar esta proposta?
                                </div>
                        }                        
                        <div
                            className="close"
                            onClick={() => setAcceptProposal(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>

                    {
                        !secondStage &&
                        <div className="accept">
                            <p>Ao aceitar, você concorda com as responsabilidades e condições descritas na proposta.</p>
                        </div>
                    }

                    {
                        secondStage &&
                        <div className="accept ">
                            <div>O prazo de conclusão para esta demanda encerra em <Moment locale='pt-br' fromNow ago>{proposalInitial?.prazo_para_conclusao}</Moment>, no dia <Moment locale='pt-br' format="DD/MM/YYYY">{proposalInitial?.prazo_para_conclusao}</Moment> às <Moment  format="HH:mm">{proposalInitial?.prazo_para_conclusao}</Moment>h.</div>
                            <span>Você pode acompanha-la pelo painel de demandas!</span>
                        </div>
                    }                    

                    <ModalButtons>

                        {
                            secondStage
                                ?   <>
                                        <ButtonDefault
                                            className="cancel"
                                            onClick={() => setSecondStage(false)}
                                        >
                                            Voltar
                                        </ButtonDefault>
                                        <ButtonDefault
                                            className="continue"
                                            onClick={() => resolveProposal(true)}
                                        >
                                            Abrir demanda
                                        </ButtonDefault>
                                    </>
                                :   <>
                                        <ButtonDefault
                                            className="cancel"
                                            onClick={() => setAcceptProposal(false)}
                                        >
                                            Cancelar
                                        </ButtonDefault>
                                        <ButtonDefault
                                            className="continue"                                        
                                            onClick={() => setSecondStage(true)}
                                        >
                                            Aceitar
                                        </ButtonDefault>
                                    </>
                        }


                    </ModalButtons>

                </ModalWrapper>

            </ModalDefault>

            {/* Modal recusar proposta */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setRefuseProposal((refuseProposal: any) => !refuseProposal)}
                openModal={refuseProposal}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Deseja recusar esta proposta?
                        </div>
                        <div
                            className="close"
                            onClick={() => setRefuseProposal(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>

                    <div className="refuse">
                        <p>Descreva a justificativa da exclusão:</p>
                    </div>

                    <ModalJustification>
                        <textarea
                            name="justify"
                            id="1"
                            className="text-area"
                            onChange={(e) => setJustificationText(e.target.value)}
                        />
                    </ModalJustification>
                    

                    <ModalButtons>

                       <ButtonDefault
                            className="cancel"
                            onClick={() => setRefuseProposal(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="continue"
                            disabled={justicationText === undefined ? true : false}
                            onClick={() => resolveProposal(false)}
                        >
                            Recusar
                        </ButtonDefault>

                    </ModalButtons>

                </ModalWrapper>

            </ModalDefault>

            {/* Modal salvar alterações preço */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalPrice((modalPrice: any) => !modalPrice)}
                openModal={modalPrice}
                width="520px"
            >
                <ModalContactHead>
                    <div>
                        Salvar alterações
                    </div>

                    <div
                        className="close"
                        onClick={() => setModalPrice(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>

                <ModalChanges>
                    <div className="adjust">
                        Com os ajustes realizados, o valor do serviço foi atualizado com base na precificação do profissional.
                    </div>

                    <div className="prices">
                        {
                            proposalInitial?.valor_cliente.toString().includes('.')
                            ?   <div className="old-price">
                                    <p>De:</p>
                                    R$ {proposalInitial?.valor_cliente.toString().replace('.', ',')}
                                    <div className="icon">
                                        <IconServiceValue />
                                    </div>
                                </div>
                            :   <div className="old-price">
                                    <p>De:</p>
                                    R$ {proposalInitial?.valor_cliente},00
                                    <div className="icon">
                                        <IconServiceValue />
                                    </div>
                                </div>
                        }
                        
                        {
                            DTOProposal?.valor_cliente.toString().includes('.')
                            ?   <div className="new-price">
                                    <p>Para:</p>
                                    R$ {DTOProposal?.valor_cliente.toString().replace('.', ',')}
                                    <div className="icon">
                                        <IconServiceValue />
                                    </div>
                                </div>
                            :   <div className="new-price">
                                    <p>Para:</p>
                                    R$ {DTOProposal?.valor_cliente},00
                                    <div className="icon">
                                        <IconServiceValue />
                                    </div>
                                </div>
                        }
                        
                    </div>

                    <div className="profit">O lucro estimado de <span>{proposalInitial?.lucro_porcentagem.replace('.00', '')}</span> passa a ser de <span>{newPercentage?.toFixed()}%</span>, com base no valor pago pelo cliente.</div>
                </ModalChanges>
                

                <ModalButtons>

                    <ButtonDefault
                        className="cancel"
                        onClick={() => setModalPrice(false)}
                    >
                        Cancelar
                    </ButtonDefault>
                    <ButtonDefault
                        className="continue"
                        disabled={justicationText === undefined ? true : false}
                        onClick={() => updateProposal()}
                    >
                        Salvar alterações
                    </ButtonDefault>

                </ModalButtons>
               
            </ModalDefault> 

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}