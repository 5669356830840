import styled, { keyframes } from "styled-components";

const appearFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(-1px);
  }
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.primaryBlue500};
    display: flex;
    align-items: center;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    justify-content: space-between;

    @media (max-width: 800px) {
        justify-content: center;
        position: relative;
    }
`;

export const Logo = styled.img`
    width: 9.53rem;
    cursor: pointer;
`;

export const AreaLinks = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    gap: 56px;

    @media (max-width: 800px) {
        display: none;        
    }
`

export const Tab = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: #FFF;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &.selected {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primaryOrange600};
    }

    &.one {
        display: flex;
        justify-content: center;
        width: 143px;        
    }

    &.two {
        display: flex;
        justify-content: center;
        width: 270px;
    }

    &.three {
        display: flex;
        justify-content: center;
        width: 230px;
    }

    &.four {
        display: flex;
        justify-content: center;
        width: 100px;
    }
`

export const AreaLogin = styled.a`
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-left: 2rem;

    @media (max-width: 800px) {
        margin-left: 0;
    }
`;

export const BurguerMenu = styled.div`
    display: none;

    @media (max-width: 800px) {
        display: block;
        margin-left: auto;
    }

    .menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
    }
`

export const DropDownMenu = styled.div`
    display: none;

    animation: ${appearFromTop} 0.5s;

    @media (max-width: 800px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        margin-top: 70px;
    }

    width: 100%;
    height: fit-content;
    background-color: ${({ theme }) => theme.colors.primaryBlue600};
`