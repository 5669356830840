/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
//  React
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";
import Checkbox from "../../components/form/InputCheckbox";
import InputDefault from "../../components/form/InputDefault";
import ModalTerms from "../../components/ModalTerms";
import { ModalHeader, ModalMain } from "../Register/styles";

// Icons
import { IconClock, IconClose, IconFile, IconLogoJuridico, IconTrash, IconUpload } from "../../components/icons";

// Styles
import { 
    ButtonCard, 
    CardWrapper, 
    ConclusionWrapper, 
    DeadlineConclusion, 
    Divider,
    Footer, 
    FormMain, 
    Header, 
    Main, 
    Orientations, 
    ServiceOptionCard, 
    ServicesCardWrapper, 
    ServiceSelector, 
    ServiceWrapper, 
    Upload
} from "./styles";

// Libraries
import Select from 'react-select';
import Moment from 'react-moment';
import DateTimePicker from 'react-datetime-picker';
import toast, { Toaster } from 'react-hot-toast';
import ReCAPTCHA from "react-google-recaptcha";

// Services
import axios from "axios";
import api from "../../services/api";

// Data
import { EstadosBrasileiros } from "../../components/Data/StatesData";
import { CivilHearing } from "../../components/Data/CivilHearing";
import { CriminalHearing } from "../../components/Data/CriminalHearing";
import { LaborHearing } from "../../components/Data/LaborHearing";
import { AnotherHearing } from "../../components/Data/AnotherHearing";
import { ModalityAudience } from "../../components/Data/ModalityAudience";
import { ProfessionalTypeNecessary } from "../../components/Data/ProfessionalTypeNecessary";
import { NotariesTypes } from "../../components/Data/Notaries";

interface Proposal {
    arquivos: any,
    numero_processo: string,
    nome_cliente: string,
    cpf: string,
    email: string,
    telefone: string,
    razao_social: string,
    cnpj: string,
    estado_comarca: string,
    cidade_comarca: string,
    prazo_para_conclusao: string,
    orientacoes: string,
    tipo_audiencia: string,
    tipo_cartorio: string,
    profissional_necessario: string,
    modalidade: string,
    endereco_virtual: string,
    rua: string,
    bairro: string,
    cep: string,
    numero: string,
    complemento: string,
}

export default function RequestService() {
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>();
    const [activePage, setActivePage] = useState<number>(1);
    const [checked, setChecked] = useState<boolean>(false);
    const [radioSelected, setRadioSelected] = useState<any>();
    const [cardSelected, setCardSelected] = useState<string>('Audiência');
    const [serviceSelected, setServiceSelected] = useState<string>();
    const [cities, setCities] = useState<any>([]);
    const [conclusionDate, setConclusionDate] = useState(new Date());
    const [orientationsText, setOrientationsText] = useState<any>();
    const [uploadFiles, setUploadFiles] = useState<any[]>([]);
    const [uploadFilesName, setUploadFilesName] = useState<any[]>([]);
    const reader = new FileReader();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [token, setToken] = useState<any>();

    const ComarcaDefault = {
        uf: '',
        cidade: '',
    };

    const [DTOComarcas, setDTOComarcas] = useState<any>([ComarcaDefault]);

    const [DTOProposal, setDTOProposal] = useState<Proposal>({
        arquivos: uploadFiles,
        numero_processo: '',
        nome_cliente: '',
        cpf: '',
        email: '',
        telefone: '',
        razao_social: '',
        cnpj: '',
        estado_comarca: '',
        cidade_comarca: '',
        prazo_para_conclusao: '',
        orientacoes: '',
        tipo_audiencia: serviceSelected ? serviceSelected : '',
        profissional_necessario: '',
        modalidade: '',
        endereco_virtual: '',
        rua: '',
        bairro: '',
        cep: '',
        numero: '',
        complemento: '',  
        tipo_cartorio: '',      
    });    

    const countyState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const countyCity = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const hearing = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    const modalities = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }  

    const professional = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const states = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }
    
    function handleOnChangeComarcas(key: number, name: any, value: string) {        

        const newDTO: any = DTOComarcas
        newDTO[key][name] = value
        setDTOComarcas([...newDTO])

        if (name === 'uf') getCitiesForUF(DTOComarcas)
    }

    function handleOnChange(name: any, value: any) {

        const newDTO: any = DTOProposal
        newDTO[name] = value
        setDTOProposal({ ...newDTO })

    }

    function getCitiesForUF(DTOComarcas: any) {
        const newCities: any = []
        DTOComarcas.map((row: any, key: number) => {
            const cityName: any = {
                uf: row.uf,
                docs: []
            }
            axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${row.uf}/municipios`).then(response => {
                response.data.map((row: any) => {
                    cityName.docs.push({
                        value: row.nome,
                        label: row.nome
                    })
                })
                newCities.push(cityName)
                setCities([...newCities])
            });
        })
    }

    async function advancePage() {

        try {

            if (activePage === 1) {
                if (checked === false) {

                    throw new Error("É necessário ler e concordar com os termos de uso e condições.")

                }                
            }

            // if (activePage === 1) {
            //     handleOnChange('uf', cepState);
            //     handleOnChange('cidade', cepCity);
            //     handleOnChange('bairro', cepCounty);
            //     handleOnChange('endereco', cepAddress);

            //     const schema = yup.object().shape({
            //         nome: yup.string().required('Nome é obrigatório'),
            //         cpf: yup.string().required('CPF é obrigatório').min(11).max(14),
            //         razao_social: yup.string(),
            //         inscricao_oab: yup.string().required('Inscrição OAB é obrigatório'),
            //         estado_oab: yup.string().required('Estado em que a OAB é registrado é obrigatório'),
            //         tipo_oab: yup.string().required('Tipo de inscrição é obrigatório'),
            //         celular: yup.string().required('Celular é obrigatório').min(11).max(11),
            //         email: yup.string().required('Email é obrigatório').email("Digite um e-mail válido"),
            //         whatsapp: yup.string().required('Whatsapp é obrigatório').min(11).max(11),
            //         cep: yup.string().required('CEP é obrigatório'),
            //         uf: yup.string().required('Estado é obrigatório'),
            //         cidade: yup.string().required('Cidade é obrigatório'),
            //         bairro: yup.string().required('Bairro é obrigatório'),
            //         endereco: yup.string().required('Endereço é obrigatório'),
            //         numero: yup.number().required('Numero é obrigatório').integer(),
            //     });
            //     await schema.validate(DTOUser, {
            //         abortEarly: false,
            //     });

            //     if (checked === false) {

            //         throw new Error("É necessário ler e concordar com os termos de uso e condições.")

            //     } else {
            //         handleOnChange('termos', 'sim')
            //     }

            //     if (DTOUser.cnpj.length < 14) {
            //         handleOnChange('cnpj', '')
            //     }

            //     if (DTOUser.tel_recado.length < 13) {
            //         handleOnChange('tel_recado', '')
            //     }

            //     if (DTOUser.tel_fixo.length < 13) {
            //         handleOnChange('tel_fixo', '')
            //     }

            //     setChecked(false);
            // }

            // if (activePage === 2) {
            //     const DTOErrors: any = []

            //     DTOServices.map((row: any, key: number) => {
            //         DTOErrors[key] = {
            //             servicos: [],
            //             outros_servicos: {}
            //         }
            //         if (!row.titulo) DTOErrors[key].titulo = true
            //         if (!row.outros_servicos.valor_diligencia) DTOErrors[key].outros_servicos.valor_diligencia = true
            //         if (!row.outros_servicos.valor_protocolo) DTOErrors[key].outros_servicos.valor_protocolo = true
            //         row.servicos.map((servico: any, servicoKey: any) => {
            //             if (servico.valor_advogado <= 0) DTOErrors[key].servicos.push({
            //                 valor_advogado: true,
            //                 key: servicoKey
            //             })
            //             if (servico.valor_preposto <= 0) DTOErrors[key].servicos.push({
            //                 valor_preposto: true,
            //                 key: servicoKey
            //             })
            //             if (servico.valor_total <= 0) DTOErrors[key].servicos.push({
            //                 valor_total: true,
            //                 key: servicoKey
            //             })

            //         })
            //     })

            //     setAdvancePrecificationError(DTOErrors)
            //     DTOErrors.map((row: any) => {
            //         if (
            //             row.titulo === true ||
            //             row.servicos.length > 0 ||
            //             row.outros_servicos.valor_diligencia === true ||
            //             row.outros_servicos.valor_protocolo === true
            //         )
            //             throw new Error("Para avançar é necessário preencher com valores válidos todos os campos obrigatórios.")
            //     })

            //     const pricing: { value: any; label: any; }[] = []

            //     DTOServices.map((row: any, key: any) => {
            //         pricing.push({
            //             value: row.titulo,
            //             label: row.titulo
            //         })
            //     })

            //     const DataPrecification = {
            //         precificacoes: DTOServices
            //     }

            //     const response = await api.post('precificacao-validar', DataPrecification)

            //     if (response.data.status !== "success") {
            //         throw new Error(response.data.message)
            //     }

            //     setPricingCounty(pricing)

            //     if (DTOServices.length < 1) {
            //         throw new Error('É necessário adicionar pelo menos uma precificação')
            //     }
            // }

            if (activePage === 3) {

                if (!DTOProposal.modalidade) {
                    handleOnChange('modalidade', 'presencial')
                }

            //     if (DTOComarcas.length < 1) {
            //         throw new Error('É necessário adicionar pelo menos uma Comarca')
            //     }

            //     const ComarcaSchema = yup.object().shape({
            //         uf: yup.string().required('Escolha um estado'),
            //         cidade: yup.string().required('Escolha uma cidade'),
            //         precificacao: yup.string().required('Escolha uma precificação'),
            //     });

            //     await ComarcaSchema.validate(DTOComarcas[0], {
            //         abortEarly: false,
            //     });

            //     const Data = {
            //         comarcas: DTOComarcas
            //     }

            //     const response = await api.post('comarca-validar', Data)

            //     if (response.data.status !== "success") {
            //         throw response.data.message
            //     }
            //     setActivePage(activePage + 1);


            }

            if (activePage === 4) {
                
                handleOnChange('arquivos', uploadFiles)

                if (!token) {

                    throw new Error ('Para enviar, confirme que você não é um robô')

                }

                const response = await api.post('service-email-site', DTOProposal)

                console.log('log do response', response.data)

                // if (response.data.status !== "success") {

                //     throw new Error (response.data.message);

                // }

                history.push('/servico-completo');


            }

            setActivePage(activePage + 1);
            

        } catch (err: any) {

            if (activePage === 1) {
                toast.error(err.message, {
                    duration: 2500,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }

            if (activePage === 4) {
                toast.error(err.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }

            // if (err instanceof yup.ValidationError) {
            //     const errors = getValidationErrors(err);

            //     setAdvancePageError(errors)

            //     if (activePage === 3) {
            //         setAdvanceCountyError(errors)
            //     }

            //     if (activePage === 4) {
            //         setAdvanceAccountError(errors)
            //     }

            //     toast.error('Preencha os campos corretamente.', {
            //         duration: 3000,
            //         className: 'toasted',
            //         style: {
            //             padding: '16px',
            //             color: '#1A2858',
            //         },
            //         iconTheme: {
            //             primary: '#F24343',
            //             secondary: '#FFFAEE',
            //         },
            //     });

            // } else {
            //     if (activePage === 2) {
            //         toast.error(err.message, {
            //             duration: 2500,
            //             className: 'toasted',
            //             style: {
            //                 padding: '16px',
            //                 color: '#1A2858',
            //             },
            //             iconTheme: {
            //                 primary: '#F24343',
            //                 secondary: '#FFFAEE',
            //             },
            //         });
            //     } else if (activePage === 3) {
            //         toast.error(err.message, {
            //             duration: 2500,
            //             className: 'toasted',
            //             style: {
            //                 padding: '16px',
            //                 color: '#1A2858',
            //             },
            //             iconTheme: {
            //                 primary: '#F24343',
            //                 secondary: '#FFFAEE',
            //             },
            //         });
            //     } else if (activePage === 5) {
            //         toast.error(err.message, {
            //             duration: 2500,
            //             className: 'toasted',
            //             style: {
            //                 padding: '16px',
            //                 color: '#1A2858',
            //             },
            //             iconTheme: {
            //                 primary: '#F24343',
            //                 secondary: '#FFFAEE',
            //             },
            //         });
            //     } else {
            //         toast.error(err.toString(), {
            //             duration: 2500,
            //             className: 'toasted',
            //             style: {
            //                 padding: '16px',
            //                 color: '#1A2858',
            //             },
            //             iconTheme: {
            //                 primary: '#F24343',
            //                 secondary: '#FFFAEE',
            //             },
            //         });
            //     }

            // }

        }
    }

    async function sendFiles(e: any) {
        try {

            setLoading(true);

            const formData = new FormData();
            formData.append('arquivo', e.target.files[0]);

            reader.addEventListener("load", () => { });

            reader.readAsDataURL(e.target.files[0]);

            const response = await api.post('attachments-files', formData)
            
            setUploadFiles(obj => [...obj, response.data.data[0]]);
            setUploadFilesName(obj => [...obj, response.data.data[1]]);

            setLoading(false)

        } catch (error: any) {

            console.log('log do erro ao envir arquivo', error);            

        }
    }

    return(
        <ServiceWrapper>
            <Header>
                <div><IconLogoJuridico /></div>
                
                <div className="progress">
                    <div className="progressbar">
                        <div className={
                            activePage === 1 ? "percent" : '' ||
                            activePage === 2 ? "percent two" : '' ||
                            activePage === 3 ? "percent three" : '' ||
                            activePage === 4 ? "percent four" : '' ||
                            activePage === 5 ? "percent five" : '' 
                        }>
                        </div>
                    </div>
                    <div>
                        <span>
                            {
                                activePage === 1 ? "20%" : '' ||
                                activePage === 2 ? "40%" : '' ||
                                activePage === 3 ? "60%" : '' ||
                                activePage === 4 ? "80%" : '' ||
                                activePage === 5 ? "100%" : ''
                            }
                        </span>
                    </div>
                </div>
            </Header>

            <Main>
                
                {
                    activePage === 1 &&
                    <div className="top">
                        <div className="one" >
                            <div className="register underline">
                                <h3>Estamos felizes em ajudar!</h3>
                                <p>Informe abaixo seus dados e confira nossos <span
                                    onClick={() => setOpenModal(true)}
                                >
                                    termos de uso e condições
                                </span>.
                                </p>
                            </div>
                        </div>
                    </div>
                }
                
                {
                    activePage === 3 &&
                    <div className="top">
                        <div className="three">
                            <div className="register">
                                <h3>{serviceSelected}</h3>
                                <div className="subtitle">
                                    Informe os dados para localizarmos o profissional apropriado.
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    activePage === 4 &&
                    <div className="top">
                        <div className="four">
                            <div className="register">
                                <h3>{serviceSelected} {DTOProposal?.modalidade}</h3>                                
                            </div>
                        </div>
                    </div>
                }                
                
                {
                    activePage === 1 &&
                    <FormMain>
                        <div className="radio">
                            <div className="radio-input">
                                <input
                                    type="radio"
                                    value="sim"
                                    name="Fisica"
                                    checked={radioSelected === 'Fisica'}
                                    onChange={(value: any) => setRadioSelected(value.target.name)}
                                    placeholder="Pessoa física"
                                />
                                <span>Pessoa física</span>
                            </div>

                            <div className="radio-input">
                                <input
                                    type="radio"
                                    value="sim"
                                    name="Juridica"
                                    checked={radioSelected === 'Juridica'}
                                    onChange={(value: any) => setRadioSelected(value.target.name)}
                                    placeholder="Pessoa jurídica"
                                />
                                <span>Pessoa jurídica</span>
                            </div>
                        </div>

                        <div className="name">
                            <InputDefault
                                title="Seu nome completo:"
                                name='nome'
                                value={DTOProposal?.nome_cliente}
                                onChangeText={(value) => handleOnChange('nome_cliente', value)}
                                className='big'
                            />

                            <InputDefault
                                title="CPF:"
                                name="cpf"
                                mask="999.999.999-99"
                                value={DTOProposal?.cpf}
                                onChangeText={(value) => handleOnChange('cpf', value)}
                                className='big'
                            />
                        </div>

                        <div className="mail">
                            <InputDefault
                                title="E-mail para contato:"
                                name="email"
                                value={DTOProposal?.email}
                                onChangeText={(value) => handleOnChange('email', value)}
                                className='big'
                            />

                            <InputDefault
                                title="Telefone contato:"
                                name="tel_contato"
                                mask="(99) 99999-9999"
                                value={DTOProposal?.telefone}
                                onChangeText={(value) => handleOnChange('telefone', value)}
                                className='big'
                            />
                        </div>

                        {
                            radioSelected === 'Juridica' &&
                            <div className="social">
                                <InputDefault
                                    title="Razão Social:"
                                    name="razao_social"
                                    value={DTOProposal?.razao_social}
                                    onChangeText={(value) => handleOnChange('razao_social', value)}
                                    className='big'
                                />

                                <InputDefault
                                    title="CNPJ:"
                                    name="cnpj"
                                    mask="99.999.999/9999-99"
                                    value={DTOProposal?.cnpj}
                                    onChangeText={(value) => handleOnChange('cnpj', value)}
                                    className='big'
                                />
                            </div>
                        }

                        <div>
                            <Checkbox
                                label="Concordo com os termos de uso e condições"
                                name="terms"
                                value={checked}
                                onClick={() => setChecked(!checked)}
                            />
                        </div>
                    </FormMain>
                }

                {
                    activePage === 2 &&
                    <ServicesCardWrapper>
                        <CardWrapper>
                            <ButtonCard
                                className={cardSelected === 'Audiência' ? 'selected' : ''}
                                onClick={() => setCardSelected('Audiência')}
                            >
                                Audiência
                            </ButtonCard>
                            <ButtonCard
                                className={cardSelected === 'Diligência' ? 'selected' : ''}
                                onClick={() => setCardSelected('Diligência')}
                            >
                                Diligência
                            </ButtonCard>
                            <ButtonCard
                                className={cardSelected === 'Protocolo' ? 'selected' : ''}
                                onClick={() => setCardSelected('Protocolo')}
                            >
                                Protocolo
                            </ButtonCard>
                            <ButtonCard
                                className={cardSelected === 'Outros' ? 'selected' : ''}
                                onClick={() => setCardSelected('Outros')}
                            >
                                Outros
                            </ButtonCard> 
                        </CardWrapper>

                        <Divider />
                        
                        <ServiceSelector>
                            {
                                cardSelected === 'Audiência' &&
                                <>
                                    <ServiceOptionCard>
                                        Cível
                                        <div
                                            className="select"
                                            onClick={() => {setServiceSelected('Audiência cível'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar                                        
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Penal
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Audiência penal'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Trabalhista
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Audiência trabalhista'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar                                       
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Outros
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Audiência'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>
                                </>
                            }

                            {
                                cardSelected === 'Diligência' &&
                                <>
                                    <ServiceOptionCard>
                                        Acompanhamento de atos oficiais
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Acompanhamento de atos oficiais'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Comparecimento em cartório
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Comparecimento em cartório'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Cópia integral
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Cópia integral'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar                                        
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Cópia parcial
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Cópia parcial'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Despacho
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Despacho'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Retirada de ofício e alvará
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Retirada de ofício e alvará'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Outros
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Outros'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                </>
                            }

                            {
                                cardSelected === 'Protocolo' &&
                                <>
                                    <ServiceOptionCard>
                                        Acautelamento
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Acautelamento'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Protocolo eletrônico
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Protocolo eletrônico'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Protocolo físico
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Protocolo físico'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>

                                    <ServiceOptionCard>
                                        Protocolo postal
                                        <div 
                                            className="select"
                                            onClick={() => {setServiceSelected('Protocolo postal'); setActivePage(activePage + 1)}}
                                        >
                                            Selecionar
                                        </div>
                                    </ServiceOptionCard>
                                </>
                            }

                            {
                                cardSelected === 'Outros' &&
                                <ServiceOptionCard>
                                    Outro serviço
                                    <div 
                                        className="select"
                                        onClick={() => {setServiceSelected('Outro serviço'); setActivePage(activePage + 1)}}
                                    >
                                        Selecionar                                    
                                    </div>
                                </ServiceOptionCard>
                            }
                        </ServiceSelector>
                    </ServicesCardWrapper>
                }

                {
                    activePage === 3 &&
                    <FormMain>
                        {DTOComarcas.map((row: any, key: number) =>
                            <div className={`comarca ${key > 0 ? 'line' : 'none'}`} key={key}>

                                <div className="state">
                                    <div className="label">
                                        <p>Estado da comarca:</p>
                                        <Select
                                            styles={countyState}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            name='estado_comarca'
                                            placeholder="Selecionar"
                                            defaultValue={row.uf !== '' ? { value: row.uf, label: row.uf } : ''}
                                            options={EstadosBrasileiros}
                                            onChange={(e: any) => { handleOnChangeComarcas(key, 'uf', e.value); handleOnChange('estado_comarca', e.label)}}
                                        />                                        
                                    </div>

                                    {cities.filter((obj: any) => obj.uf === row.uf)[0]?.docs.length > 0 &&
                                        <div className="label">
                                            <p>Cidade da comarca:</p>
                                            <Select
                                                styles={countyCity}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                name='cidade'
                                                placeholder="Selecionar"
                                                defaultValue={row.cidade !== '' ? { value: row.cidade, label: row.cidade } : ''}
                                                options={cities.filter((obj: any) =>
                                                    obj.uf === row.uf
                                                )[0]?.docs}
                                                onChange={(e: any) => { handleOnChangeComarcas(key, 'cidade', e.value); handleOnChange('cidade_comarca', e.label) }}
                                            />                                            
                                        </div>
                                    }
                                </div>
                                
                            </div>
                        )}    

                        <div className="process">
                            <InputDefault
                                title="Número do processo:"
                                name="numero_processo"
                                value={DTOProposal?.numero_processo}
                                mask={'9999999-99.9999.9.99.9999'}
                                onChangeText={(value: any) => handleOnChange('numero_processo', value)}
                                className='big'
                            />
                        </div>    

                        {
                            cardSelected === 'Audiência' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>audiências</span>, informe também:
                                </div>

                                <div className="subtype">
                                    <div className="label-state">
                                        <p>Subtipo:</p>
                                        {
                                            serviceSelected === 'Audiência cível' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal?.tipo_audiencia !== '' ? { value: DTOProposal?.tipo_audiencia, label: DTOProposal?.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={CivilHearing}
                                                />
                                            </>
                                        }
                                        {
                                            serviceSelected === 'Audiência penal' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal?.tipo_audiencia !== '' ? { value: DTOProposal?.tipo_audiencia, label: DTOProposal?.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={CriminalHearing}
                                                />
                                            </>
                                        }
                                        {
                                            serviceSelected === 'Audiência trabalhista' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal?.tipo_audiencia !== '' ? { value: DTOProposal?.tipo_audiencia, label: DTOProposal?.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={LaborHearing}
                                                />
                                            </>
                                        }
                                        {
                                            serviceSelected === 'Audiências' &&
                                            <>
                                                <Select
                                                    name="tipo_audiencia"
                                                    styles={hearing}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={DTOProposal?.tipo_audiencia !== '' ? { value: DTOProposal?.tipo_audiencia, label: DTOProposal?.tipo_audiencia } : ''}
                                                    onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                    options={AnotherHearing}
                                                />
                                            </>
                                        }
                                    </div>

                                    <div className="label-state">
                                        <p>Modalidade:</p>
                                        <Select
                                            name="modalidade"
                                            styles={modalities}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            placeholder='Selecionar'
                                            defaultValue={DTOProposal?.modalidade !== '' ? { value: DTOProposal?.modalidade, label: DTOProposal?.modalidade } : ''}
                                            onChange={(selected: any) => handleOnChange('modalidade', selected.value)}
                                            options={ModalityAudience}
                                        />
                                    </div>
                                </div>

                                <div className="professional">
                                    <div className="label-state">
                                        <p>Profissional necessário:</p>
                                        <Select
                                            name="profissional_necessario"
                                            styles={professional}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            placeholder='Selecionar'
                                            defaultValue={DTOProposal?.profissional_necessario !== '' ? { value: DTOProposal?.profissional_necessario, label: DTOProposal?.profissional_necessario } : ''}
                                            onChange={(selected: any) => handleOnChange('profissional_necessario', selected.value)}
                                            options={ProfessionalTypeNecessary}
                                        />
                                    </div>                                    

                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        value={DTOProposal?.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className='big'                                        
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={DTOProposal?.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className='mid'
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        value={DTOProposal?.cep}
                                        mask="99999-999"
                                        onChangeText={(value) => handleOnChange('cep', value)}
                                        className='small'
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal?.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className='num'
                                    />
                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal?.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className='comp'
                                    />

                                    <InputDefault
                                        title="Endereço virtual:"
                                        name="link"
                                        value={DTOProposal?.endereco_virtual}
                                        onChangeText={(value) => handleOnChange('endereco_virtual', value)}
                                        className='big'
                                    />
                                    
                                </div>

                            </div>
                        }   

                        {
                            cardSelected === 'Diligência' && serviceSelected === 'Comparecimento em cartório' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>comparecimento em cartório</span>, informe também:
                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        value={DTOProposal?.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className='big'
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={DTOProposal?.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className='mid'                                            
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        mask="99999-999"
                                        value={DTOProposal?.cep}
                                        onChangeText={(value) => handleOnChange('cep', value)}
                                        className='small'
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal?.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className='num'
                                    />
                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal?.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className='comp'
                                    />
                                    <div className="label-state">
                                        <p>Tipo de cartório:</p>
                                        <Select
                                            name="estado_oab"
                                            styles={states}
                                            components={{
                                                IndicatorSeparator: () => null
                                            }}
                                            theme={(theme) => ({
                                                ...theme,
                                                borderRadius: 8,
                                                colors: {
                                                    ...theme.colors,
                                                    primary25: '#DDFAF4',
                                                    primary50: '#DDFAF4',
                                                    primary: '#02E8BE',
                                                },
                                            })}
                                            placeholder='Selecionar'
                                            defaultValue={DTOProposal?.tipo_cartorio !== '' ? { value: DTOProposal?.tipo_cartorio, label: DTOProposal?.tipo_cartorio } : ''}
                                            onChange={(selected: any) => handleOnChange('tipo_cartorio', selected.value)}
                                            options={NotariesTypes}
                                        />
                                    </div>
                                </div>
                            </div>
                        }  

                        {
                            cardSelected === 'Diligência' && serviceSelected === 'Cópia parcial' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>cópia parcial</span>, informe também:
                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        placeholder="Nome da rua"
                                        value={DTOProposal?.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className='big'
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={DTOProposal?.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className='mid'
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        mask="99999-999"
                                        value={DTOProposal?.cep}
                                        onChangeText={(value) => handleOnChange('cep', value)}
                                        className='small'
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal?.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className='num'
                                    />

                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal?.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className='comp'
                                    />

                                    
                                </div>
                            </div>
                        }   

                        {
                            cardSelected === 'Diligência' && serviceSelected === 'Cópia integral' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>cópia integral</span>, informe também:
                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        value={DTOProposal?.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className='big'
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={DTOProposal?.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className='mid'
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        mask="99999-999"
                                        value={DTOProposal?.cep}
                                        onChangeText={(value) => handleOnChange('cep', value)}
                                        className='small'
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal?.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className='num'
                                    />
                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal?.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className='comp'
                                    />
                                </div>
                            </div>
                        }    

                        {
                            cardSelected === 'Protocolo' && serviceSelected === 'Acautelamento' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>acautelamento</span>, informe também:
                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        value={DTOProposal?.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className='big'
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={DTOProposal?.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className='mid'
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        mask="99999-999"
                                        value={DTOProposal?.cep}
                                        onChangeText={(value) => handleOnChange('cep', value)}
                                        className='small'
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal?.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className='num'
                                    />
                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal?.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className='comp'
                                    />
                                </div>
                            </div>
                        }    

                        {
                            cardSelected === 'Protocolo' && serviceSelected === 'Protocolo postal' &&
                            <div className="bottom">

                                <div className="subtitle">
                                    Para <span>protocolo postal</span>, informe também:
                                </div>

                                <div className="address">
                                    <InputDefault
                                        title="Rua:"
                                        name="rua"
                                        value={DTOProposal?.rua}
                                        onChangeText={(value) => handleOnChange('rua', value)}
                                        className='big'
                                    />
                                    <InputDefault
                                        title="Bairro:"
                                        name="bairro"
                                        value={DTOProposal?.bairro}
                                        onChangeText={(value) => handleOnChange('bairro', value)}
                                        className='mid'
                                    />
                                    <InputDefault
                                        title="CEP:"
                                        name="Cep"
                                        mask="99999-999"
                                        value={DTOProposal?.cep}
                                        onChangeText={(value) => handleOnChange('cep', value)}
                                        className='small'                                        
                                    />
                                    <InputDefault
                                        title="Número:"
                                        name="numero"
                                        value={DTOProposal?.numero}
                                        onChangeText={(value) => handleOnChange('numero', value)}
                                        className='num'                                        
                                    />
                                    <InputDefault
                                        title="Complemento:"
                                        name="complemento"
                                        value={DTOProposal?.complemento}
                                        onChangeText={(value) => handleOnChange('complemento', value)}
                                        className='comp'                                        
                                    />
                                </div>
                            </div>
                        }
                    </FormMain>
                }

                {
                    activePage === 4 &&
                    <ConclusionWrapper>
                        <div className="header">
                            <DeadlineConclusion>
                                <span>Prazo para conclusão da demanda:</span>
                                <div className="calendar-time">
                                    <div className="calendar"><DateTimePicker onChange={setConclusionDate} value={conclusionDate} calendarIcon={null} disableClock={true} /></div>
                                    <div className="time"><IconClock /><Moment locale='pt-br' fromNow ago>{conclusionDate}</Moment></div>
                                </div>
                            </DeadlineConclusion>

                            <div>
                                <ReCAPTCHA
                                    sitekey={'6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'}
                                    onChange={(value: any) => setToken(value)}
                                />
                            </div>
                        </div>

                        <div className="bottom">
                            <Orientations>
                                <span>Orientações gerais:</span>
                                <div className="text">
                                    <textarea
                                        name=""
                                        id=""
                                        className="text-area"
                                        onChange={(e) => setOrientationsText(e.target.value)}
                                        onBlur={() => handleOnChange('orientacoes', orientationsText)}
                                        value={orientationsText}
                                    />
                                </div>
                            </Orientations>

                            <Upload>
                                <span>Documentos:</span>
                                <div className="archives">

                                    {
                                        uploadFilesName &&
                                        uploadFilesName.map((row: any) => (
                                            <div className="file-uploaded">
                                                <div className="icon-title">
                                                    <IconFile />
                                                    {row}
                                                </div>

                                                <div
                                                    className="icon-trash"
                                                    onClick={() => setUploadFiles(uploadFiles.filter(obj => obj !== row))}
                                                >
                                                    <IconTrash />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    

                                    <div className="button">
                                        <IconUpload />
                                        <input
                                            type="file"
                                            name="Files"
                                            // accept="image/*"
                                            className="file-input"
                                            onChange={sendFiles}
                                            onClick={(e: any) => { e.currentTarget.value = null }}
                                        />
                                    </div>
                                </div>
                            </Upload>
                        </div>
                    </ConclusionWrapper>
                }
                
            </Main>

            <Footer>
                <div className="container">
                    <ButtonDefault
                        className="cancel"
                        onClick={() => history.push('/')}
                    >
                        Cancelar
                    </ButtonDefault>

                    <div className="buttons">
                        {
                            activePage !== 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Voltar
                            </ButtonDefault>
                        }

                        <ButtonDefault
                            className="access"
                            onClick={() => advancePage()}
                        >
                            Continuar
                        </ButtonDefault>

                    </div>
                </div>
            </Footer>

            {/* Modal termos para cadastro JurídicoJá */}
            <ModalTerms
                id={'overlayModal'}
                onClose={() => setOpenModal((openModal) => !openModal)}
                openModal={openModal}
                width="100%"
            >
                <ModalHeader>
                    <div><IconLogoJuridico /></div>

                    <button
                        onClick={() => setOpenModal(false)}
                    >
                        <IconClose />
                    </button>

                </ModalHeader>

                <ModalMain>
                    <div className="title">
                        <p>TERMO, CONDIÇÃO DE USO E POLÍTICAS DE PRIVACIDADE</p>                        
                    </div>

                    <div className="subtitle">
                        POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.
                    </div>

                    <p>
                        DISPOSIÇÕES GERAIS
                    </p>

                    <div className="considerations">
                        <p>CONSIDERANDO QUE:</p>

                        <div className="recoil">
                            <div className="list">
                                <div>(I)</div>  
                                <p>
                                    A empresa 123JUS é uma empresa de tecnologia que criou sistema para intermediar a contratação de profissionais jurídicos e auxiliar na execução dos serviços dos mesmos;
                                </p>
                            </div>
                             <div className="list">
                                <div>(II)</div>
                                <p>
                                    que a plataforma será integrada com chat para comunicação, sistema web e outras ferramentas de tecnologia;
                                </p>
                             </div>
                             <div className="list">
                                <div>(III)</div>
                                <p>
                                    que a intermediação de serviços se dará mediante planos de assinatura, conforme livre escolha do usuário no momento de cadastro;
                                </p>
                             </div>
                             <div className="list">
                                <div>(IV)</div>
                                <p>
                                   que gateway de pagamento irá "distribuir" valores recebidos pelos usuários solicitantes de serviços entre o usuário executor do serviço e a plataforma 123JUS em contraprestação à intermediação da contratação e pagamento do serviço prestado; 
                                </p>
                             </div>
                             <div className="list">
                                <div>(V)</div>
                                <p>
                                    que a plataforma contará com os seguintes tipos de usuários: usuários solicitantes de serviço (clientes), usuários prestadores de serviço (profissional jurídico), usuários auditores (jurídico interno de empresas) e usuários representantes regionais (prestam serviço aos profissionais jurídicos intermediando seus serviços), podendo serem criados novos tipos de usuários em que este termo precise ser alterado, valendo desde já para todos que utilizarem os serviços da empresa 123JUS.
                                </p>
                             </div>
                        </div>

                    </div>

                    <p>
                        Assim, ciente das considerações acima e de acordo com as mesmas a EMPRESA disponibiliza para todos os usuários contratantes este termo, condições de uso e políticas de privacidade. Qualquer usuário ou cliente que deseje utilizar os serviços da EMPRESA será denominado USUÁRIO e/ou no plural USUÁRIOS quando os termos forem os mesmo para mais de um tipo de usuário, deve ler atentamente este termo, considerações acima expostas e caso queira deve aceitar os Termos, Condições Gerais e políticas de privacidade com todos os princípios que o regem. A sua aceitação é indispensável à utilização, devendo o usuário ler com atenção, certificar-se de haver entendido e aceitar todas as condições estabelecidas, além de outras regras que possam ser aplicáveis, não se restringindo as aqui descritas, tais como: Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), Lei nº 10.406/2002 (“Código Civil Brasileiro”), Lei nº 13.709/2018 (Lei de Proteção de Dados), Código de Ética e Disciplina da Ordem dos Advogados do Brasil e Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”), antes de concluir o cadastro e/ou solicitar ou aceitar qualquer serviço.
                    </p>   

                    <p>
                        Ao acessar e usar os Serviços você concorda com os presentes termos, forma de pagamento, prazo e condições, que estabelecem o relacionamento contratual entre você, na qualidade de usuário(a), e a EMPRESA. Se você não concorda com estes termos, você não pode acessar nem usar os serviços da EMPRESA. Mediante referido acesso e uso, estes Termos imediatamente encerram, substituem e superam todos os acordos, termos e acertos anteriores. Podendo a EMPRESA encerrar este termo ou serviços em relação a você a qualquer tempo e condição. 
                    </p>    

                    <p>
                        Ao acessar/cadastrar/utilizar a plataforma o Utilizador declara ser civilmente capaz nos termos da Lei nº 10.406/2002 (“Código Civil Brasileiro”) para compreender, aceitar e cumprir os Termos de Uso e a Política de Privacidade. No caso de pessoas jurídicas, estas devem estar devidamente representadas. No ato da realização do cadastro o Usuário em nome de pessoa jurídica declara estar apto para representá-la nos termos que seguem e em outras ações relacionadas ao uso do site e aplicativo. O Usuário responderá solidariamente perante a Plataforma por violações aos Termos ou das legislações vigentes aplicáveis. O cadastro de utilizador com idade inferior a 18 (dezoito) anos não será de responsabilidade da Plataforma e sim de seus responsáveis legais, respondendo inclusive por danos eventualmente causados e ou serviços contratados.
                    </p>  

                    <p>
                        Este termo se aplica a todos advogados(as), estudantes, pessoas físicas, pessoas jurídicas, que busquem ofertar ou contratar serviços. É recomendável que os denominados ESTUDANTES que queira prestar serviços, que possuam a Carteira de Estagiário da Ordem dos Advogados do Brasil (“OAB-E”) para atuação com excelência.
                    </p>          

                    <p>
                        De acordo com os Termos, os Usuários assumem a obrigação de usar o site e o aplicativo de forma positiva, respeitando o princípio da boa-fé e sem enviar ao sistema material que seja ameaçador, violento, difamatório, pornográfico, abusivo, ofensivo, racista, discriminatório ou em desacordo com os direitos fundamentais resguardados pelo texto constitucional de 1988 ("Constituição da República Federativa do Brasil") ou outra legislação vigente;
                    </p>

                    <p>
                       O Usuário ainda manifesta que só fará uso de materiais que tenham todas licenças e/ou aprovações necessárias; 
                    </p>

                    <p>
                       Não irá incitar condutas que possam ser consideradas ilícitas na esfera civil e criminal ou que viole direito de terceiros em território nacional ou internacional, ou ilegais de qualquer forma; 
                    </p>
                    
                    <p>
                        Não fará o uso de fraudes ou dissimulações a fim de viciar as contratações ou escolhas dos Usuários ou funcionamento do sistema, como violações de segurança, protocolos, perfis falsos, ou qualquer outro meio.
                    </p>

                    <p>
                        Os Usuários que não agirem em conformidade e zelo para o bom funcionamento da plataforma serão responsabilizados por quaisquer danos e prejuízos que causarem. A EMPRESA informa que irá cooperar com autoridades que necessitarem identificar Usuários que estejam em desconformidade com os dispostos nestes Termos. 
                    </p>

                    <p>
                        O usuário assume total responsabilidade por prejuízos, diretos ou indiretos, incluindo indenizações, lucros cessantes, honorários advocatícios e demais encargos judiciais ou extrajudiciais que a EMPRESA seja obrigado a incorrer em razão de ato ou omissão do Usuário. 
                    </p>

                    <p>
                        A EMPRESA terá direito a indenização por danos causados pelo Usuário decorrentes de atividades no site ou por descumprimento dos termos gerais de uso e demais políticas, ou direitos de terceiros, incluindo honorários de advogados.
                    </p>

                    <p>
                        A EMPRESA está em acordo com todas as leis e tratados vigentes em território brasileiro. Caso o Usuário utilize a plataforma em outro local, estará sujeito ao cumprimento das normas locais.
                    </p>

                    <p>
                        O uso do nome “123JUS”, bem como os conteúdos que o acompanhe na prática empresarial como nome empresarial, marca, domínio, programas, banco de dados, documentos, são de propriedade exclusiva da EMPRESA e estão protegidos por leis e tratados, visto registro em órgão competente. A reprodução ou uso sem a devida autorização legal é expressamente proibido, acarretando responsabilidade penal e civil.
                    </p>

                    <p>
                        A página dos usuários pode expor links de outros sites, o que não significa que a EMPRESA seja responsável pelos mesmos. Portanto, a EMPRESA não possui responsabilidade pelo conteúdo, prática e serviços ofertados em site de terceiros, ainda que apresentados em sua plataforma.
                    </p>

                    <p>
                        Os links de outros sites apresentados na plataforma não incidem em relação de sociedade, supervisão, cumplicidade, solidariedade ou posse.
                    </p>
                    
                    <p>
                        Os usuários, expressamente afirmam e reconhecem que não existem vínculos além do negócio jurídico de intermediação, reiterando que não existe relação trabalhista ou societária. 
                    </p>

                    <p className="two">
                        Este documento poderá sofrer mudanças a qualquer momento, bem como as funcionalidades do sistema, sem aviso prévio. O utilizador será notificado a aceitar os termos atualizados e estando em desacordo com as alterações deverá encerrar sua conta imediatamente. Caso não seja feito incidirá em aceitação automática do novo regulamento. A alteração dos termos poderá ser substituída por avisos e notificações dispostos no site e no aplicativo da plataforma. As alterações não vigorarão sob as negociações já iniciadas antes da publicação, sendo, neste caso, vigente a redação anterior.
                    </p>

                    <div className="space">
                        DOS SERVIÇOS
                    </div>

                    <p>
                        Ao aceitar este termo você está ciente e declara que a EMPRESA não é fornecedora de serviços, bens, funciona apenas como intermediadora dos serviços dos usuários prestadores e os que solicitam serviços, e, que estes serviços são prestados independentemente da EMPRESA QUE não se responsabiliza pela execução, qualidade, eventuais danos e qualquer outro fato ligado à realização do mesmo.
                    </p>

                    <p>
                        A EMPRESA não se responsabiliza por indisponibilidade decorrente de falhas no sistema, seja em períodos longos ou curtos. Os usuários devem estar cientes de que a qualquer momento o acesso pode ser interrompido temporariamente sem aviso prévio em caso de falhas, manutenção ou alterações no sistema.
                    </p>

                    <p>
                        A EMPRESA não se responsabiliza por danos causados em caso de prejuízo, perda ou danos em função da interrupção do sistema. Para tanto, recomenda-se que os usuários mantenham forma sobressalente de contratação, sendo certo que não haverá indenização ou reparação por parte da EMPESA, sendo isenta a responsabilidade desta.
                    </p>

                    <p>
                        Nas audiências em que os USUÁRIOS prestadores de serviço prestem depoimento como preposto para os USUÁRIOS solicitantes de serviço, deve este buscar o contato do prestador de serviço e fazer as devidas orientações. Ficando ciente que o ideal é sempre enviar preposto da própria empresa e que optar pela contratação de terceiros é um risco, já que estes não vivem o dia a dia da empresa representada, podendo haver má interpretação pelo Poder Judiciário das respostas dada, ou mesmo respostas em desacordo com o esperado. Não tem a EMPRESA qualquer responsabilidade sobre a oitiva de prepostos ou testemunhas orientados ou não pelo USUÁRIO solicitante de serviço.
                    </p>

                    <p>
                        A EMPRESA Já não se responsabiliza por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos vídeos ou áudio. Todavia os Usuários se comprometem a fazer o uso de arquivos livres de vírus ou tecnicamente danosos, incluindo, mas não se limitando a vírus como "cavalo de Tróia", worms, macros, componentes maliciosos, dados corrompidos e outros programas maliciosos ou projetados para alterar o bom funcionamento do sistema ou operações comuns de computadores.
                    </p>

                    <p>
                        O usuário é responsável por adquirir e atualizar os equipamentos e dispositivos necessários para acessar e usar os serviços e aplicativos da EMPRESA. A EMPRESA não garante que os serviços funcionarão em qualquer aparelho. Além disso, os serviços poderão estar sujeitos a mau funcionamento e atrasos inerentes ao uso da Internet e de comunicações eletrônicas.
                    </p>

                    <p>
                        A EMPRESA não será responsável por danos indiretos, incidentais, especiais, punitivos ou emergentes, inclusive lucros cessantes, perda de dados, danos morais ou patrimoniais relacionados, associados ou decorrentes de qualquer uso dos serviços ainda que a EMPRESA tenha sido alertada para a possibilidade desses danos.
                    </p>

                    <p className="two">
                        Para manter a qualidade dos serviços ofertados pela plataforma, a EMPRESA possui o direito de impedir ou interromper acesso de determinado Usuário que atue em desconformidade com o que consta nos Termos e Condições de Uso, da Política de Privacidade ou contratos que se celebrem através do uso da plataforma, sem a necessidade de notificação prévia.
                    </p>

                    <p>
                        DOS PRAZOS PARA REALIZAÇÃO DOS SERVIÇOS
                    </p>
                    
                    <p>
                        Quando o usuário solicitante estabelece um prazo para realização de serviços, ele entende que esse prazo é apenas direcionador.  Os prazos poderão sofrer alterações pela EMPRESA, para maior ou menor, em virtude da complexidade do pedido ou da facilidade da diligência, considerando (outros serviços em destacamento, saída para diligência, etc.), bem como em decorrência dos procedimentos administrativos adotados pelos órgãos responsáveis, além dos casos em que para cumprimento da medida, o correspondente tiver que aguardar a devolução do processo e/ou procedimento da conclusão, da ausência de localização, indisponibilidade, entre outros fatores de força maior e caso fortuito.
                    </p>

                    <p className="two">
                        A EMPRESA não se responsabiliza pela perda de prazos processuais dos usuários solicitantes em função de atraso na entrega dos serviços contratados. Uma vez que não atua nos processos dos usuários solicitante, e não se responsabiliza pelo regular andamento dos mesmos. Cabe ao usuário solicitante de serviço solicitar o serviço com antecedência, em tempo hábil para caso haja algum atraso ou insatisfação procurar outra forma de obter o serviço desejado.
                    </p>

                    <p>
                        DO PREÇO PELOS SERVIÇOS PRESTADOS POR PROFISSIONAIS
                    </p>

                    <p>
                        A EMPRESA indica aos usuários a observância do disposto no Código de Ética e Disciplina da Ordem dos Advogados do Brasil, Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”) e Tabela de Honorários da Ordem dos Advogados do Brasil. 
                    </p>

                    <p>
                        Os usuários que realizam os serviços são livres para no momento de cadastro estipularem os valores que serão cobrados pelos serviços prestados, não tendo a plataforma qualquer responsabilidade sobre os mesmos. O valor se dá em observância ao tempo e complexidade do serviço. Poderá haver pedido de majoração pelo usuário que realiza os serviços, o que será repassado para o solicitante analisar e aprovar ou cancelar o serviço. 
                    </p>

                    <p>
                        Os preços das diligências e serviços obedecem às tabelas da Ordem dos Advogados quando no Estado correspondente ao serviço houver, levando em conta ainda fatores como local, dificuldade de acesso, valores praticados pelos demais advogados, simplicidade ou complexidade do ato. Podendo haver desconto por eventual simplicidade do ato praticado.
                    </p>

                    <p className="two">
                        A EMPRESA declara que não há relação trabalhista entre as partes que pactuam entre si, bem como não há vínculo societário, agenciamento, consórcio, ou relação de qualquer natureza que não seja apenas o negócio jurídico.
                    </p>

                    <p>
                        DO CADASTRO
                    </p>

                    <p>
                        Cada usuário poderá manter somente um cadastro junto a plataforma 123JUS.
                        O cadastro é pessoal e intransferível. Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>

                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>
                    

                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome 123JUS ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da 123JUS. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>

                    <p>
                        A 123JUS se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>

                    <p>
                        A 123JUS poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                    </p>

                    <p>
                      Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.  
                    </p>

                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Contratante a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>

                    <p>
                        A concessão de prazo para pagamento dos serviços poderá ou não ser concedido em função de análise do perfil do contratante, bem como documentos. Podendo tal concessão ser suspensa ou cancelada a qualquer tempo. 
                    </p>
                    
                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo 123JUS, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>

                    <p>
                        A 123JUS não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                    </p>

                    <p>
                        É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>

                    <p className="two">
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>
                    
                    <p>DA DIVULGAÇÃO DE VAGAS</p>

                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Correspondentes, as quais o Contratante possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da 123JUS.
                    </p>
                    
                    <p>
                        A EMPRESA poderá disponibilizar para os usuários gratuitamente a possibilidade de divulgarem vagas e se candidatarem para as mesmas, sendo de responsabilidade dos mesmos as informações prestadas e o prosseguimento do processo seletivo.
                    </p>

                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, inclusive a divulgação e candidatura para vaga, são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>

                    <p>OBSERVÂNCIA À LEI DE PROTEÇÃO DE DADOS</p>

                    <p>
                        Estamos empenhados em salvaguardar a sua privacidade ao estabelecer esta relação conosco. Este termo tem a finalidade de deixar o mais claro possível a nossa política de coleta e compartilhamento de dados, informando sobre os dados coletados e como os utilizamos.
                    </p>

                    <p>
                        Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários à prestação de nossos serviços.
                    </p>

                    <p>
                        Pelo presente as partes declaram que tanto o aplicativo quanto o site quanto do uso do sistema observarão as disposições da à Lei 13.709/18 que regulamenta a proteção de dados pessoais e da Lei nº 12.965/14 que regulamenta o Marco Civil da Internet. Desde já você autoriza o colhimento e uso de dados nos seguintes teremos:
                    </p>

                    <div className="considerations">
                        <div className="recoil">
                            <div className="list">
                                <div>(I)</div>
                                <p>
                                    Algumas informações pessoais são obtidas quando você se registra, preenchendo um formulário de contato ou cadastro. Durante o cadastro a EMPRESA solicita alguns dados como nome, endereço de e-mail e outras informações pessoais. 
                                </p>
                            </div>

                            <div className="list">
                                <div>(II)</div>
                                <p>
                                    Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
                                </p>
                            </div>

                            <div className="list">
                                <div>(III)</div>
                                <p>
                                    Ao submeter seus dados para pagamento, podemos coletar informações sobre a compra ou transação. Isso abrange suas informações de pagamento, como os dados do seu cartão de crédito ou débito, informações de conta e autenticação, além dos dados de faturamento, tais como endereço completo, CPF e CNPJ.
                                </p>                                
                            </div>

                            <div className="list">
                                <div>(IV)</div>
                                <p>
                                    As informações pessoais transmitidas não são reveladas sem a sua autorização prévia. Portanto, não serão vendidas, compartilhadas sem ser para os propósitos aqui expostos ou alugadas. Suas informações servem exclusivamente para criar um perfil dos usuários e permitem melhorar a qualidade dos serviços, ao mesmo tempo em que possibilitam direcionamento de conteúdos com maior eficácia.
                                </p>
                            </div>

                            <div className="list">
                                <div>(V)</div>
                                <p>
                                    As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial. Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação.
                                </p>
                            </div>

                            <div className="list">
                                <div>(VI)</div>
                                <p>
                                    Desde já fica permitido o uso de cookie, que é um pequeno arquivo que seu navegador salva no seu disco rígido. Os cookies não conterão nenhuma informação pessoal identificável, mas permitirão oferecer determinadas funcionalidades que facilitam a navegação.
                                </p>
                            </div>

                            <div className="list">
                                <div>(VII)</div>
                                <p>
                                    Ao navegar na Internet, algumas informações serão transmitidas automaticamente entre computadores e serão coletadas como parte das exigências tecnológicas inerentes à navegação, para fins estatísticos. São elas: nome do domínio, IP, data e hora de navegação no site da EMRPESA, páginas visitadas dentro do site da EMPRESA, tipo de navegador e outras correlacionadas.
                                </p>
                            </div>
                        </div>
                    </div>

                    <p>
                        Compartilhamos os seus dados com a empresa responsável pelo processamento de pagamentos, para fins de viabilizar pagamentos e o processamento de dados, bem como para tornar o produto mais relevante e eficiente à sua finalidade.
                    </p>

                    <p>
                        Estes dados são armazenados em servidores seguros nossos ou de fornecedores contratados, acessados e utilizados de acordo com nossas políticas e padrões de segurança. Todo o tráfego entre nossos servidores ou entre o seu computador e nossos servidores é encriptado através do protocolo seguro SSL ou semelhante.
                    </p>

                    <p>
                        Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que os dados que coletamos sejam processados de acordo com segurança onde quer que os dados estejam localizados.
                    </p>

                    <p>
                        Nós manteremos as informações que coletamos de você até que ocorra a solicitação de exclusão definitiva por sua parte. Neste caso, nós cessaremos imediatamente a utilização dos seus dados para fins comerciais, porém armazenaremos os seus dados enquanto tenhamos obrigações legais, tributárias ou judiciais a cumprir com tais dados.
                    </p>

                    <p>
                        Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas informações para as seguintes FINALIDADES:
                    </p>

                    <div className="autorize">
                        <ul>
                            <li>
                                Fornecer, melhorar e desenvolver os Serviços disponíveis. Usamos os seus dados para melhorar a qualidade dos nossos serviços, através da personalização do serviço prestado.
                            </li>
                            <li>
                                Obrigações legais. Compartilhamos seus dados com terceiros para cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus dados pessoais a terceiros, a um tribunal, reguladores ou agências governamentais.
                            </li>
                        </ul>
                    </div>

                    <p className="two">
                        A não observância de qualquer disposição das referidas leis implicarão em responsabilidade exclusiva do infrator.
                    </p>

                    <p>
                        LICENÇA 
                    </p>

                    <p>
                        A EMPRESA, outorga a você licença de caráter não exclusivo e limitado, proibindo a sublicença à terceiros, sendo revogável por opção da mesma para: (I) uso do site; e (II) acesso e uso de aplicativo. Quaisquer direitos não expressamente aqui outorgado são restritos à EMPRESA.
                    </p>

                    <p className="two">
                        Este documento e/ou o uso dos serviços não conferem direito sobre os serviços, exceto pela licença limitada concedida acima, ficando restrito o uso de nomes societários, logotipos, nomes de produtos ou de serviços, marcas comerciais ou qualquer outra propriedade da EMPRESA.
                    </p>

                    <p>OBRIGAÇÕES DOS USUÁRIOS</p>

                    <p className="two">
                        O Usuário não deve autorizar terceiros a usarem sua conta receberem serviços em seu nome, salvo se constituírem sociedade. O Usuário concorda em cumprir todas as leis aplicáveis quando usar os serviços da EMRPESA e apenas poderá usar os serviços da EMPRESA para finalidades lícitas e previstas neste termo. Não poderá o usuário utilizar a plataforma ou aplicativo para fins ilícitos ou para causar transtorno, aborrecimento, inconveniente ou danos à propriedade a qualquer pessoa.
                    </p>


                    <p>CONFIDENCIALIDADE</p>

                    <p className="two">
                        Os usuários se comprometem a manter em estrita confidencialidade todos os documentos e informações, que não sejam de conhecimento público e outras informações divulgadas ou recebidas em decorrência da utilização do site e/ou aplicativo e dos serviços prestados pelos usuários fornecedores de serviços, tais como preços, estratégias, documentos produtos e serviços.
                    </p>

                    <p>TERMOS DE USO E CONDIÇÕES GERAIS PARA PAGAMENTO</p>

                    <div className="payment">
                        1. 
                        <p>DO PAGAMENTO</p>
                    </div>

                    <div className="payment">
                        1.1 
                        <p>
                            Os Usuários aceitam, expressamente, pagar por todos os serviços contratados sempre que houver a conclusão do serviço ou deslocamento pelo usuário que realiza o serviço, ainda que indisponível a execução por questões alheias à sua atuação, como indisponibilidade. Concordam a pagar a EMPRESA remuneração que envolverá: o valor acordado, taxa do meio de pagamento em porcentagem declarada por transação. Os valores serão celebrados de acordo  com cadastro e/ou tarifas ofertadas no site e aceitas entre as partes.
                        </p>
                    </div>

                    <div className="payment">
                        1.2
                        <p>
                            A verificação de conta e preenchimento de formulário são fatores importantes para a utilização dos serviços. Os Usuários solicitantes irão fornecer informações próprias: nome/razão social, CPF/CNPJ, endereço, endereço de cobrança, endereço eletrônico, números de telefones, conta bancária, bem como após o cadastro receberão pelo setor financeiro a informação dos dados bancários da EMPRESA e forma de pagamento.
                        </p>
                    </div>

                    <div className="payment">
                        1.2.1
                        <p>
                            A falta de veracidade nestas informações acarretará sanções ao Usuário. 
                        </p>
                    </div>

                    <div className="payment">
                        1.3
                        <p>
                            Os pagamentos poderão ser realizados de acordo com critérios da EMPRESA das formas que seguem:
                        </p>
                    </div>

                    <div className="payment">
                        1.4.1
                        <p>
                            Boleto Bancário: Deverão ser observadas as regras de compensação do banco ao qual o pagamento foi efetuado.
                        </p>
                    </div>

                    <div className="payment">
                        1.4.2
                        <p>
                            Depósito em Conta: caso o vencimento seja em feriados ou finais de semana deverá ser feita a transação no dia útil anterior.
                        </p>
                    </div>

                    <div className="payment">
                        1.5
                        <p>
                            Os pagamentos serão realizados após envio da fatura por email (email de cadastro), podendo haver prévio pagamento de despesas a serem pactuadas. O comprovante de pagamento será disponibilizado mediante envio por email para financeiro@123jus.com.br.
                        </p>
                    </div>

                    <div className="payment two">
                        2.
                        <p>DA RESTITUIÇÃO </p>
                    </div>

                    <div className="payment">
                        2.1
                        <p>
                            A EMPRESAS possui políticas a respeito do cancelamento de transações e restituição. Essas deverão ser feitas no valor exato da transação.
                        </p>
                    </div>

                    <div className="payment">
                        2.2
                        <p>
                            O Usuário deverá restituir a sua exclusiva responsabilidade.
                        </p>
                    </div>

                    <div className="payment">
                        2.3
                        <p>
                            Em casos onde a 123JUS seja intimada a efetuar restituições, o Usuário será imediatamente notificado a fim de que este efetue a transação, a sua exclusiva responsabilidade.
                        </p>
                    </div>

                    <div className="payment">
                        2.3.1
                        <p>
                            Caso a restituição não seja feita nos prazos e condições estipulados, será demandado processo de cobrança dos valores devidos.
                        </p>
                    </div>

                    <div className="payment two">
                        3. 
                        <p>
                            DOS CHARGEBACKS E ESTORNOS
                        </p>
                    </div>
                    
                    <div className="payment">
                        3.
                        <p>
                            O presente item trata sobre o procedimento realizado pelo consumidor junto a operadora de cartão de débito ou crédito, alegando desconhecimento de transação específica efetuada com o cartão de sua titularidade.
                        </p>
                    </div>

                    <div className="payment">
                        3.1
                        <p>
                            O presente item trata sobre o procedimento realizado pelo consumidor junto a operadora de cartão de débito ou crédito, alegando desconhecimento de transação específica efetuada com o cartão de sua titularidade.
                        </p>
                    </div>

                    <div className="payment">
                        3.2
                        <p>
                            Deverão ser observadas regras de estorno de cada bandeira, mantendo as boas práticas comerciais a fim de que se evite o tratado no item 3.1. Recomendamos que os comprovantes de transações sejam conservados.
                        </p>
                    </div>

                    <div className="payment">
                        3.3
                        <p>
                            Em caso de notificações de Chargerback recebidas pela EMPRESA, o Usuário será notificado para realizar as devidas providências junto ao reclamante, no prazo MÁXIMO de 30 dias e iniciará o devido processo de cobrança de valores devidos.
                        </p>
                    </div>

                    <div className="payment">
                        3.3.1
                        <p>
                            No ato de descumprimento do prazo definido no item anterior, bem como falta de atualização à Plataforma sobre os trâmites, a EMPRESA iniciará processo de cobrança.
                        </p>
                    </div>

                    <div className="payment">
                        3.4
                        <p>
                            O Usuário terá o limite máximo de 1% de pedidos de Chargerback no mês, em relação ao número máximo de transações, do contrário sua conta será suspensa pelo prazo de 60 dias. 
                        </p>
                    </div>

                    <div className="payment">
                        3.5
                        <p>
                            Para reincidentes no disposto no item 3.4 será aplicada multa de 25% sob o valor do Chargerback e a suspensão se dará por 90 dias.
                        </p>
                    </div>

                    <div className="payment">
                        3.6
                        <p>
                            Para reincidentes em dobro, as sanções citadas no item 3.5 serão aplicadas em dobro.
                        </p>
                    </div>

                    <div className="payment">
                        4.
                        <p>
                            DAS DISPUSTAS E RETENÇÕES
                        </p>
                    </div>

                    <div className="payment">
                        4.1
                        <p>
                            A EMPRESA reconhece a observância das regras de disputas quando em desacordo comercial e Chargebacks iniciados por seus Usuários. Em caso de disputas, os saldos estarão retidos até a sua resolução.
                        </p>
                    </div>

                    <div className="payment">
                        4.2
                        <p>
                            A disputas devem ser solucionadas em um prazo máximo de 30 dias, podendo variar de acordo com cada banco ou administradora de cartão. Durante o período de disputa, todo saldo a ela relacionado estará retido.
                        </p>
                    </div>

                    <div className="payment">
                        4.3
                        <p>
                            Se o Usuário perder a disputa, autoriza previamente que o saldo anteriormente retido seja usado para dar quitação a disputa ou qualquer outro débito proveniente ou junto ao EMPRESA.
                        </p>
                    </div>

                    <div className="payment">
                        4.4
                        <p>
                            Os saldos dos Usuários poderão ser retidos para auditoria e apuração de conduta, quando o referido atuar em desacordo com as Políticas desta Plataforma e de estabelecimentos bancários, administradoras de cartão, lei e práticas de Mercado e/ou Normas do BACEN.
                        </p>
                    </div>

                    <div className="payment">
                        4.5
                        <p>
                            Condutas em que forem constatadas fraude em termos gerais, lavagem de dinheiro, etc., ocasionarão cancelamento da conta do Usuário na Plataforma EMPRESA. Nestes casos, os saldos serão retidos e as informações do infrator serão entregues de boa-fé às autoridades.
                        </p>
                    </div>

                    <div className="payment">
                        5.
                        <p>
                            DO DOMICÍLIO BANCÁRIO E COBRANÇA DE VALORES
                        </p>
                    </div>

                    <div className="payment">
                        5.1
                        <p>
                            Por meio deste o Usuário expressamente autorizada a EMPRESA efetuar operações de débito automático em conta bancária vinculada à conta utilizada na Plataforma, conforme formulário de cadastro, para as finalidades definidas em itens anteriores.
                        </p>
                    </div>

                    <div className="payment">
                        5.2
                        <p>
                            Quando ocorrerem restituições, estornos ou cancelamentos em favor da EMPRESA, o valor exato da respectiva transação deverá ser entregue a EMPRESA pelo Usuário, sendo o valor corrigido sobre IGP-M/FGV (ou outro índice), contando a data do repasse, com juros de 1% (um por cento) a.m. ou fração pró-rata, com o acréscimo de encargos operacionais e qualquer perdas e danos ocorridos.
                        </p>
                    </div>

                    <div className="payment">
                        5.3
                        <p>
                            Podem ser adotadas as seguintes medidas com fins de cobrança de valores devidos a EMPRESA:
                        </p>
                    </div>

                    <div className="payment">
                        5.3.1
                        <p>
                            Compensação de valores através de outros créditos, presentes ou futuros, devidos.
                        </p>
                    </div>

                    <div className="payment">
                        5.3.2
                        <p>Lançamentos de débitos na conta bancária vinculada.</p>
                    </div>
                    
                    <div className="payment">
                        5.3.3
                        <p>
                            Compensação por meio de cheque, boleto, TED, DOC ou depósito identificado, de acordo com tratativas entre as partes.
                        </p>
                    </div>

                    <div className="payment">
                        5.3.4
                        <p>
                            Escritórios de cobrança especializados.
                        </p>
                    </div>

                    <div className="payment">
                        5.3.5
                        <p>Ações Judicial, protestos e negativações.</p>
                    </div>

                    <div className="payment">
                        5.4
                        <p>
                            Falta ou atraso de pagamento, seja total ou parcial, dentro dos prazos previamente acordados em contrato ou em respectivas atualizações, podem sujeitar o Usuário ao pagamento dos seguintes encargos:
                        </p>
                    </div>

                    <div className="payment">
                        5.4.1
                        <p>
                            Atualização monetária das dívidas, baseando-se no IPC/FGV, ou outro índice.
                        </p>
                    </div>

                    <div className="payment">
                        5.4.2
                        <p>
                            Acréscimo de juros de 2% (um por cento) a.m. Não obstante, o EMPRESA se reserva no direito de incluir os débitos do Usuário no cadastro de Pendências Financeiras (PEFIN) e qualquer outro órgão de proteção de crédito.
                        </p>
                    </div>

                    <div className="payment">
                        5.4.3
                        <p>
                            Inscrição nos órgãos de cadastro e proteção ao crédito.
                        </p>
                    </div>

                    <div className="payment">
                        6.
                        <p>
                            DO PRAZO DE PAGAMENTO
                        </p>
                    </div>

                    <div className="payment">
                        6.1
                        <p>
                            O usuário solicitante de serviços, receberá até o dia 05 (cinco) de cada mês via plataforma a fatura dos serviços realizados no mês anterior, compreendendo período do primeiro ao último dia do referido mês, com todos os serviços que foram realizados. O usuário poderá concordar com a fatura ou impugnar no prazo de até 5 dias corridos. A EMPRESA irá analisar a impugnação e caso verifique erro irá alterar o valor total da fatura enviando para o usuário solicitante boleto e nota fiscal para pagamento. A partir do momento do envio do boleto o usuário solicitante terá o prazo de 10 (dez) dias corridos para realizar o pagamento dos valores, a data de vencimento será sempre no dia 20 (vinte) de cada mês. No caso dos usuários autorizados a fazerem depósito em conta, deverão fazê-lo no mesmo prazo do vencimento do boleto citado nesta cláusula.
                        </p>
                    </div>

                    <div className="payment">
                        6.1.1
                        <p>
                            O não envio de concordância ou impugnação no prazo estipulado no item 6.1. implicará automaticamente em concordância pelo usuário solicitante, que receberá o boleto para devido pagamento no prazo estipulado.
                        </p>
                    </div>

                    <div className="payment">
                        6.1.2
                        <p>
                            O atraso no pagamento ensejará atualização monetária baseando-se no IPC/FGV e multa de 10% do valor total da fatura mais juros de 0,33% ao dia. Em caso de atraso fica facultado à EMPRESA suspender o cadastro do usuário solicitante para solicitar novos serviços.
                        </p>
                    </div>

                    <div className="payment">
                        6.1.3
                        <p>
                            Em caso de vencimento do boleto em fim de semana ou feriado o mesmo deve ser pago em dia útil anterior. 
                        </p>
                    </div>                    

                    <p className="space">RESCISÃO</p>

                    <p>
                        Os usuários tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento. O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.
                    </p>

                    <p>
                        As condições de cancelamento quando estiverem em curso a realização de serviços serão tratadas entre os usuários ou entre o usuário e a EMPRESA.
                    </p>

                    <p>
                        A 123JUS se reserva no direito de suspender, cancelar, impedir ou interromper o acesso de usuários à plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>

                    <p>
                        O usuário infrator poder ser notificado sobre o uso inadequado, à critério da 123JUS.
                    </p>

                    <p>
                        Na suspeita de uso inadequado ou reincidência o usuário poderá ter o cadastrado cancelado.
                    </p>

                    <p>
                        Uma vez cancelado o cadastro do usuário solicitante de serviços as obrigações contraídas pendentes devem ser concluídas com totalidade de pagamentos devidos pelos serviços solicitados.
                    </p>

                    <p>
                        A Empresa se reserva no direito de interromper o acesso e uso do Serviço do usuário solicitante caso este tenha decretado falência ou perca autorização para exercer sua atividade, bem como em caso de inadimplência por mais 10 (dez) dias da data prevista de pagamento.
                    </p>

                    <p className="two">
                        A Empresa não se responsabiliza por eventual perda de prazos processuais, erros na realização, demais danos por atrasos ou não realização dos atos contratados, devendo o usuário, solicitar os serviços com prazo adequado, para caso não possam serem realizados buscar outra meio de realização em tempo hábil.
                    </p>

                    <p>
                        POLÍTICAS DE COOKIES
                    </p>

                    <p>
                        Cookies são pequenos arquivos de texto ou fragmentos de informação que são baixados em seu computador, smartphone ou qualquer outro dispositivo com acesso à internet quando você visita nossa aplicação.
                    </p>

                    <p>
                        Assim, essa página consegue armazenar e recuperar os dados sobre os seus hábitos de navegação, de forma a melhorar a experiência de uso, por exemplo. É importante frisar que eles não contêm informações pessoais específicas, como dados sensíveis ou bancários.
                    </p>

                    <p>
                        O seu navegador armazena os cookies no disco rígido, mas ocupam um espaço de memória mínimo, que não afetam o desempenho do seu computador. A maioria das informações são apagadas logo ao encerrar a sessão, como você verá no próximo tópico.
                    </p>

                    <p>Os cookies, quanto a seus proprietários, podem ser:</p>

                    <div className="cookies">
                        <p>
                            Cookies proprietários: são cookies definidos por nós ou por terceiros em nosso nome
                        </p>
                        <p>
                            Cookies de terceiros: são cookies definidos por terceiros confiáveis em nossa aplicação
                        </p>
                    </div>
                    
                    <p>Os cookies, quanto ao seu tempo de vida, podem ser:</p>

                    <div className="cookies">
                        <p>
                            Cookies persistentes ou permanentes: são cookies que permanecem no seu dispositivo durante um período determinado ou até que você os exclua.
                        </p>
                    </div>

                    <p>Os cookies, quanto a sua finalidade, podem ser:</p>

                    <div className="cookies">
                        <p>
                            Cookies de sessão ou temporários: são cookies que expiram assim que você fecha o seu navegador, encerrando a sessão.
                        </p>

                        <p>
                            Cookies necessários: são cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos; se estes, nossos serviços podem apresentar mal desempenho ou não funcionar.
                        </p>

                        <p>
                            Cookies de funcionalidade: são cookies que memorizam suas preferências e escolhas (como seu nome de usuário) 
                        </p>

                        <p>
                            Cookies de publicidade: são cookies que direcionam anúncios em função dos seus interesses e limitam a quantidade de vezes que o anúncio aparece.
                        </p>
                    </div>

                    <p>Porque usamos cookies:</p>

                    <p>
                        A EMPRESA utiliza Cookies para fornecer a melhor experiência de uso, tornando nossas aplicações mais fáceis e personalizadas, tendo por se suas escolhas e comportamento de navegação.

                    </p>

                    <p>
                        Assim, buscamos entender como você utiliza nossas aplicações e ajustar o conteúdo para torná-lo mais relevante para você, além de lembrar de suas preferências.
                    </p>

                    <p>
                        Os Cookies participam deste processo porquanto armazenam, leem e executam os dados necessários para cumprir com o nosso objetivo.
                    </p>


                    <p>Que tipo de cookies utilizamos:</p>

                    <p>
                        Vários são os tipos de cookies que podem ser utilizados pela EMPRESA. Dentre eles, não se restringindo a cookies necessários, de publicidade, desempenho, funcionalidade e outros que a EMPRESA julgar necessário. É importante lembrar que você pode gerenciar a permissão concedida para cada Cookie em seu navegador. Além disso, uma vez que os Cookies capturam dados sobre você, aconselhamos a leitura de nossa Política de Privacidade, disponível neste documento.
                    </p>

                    <p>Gerenciamento de cookies:</p>
                    
                    <p>
                        A instalação dos cookies está sujeita ao seu consentimento. Apesar da maioria dos navegadores estar inicialmente configurada para aceitar cookies de forma automática, você pode rever suas permissões a qualquer tempo, de forma a bloqueá-los, aceitá-los ou ativar notificações para quando alguns cookies forem enviados ao seu dispositivo. 
                    </p>

                    <p>
                        Atualmente, na primeira vez que você acessa nossas aplicações, será requerida a sua concordância com a instalação destes. Apenas após a sua aceitação eles serão ativados.
                    </p>

                    <p>
                        Como já dito, você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Você também pode configurá-los caso a caso. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                    </p>

                    <p>
                        Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
                    </p>

                    <p>
                        Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
                    </p>
                    
                    <div className="links">
                        <Link to={{ pathname: `https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s` }} target="_blank">
                            Se você usa o Firefox.
                        </Link>  
                        <Link to={{ pathname: `https://support.apple.com/pt-br/guide/safari/sfri11471/mac#:~:text=Voc%C3%AA%20pode%20ver%20todos%20os,remover%20alguns%20deles%20ou%20todos.&text=No%20app%20Safari%20do%20Mac,em%20Remover%20ou%20Remover%20Tudo.` }} target="_blank">
                            Se você usa o Safari.
                        </Link>  
                        <Link to={{ pathname: `https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop` }} target="_blank">
                            Se você usa o Google Chrome.
                        </Link>  
                        <Link to={{ pathname: `https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d` }} target="_blank">
                            Se você usa o Microsoft Edge.
                        </Link>  
                    </div>

                    <p>
                        Disposições finais sobre a política de cookies:
                    </p>

                    <p>
                        Para a 123JUS, a privacidade e confiança são fundamentais para a nossa relação com você. Estamos sempre nos atualizando para manter os mais altos padrões de segurança.
                    </p>
                    
                    <p>
                        Assim, reservamo-nos o direito de alterar esta Política de Cookies a qualquer tempo. As mudanças entrarão em vigor logo após a publicação, e você será avisado.
                    </p>

                    <p>
                        Ao continuar a navegação nas nossas aplicações após essa mudança se tornar eficaz, você concorda com elas. Aconselhamos que você sempre verifique esta Política, bem como a nossa Política de Privacidade.
                    </p>

                    <p className="two">
                        Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco pelo email contato@123jus.com.br. 
                    </p>

                    <p>
                        OUTRAS DISPOSIÇÕES
                    </p>
                    
                    <p className="underlined">
                        <span>Tributos.</span> Qualquer tributo de qualquer natureza que possa incidir em decorrência das operações aqui previstas deverá ser arcado, exclusivamente, pela Parte a quem a lei aplicável atribuir a condição de contribuinte fiscal.
                    </p>

                    <p className="underlined">
                        <span>Renúncia.</span> Nenhuma renúncia por qualquer das Partes a qualquer termo ou disposição deste atermo ou a qualquer descumprimento deste deverá afetar o direito de tal Parte de posteriormente exigir o cumprimento de tal termo ou disposição ou de exercer qualquer direito ou recurso na hipótese de qualquer outro descumprimento, seja ou não semelhante.
                    </p>

                    <p className="underlined">
                        <span>Tributos.</span> Se qualquer disposição deste termo for considerada inválida ou inexequível por qualquer tribunal com jurisdição competente, as demais disposições permanecerão em pleno vigor e efeito. Qualquer disposição deste instrumento que for considerada inválida ou inexequível somente em parte ou grau permanecerá em pleno vigor e efeito na medida em que não tenha sido considerada inválida ou inexequível.
                    </p>

                    <p className="underlined">
                        <span>Independência.</span> Qualquer tributo de qualquer natureza que possa incidir em decorrência das operações aqui previstas deverá ser arcado, exclusivamente, pela Parte a quem a lei aplicável atribuir a condição de contribuinte fiscal.
                    </p>

                    <p className="underlined">
                        <span>Responsabilidade por impostos.</span> Exceto se expressamente estabelecido de outra forma, a EMPRESA e usuários arcarão com seus próprios gastos, impostos, custos e despesas incorridos execução dos serviços prestados.
                    </p>

                    <p className="underlined">
                        <span>Lei Aplicável.</span> Este Contrato reger-se-á por e será interpretado de acordo com as Leis da República Federativa do Brasil.
                    </p>

                    <p className="underlined">
                        <span>Totalidade dos Entendimentos.</span> Qualquer tributo de qualquer natureza que possa incidir em decorrência das operações aqui previstas deverá ser arcado, exclusivamente, pela Parte a quem a lei aplicável atribuir a condição de contribuinte fiscal.
                    </p>

                    <p className="underlined">
                        <span>Eleição de Foro.</span> Qualquer tributo de qualquer natureza que possa incidir em decorrência das operações aqui previstas deverá ser arcado, exclusivamente, pela Parte a quem a lei aplicável atribuir a condição de contribuinte fiscal.
                    </p>

                    <p>
                        Estes Termos de Uso, em conjunto com a Política de Cookies e de Privacidade e quaisquer outros avisos legais publicados pela EMPRESA integram o contrato entre a EMPRESA e os usuários. Se a conta do usuário for encerrada por violações relevantes a este termo ou às políticas de privacidades o usuário poderá ser proibido de fazer qualquer uso dos serviços da EMPRESA. O uso do website da EMPRESA pelos usuários após qualquer aditamento aos Termos de Uso implicará o consentimento e a aceitação dos termos aditados.
                    </p>

                </ModalMain>
            </ModalTerms> 

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </ServiceWrapper>
    )
}