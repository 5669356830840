import styled from "styled-components"

export const Container = styled.button`
   display: flex;
   width: 100%;
   height: 48px;
   border-radius: 8px;
   border: none;
   justify-content: center;
   font-size: 16px;
   line-height: 24px;
   align-items: center;
   cursor: pointer;
   transition: all .3s;
            &:hover {
                transform: scale(1.05);
            }
   &.access {
       background: ${({theme}) => theme.colors.primaryOrange500};
       color: ${({theme}) => theme.colors.white};
   }
   &.cancel {
        border: 2px solid ${({theme}) => theme.colors.interfaceText400};
        background: ${({ theme }) => theme.colors.interfacePure0};
        color: ${({theme}) => theme.colors.interfaceText400};
    }
   &.continue {
        background: ${({theme}) => theme.colors.secondaryGreen500};
        color: ${({theme}) => theme.colors.white};
   }        
   &.danger {
        background: ${({theme}) => theme.colors.interfaceDanger400};
        color: ${({theme}) => theme.colors.white};
   }        
   &.save {
        background: ${({theme}) => theme.colors.primaryBlue500};
        color: ${({theme}) => theme.colors.white};
   }        
`