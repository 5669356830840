// React
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Components
import ButtonDefault from "../../components/form/ButtonDefault";

//  Styles
import { Container, Header, Main } from "./styles";

// Icons
import { Icon123JusBig } from "../../components/icons";

// Assets
import UserComplete from '../../assets/images/CreateUserComplete.png'

export interface User {
    userName: string,
}

export default function CreateUserComplete() {
    const history = useHistory();
    const location = useLocation<User>();
    const [ user, setUser ] = useState<any>();

    useEffect(() => {

        if (location?.state?.userName !== '') {
            
            setUser(location?.state?.userName);
        }

    }, [location])

    function goToDash() {
        setTimeout(() => {
            history.push('/login')
        }, 3000)
    }

    return (
        <Container>
            <Header>
                <div className="logo"><Icon123JusBig /></div>
            </Header>

            <Main>
                <div className="image">
                    <img src={UserComplete} alt="Imagem de cadastro concluído" />
                </div>

                <div className="status">
                    
                    <div className="status-title">Olá, <span>{user ? user : 'usuário'}!</span></div>
                    
                    <div className="status-subtitle">
                        Me chamo Júlia, sou sua atendente virtual. <br/>
                        Vou te ajudar de agora em diante! <br/>
                        Agora você pode desfrutar do painel configurado <span>especialmente para você</span> !
                    </div>
                    <div className="buttons">
                        <ButtonDefault
                            className="continue"
                            onClick={goToDash}
                        >
                            Vamos Lá
                        </ButtonDefault>
                    </div>
                </div>

                <div className="progress">
                        <div className="progressbar"></div>
                        <div className="bar-title">
                            <span>100%</span> do perfil completo
                        </div>
                    </div>
            </Main>
        </Container>
    )
}