import { useEffect, useRef, useState } from "react";
import { useAuth } from "../../../contexts/UserContext";
import api from "../../../services/api";
import { IconClose, IconDownload, IconFilter, IconSearch } from "../../icons";
import { DownloadFile } from "../ProfessionalsFilter/styles";
import { Container } from "./styles";

interface FilterProps {
    filters?: any,
    setFilters?: any,
    setSearchDemands?: any,
    setClearAll?: any,
    requestedPayload?: any,
}

export default function DemandsFilter(
    {
        filters,
        setFilters,
        setSearchDemands,
        setClearAll,
        requestedPayload,

    }: FilterProps) {

    const filterRef = useRef<any>();

    const { user } = useAuth();
    const [ DTOSearch, setDTOSearch ] = useState<any>();
    const [ openFiltersMenu, setOpenFiltersMenu ] = useState<boolean>(false);
    
    function handleOnChanges(value: any) {
        setDTOSearch(value)
    }

    function clearSearch() {
        setDTOSearch('')
        setSearchDemands('')        
    }

    function searchKeyPressed(event: any) {

        if (event.key === 'Enter') {
            setSearchDemands(DTOSearch)
        }

    }

    async function downloadProposal() {
        try {

            const response = await api.get(`proposta-csv?${requestedPayload}`);
            const url = window.URL.createObjectURL(new Blob([s2ab(response.data)]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'demandas.csv');
            document.body.appendChild(link);
            link.click();

        } catch (error: any) {

            console.log('log error download csv', error)

        }
    }

    function s2ab(s:any) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
    }

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            
            if (openFiltersMenu && filterRef.current && !filterRef.current.contains(e.target)) {
                setOpenFiltersMenu(false);
            }

        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openFiltersMenu]);


    return (
        <Container 
            filter={filters}            
        >
            <div 
                className="search"
            >
                <div
                    className="buscar"
                    onClick={() => setSearchDemands(DTOSearch)}
                >
                    <IconSearch />
                </div>
                <input
                    placeholder="Pesquisar"
                    onChange={(e) => handleOnChanges(e.target.value)}
                    onKeyDown={(e) => searchKeyPressed(e)}
                    value={DTOSearch}
                />
                {
                    DTOSearch &&
                    <div
                        className="clean-search"
                        onClick={() => clearSearch()}
                    >
                        <IconClose />
                    </div>
                }
            </div>

            
            <div
                className="button"
                ref={filterRef}
                onClick={() => setOpenFiltersMenu(!openFiltersMenu)}
            >
                {
                    filters?.length < 1
                        ? <div className="button-filter">
                            <IconFilter />                           
                        </div>
                        : <div className="button-filter">
                            <div className="icon-filter">
                                <IconFilter />
                            </div>
                            <div>
                                {filters?.length}
                            </div>
                            <div
                                className="clear-icon"
                                onClick={() => {setFilters([]); setClearAll();}}
                            >
                                <IconClose />
                            </div>
                        </div>
                }
                

                <div className={openFiltersMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                    <div className="filter">
                        Filtrar por...
                        <div className="button-search">
                            <IconSearch />
                        </div>
                    </div>
                    {
                        user?.profile !== 'comum' &&
                        <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'status'])}
                        >
                            Status
                        </div>
                    }

                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'comarca'])}
                    >
                        Comarca
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'servico'])}
                    >
                        Tipo do serviço
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'subtype_service'])}
                    >
                        Subtipos de serviço
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'subtipo'])}
                    >
                        Subtipos de audiência
                    </div>
                    <div
                            className="filters"
                            onClick={() => setFilters((filters: any) => [...filters, 'modality'])}
                        >
                            Modalidade
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'valor'])}
                    >
                        Valor
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'solicitada'])}
                    >
                        Data de inicio
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'conclusao'])}
                    >
                        Prazo de conclusão
                    </div>
                </div>



                {/* <div className={openFiltersMenu ? 'hidden-menu' : 'hidden-menu no-show'}>
                    <div className="filter">
                        Filtrar por...
                        <div className="button-search">
                            <IconSearch />
                        </div>
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'comarca'])}
                    >
                        Comarca
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'servico'])}
                    >
                        Tipo do serviço
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'subtipo'])}
                    >
                        Subtipo
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'valor'])}
                    >
                        Valor
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'solicitada'])}
                    >
                        Solicitada em
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'aceite'])}
                    >
                        Prazo de aceite
                    </div>
                    <div
                        className="filters"
                        onClick={() => setFilters((filters: any) => [...filters, 'conclusao'])}
                    >
                        Prazo de conclusão
                    </div>
                </div>
                                 */}

            </div>            

            {
                user.profile !== 'comum' &&
                <DownloadFile
                    onClick={() => downloadProposal()}
                >
                    <IconDownload />
                </DownloadFile>
            } 


        </Container>
    )
}