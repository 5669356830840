import { ButtonContainer, Container, IconContainer, SubtitleContainer, TitleContainer } from "./styles";

interface CardProps {
    title?: string,
    subtitle?: any;
    icon?: any;
    button?: any;
    className?: any,
}

export default function BannerPlan({
    title, 
    subtitle, 
    icon, 
    button,
    className,
}: CardProps) {
    return (
        <Container className={className}>

            <IconContainer className={className}>
                {icon}
            </IconContainer>

            <TitleContainer>
                {title}
            </TitleContainer>

            <SubtitleContainer>
                {subtitle}
            </SubtitleContainer>

            <ButtonContainer className={className}>
                {button}
            </ButtonContainer> 

        </Container>
    )
}