import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }


  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clipPath: ellipse(120% 100% at 50.00% 0%); */
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 678px;
  padding-top: 5rem;
  padding-bottom: 7rem;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clip-path: ellipse(120% 100% at 50% 0%);
  transform: translateZ(0);
`;

export const AreaHeader = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  z-index: 2;
  position: fixed;
  top: 0;
`;

export const AreaSite = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 6.25rem;
  max-width: 1400px;
  z-index: 2;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 3rem;
  }
`;

export const AreaImage2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;

  .circle {
    animation: ${appearFromLeft} 0.7s;
    position: absolute;
    top: 150px;

    .blue-circle {
      width: 400px;
      height: 400px;
      background-color: ${({ theme }) => theme.colors.secondaryPool600};
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 7%;
      z-index: -1;
    }

    .green-circle {
      width: 400px;
      height: 400px;
      background-color: ${({ theme }) => theme.colors.secondaryGreen500};
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 10%;
      z-index: -1;
    }
  }
  
  @media (max-width: 800px) {    

    .circle {
      width: 50%;  
      top: 65%;
      .blue-circle {
        /* width: 120px;
        height: 120px;
        top: 0;
        left: 0; */
        display: none;
      }
    }
  }
`;

export const Image = styled.img`
  width: 100%;  
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  animation: ${appearFromRight} 0.7s;


  @media (max-width: 800px) {
    width: 85%;
  }
`;

export const DescriptionTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 400px;
  color: ${({ theme }) => theme.colors.interfacePure0};
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 24px;
  position: relative;

  span {
    color: ${({ theme }) => theme.colors.secondaryPool600};
  }

  .subtitle {
    width: 469px;
    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;   
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: 52%;    
  }
  
  @media (max-width: 800px) {
    font-size: 24px;
    gap: 16px;

    .subtitle {
      width: 100%;
    }
  }
`;

export const DemandButton = styled.button`
  width: 469px;
  height: 48px;
  border-radius: 8px;
  border: none;
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
  transition: all .2s;

  &.first {
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
    color: ${({ theme }) => theme.colors.interfacePure0};
    outline: none;
    border: none;
  }

  &.first-green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen500};
    color: ${({ theme }) => theme.colors.interfacePure0};
  }
  
  &.second {
    border: 2px solid ${({ theme }) => theme.colors.primaryOrange500};
    color: ${({ theme }) => theme.colors.primaryOrange500};
    outline: none;    
  }

  &.second-green {
    border: 2px solid ${({ theme }) => theme.colors.secondaryGreen200};
    color: ${({ theme }) => theme.colors.secondaryGreen200};
  }

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 800px) {

    &.first {
      width: 100%;
      font-size: 16px;
    }
    &.second {
      width: 100%;
    }
  }
`

export const InfoWrapper = styled.div`
  height: fit-content;
  position: relative;
`

export const DemandInfos = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1040px;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 324px;   

  @media (max-width: 800px) {
    max-width: 85%;
    margin-bottom: 750px;
  }

  .light {
    font-size: 28px;
  }
`

export const DemandTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.primaryBlue500};

  span {
    color: ${({ theme }) => theme.colors.primaryOrange600};
  }

  &.first {
    max-width: 700px;
  }

  &.green {
    span {
      color: ${({ theme }) => theme.colors.secondaryGreen500};
    }
  }

  &.light {
    margin-top: 120px;
    
    &.green {
      span {
        color: ${({ theme }) => theme.colors.secondaryGreen500};
      }  
    }

    span {
      color: ${({ theme }) => theme.colors.secondaryPool600};
    }
  }

  @media (max-width: 800px) {
    line-height: 42px;
  }
`

export const DemandDescription = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: ${({ theme }) => theme.colors.primaryBlue500};

  max-width: 770px;
`

export const StructureCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;

   @media (max-width: 800px) {
    grid-template-columns: 1fr;
   }
`;

export const DemandCard = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  height: 120px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 16px;
  padding: 32px;

  &.green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen050};
  }

  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primaryBlue500};
  
  .info {
    max-width: 355px;
  }

  @media (max-width: 800px) {
    gap: 20px;

    .info {
      max-width: 200px;
      font-size: 16px;
    }
  }
`

export const WideCard = styled.div`
  width: calc(100% - ((100% - 1040px) / 2));  
  height: 296px;
  background-color: ${({ theme }) => theme.colors.secondaryPool100};
  border-radius: 16px 0 0 16px;  
  padding-left: 24px;

  display: flex;
  align-items: center;  

  position: absolute;
  bottom: 0;
  right: 0;

  @media (max-width: 800px) {
    width: 85%;
    height: fit-content;
    text-align: center;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    padding: 24px;
    margin-bottom: -650px;
    margin-left: 7.5%;
    margin-right: 7.5%;
  }
`

export const VectorColumn = styled.div`
  display: flex;
  align-items: flex-end;  

  .vector {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .icon {
      width: 64px;
      height: 64px;

      &.people {
        height: 80px;
        img {
          max-height: 80px;
        }
      }     
    
    }

    &.cards {
      margin-left: 18px;
      gap: 40px;
    }

    &.people {
      margin-left: 8px;
    }

  }

  .one {
    align-self: center;
  }

  .vector-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    margin-right: 8px;
    margin-left: -1px;
    z-index: 0;
  }

  .no-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    margin-left: 10px;

    .bottom {
      max-height: 80px;
    }
  }

  @media (max-width: 800px)  {
    flex-direction: column;
    
    .vector {
      flex-direction: row;

      &.cards {
        gap: 25px;
        margin: 0;
      }
    }
    
    .vector-icon {
      transform: rotate(90deg);
      margin-top: -10px;
      margin-right: 50px;
    }

    .vector-double {
      transform: rotateY(180deg) rotateZ(90deg);
      margin-right: 90px;
      margin-top: -30px;
    }

    .no-card {
      flex-direction: row;
      margin-left: 0;
      margin-right: 20px;
      margin-top: -25px;
    }

    .one {
      transform: rotateZ(90deg) rotateX(180deg);
    }
  }
`

export const VectorCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 144px;
  height: 72px;
  border-radius: 8px;
  z-index: 1;

  font-size: 1rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.interfacePure0};

  &.blue {
    background-color: ${({ theme }) => theme.colors.secondaryPool600};
  }

  &.orange {
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
  }

  &.green {
    background-color: ${({ theme }) => theme.colors.secondaryGreen500};
  }
`

export const DemandSolicitation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 1040px;
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;

  .subtitle {
    font-size: 20px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primaryBlue500};

    span {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primaryOrange600};
    }
  }

  @media (max-width: 800px) {
    max-width: 85%;

    .mobile {
      font-size: 32px;
    }
  }
`

export const WrapperServiceCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

export const ServiceCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 34px;
  width: 100%;
  height: 112px;
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.background};  

  .title {
    width: 190px;
    font-size: 24px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.primaryBlue500};
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.primaryOrange600};
    border-radius: 8px;
    cursor: pointer;
    transition: all .3s;

    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.interfacePure0};

    &:hover {
      transform: scale(1.1);
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    gap: 16px;

    .title {
      width: 150px;
      font-size: 20px;
    }
  }
`;

export const IslandSection = styled.div`
  display: flex;
  flex-direction: column;
  height: 1224px;
  margin-top: 120px;

  @media (max-width: 800px) {
    height: 2000px;
  }
`

export const IslandQuality = styled.div`
  position: relative;
  height: fit-content;

  .island {
    width: calc(80% - ((100% - 1040px) / 2));
    height: 389px;

    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
    gap: 32px;

    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 16px 16px 0;
    background-color: ${({ theme }) => theme.colors.secondaryPool600};

    .title {
      font-size: 32px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.interfacePure0};
    }

    .subtitle {
      width: 425px;
      font-size: 24px;
      font-weight: 500;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.interfacePure0};

      span {
        font-weight: 700;
      }
    }

    .image {
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: -35%;
      margin-bottom: -8%;
    }
  }

  @media (max-width: 800px) {
    width: 85%;
    margin: 0 auto;

    .island {
      width: 100%;
      height: 500px;
      justify-content: flex-start;
      border-radius: 22px;
      padding: 24px;

      .title {
        text-align: center;
        font-size: 24px;
      }

      .subtitle {
        text-align: center;
        width: 85%;
        font-size: 20px;
      }

      .image {
        width: 85%;
        left: 7.5%;
        right: 7.5%;
        margin-bottom: -75px;
      }
    }
  }
`

export const IslandSystem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  max-width: 1040px;
  height: 248px;
  margin-top: 600px;
  margin-left: auto;
  margin-right: auto;

  .left {
    display: flex;
    flex-direction: column;
    gap: 28px;

    .title {
      font-size: 32px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.primaryBlue500};

      span {
        color: ${({ theme }) => theme.colors.primaryBlue100};
      }
    }

    .fact {
      display: none;
      width: 335px;
      height: 152px;
      background-color: ${({ theme }) => theme.colors.secondaryPool100};
      border-radius: 16px;
      padding: 16px;

      font-size: 1rem;
      font-weight: 500;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.primaryBlue600};

      span {
        font-weight: 700;
      }
    }

    .subtitle {
      max-width: 600px;
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      color: ${({ theme }) => theme.colors.primaryBlue500};

      p {
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 700;
        margin-top: 8px;
      }
    }
  }

  .right {
    width: 335px;
    height: 152px;
    background-color: ${({ theme }) => theme.colors.secondaryPool100};
    border-radius: 16px;
    padding: 16px;

    font-size: 1rem;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primaryBlue600};

    span {
      font-weight: 700;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 85%;
    margin-top: 625px;

    .left {

      .title {
        font-size: 26px;
      }

      .fact {
        display: block;
      }

      .subtitle {
        text-align: center;
      }
    }

    .right {
      display: none;
    }
  }
`

export const WrapperIslandCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  width: 100%;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  @media (max-width: 800px) {
    width: 85%;
    grid-template-columns: 1fr;
    margin-top: 290px;
  }
`

export const IslandCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 335px;
  height: 268px;
  padding: 0 30px;

  .info {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primaryBlue500};
    text-align: center;
    max-width: 270px;

    span {
      font-weight: 700;
    }
  }

  @media (max-width: 800px) {
    justify-content: space-evenly;
  }
`
