/* eslint-disable react-hooks/exhaustive-deps */

// React //
import { useEffect, useState } from "react";

// Components
import ProfessionalCard from "../../components/Ui/ProfessionalCard";
import ProfessionalsFilter from "../../components/Ui/ProfessionalsFilter";
import ProfessionalsTab from "../../components/Ui/ProfessionalsTabs";
import ButtonDefault from "../../components/form/ButtonDefault";

// Services //
import api from "../../services/api";
import { useAuth } from "../../contexts/UserContext";

// Libraries //
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-date-picker';
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/pt-br';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';

// Icons //
import { 
    IconAdvancePagination, 
    IconBackPagination, 
    IconButtonArrowDown, 
    IconClose, 
} from "../../components/icons";

// Styles //
import { 
    Container, 
    Filter, 
    Menu, 
    Paginate 
} from "./styles";
import { useLocation, } from "react-router-dom";


interface Pagination {
    currentPage: string,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number,
}

export default function Professionals(props: any) {
    const { setPageName, setPageType } = useAuth();
    const location = useLocation<any>();
    const [ loading, setLoading ] = useState<any>(false);
    const [ selected, setSelected ] = useState('1');
    const [ analysisTotal, setAnalysisTotal ] = useState<any>();
    const [ activeTotal, setActiveTotal ] = useState<any>();
    const [ inactiveTotal, setInactiveTotal ] = useState<any>();
    const [ profilesAnalysis, setProfilesAnalysis ] = useState<any>();
    const [ profilesActives, setProfilesActives ] = useState<any>();
    const [ profilesInactives, setProfilesInactives ] = useState<any>();
    const [ filterProfiles, setFilterProfiles ] = useState<any>([]);
    const [ searchProfiles, setSearchProfiles ] = useState<any>('');
    const [ openCounties, setOpenCounties ] = useState<boolean>(false);
    const [ dataCounties, setDataCounties ] = useState<any>();
    const [ countieSelected, setCountieSelected ] = useState<any>('');
    const [ openPlan, setOpenPlan ] = useState<boolean>(false);
    const [ openDate, setOpenDate ] = useState<boolean>(true);
    const [ planSelected, setPlanSelected ] = useState<any>({
        name: '',
        value: '',
    });
    const [ paginateProfileActive, setPaginateProfileActive ] = useState<Pagination>(); 
    const [ paginateProfileInactive, setPaginateProfileInactive ] = useState<Pagination>();
    const [ paginateProfileAnalysis, setPaginateProfileAnalysis ] = useState<Pagination>();
    const [ starterDate, setStarterDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() - 1));
    const [ endDate, setEndDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() + 1));
    const [ filterDate, setFilterDate ] = useState<any>();
    // const [ pageSelected, setPageSelected ] = useState<number>();
    const initalDate = moment(starterDate).format('YYYY/MM/DD HH:mm');
    const finalDate = moment(endDate).format('YYYY/MM/DD HH:mm');

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 40,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    } 
    
    async function getProfessionalsAnalysis(page: any) {
        try {

            if (searchProfiles === undefined) {
                setSearchProfiles('')
            }
            if (countieSelected === undefined) {
                setCountieSelected('')
            }
            if (planSelected === undefined) {
                setPlanSelected('')
            }
            setLoading(true)            

            const response = await api.get(`usuario?estadocidade=${countieSelected}&nome=${searchProfiles}&status=em analise&plano=${planSelected.value}&page=${page}&limit=10&ordem=nome&dataInicio=${initalDate}&dataFim=${finalDate}`)
            setAnalysisTotal(response.data.result.pagination.total)
            setProfilesAnalysis(response.data.result.data) 
            setPaginateProfileAnalysis(response.data.result.pagination) 

            if (response.data.status !== 'success') {
                throw new Error ('Não foi possível encontrar perfis em análise.')
            }

            setLoading(false)

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            setLoading(false)
        }
    };

    async function getProfessionalsActives(page: any) {
        try {

            if (searchProfiles === undefined) {
                setSearchProfiles('')
            }
            if (countieSelected === undefined) {
                setCountieSelected('')
            }
            if (planSelected === undefined) {
                setPlanSelected('')
            }

            setLoading(true)

            const response = await api.get(`usuario?estadocidade=${countieSelected}&nome=${searchProfiles}&status=ativo&plano=${planSelected.value}&page=${page}&limit=10&ordem=nome&dataInicio=${initalDate}&dataFim=${finalDate}`)
            setActiveTotal(response.data.result.pagination.total);
            setProfilesActives(response.data.result.data);
            setPaginateProfileActive(response.data.result.pagination);

            if (response.data.status !== 'success') {
                throw new Error('Não foi possível encontrar perfis ativos.')
            }

            setLoading(false)

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            setLoading(false)
        }
    };

    async function getProfessionalsInactive(page: any) {
        try {

            if (searchProfiles === undefined) {
                setSearchProfiles('')
            }
            if (countieSelected === undefined) {
                setCountieSelected('')
            }
            if (planSelected === undefined) {
                setPlanSelected('')
            }
            setLoading(true)

            const response = await api.get(`usuario?estadocidade=${countieSelected}&nome=${searchProfiles}&status=inativo&plano=${planSelected.value}&page=${page}&limit=10&ordem=nome&dataInicio=${initalDate}&dataFim=${finalDate}`)
            setInactiveTotal(response.data.result.data.length);
            setProfilesInactives(response.data.result.data);
            setPaginateProfileInactive(response.data.result.pagination);

            if (response.data.status !== 'success') {
                throw new Error ('Não foi possivel encontrar perfis inativos.')
            }

            setLoading(false)

        } catch (error: any) {
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            setLoading(false);
        }
        
    };

    useEffect(() => {

        setPageName('Profissionais');
        setPageType('normal');
          
        if (searchProfiles === undefined) {
            setSearchProfiles('')
        }
        else if (countieSelected === undefined) {
            setCountieSelected('')
        }
        else if (planSelected === undefined) {
            setPlanSelected('')
        } else {            

            getProfessionalsAnalysis(1);
            getProfessionalsActives(1);
            getProfessionalsInactive(1);   

        }
        
    }, [selected, countieSelected, planSelected, searchProfiles, filterDate])

    useEffect(() => {

        async function getCounties() {
            try {

                setLoading(true)
                
                const response = await api.get('comarca');

                const countieArray: { value: any, label: any, id_comarca: number }[] = []
                
                response.data.result.map((row: any) => (
                    countieArray.push({
                        label: `${row.uf} - ${row.cidade}`,
                        value: `${row.uf} - ${row.cidade}`,
                        id_comarca: row.id_comarca,
                    })
                ))
                    
                setDataCounties(countieArray);
                
                if (response.data.status !== 'success') {
                    throw new Error('Não foi possível encontrar comarcas.')
                }

                setLoading(false)

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                setLoading(false)
            }
        }

        getCounties();

    }, [filterProfiles])

    const onClickPage = (name: any, selectedItem: { selected: number; }) => {

        let pageNumber = selectedItem.selected + 1                

        if (name === 'analise') {
            getProfessionalsAnalysis(pageNumber); 
        }

        if (name === 'ativo') {
            getProfessionalsActives(pageNumber); 
        }

        if (name === 'inativo') {
            getProfessionalsInactive(pageNumber); 
        }
    }
    
    function clearAll() {
        setCountieSelected('');
        setPlanSelected('');    
        setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
        setEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
    }   

    useEffect(() => {

        if (location.state !== undefined && location?.state.selected === "2") {
            setSelected('2');            
        }

    }, [location])

    function removeEspecificValueFromFilters(value: string) {
        const arrayFiltered = filterProfiles;
        const index = arrayFiltered.indexOf(value);
        const newArray = arrayFiltered.splice(index, 1);
        console.log('log do novo array', newArray, arrayFiltered)
        setFilterProfiles(arrayFiltered)        

        if (value === 'periodo') {
            setOpenDate(false)
        } 
        
    }

    return (
        <Container>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            <Menu>
                <ProfessionalsTab
                    selected={selected}
                    setSelected={setSelected}
                    analysis={analysisTotal}
                    active={activeTotal}
                    inactive={inactiveTotal}
                />
                <ProfessionalsFilter
                    filters={filterProfiles}
                    setFilters={setFilterProfiles}
                    setSearchProfiles={setSearchProfiles}
                    setClean={() => clearAll()}
                />
            </Menu>

            <Filter>
                {
                    filterProfiles.length > 0 &&
                    <>
                        <div className="results">
                            {
                                filterProfiles !== undefined && selected === '1' &&
                                `${profilesAnalysis?.length} resultados:`
                            }
                            {
                                filterProfiles !== undefined && selected === '2' &&
                                `${profilesActives?.length} resultados:`
                            }
                            {
                                filterProfiles !== undefined && selected === '3' &&
                                `${profilesInactives?.length} resultados:`
                            }
                        </div>

                        <div className="buttons">
                            {
                                filterProfiles.includes('comarca') && 
                                <div className="countie-selector">
                                    <div 
                                        className="countie"
                                        onClick={() => setOpenCounties(!openCounties)}
                                    >
                                        Comarca: 
                                        {
                                            countieSelected &&
                                            <div className="countie-selected">
                                                <div 
                                                    className="close-icon"
                                                    onClick={() => setCountieSelected('')}
                                                >
                                                    <IconClose />
                                                </div>
                                                <div>Comarca:</div>
                                                <div>{countieSelected}</div>
                                            </div>
                                        }
                                    </div>                            

                                    {
                                        countieSelected === '' &&
                                        <div className="filters">
                                            <Select
                                                styles={city}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                name='id_comarca'
                                                placeholder="Selecionar"
                                                options={dataCounties}
                                                // defaultValue={uf && cidade ? { value: `${uf} - ${cidade}`, label: `${uf} - ${cidade}`} : ''}
                                                onChange={(e: any) => setCountieSelected(e.value)}
                                                // onChange={(selected: any) => handleOnChange('cidade_comarca', selected.value)}
                                            />
                                        </div>
                                    }
                                </div>
                            }
                            {
                                filterProfiles.includes('plano') &&
                                <>
                                    <div 
                                        className="plan"
                                        onClick={() => setOpenPlan(!openPlan)}
                                    >
                                        Plano <IconButtonArrowDown />
                                        <div className={openPlan ? 'plan-map' : 'plan-map no-show'}>
                                            <div
                                                className="filters"
                                                onClick={() => setPlanSelected({name: '123JUS Free', value: 1})}
                                            >
                                                123JUS Free
                                            </div>
                                            <div 
                                                className="filters"
                                                onClick={() => setPlanSelected({ name: '123JUS Pro', value: 2 })}
                                            >
                                                123JUS Já Pro
                                            </div>
                                            <div 
                                                className="filters"
                                                onClick={() => setPlanSelected({ name: '123JUS Premium', value: 3 })}
                                            >
                                                123JUS Já Premium
                                            </div>
                                        </div>

                                    </div>
                                    {
                                        planSelected &&
                                        <div 
                                            className="plan-selected"
                                            onClick={() => setOpenPlan(!openPlan)}
                                        >
                                            <div>Plano: </div>
                                            <div>{planSelected.name}</div>
                                            <div
                                                className="close-icon"
                                                onClick={() => setPlanSelected('')}
                                            >
                                                <IconClose />
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            {
                                filterProfiles.includes('periodo') &&
                                <div className={openDate ? 'date-map' : 'date-map no-show'}>
                                    <div className="select-date">
                                        <div className="start-date">
                                            <div className="filters">
                                                Data de inicio:
                                            </div>

                                            <div className="date-field">
                                                <DatePicker onChange={setStarterDate} value={starterDate} calendarIcon={null}  disableCalendar={true} clearIcon={null} />                                                
                                            </div>
                                        </div>
                                        <div className="start-date">
                                            <div className="filters">
                                                Data de fim:
                                            </div>                                    

                                            <div className="date-field">
                                                <DatePicker onChange={setEndDate} value={endDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <ButtonDefault
                                            className="save"
                                            onClick={() => {
                                                setOpenDate(false);
                                                setFilterDate(new Date());
                                            }}
                                        >
                                            Aplicar data
                                        </ButtonDefault>
                                    </div>
                                </div>                    
                            }
                            {
                                filterProfiles.includes('periodo') && starterDate && endDate &&
                                <div className="selected-data">
                                    <div>Solicitada:</div>
                                    <div>
                                        De <Moment locale='pt-br' format="DD/MM/YYYY">{starterDate}</Moment> a <Moment locale='pt-br' format="DD/MM/YYYY">{endDate}</Moment>
                                    </div>
                                    <div
                                        className="close-icon"
                                        onClick={() => { 
                                            setOpenDate(false);
                                            setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
                                            setEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
                                            removeEspecificValueFromFilters('periodo');
                                            setOpenDate(true); 
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                        
                }                

            </Filter>
            
            {
                selected === '1' && profilesAnalysis?.length > 0 && loading === false &&
                profilesAnalysis?.map((row: any) => (
                    <ProfessionalCard
                        profile={row}
                        key={row.id_usuario}
                    />
                ))
                
            }

            {
                selected === '1' && profilesAnalysis?.length <= 0 &&
                <div className="zero">Sem profissionais disponíveis no momento.</div>
            }

            {
                selected === '2' && profilesActives?.length > 0 && loading === false &&
                profilesActives?.map((row: any) => (
                    <ProfessionalCard 
                        profile={row} 
                        key={row.id_usuario} 
                    />
                ))
                
            }

            {
                selected === '2' && profilesActives?.length <= 0 &&
                <div className="zero">Sem profissionais disponíveis no momento.</div>
            }

            {
                selected === '3' && profilesInactives?.length > 0 && loading === false &&
                profilesInactives?.map((row: any) => (
                    <ProfessionalCard 
                        profile={row} 
                        key={row.id_usuario}
                    />

                ))
                
            }

            {
                selected === '3' && profilesInactives?.length <= 0 &&
                <div className="zero">Sem profissionais disponíveis no momento.</div>
            }

            {
                selected === '1' && paginateProfileAnalysis &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('analise', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={paginateProfileAnalysis.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {   
                selected === '2' && paginateProfileActive &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('ativo', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={paginateProfileActive.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {   
                selected === '3' && paginateProfileInactive &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('inativo', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={paginateProfileInactive.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }           

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}