/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Icons //
import {
    IconAccept,
    IconClock,
    IconEditProfile,
    IconFinishedAccept,
    IconFinishedRefused,
    IconInProgress,
    IconListDemand,
    IconOrientation,
    IconPendency,
    IconProfileButtonDown,
    IconProfileButtonUp,
    IconWrong,    
} from "../../icons";

// Styles //
import {
    Container,
    DivArrow,
    DivCode,
    DivIcon,
    DivService,
    DivStarts,
    DivDeadline,
    DivDemand,
    DemandBottom,
    DemandTop,
    DemandWrapper,
    DivStatus,
    DivCounty,
    DivProfessional,
    StatusHover
} from "./styles";

// Libraries
import Moment from 'react-moment';
import 'moment/locale/pt-br';

// Context
import { useAuth } from "../../../contexts/UserContext";

export default function DemandCard({ demand, type }: any) {
    const history = useHistory();
    const { user } = useAuth();

    // const [ loading, setLoading ] = useState<any>();
    const [ cardOpen, setCardOpen ] = useState<any>(false);
    const [ selected, setSelected ] = useState<any>('');      

    function handleOpenMenu(item: any) {
        
        setSelected(item)
        setCardOpen(!cardOpen)
        
    }

    function goToDemand(value: any) {
        history.push({
            pathname: `/demandas/${value}`,
            state: {
                type: type,
            }
        })
    }
    
   return (
        <Container>

            <DemandWrapper
                menuOpen={cardOpen}
                key={demand.id_proposta}
            >
                {
                    user.profile === 'comum' &&
                    <>
                        <DemandTop>
                            <DivIcon>
                                <IconListDemand />
                            </DivIcon>

                            <DivService>
                                <div className="title">Serviço:</div>
                                <div className="subtitle">{demand.descricao_servico_completo}</div>
                            </DivService>

                            <DivDeadline>
                                <div className="title">Prazo de conclusão:</div>
                                <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{demand.prazo_para_conclusao}</Moment></div>
                            </DivDeadline> 

                            <DivCounty>
                                <div className="title">Comarca:</div>
                                <div className="subtitle">{demand.uf_comarca} - {demand.cidade_comarca}</div>
                            </DivCounty>

                            <DivCode>
                                <div className="title">Código:</div>
                                <div className="subtitle">#{demand.id_proposta}</div>
                            </DivCode>

                            <DivStatus>                            
                                {
                                    type === '1' && user.profile === 'comum' &&
                                    <>
                                            <StatusHover
                                                className={
                                                    demand.tipoUrgencia === 'Aguardando Ciência' ? "interrupted" : '' ||
                                                    demand.tipoUrgencia === 'Atraso' ? "delayed" : '' ||
                                                    demand.tipoUrgencia === 'Com Pendência' ? "interrupted" : '' ||
                                                    demand.tipoUrgencia === 'Pendente de Orientação' ? "interrupted" : '' ||
                                                    demand.tipoUrgencia === 'Pendência Resolvida' ? "pending" : ''
                                                }
                                            >
                                                {
                                                    demand.tipoUrgencia === 'Aguardando Ciência' ? "Aguardando Ciência" : '' ||
                                                    demand.tipoUrgencia === 'Atraso' ? "Em atraso" : '' ||
                                                    demand.tipoUrgencia === 'Com Pendência' ? "Com pendência" : '' ||
                                                    demand.tipoUrgencia === 'Pendente de Orientação' ? "Pendente de Orientação" : '' ||
                                                    demand.tipoUrgencia === 'Pendência Resolvida' ? "Pendência resolvida" : '' 
                                                }

                                        </StatusHover>
                                        <div
                                                className={
                                                    demand.tipoUrgencia === 'Aguardando Ciência' ? "status interrupted" : '' ||
                                                    demand.tipoUrgencia === 'Atraso' ? "status delayed" : '' ||
                                                    demand.tipoUrgencia === 'Com Pendência' ? "status interrupted" : '' ||
                                                    demand.tipoUrgencia === 'Pendente de Orientação' ? "status interrupted" : '' ||
                                                    demand.tipoUrgencia === 'Pendência Resolvida' ? "status pending" : ''
                                                }
                                        >
                                                {
                                                    demand.tipoUrgencia === 'Aguardando Ciência' ? <IconOrientation /> : '' ||
                                                    demand.tipoUrgencia === 'Atraso' ? <IconClock /> : '' ||
                                                    demand.tipoUrgencia === 'Com Pendência' ? <IconPendency /> : '' ||
                                                    demand.tipoUrgencia === 'Pendente de Orientação' ? <IconPendency /> : ''  ||
                                                    demand.tipoUrgencia === 'Pendência Resolvida' ? <IconPendency /> : ''
                                                }
                                        </div>
                                    </> 
                                }

                                {
                                    type === '2' && user.profile === 'comum'  &&
                                    <>
                                        <StatusHover
                                            className={
                                                (demand.status === 'Em Andamento' && demand.status_pendencias === 'sem pendencia') ? 'progress' : '' ||
                                                demand.status_andamento === 'Em andamento' ? 'progress' : '' ||                                                
                                                demand.status === 'Com Pendencia' ? 'interrupted' : '' ||                                                
                                                demand.status_andamento === 'Pendência Resolvida' ? 'pending' : '' ||
                                                demand.status === 'Concluida' ? 'finished accepted' : '' 
                                            }
                                        >
                                            {
                                                (demand.status === 'Em Andamento' && demand.status_pendencias === 'sem pendencia') ? 'Em Andamento' : '' ||
                                                demand.status_andamento === 'Em andamento' ? 'Em andamento' : '' ||
                                                demand.status === 'Com Pendencia' ? 'Com Pendência' : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? 'Pendência resolvida' : '' ||
                                                demand.status === 'Concluida' ? 'Concluida' : ''
                                                
                                            }
                                        </StatusHover>
                                        <div
                                            className={
                                                (demand.status === 'Em Andamento' && demand.status_pendencias === 'sem pendencia') ? 'status progress' : '' ||
                                                demand.status_andamento === 'Em andamento' ? 'status progress' : '' ||
                                                demand.status === 'Com Pendencia' ? 'status interrupted' : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? 'status pending' : '' ||
                                                demand.status === 'Concluida' ? 'status finished accepted' : ''
                                            }
                                        >
                                            {
                                                (demand.status === 'Em Andamento' && demand.status_pendencias === 'sem pendencia') ? <IconInProgress /> : '' ||
                                                demand.status_andamento === 'Em andamento' ? <IconInProgress /> : '' ||
                                                demand.status === 'Com Pendencia' ? <IconPendency /> : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? <IconPendency /> : '' ||
                                                (demand.status === 'Concluida' && demand.status_pendencias === 'sem pendencia') ? <IconAccept /> : ''                                             }
                                        </div>
                                    </>
                                }

                                {
                                    type === '3' && user.profile === 'comum' &&
                                    <>
                                        <StatusHover className={
                                            (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? "finished accepted" : '' ||
                                            demand.status === 'Cancelada' ? "canceled" : '' ||
                                            (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? "finished rejected" : '' ||
                                            demand.status === 'Interrompida' ? "interrupted" : '' 
                                        }
                                        >
                                            {
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? "Finalizada com aceite" : '' ||
                                                demand.status === 'Cancelada' ? "Cancelada" : '' ||
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? "Finalizada com recusa" : '' ||
                                                demand.status === 'Interrompida' ? "Interrompida" : '' 
                                            }
                                        </StatusHover>
                                        <div
                                            className={
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? "status finished accepted" : '' ||
                                                demand.status === 'Cancelada' ? "status canceled" : '' ||
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? "status finished rejected" : '' ||
                                                demand.status === 'Interrompida' ? "status interrupted" : '' 
                                            }
                                        >
                                            {
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? <IconFinishedAccept /> : '' ||
                                                demand.status === 'Cancelada' ? <IconWrong /> : '' ||
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? <IconFinishedRefused /> : '' ||
                                                demand.status === 'Interrompida' ? <IconWrong /> : '' 
                                            }
                                        </div>
                                    </>
                                }
                            </DivStatus>

                            <DivDemand
                                onClick={() => goToDemand(demand.id_proposta)}
                            >
                                <IconEditProfile />
                            </DivDemand>

                            <DivArrow
                                onClick={() => handleOpenMenu(demand)}
                            >
                                {
                                    cardOpen
                                        ? <IconProfileButtonUp />
                                        : <IconProfileButtonDown />
                                }
                            </DivArrow>
                        </DemandTop>
                    
                        <DemandBottom
                            menuOpen={cardOpen}
                        >
                            <DivIcon>
                            </DivIcon>

                            <DivService>
                                <div className="title">Nº do processo:</div>
                                <div className="subtitle">{demand.numero_processo}</div>
                            </DivService>
                            {
                                type === '3' &&
                                <DivStarts>
                                    <div className="title">Encerrada em:</div>
                                    <div className="subtitle">
                                        {
                                            demand.modificado !== null
                                                ? <Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{demand.modificado}</Moment>
                                                : '----------'
                                        }

                                    </div>
                                </DivStarts>
                            }

                            {
                                type !== '3' &&
                                <DivStarts>
                                    <div className="title">Data de início:</div>
                                    <div className="subtitle">
                                        {
                                            demand.data_inicio !== null
                                                ? <Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{demand.data_inicio}</Moment>
                                                : '----------'
                                        }

                                    </div>
                                </DivStarts>

                            }

                            <DivCounty>
                                <div className="title">Valor:</div>
                                <div className="subtitle">
                                    {   demand?.valor_profissional !== null
                                        ?
                                            demand?.valor_profissional.toString().includes('.')
                                                ? <div className="value">R$ {demand?.valor_profissional.toString().replace('.', ',')}</div>
                                                : <div className="value">R$ {demand?.valor_profissional},00</div>
                                        : 'R$ 0,00'
                                    }  
                                </div>
                            </DivCounty>

                            <DivCode>
                            </DivCode>

                            <DivStatus>
                                <div className="status"></div>
                            </DivStatus>

                            <DivDemand className="fake">
                            </DivDemand>

                            <DivArrow className="fake">
                            </DivArrow>
                        </DemandBottom>
                    </>
                }

                {
                    user.profile !== 'comum' &&
                    <>
                        <DemandTop>
                            <DivIcon>
                                <IconListDemand />
                            </DivIcon>

                            <DivService>
                                <div className="title">Serviço:</div>
                                <div className="subtitle">{demand.descricao_servico_completo}</div>
                            </DivService>

                            <DivCounty>
                                <div className="title">Comarca:</div>
                                <div className="subtitle">{demand.uf_comarca} - {demand.cidade_comarca}</div>
                            </DivCounty>

                            <DivProfessional>
                                <div className="title">Profissional:</div>
                                <div className="subtitle">{demand?.nome_profissional ? demand?.nome_profissional.split(' ')[0].replace('>', '') : 'Nome'} - D{demand.id_usuario}</div>
                               <div className="show-name">{demand?.nome_profissional?.replace('>', '')}</div>
                            </DivProfessional>

                            <DivDeadline>
                                <div className="title">Prazo de conclusão:</div>
                                <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{demand.prazo_para_conclusao}</Moment></div>
                            </DivDeadline>

                            <DivCode>
                                <div className="title">Código:</div>
                                <div className="subtitle">#{demand.id_proposta}</div>
                            </DivCode>

                            <DivStatus>
                                {
                                    type === '1' && user.profile !== 'comum' &&
                                    <>
                                        <StatusHover
                                            className={
                                                demand.tipoUrgencia === 'Interrompida' ? "interrupted" : '' ||
                                                demand.tipoUrgencia === 'Pendente de Orientação' ? "interrupted" : '' ||
                                                demand.tipoUrgencia === 'Com Pendência' ? "interrupted" : '' ||
                                                demand.tipoUrgencia === 'Atraso' ? "delayed" : '' ||
                                                demand.tipoUrgencia === 'Pendência Resolvida' ? "pending" : ''
                                            }
                                        >
                                            {
                                                demand.tipoUrgencia === 'Interrompida' ? "Interrompida" : '' ||
                                                demand.tipoUrgencia === 'Pendente de Orientação' ? "Pendente de Orientação" : '' ||
                                                demand.tipoUrgencia === 'Com Pendência' ? "Com pendência" : '' ||
                                                demand.tipoUrgencia === 'Atraso' ? "Em atraso" : '' ||
                                                demand.tipoUrgencia === 'Pendência Resolvida' ? "Pendência resolvida" : '' 
                                            }

                                        </StatusHover>
                                        <div
                                            className={
                                                demand.tipoUrgencia === 'Interrompida' ? "status interrupted" : '' ||
                                                demand.tipoUrgencia === 'Pendente de Orientação' ? "status interrupted" : '' ||
                                                demand.tipoUrgencia === 'Com Pendência' ? "status interrupted" : '' ||
                                                demand.tipoUrgencia === 'Atraso' ? "status delayed" : '' ||
                                                demand.tipoUrgencia === 'Pendência Resolvida' ? "status pending" : '' 
                                            }
                                        >
                                            {
                                                demand.tipoUrgencia === 'Interrompida' ? <IconWrong /> : '' ||
                                                demand.tipoUrgencia === 'Pendente de Orientação' ? <IconOrientation /> : '' ||
                                                demand.tipoUrgencia === 'Com Pendência' ? <IconPendency /> : '' ||
                                                demand.tipoUrgencia === 'Atraso' ? <IconClock /> : '' ||
                                                demand.tipoUrgencia === 'Pendência Resolvida' ? <IconPendency /> : '' 
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    type === '2' && user.profile !== 'comum' &&
                                    <>
                                        <StatusHover
                                            className={
                                                (demand.status === 'Concluida' && demand.status_pendencias === 'sem pendencia') ? 'pending' : '' ||
                                                demand.status_orientacao === 'Aguardando Ciência' ? 'pending' : '' ||
                                                demand.status_andamento === 'Com Pendência' ? 'interrupted' : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? 'pending' : '' ||
                                                demand.status_andamento === 'Em Andamento' ? 'progress' : ''
                                            }
                                        >
                                            {
                                                (demand.status === 'Concluida' && demand.status_pendencias === 'sem pendencia') ? 'Concluida' : '' ||
                                                demand.status_orientacao === 'Aguardando Ciência' ? 'Aguardando ciência' : '' ||
                                                demand.status_pendencias === 'a resolver por profissional' ? 'Com Pendência' : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? 'Pendência resolvida' : '' ||
                                                demand.status_andamento === 'Em Andamento' ? 'Em Andamento' : '' 
                                            }
                                        </StatusHover>
                                        <div
                                            className={
                                                (demand.status === 'Concluida' && demand.status_pendencias === 'sem pendencia') ? 'status pending' : '' ||
                                                demand.status_orientacao === 'Aguardando Ciência' ? 'status pending' : '' ||
                                                demand.status_andamento === 'Com Pendência' ? 'status interrupted' : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? 'status pending' : '' ||
                                                demand.status_andamento === 'Em Andamento' ? 'status progress' : '' 
                                            }
                                        >
                                            {
                                                (demand.status === 'Concluida' && demand.status_pendencias === 'sem pendencia') ? <IconAccept /> : '' ||
                                                demand.status_orientacao === 'Aguardando Ciência' ? <IconOrientation /> : '' ||
                                                demand.status_pendencias === 'a resolver por profissional' ? <IconPendency /> : '' ||
                                                demand.status_andamento === 'Pendência Resolvida' ? <IconPendency /> : '' ||
                                                demand.status_andamento === 'Em Andamento' ? <IconInProgress /> : ''
                                            }
                                        </div>
                                    </>
                                }

                                {
                                    type === '3' && user.profile !== 'comum' &&
                                    <>
                                        <StatusHover className={
                                            (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? "finished accepted" : '' ||
                                            demand.status === 'Cancelada' ? "canceled" : '' ||
                                            (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? "finished rejected" : ''
                                        }
                                        >
                                            {
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? "Finalizada com aceite" : '' ||
                                                demand.status === 'Cancelada' ? "Cancelada" : '' ||
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? "Finalizada com recusa" : ''
                                            }
                                        </StatusHover>
                                        <div
                                            className={
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? "status finished accepted" : '' ||
                                                demand.status === 'Cancelada' ? "status canceled" : '' ||
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? "status finished rejected" : ''
                                            }
                                        >
                                            {
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Aceita') ? <IconFinishedAccept /> : '' ||
                                                demand.status === 'Cancelada' ? <IconWrong /> : '' ||
                                                (demand.status === 'Finalizada' && demand.status_finalizacao === 'Recusada') ? <IconFinishedRefused /> : ''
                                            }
                                        </div>
                                    </>
                                }
                               
                            </DivStatus>

                            <DivDemand
                                onClick={() => goToDemand(demand.id_proposta)}
                            >
                                <IconEditProfile />
                            </DivDemand>

                            <DivArrow
                                onClick={() => handleOpenMenu(demand)}
                            >
                                {
                                    cardOpen
                                        ? <IconProfileButtonUp />
                                        : <IconProfileButtonDown />
                                }
                            </DivArrow>
                        </DemandTop>

                        <DemandBottom
                            menuOpen={cardOpen}
                        >
                            <DivIcon>
                            </DivIcon>

                            <DivService>
                                <div className="title">Nº do processo:</div>
                                <div className="subtitle">{demand.numero_processo}</div>
                            </DivService>

                            <DivCounty>
                                <div className="title">Data de início:</div>
                                <div className="subtitle">
                                    {
                                        demand.data_inicio !== null
                                            ?   <Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{demand.data_inicio}</Moment>
                                            :   '----------'
                                    }
                                    
                                </div>
                            </DivCounty>

                            <DivDeadline>
                                {/* <div className="title">Prazo de conclusão:</div>
                                <div className="subtitle"><Moment locale='pt-br' format="DD/MM/YYYY, HH:mm" >{demand.prazo_para_conclusao}</Moment></div> */}
                            </DivDeadline>   

                            <DivDeadline>                                
                            </DivDeadline>

                            <DivCode>
                            </DivCode>                

                            <DivStatus>  
                                <div className="status"></div>                      
                            </DivStatus>

                            <DivDemand className="fake">
                            </DivDemand>

                            <DivArrow className="fake">
                            </DivArrow>
                        </DemandBottom>
                    </>
                }
            </DemandWrapper>

        </Container>
    )
}