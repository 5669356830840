import { IconCheckedBlue, IconCheckedGreen, IconNotChecked } from "../icons";
import { WrapperCard } from "./styles";

interface CardProps {
    id: any;
    iconTop: any;
    title: string;
    subtitle?: any;
    value?: string;
    className: string;
    onClick: () => void;
    selected?: any;
    page?: any,
    width?: any,
}

export default function Card({ id, iconTop, title, className, subtitle, value, onClick, selected, page, width }: CardProps) {
    return(
        <WrapperCard type={page} style={width={width}} className={`${className} ${selected === id ? 'selected' : ''}`} id={id} onClick={onClick} >
            {
                iconTop && 
                <div className="icon-top">{iconTop}</div>
            }
            {
                title &&
                <div className="title">{title}</div>
            }

            {
                subtitle &&
                <div className="subtitle">{subtitle}</div>
            }

            {
                value &&
                <div className="value"><div className="grey">{value}</div></div>
            }

            {
                className === 'free' &&
                <div className="benefits">
                    <div className="benefit-list">
                        <span><IconCheckedBlue /></span>
                        <p className="list">Visualização de oportunidades de serviços</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconCheckedBlue /></span>
                        <p className="list">Cadastro de serviços e valores</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconNotChecked /></span>
                        <p className="list">Recebimento de serviços</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconNotChecked /></span>
                        <p className="list">Envio de propostas</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconNotChecked /></span>
                        <p className="list">Sistema para acompanhamento e gestão de contratações</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconNotChecked /></span>
                        <p className="list">Gestão de perfil</p>
                    </div>
                </div>
            }

            {
                className === 'pro' &&
                < div className="benefits">
                    <div className="benefit-list">
                        <span><IconCheckedGreen /></span>
                        <p className="list">Visualização de oportunidades de serviços</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconCheckedGreen /></span>
                        <p className="list">Cadastro de serviços e valores</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconCheckedGreen /></span>
                        <p className="list">Recebimento de serviços</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconCheckedGreen /></span>
                        <p className="list">Envio de propostas</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconCheckedGreen /></span>
                        <p className="list">Sistema para acompanhamento e gestão de contratações</p>
                    </div>
                    <div className="benefit-list">
                        <span><IconCheckedGreen /></span>
                        <p className="list">Gestão de perfil</p>
                    </div>
                </div>
            }

        </WrapperCard>
    )
}