/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";

// Libraries
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/pt-br';
import DateTimePicker from 'react-datetime-picker';
import toast, { Toaster } from 'react-hot-toast';

// Icons
import { IconClock, IconFile, IconTrash, IconUpload } from "../icons";

// Styles
import { 
    Container, 
    DeadlineAccept, 
    DeadlineConclusion, 
    Header, 
    Main, 
    Orientations, 
    Upload 
} from "./styles";

// Services
import api from "../../services/api";

interface DeadlineProps {
    handleOnChange?: any,
    deadline?: any,
    reuseDeadline?: any,
    reuseConclusionDate?: any,
    reuseOrientations?: any,
    reuseFiles?: any,
    reuseProposal?: boolean,
}

export default function DeadlineProposal({handleOnChange, deadline, reuseDeadline, reuseConclusionDate, reuseOrientations, reuseFiles, reuseProposal}: DeadlineProps) {
    const [ loading, setLoading ] = useState<boolean>();
    const [ conclusionDate, setConclusionDate ] = useState(new Date());
    const [ oldConclusionDate, setOldConclusionDate ] = useState(new Date());
    const [ proposalDate, setProposalDate ] = useState(new Date());
    const [ oldProposalDate, setOldProposalDate ] = useState(new Date());
    const [ uploadFiles, setUploadFiles ] = useState<any[]>([]);
    const [ oldUploadFiles, setOldUploadFiles ] = useState<any[]>([]);
    const reader = new FileReader();
    const [ orientationsText, setOrientationsText ] = useState<any>();
    const [ oldOrientationsText, setOldOrientationsText ] = useState<any>();
    
    useEffect(() => {
        
        if (proposalDate) {
            
            const date = moment(proposalDate).format("YYYY-MM-DD HH:mm:ss")
            handleOnChange('prazo_aceite', date)
            deadline(proposalDate)
            
        }

        if(conclusionDate) {

            const date = moment(conclusionDate).format("YYYY-MM-DD HH:mm:ss")
            handleOnChange('prazo_para_conclusao', date)
        }

        if (reuseProposal) {
            handleOnChange('arquivos', oldUploadFiles)

        } else {
            handleOnChange('arquivos', uploadFiles);       

        }


    }, [proposalDate, conclusionDate, uploadFiles, oldUploadFiles])

    useEffect(() => {
        
        if (reuseProposal === true) {
            setOldProposalDate(new Date(reuseDeadline));
            setOldConclusionDate(new Date(reuseConclusionDate));
            setOldUploadFiles(reuseFiles);
            setOldOrientationsText(reuseOrientations);
        }

    }, [reuseProposal])

    useEffect(() => {
        const oldDate = moment(oldProposalDate).format("YYYY-MM-DD HH:mm:ss")
        handleOnChange('prazo_aceite', oldDate)

        const date = moment(oldConclusionDate).format("YYYY-MM-DD HH:mm:ss")
        handleOnChange('prazo_para_conclusao', date)

    }, [oldConclusionDate, oldProposalDate])

    async function sendFiles(e: any) {
        try {
            
            setLoading(true);
            if (reuseProposal) {

                const formData = new FormData();
                formData.append('arquivo', e.target.files[0]);

                reader.addEventListener("load", () => { });

                reader.readAsDataURL(e.target.files[0]);

                const response = await api.post('fileupload', formData)

                setOldUploadFiles(obj => [...obj, {
                    arquivo_final: "não",
                    caminho: response.data.name
                }]);

            } else {
                
                const formData = new FormData();
                formData.append('arquivo', e.target.files[0]);
    
                reader.addEventListener("load", () => {});
                
                reader.readAsDataURL(e.target.files[0]);
    
                const response = await api.post('fileupload', formData)
    
                setUploadFiles(obj => [...obj,{
                        arquivo_final: "não",
                        caminho: response.data.name
                }]);

            }
            
            
            setLoading(false)

        } catch (error: any) {
            console.log('log do erro ao envir arquivo', error)

            // toast.error('Erro ao enviar vídeo', {
            //     className: "toast-error",
            //     style: {
            //         padding: '16px',
            //         color: '#1A2858',
            //     },
            //     iconTheme: {
            //         primary: '#F24343',
            //         secondary: '#FFFAEE',
            //     },
            // })

        }       
    }    
    
    return (
        <Container>

            {
                loading &&
                <div></div>
            }

            <Header>
                {
                    reuseDeadline
                    ?   <DeadlineAccept>
                            <span>Prazo para aceite de proposta:</span>
                            <div className="calendar-time">
                                <div className="calendar"><DateTimePicker onChange={setOldProposalDate} value={oldProposalDate} calendarIcon={null} disableClock={true} /></div>
                                <div className="time"><IconClock /><Moment locale='pt-br' fromNow ago>{oldProposalDate}</Moment></div>
                            </div>
                        </DeadlineAccept>
                    :   <DeadlineAccept>
                            <span>Prazo para aceite de proposta:</span>
                            <div className="calendar-time">
                                <div className="calendar"><DateTimePicker onChange={setProposalDate} value={proposalDate} calendarIcon={null} disableClock={true} /></div>
                                <div className="time"><IconClock /><Moment locale='pt-br' fromNow ago>{proposalDate}</Moment></div>
                            </div>
                        </DeadlineAccept>
                }
                
                {
                    reuseConclusionDate
                    ?   <DeadlineConclusion>
                            <span>Prazo para conclusão da demanda:</span>
                            <div className="calendar-time">
                                <div className="calendar"><DateTimePicker onChange={setOldConclusionDate} value={oldConclusionDate} calendarIcon={null} disableClock={true} /></div>
                                <div className="time"><IconClock /><Moment locale='pt-br' fromNow ago>{oldConclusionDate}</Moment></div>
                            </div>
                        </DeadlineConclusion>
                    :   <DeadlineConclusion>
                            <span>Prazo para conclusão da demanda:</span>
                            <div className="calendar-time">
                                <div className="calendar"><DateTimePicker onChange={setConclusionDate} value={conclusionDate} calendarIcon={null} disableClock={true} /></div>
                                <div className="time"><IconClock /><Moment locale='pt-br' fromNow ago>{conclusionDate}</Moment></div>
                            </div>
                        </DeadlineConclusion>
                }
                

            </Header>

            <Main>
                <h3>Oriente sobre a realização da demanda!</h3>

                <div className="subtitle">Conte ao profissional informações relevantes a se considerar durante a realização da demanda.</div>

                <div className="upload-orientation">
                    {
                        reuseProposal &&
                        <Orientations>
                            <span>Orientações prévias:</span>
                            <div className="text">
                                <textarea
                                    name=""
                                    id=""
                                    className="text-area"
                                    onChange={(e) => setOldOrientationsText(e.target.value)}
                                    onBlur={() => handleOnChange('orientacoes', oldOrientationsText)}
                                    value={oldOrientationsText}
                                />
                            </div>
                        </Orientations>
                    }

                    {
                        !reuseProposal &&
                        <Orientations>
                            <span>Orientações prévias:</span>
                            <div className="text">
                                <textarea 
                                    name="" 
                                    id="" 
                                    className="text-area"
                                    onChange={(e) => setOrientationsText(e.target.value)}
                                    onBlur={() => handleOnChange('orientacoes', orientationsText)} 
                                    value={orientationsText}
                                />
                            </div>
                        </Orientations>
                    }

                    <Upload>
                        <span>Documentos:</span>
                        <div className="archives">

                            {
                                uploadFiles &&
                                uploadFiles.map((row: any) => (
                                    <div className="file-uploaded">
                                        <div className="icon-title" key={row.caminho}>
                                            <IconFile />
                                            {row.caminho}
                                        </div>
                                        
                                        <div 
                                            className="icon-trash"
                                            onClick={() => setUploadFiles(uploadFiles.filter(obj => obj !== row))}
                                        >
                                            <IconTrash />
                                        </div>
                                    </div>  
                                ))
                            }

                            {
                                oldUploadFiles &&
                                oldUploadFiles.map((row: any) => (
                                    <div className="file-uploaded" key={row.caminho}>
                                        <div className="icon-title">
                                            <IconFile />
                                            {row.caminho}
                                        </div>

                                        <div
                                            className="icon-trash"
                                            onClick={() => setOldUploadFiles(oldUploadFiles.filter((obj: any) => obj !== row))}
                                        >
                                            <IconTrash />
                                        </div>
                                    </div>
                                ))
                            }

                            <div className="button">
                                <IconUpload /> 
                                <input
                                    type="file"
                                    name="Files"
                                    // accept="image/*"
                                    className="file-input"
                                    onChange={sendFiles}
                                    onClick={(e: any) => {e.currentTarget.value = null}}
                                />
                            </div>
                        </div>
                    </Upload>

                </div>
            </Main>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}