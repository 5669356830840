import styled from "styled-components";

export const Container = styled.div`

  width: 100%;
  height: 280px;
  margin-top: 100px;

  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 280px;
    background-color: ${({ theme }) => theme.colors.secondaryPool100};

    .center {
      display: flex;
      justify-content: center;
      gap: 75px;
      /* background-color: red; */
      width: 1040px;
      /* height: 184px; */

      .info {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .text {
          color: ${({ theme }) => theme.colors.primaryBlue600};
          font-size: 1rem;
          font-weight: 500;
          line-height: 150%;
          cursor: pointer;

          a {
            color: ${({ theme }) => theme.colors.primaryBlue600};
          }
        }
      }

      .contact {
        display: flex;
        flex-direction: column;
        gap: 16px;
        color: ${({ theme }) => theme.colors.primaryBlue600};
        font-size: 1rem;
        font-weight: 500;
        line-height: 150%;        

        .logo {
          margin-bottom: 16px;
        }

        .mail {
          cursor: pointer;
        }

        .mail,
        .social {
          display: flex;
          align-items: center;
          gap: 18px;

          svg {
            path {
              fill: ${({ theme }) => theme.colors.primaryBlue200};
            }
          }
        }

        .phone {
          display: flex;
          gap: 16px;         
        }
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
    height: 80px;
    background-color: ${({ theme }) => theme.colors.primaryBlue500};

    color: ${({ theme }) => theme.colors.interfacePure0};
    font-size: 16px;
    font-weight: 500;

    .left {
      display: flex;
      gap: 10px;
      span {
        font-weight: 700;
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      .heart {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .links {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      a {
        color: ${({ theme }) => theme.colors.interfacePure0};
      }
    }
  }


  @media (max-width: 800px) {
    height: fit-content;
    
    .top {
      height: fit-content;
      padding: 48px 24px;
      .center {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .info {
          text-align: center;
        }

        .contact {
          text-align: center;
          align-items: center;
        }
      }
    }

    .bottom {
      flex-direction: column;
      height: fit-content;
      padding: 24px 0;
      gap: 24px;
      
      .left,
      .right {
        flex-direction: column;
      }
    }
  }
`;

