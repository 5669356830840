// React
import { Link } from 'react-router-dom';

//Image //
import { useState } from 'react';

// Context
import { useAuth } from '../../../contexts/UserContext';

// Icons //
import { IconEmail, IconVideo, IconWrong, VirtualAssistent } from '../../icons';

// Components
import ModalDefault from '../../ModalDefault';
import ButtonDefault from '../../form/ButtonDefault';
import InputDefault from '../../form/InputDefault';

// Libraries
import toast, { Toaster } from 'react-hot-toast';

// Styles
import {
    Container,
    ImageWellcome,
    SectionMessage,
    SectionCards,
    Card,
    WrapperModal,
    ModalTitle,
    ModalContacts,
    Divider,
} from './styles';

// Services
import api from '../../../services/api';

export interface InviteProps {
    nome?: string,
    email?: string,
}

export interface DateProps {
    month?: string,
    year?: string,
    financial?: boolean,
}

export default function CardWelcomeOperator({month, year, financial}: DateProps) {
    const { user } = useAuth();
    const [inviteProfessional, setInviteProfessional] = useState<any>();
    const [DTOInvite, setDTOInvite] = useState<InviteProps>({
        nome: '',
        email: '',
    });

    const fullName = user.nome;
    const firstName = fullName?.split(' ')[0];


    function handleOnChange(name: any, value: any) {

        const newDTO: any = DTOInvite
        newDTO[name] = value
        setDTOInvite({ ...newDTO })

    }

    async function inviteAProfessional() {

        try {

            const response = await api.post('site/contato/convidar', DTOInvite);
            // console.log('log do respose do Convidar profissional', response.data.result)

            if (response.data.status !== 'success') {

                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].message);
                }

            } else {
                setInviteProfessional(false);

                toast(<div className="warning"><span><IconEmail color='#23ACFA' /></span>Convite enviado com sucesso!</div>,
                    {
                        duration: 3000,
                        style: {
                            padding: '16px',
                            minWidth: '320px',
                            color: '#1A2858',
                            background: '#E4F5FF',
                            border: '2px solid #88D3FE',
                        },
                    });
            }

        } catch (error: any) {

            console.log('log error invite professional', error)

        }

    }

    return (
        <Container>
            
            <ImageWellcome >
                <div className='virtual'>
                    <VirtualAssistent />
                </div>
            </ImageWellcome>

            <div className='right'>
                {
                    !financial &&
                    <SectionMessage>
                        <h1 className='wellcomeTitle'>Bem vindo, <span>{firstName}</span>!</h1>
                        <div className='wellcomeParagraph'>
                            Este é o desempenho da plataforma no mês de <span>{month}/{year}!</span>
                        </div>
                    </SectionMessage>
                }

                {
                    financial &&
                    <SectionMessage>
                        <h1 className='wellcomeTitle'>Bem vindo, <span>{firstName}</span>!</h1>
                        <div className='wellcomeParagraph'>
                            Este é o <strong>desempenho financeiro</strong> <br />
                            no mês de <span>{month}/{year}!</span>
                        </div>
                    </SectionMessage>
                }
                

                <SectionCards>
                    <Link to={{ pathname: "https://123jus.notion.site/Operadores-5c0302a6aa594a5e8b4b6f3f6a2a5747" }} target="_blank">
                        <Card>
                            <IconVideo />
                            <span className='wellcomeCardTitle'>Tutorial</span>
                        </Card>
                    </Link>

                    <Card
                        onClick={() => setInviteProfessional(!inviteProfessional)}
                        className='invite'
                    >
                        <IconEmail />
                        <span className='wellcomeCardTitle'>Convidar</span>
                    </Card>
                </SectionCards>

            </div>

            {/* Modal convite profissional */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setInviteProfessional((inviteProfessional: any) => !inviteProfessional)}
                openModal={inviteProfessional}
                width="520px"
            >
                <WrapperModal>

                    <ModalTitle>
                        Convidar profissional
                        <div
                            onClick={() => setInviteProfessional(false)}
                        >
                            <IconWrong />
                        </div>
                    </ModalTitle>

                    <Divider />

                    <ModalContacts>
                        <div className='name'>
                            <span>Nome do profissional:</span>
                            <InputDefault
                                title=""
                                name="nome_profissional"
                                value={DTOInvite.nome}
                                onChangeText={(value: any) => handleOnChange('nome', value)}
                                className='name'
                            />
                        </div>

                        <div className='mail'>
                            <span>E-mail para contato:</span>
                            <InputDefault
                                title=""
                                name="email_profissional"
                                value={DTOInvite.email}
                                onChangeText={(value: any) => handleOnChange('email', value)}
                                className='name'
                            />
                        </div>

                        <div className='buttons'>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => setInviteProfessional(false)}
                            >
                                Cancelar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => inviteAProfessional()}                                
                            >
                                Enviar convite
                            </ButtonDefault>

                        </div>
                    </ModalContacts>
                </WrapperModal>

            </ModalDefault>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}
