import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    
    //global styles

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        list-style: none;
        text-decoration: none;
    }

    html {
        scroll-behavior: smooth;
    }

    body, input, textarea, button {
        font: 500 1rem Montserrat, sans-serif;
        color: #5A646E;
    }

    body {
        font-family: 'Montserrat', sans-serif !important;
        -webkit-font-smoothing: antialiased;
        background: #fff;
    }

    img, picture, video, svg {
        display: block;
        max-width: 100%;
    }

    input, button, textarea, select {
        font: inherit;
    }

    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
        font-family: Montserrat, sans-serif;
        color: #5A646E;
        font-weight: 500;
    }

    div > .text {
        font-family: 'Montserrat', sans-serif !important;
    }

    h1 {
        font-size: 1rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    button {
        cursor: pointer;
    }


    @media (max-width: 1080px) {
        html {
            font-size: 93.75%; // 15px
        }
    }
    @media (max-width: 720px) {
        html {
            font-size: 87.5%; // 14px
        }
    }

    #root {
        height: 100%;
    }
    .w-100{
        width: 100%;
    }    

    @media print {
        body {
            width: 1920px;
        }
        body * {
            visibility: hidden;
        }
        #area-print * {
            visibility: visible;
            background: black;
        }
        #area-print {
            width: 1920px;
            position: fixed;
            left: 0;
            top: 0;
            height: 100%;
            #tableManagerColumns {
                display: none;
            }
        }
    }

    .Title {
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #5A646E;
    }
    .SubTitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #8A99A8;
     }

   .Paragraph {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        color: #8A99A8;
   }
`;
