/* eslint-disable react/jsx-no-target-blank */
import { IconAttachment, IconDownload, IconUpload } from "../../icons";
import { WrapperFiles } from "./styles";
import Moment from 'react-moment';
import 'moment/locale/pt-br';

interface FileProps {
    title?: string,
    files?: any,
    edit?: any,
    sendFiles?: any,
    hideDate?: boolean,
}

export default function DemandFiles({title, files, edit, sendFiles, hideDate}: FileProps) {

    return (
        <WrapperFiles>
            <span>{title ? title : 'Arquivos:'}</span>
            {
                files?.length === 0 &&
                <div className="file">Sem arquivos anexados</div>
            }
            {
                files?.length > 0 &&
                files.map((row: any) => (
                    <div className={row.arquivo_final === 'sim' ? 'file last' : 'file'} key={row.length + 1}>
                        <div className="file-details">
                            <div className="icon-title">
                                <div className="icon-file">
                                    <IconAttachment />
                                </div>
                                <div className="title-file">
                                    {row.caminho}
                                </div>
                            </div>
                            {
                                !hideDate &&
                                <div className="date"><Moment locale='pt-br' format="DD/MM/YYYY">{files?.criado}</Moment></div>
                            }
                        </div>
                        <div className="download">
                            <a href={`https://backend.juridicoja.com/getfile/${row.caminho}`} target="_blank" rel="noreferrer">
                                <IconDownload />
                            </a>                            
                        </div>
                    </div>
                ))
            }
            {
                edit &&
                <div
                    className="add"
                    // onClick={() => console.log('Clikei no add files')}
                >
                    <IconUpload />
                    <input
                        type="file"
                        name="Files"
                        // accept="image/*"
                        className="file-input"
                        onChange={sendFiles}
                    />
                </div>
            }

        </WrapperFiles>
    )
}