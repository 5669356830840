import styled from "styled-components";

export const Container = styled.div`
    /* max-width: 1040px; */
    margin-top: 64px;
`

export const ChangePass = styled.div`
    
    .pass-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        
        h1 {
            font-size: 22px;
        }

        .buttons {
            display: flex;
            gap: 16px;
            width: 416px;
        }

        .save {
            max-width: 200px;
        }
    }

    .pass-text {
        margin-bottom: 24px;
        font-Size: 16px;
    }

    .email-pass {
        display: flex;
        gap: 16px;
    }

    .email {
        width: 100%;
    }
    .password {
        position: relative;
        width: 100%;
        .big {
            width: 100%;
        }
    }
    .icon {
        position: absolute;
        top:55%;
        right: 5%;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfaceText300};
            }
        }
    }
    
`
