import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 120px;
    border-radius: 8px;
    border: 2px solid transparent;

    &.free {
        background-color: ${({ theme }) => theme.colors.secondaryPool100};
        border: 2px solid ${({ theme }) => theme.colors.secondaryPool600};
    }

    &.pro {
        background-color: ${({ theme }) => theme.colors.secondaryGreen050};
        border: 2px solid ${({ theme }) => theme.colors.secondaryGreen600};
    }

    &.danger {
        background-color: ${({ theme }) => theme.colors.interfaceDanger100};
        border: 2px solid ${({ theme }) => theme.colors.interfaceDanger600};        
    }

`

export const IconContainer = styled.div`
    display: flex;
    align-items: center;
    width: 32px;
    height: 56px;

    &.danger {
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfaceDanger600};
            }
        }
    }
`

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 200px;
    height: 56px;
    font-weight: 500;
    font-size: 24px;
    color: ${({ theme }) => theme.colors.primaryBlue600};          
`

export const SubtitleContainer = styled.div`
    display: flex;
    align-items: center;
    width: 400px;
    height: 56px;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primaryBlue600};
    span {
        font-weight: 600;
    }
`

export const ButtonContainer = styled.div`
    width: 240px;
    height: 56px;    
`