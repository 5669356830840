import { useEffect } from 'react';
import { useState } from 'react';
import { useAuth } from '../../contexts/UserContext';
import api from '../../services/api';
import Header from '../Header';
import { IconFinancial, IconListDemand, IconListHome, IconListProps, IconOperator, IconProfessional } from '../icons';
import Sidebar from '../Sidebar';
import { Container, Main } from './styles';
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

interface ItemSubmenu {
    id: any;
    name: string;
    to: string;
    icon: any;
}

export interface PropsSidebar {
    id: any;
    name: string;
    to: string;
    notification?: number;
    icon: any;
    subMenu: ItemSubmenu[];
}

export default function Layout(props:any) {
    const { user, pageName, pageType } = useAuth();
    const [ counters, setcounters ] = useState<any>();   

    useEffect(() => {
        async function getCounters() {
            try {
                
                const response = await api.get('contadores');
                setcounters(response.data.result);
                
                if (response.data.status !== 'success') {
                    throw new Error('Não foi possível encontrar os contadores')
                }

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }
            
        }

        getCounters();
    }, [pageName])

    const adminData = [
        {
            id: 1,
            name: 'Dashboard',
            to: '/dashboard',
            // notification: 0,
            icon: <IconListHome />,
            subMenu: []
        },
        {
            id: 2,
            name: 'Propostas',
            to: '/propostas',
            notification: counters?.qtd_propostas ? counters.qtd_propostas : 0,
            icon: <IconListProps />,
            subMenu: [
                {
                    id: 1,
                    name: 'Dashboard',
                    to: '/newPage',
                    icon: <IconListHome />,
                },
                {
                    id: 2,
                    name: 'Dashboard',
                    to: '/home',
                    icon: <IconListHome />,
                },
                {
                    id: 3,
                    name: 'Dashboard',
                    to: '/home',
                    icon: <IconListHome />,
                },
            ]
        },
        {
            id: 3,
            name: 'Demandas',
            to: '/demandas',
            notification: counters?.qtd_demandas ? counters.qtd_demandas : 0,
            icon: <IconListDemand />,
            subMenu: []
        },
        {
            id: 4,
            name: 'Profissionais',
            to: '/profissionais',
            notification: counters?.qtd_profissionais ? counters.qtd_profissionais : 0,
            icon: <IconProfessional />,
            subMenu: []
        },
        {
            id: 5,
            icon: <IconOperator />,
            name: 'Operadores',
            to: '/operadores',
            notification: counters?.qtd_operadores ? counters.qtd_operadores : 0,
            subMenu: []
        },
        {
            id: 6,
            icon: <IconFinancial />,
            name: 'Financeiro',
            to: '/financeiro',
            // notification: 0,
            subMenu: []
        },
    ] 
    const operatorData = [
        {
            id: 1,
            name: 'Dashboard',
            to: '/dashboard',
            // notification: 0,
            icon: <IconListHome />,
            subMenu: []
        },
        {
            id: 2,
            name: 'Propostas',
            to: '/propostas',
            notification: counters?.qtd_propostas ? counters.qtd_propostas : 0,
            icon: <IconListProps />,
            subMenu: [
                {
                    id: 1,
                    name: 'Dashboard',
                    to: '/newPage',
                    icon: <IconListHome />,
                },
                {
                    id: 2,
                    name: 'Dashboard',
                    to: '/home',
                    icon: <IconListHome />,
                },
                {
                    id: 3,
                    name: 'Dashboard',
                    to: '/home',
                    icon: <IconListHome />,
                },
            ]
        },
        {
            id: 3,
            name: 'Demandas',
            to: '/demandas',
            notification: counters?.qtd_demandas ? counters.qtd_demandas : 0,
            icon: <IconListDemand />,
            subMenu: []
        },
        {
            id: 4,
            name: 'Profissionais',
            to: '/profissionais',
            notification: counters?.qtd_profissionais ? counters.qtd_profissionais : 0,
            icon: <IconProfessional />,
            subMenu: []
        },          
    ]
    const data = [
        {
            id: 1,
            name: 'Dashboard',
            to: '/dashboard',
            // notification: 0,
            icon: <IconListHome />,
            subMenu: []
        },
        {
            id: 2,
            name: 'Propostas',
            to: '/propostas',
            notification: counters?.qtd_propostas ? counters.qtd_propostas : 0,
            icon: <IconListProps />,
            subMenu: [
                {
                    id: 1,
                    name: 'Dashboard',
                    to: '/newPage',
                    icon: <IconListHome />,
                },
                {
                    id: 2,
                    name: 'Dashboard',
                    to: '/home',
                    icon: <IconListHome />,
                },
                {
                    id: 3,
                    name: 'Dashboard',
                    to: '/home',
                    icon: <IconListHome />,
                },
            ]
        },
        {
            id: 3,
            name: 'Demandas',
            to: '/demandas',
            notification: counters?.qtd_demandas ? counters.qtd_demandas : 0,
            icon: <IconListDemand />,
            subMenu: []
        },
    ]    

    return (
        <Container>
            <Sidebar
                list={
                    user?.profile === 'admin' ? adminData 
                    : user?.profile === 'operador' ? operatorData 
                    : user?.profile === 'comum' && data
                }
            />

            <Main>
                <Header title={pageName ? pageName : 'Inicial'} type={pageType} />
                {props.children}
            </Main>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}
