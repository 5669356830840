import styled from 'styled-components';

interface Props {
    openModal: boolean
    width?: string;
    heigth?: any,
}

export const ContainerOverlay = styled.div<Props>`
  background: rgba(0, 0, 0, 0.5);
  opacity: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${props => props.openModal ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const Container = styled.div<Props>`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: auto;
    max-height: ${props => props.heigth ? props.heigth : '90vh'};
    background: ${(props) => props.theme.colors.white};
    border-radius: 16px;
    padding: 16px;
    width: ${props => props.width ? props.width : '400px'};
    overflow: initial;
    margin-top: 65vh;
    margin-left: 73%;


    scrollbar-width: thin;
    scrollbar-color: ${(props) => props.theme.colors.interfaceText200} ${(props) => props.theme.colors.primaryBlue100};
    &::-webkit-scrollbar {
      width: 12px;
      background: ${(props) => props.theme.colors.interfaceText200};
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.primaryBlue100};
      border-radius: 12px;
    }

    @media (max-width: 800px) {
      margin-left: auto;
      margin-right: auto;
    }
`;
