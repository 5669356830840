import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }


  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

const appearFromRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const Container = styled.div`
  height: fit-content;
  /* display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clipPath: ellipse(120% 100% at 50.00% 0%); */
`;

export const AreaHeader = styled.div`
  display: flex;
  width: 100%;
  height: 5rem;
  margin: 0 auto;
  z-index: 2;
  position: fixed;
  top: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 678px;
  padding-top: 5rem;
  padding-bottom: 12rem;
  position: relative;
  align-items: center;
  display: block;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.primaryBlue500};
  clip-path: ellipse(120% 100% at 50% 0%);
  transform: translateZ(0);

  @media (max-width: 800px) {
    clip-path: none;   
    height: fit-content;
    padding-bottom: 2rem;
  }
`;

export const AreaSite = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 6.25rem;
  max-width: 1400px;
  z-index: 2;
`;

export const AreaImageContact = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;

  /* animation: ${appearFromLeft} 0.7s; */

  img {
    max-width: 560px;
    align-self: flex-end;
    margin-right: -55px;
    margin-bottom: -13px;
  }

  .square {
    width: calc(100% - (100% - 1040px));
    height: 194px;
    background-color: ${({ theme }) => theme.colors.interfacePure0};
    z-index: 99;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -112px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`

export const Image = styled.img`
  width: 100%;  
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  animation: ${appearFromRight} 0.7s;

  .sent {
    padding-left: 45px;
    .title {
      font-size: 40px;
      font-weight: 700;
      line-height: 46px;
      color: ${({ theme }) => theme.colors.secondaryGreen100};
      margin-bottom: 32px;
    }

    .subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 150%;
      color: ${({ theme }) => theme.colors.interfacePure0};
    }
  }

  @media (max-width: 800px) {
    width: 85%;
    margin-left: auto;
    margin-right: auto;

    .sent {
      margin: 0 auto;
      padding-left: 0;
    }
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-left: 45px;
  max-width: 512px;

  label {
    .title {
      color: ${({ theme }) => theme.colors.interfacePure0};
      font-size: 14px;
      font-weight: 500;
    }
  }

  .first {
    display: flex;
    gap: 16px;       
    label {
      width: 100%;
    }
  }

  .message {
    .title {
      color: ${({ theme }) => theme.colors.interfacePure0};
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
    }
    
    .text-area {
      width: 512px;
      height: 80px;
      border-radius: 8px;
      padding: 12px 16px;
      resize: none;
      outline: none;
      /* color: ${({ theme }) => theme.colors.interfaceText300};
      font-size: 1rem;
      font-weight: 400; */
      &textarea::placeholder {
        color: red;
      }

    }
    
  }

  .buttons {
    display: flex;
    gap: 16px;    
  }

  @media (max-width: 800px) {
    margin-left: 0;

    .first {
      flex-direction: column;
    }
    
    .message {
      .text-area {
        width: 100%;
      }
    }

    .buttons {
      flex-direction: column;

      .captcha {
        margin: 0 auto;         
      }
    }
  }
`

export const FormButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 48px;
  background-color: ${({ theme }) => theme.colors.primaryOrange500};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: all .3s;

  font-size: 18px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.interfacePure0};

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`