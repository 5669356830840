/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";

// Libraries
import toast, { Toaster } from 'react-hot-toast';

// Styles
import { ModalPrecification, Wrapper } from "./styles";

// Components
import ButtonDefault from "../form/ButtonDefault";
import ModalDefault from "../ModalDefault";
import InputDefault from "../form/InputDefault";
import InputMoney from "../form/InputMoney";

// Icons
import { IconClose, IconPlus, IconTrash } from "../icons";

// Context
import { useAuth } from "../../contexts/UserContext";

// Services
import api from "../../services/api";

interface UpdatePriceProps {
    setUpdatePrices?: any
    updatePrices?: any,
    userId?: any,
    editProfile?: any,
}

export default function Prices({setUpdatePrices, userId, editProfile}: UpdatePriceProps) {

    const { user } = useAuth();
    // const [ advancePrecificationError, setAdvancePrecificationError] = useState<any>([
    //     {
    //         titulo: false,
    //         outros_servicos: {
    //             valor_diligencia: false,
    //             valor_protocolo: false
    //         },
    //         servicos: [
    //             {
    //                 key: 0,
    //                 valor_advogado: false,
    //             },
    //             {
    //                 key: 0,
    //                 valor_preposto: false,
    //             },
    //             {
    //                 key: 0,
    //                 valor_total: false,
    //             },
    //             {
    //                 key: 1,
    //                 valor_advogado: false,
    //             },
    //             {
    //                 key: 1,
    //                 valor_preposto: false,
    //             },
    //             {
    //                 key: 1,
    //                 valor_total: false,
    //             },
    //             {
    //                 key: 2,
    //                 valor_advogado: false,
    //             },
    //             {
    //                 key: 2,
    //                 valor_preposto: false,
    //             },
    //             {
    //                 key: 2,
    //                 valor_total: false,
    //             },
    //             {
    //                 key: 3,
    //                 valor_advogado: false,
    //             },
    //             {
    //                 key: 3,
    //                 valor_preposto: false,
    //             },
    //             {
    //                 key: 3,
    //                 valor_total: false,
    //             },
    //         ]
    //     }
    // ]);
    const [ precificationItem, setPrecificationItem ] = useState<any>();
    const [ openModalConfirmation, setOpenModalConfirmation ] = useState<boolean>(false);
    const [ excludePrecification, setExcludePrecification ] = useState<any>();

    const ServicesDefault =
    {
        id: 1,
        titulo: '',
        servicos: [
            {
                id_servico: 2,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',
            },
            {
                id_servico: 18,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',
            },
            {
                id_servico: 1,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',
            },
            {
                id_servico: 17,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',
            },
            {
                id_servico: 3,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',
            },
            {
                id_servico: 19,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',
            },
        ],
        outros_servicos: {
            valor_diligencia: '',
            valor_protocolo: ''
        }
    };

    const [DTOServices, setDTOServices] = useState<any>([ServicesDefault]);

    function handleOnChangeServices(id: any, name: any, value: any, keyObj: any) {

        let newValue = value.replace(/\D/g, '');
        
        if (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1)
        }

        // if (newValue.length <= 3) {
        //     // newValue = newValue + '.00'
        //     const newDto = DTOServices
        //     if (id > 0) {
        //         const filterDto = newDto.map((row: any) => row.servicos[keyObj]).filter((obj: any) => obj.id_servico === id);
        //         filterDto[0][name] = Number(newValue)
        //     }

        //     newDto.map((row: any, key: any) => {
        //         if (key === keyObj && name === 'titulo') {
        //             row.titulo = value
        //         } else if (key === keyObj && name === 'protocolo') {
        //             row.outros_servicos.valor_protocolo = Number(newValue)
        //         } else if (key === keyObj && name === 'diligencia') {
        //             row.outros_servicos.valor_diligencia = Number(newValue)
        //         }
        //         return null
        //     })            

        // } else {
            let txtArray = newValue.split('')
            txtArray.splice(txtArray.length - 2, 0, ".");
            var correctedValue = txtArray.join('')

            const newDto = DTOServices

            if (id > 0) {
                const filterDto = newDto.map((row: any) => row.servicos[keyObj]).filter((obj: any) => obj.id_servico === id);
                filterDto[0][name] = Number(correctedValue)
            }

            newDto.map((row: any, key: any) => {
                if (key === keyObj && name === 'titulo') {
                    row.titulo = value
                } else if (key === keyObj && name === 'protocolo') {
                    row.outros_servicos.valor_protocolo = Number(correctedValue)
                } else if (key === keyObj && name === 'diligencia') {
                    row.outros_servicos.valor_diligencia = Number(correctedValue)
                }
                return null
            })    
            
        // }     
        
        setUpdatePrices(DTOServices)

    };  

    useEffect(() => {

        handleRemovePrecification(precificationItem);

    }, [excludePrecification])


    function handleAddPrecification() {

        DTOServices.push(ServicesDefault)
        setDTOServices([...DTOServices])

    };

    function confirmDeletePrecification(item: any) {

        setOpenModalConfirmation(true)
        setPrecificationItem(item)

    };

    function handleRemovePrecification(indice: number) {
        if (excludePrecification === true) {

            const newDTO: any = []
            DTOServices.map((row: any, key: number) => {
                if (key !== indice) newDTO.push(row)
            })
            setDTOServices([...newDTO])
            setOpenModalConfirmation(false)
            setExcludePrecification(false)
        }

    }

    useEffect(() => {
        async function getServicesData() {
            try {
                if (user.profile !== 'comum') {
                    
                    const response = await api.get('precificacao?ordem=criado&tipo_ordem=asc&id_usuario=' + userId)
                    setDTOServices(response.data.result)                    
                    
                    if (response.data.status !== 'success') {
                        throw new Error('Não foi possível encontrar os dados')
                    }

                }   else {

                    const response = await api.get('precificacao?ordem=criado&tipo_ordem=asc&id_usuario=' + user.id)
                    setDTOServices(response.data.result)
                    
                    if (response.data.status !== 'success') {
                        throw new Error('Não foi possível encontrar os dados')
                    }

                }
            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
            }
        }

        getServicesData();
        
    }, [])

    return (
        <Wrapper>

            <form className='services-form' id="services-form">

                {DTOServices?.map((service: any, serviceKey: number) =>
                    <div className={`services ${serviceKey > 0 ? 'line' : 'none'}`} key={service?.id_precificacao}>
                        <div className="services-top">
                            <InputDefault
                                title="Título da precificação:"
                                name="titulo"
                                placeholder="Ex: Região Metropolitana"
                                value={service.titulo}
                                onChangeText={(value) => handleOnChangeServices(0, 'titulo', value, serviceKey)}
                                className='big'                                
                                disabled={editProfile ? false : true}
                            />

                            <button
                                type="button"
                                onClick={() => confirmDeletePrecification(serviceKey)}
                            >
                                <IconTrash /> Excluir precificação
                            </button>
                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">AUDIÊNCIA TRABALHISTA PRESENCIAL</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Somente Advogado:"
                                    name="presencial1"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[0]?.valor_advogado ? service?.servicos[0]?.valor_advogado.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(2, 'valor_advogado', value, 0)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Somente Preposto:"
                                    name="preposto1"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[0]?.valor_preposto ? service.servicos[0].valor_preposto.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(2, 'valor_preposto', value, 0)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Advogado + Preposto:"
                                    name="advprep1"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[0]?.valor_total ? service.servicos[0].valor_total.toFixed(2) : ''} 
                                    onChangeText={(value) => handleOnChangeServices(2, 'valor_total', value, 0)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                            </div>

                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">AUDIÊNCIA TRABALHISTA VIRTUAL</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Somente Advogado:"
                                    name="virtual1"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[1]?.valor_advogado ? service.servicos[1].valor_advogado.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(18, 'valor_advogado', value, 1)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Somente Preposto:"
                                    name="preposto2"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[1]?.valor_preposto ? service.servicos[1].valor_preposto.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(18, 'valor_preposto', value, 1)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Advogado + Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="advprep2"
                                    value={service.servicos[1]?.valor_total ? service.servicos[1].valor_total.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(18, 'valor_total', value, 1)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                            </div>

                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">AUDIÊNCIA CÍVEL PRESENCIAL</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Somente Advogado:"
                                    name="presencial2"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[2]?.valor_advogado ? service.servicos[2].valor_advogado.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(1, 'valor_advogado', value, 2)} 
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Somente Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="preposto3"
                                    value={service.servicos[2]?.valor_preposto ? service.servicos[2].valor_preposto.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(1, 'valor_preposto', value, 2)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Advogado + Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="advprep3"
                                    value={service.servicos[2]?.valor_total ? service.servicos[2].valor_total.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(1, 'valor_total', value, 2)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                            </div>
                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">AUDIÊNCIA CÍVEL VIRTUAL</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Somente Advogado:"
                                    name="virtual2"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[3]?.valor_advogado ? service.servicos[3].valor_advogado.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(17, 'valor_advogado', value, 3)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Somente Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="preposto4"
                                    value={service.servicos[3]?.valor_preposto ? service.servicos[3].valor_preposto.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(17, 'valor_preposto', value, 3)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Advogado + Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="advprep4"
                                    value={service.servicos[3]?.valor_total ? service.servicos[3].valor_total.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(17, 'valor_total', value, 3)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                            </div>
                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">AUDIÊNCIA PENAL PRESENCIAL</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Somente Advogado:"
                                    name="presencial3"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[4]?.valor_advogado ? service?.servicos[4]?.valor_advogado?.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(3, 'valor_advogado', value, 4)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Somente Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="preposto4"
                                    value={service.servicos[4]?.valor_preposto ? service?.servicos[4]?.valor_preposto?.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(3, 'valor_preposto', value, 4)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Advogado + Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="advprep4"
                                    value={service.servicos[4]?.valor_total ? service?.servicos[4]?.valor_total?.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(3, 'valor_total', value, 4)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                            </div>
                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">AUDIÊNCIA PENAL VIRTUAL</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Somente Advogado:"
                                    name="virtual3"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.servicos[5]?.valor_advogado ? service.servicos[5].valor_advogado.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(19, 'valor_advogado', value, 5)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Somente Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="preposto4"
                                    value={service.servicos[5]?.valor_preposto ? service.servicos[5].valor_preposto.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(19, 'valor_preposto', value, 5)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Advogado + Preposto:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="advprep4"
                                    value={service.servicos[5]?.valor_total ? service.servicos[5].valor_total.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(19, 'valor_total', value, 5)}
                                    className='mid'
                                    disabled={editProfile ? false : true}
                                />
                            </div>
                        </div>

                        <div className="services-hearing">
                            <p className="hearing-title">OUTROS SERVIÇOS</p>

                            <div className="hearing-fields">
                                <InputMoney
                                    title="Diligências:"
                                    name="diligencias"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    value={service.outros_servicos?.valor_diligencia ? service.outros_servicos?.valor_diligencia?.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(0, 'diligencia', value, serviceKey)}
                                    className='small'
                                    disabled={editProfile ? false : true}
                                />
                                <InputMoney
                                    title="Protocolos:"
                                    placeholder="R$ 00,00"
                                    mask="R$ 999.999,99"
                                    name="protocolos"
                                    value={service.outros_servicos?.valor_protocolo ? service.outros_servicos?.valor_protocolo?.toFixed(2) : ''}
                                    onChangeText={(value) => handleOnChangeServices(0, 'protocolo', value, serviceKey)}
                                    className='small'
                                    disabled={editProfile ? false : true}
                                />
                            </div>
                        </div>

                    </div>
                )}

                <div className="dot-border" onClick={handleAddPrecification}><IconPlus /> Adicionar precificação</div>
            </form>

            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalConfirmation((openModalConfirmation) => !openModalConfirmation)}
                openModal={openModalConfirmation}
                width="45%"
            >
                <ModalPrecification>
                    <div className="header">
                        <p>Deseja excluir a precificação?</p>
                        <div
                            className="close"
                            onClick={() => setOpenModalConfirmation(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="main">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalConfirmation(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setExcludePrecification(true)}
                        >
                            Confirmar
                        </ButtonDefault>
                    </div>
                </ModalPrecification>
            </ModalDefault>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>

        </Wrapper>
    )
}