import styled from "styled-components";

export const Container = styled.div`
    width: 1040px;
    max-width: 90%;
    height: auto;
    min-height: 100vh;
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0};     
    
    .toasted {
        z-index: 99999;        
    }

    .warning {
        display: flex;
        gap: 8px;        
    }
`

export const Header = styled.div`
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 48px;   

    .status {
        display: flex;
        justify-content: space-between;
        width: 480px;
        position: relative;
        .status-square {
            width: 55px;
            display: flex;
            flex-direction: column;
            gap: 16px;
            
            p {
                font-size: 14px;
                font-weight: 400;
                white-space: nowrap;  
                &.on {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.primaryBlue600};
                }
            }
            &.first {
                white-space: nowrap;
                p {
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.primaryBlue600};
                }
            }
            .second {
                align-self: center;
            }
            .third {
                align-self: center;
            }
            .fourth {
                align-self: flex-end;
            }
        }
        .square {
            width: 48px;
            height: 48px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};   
            z-index: 5;
            display: flex;
            align-items: center;
            justify-content: center;  
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
            }
        }
        .active {
            background: ${({ theme }) => theme.colors.primaryOrange50};
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryOrange700};
                }
            }
        }
    }
`

export const Main = styled.div`

    .top {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 32px;

        .two {
            height: 40px;
            .register {
                height: 40px;
            }
        }
        
        .three {
            .register {
                .subtitle {
                    font-size: 18px;
                    font-weight: 400;
                    span {
                        font-size: 18px;
                        font-weight: 600;
                    }
                }
            }
        }

        .four {
            height: 40px;
        }

        .register {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            height: 80px;
            max-width: 680px;
            h3 {
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                span {
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 700;
                    color: ${({ theme }) => theme.colors.primaryOrange700};
                }
            }
            &.underline {
                p {
                font-size: 18px;
                font-weight: 400;
                line-height: 27px;
                    span {
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        cursor: pointer;
                    }
                }
            }
            &.services {
                .title {
                    margin-bottom: -10px;
                }
                p {
                    span {
                        font-weight: 500;
                    }
                }
            }
            &.permission {
                max-width: 800px;
                margin-bottom: 32px;
            }
        }

        .subtitle, p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: ${({ theme }) => theme.colors.interfaceText400};
                span {
                    font-weight: 500;
                }
        }

        .attention {
            border: 1px solid ${({ theme }) => theme.colors.primaryOrange300};
            width: 333px;
            height: 88px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10%;
            border-radius: 8px;
            position: relative;

            .star {
                position: absolute;
                top: 19%;
                left: 4%;
            }

            span {
                margin-top: 5px;
                color: ${({ theme }) => theme.colors.primaryOrange600};
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
            }

            p {
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
            }

        }
    }

    form {
        padding-bottom: 140px;
        .name,
        .process {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 24px 16px;
            margin-bottom: 24px;
            max-width: 864px;
            .big {
                width: 100%;
                max-width: 424px;
                &:hover {
                    border-color: red;
                }
            }           

            .small {
                width: 100%;
                max-width: 204px;
                align-self: flex-start;
                &:last-child {
                    width: calc(35% - 24px);
                }
            }            
        }

        .bottom {
            max-width: 864px;
            margin-top: 70px;
            margin-bottom: 24px;

            .subtitle {
                color: ${({ theme }) => theme.colors.interfaceText500};
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 32px;

                span {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }

            .subtype, 
            .professional {
                max-width: 864px;
                display: flex;
                gap: 16px;
                margin-bottom: 24px;
                .label-state {
                    width: 50%;
                    position: relative;
                    p {
                        margin-bottom: 8px;
                        font-size: 14px;
                        font-weight: 500;
                        color: ${({ theme }) => theme.colors.interfaceText400};
                    }  
                    .error {
                        position: absolute;
                        display: block;
                        top: 5%;
                        right: 5%;
                        color: ${({ theme }) => theme.colors.interfaceDanger};
                    }

                    .date {
                        width: 100%;
                        height: 48px;

                        .react-datetime-picker {
                            width: 100%;
                            height: 48px;
                            display: inline-flex;
                            position: relative;
                        }
                        .react-datetime-picker,
                        .react-datetime-picker *,
                        .react-datetime-picker *:before,
                        .react-datetime-picker *:after {
                            -moz-box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                        .react-datetime-picker--disabled {
                            background-color: #f0f0f0;
                            color: #6d6d6d;
                        }
                        .react-datetime-picker__wrapper {
                            display: flex;
                            flex-grow: 1;
                            flex-shrink: 0;
                            border: thin solid #EAEEF3;
                            border-radius: 8px;
                            padding: 8px 16px;

                        }
                        .react-datetime-picker__inputGroup {
                            min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                            flex-grow: 1;
                            padding: 0 2px;
                        }
                        .react-datetime-picker__inputGroup__divider {
                            padding: 8px 0;
                            white-space: pre;
                            &:nth-child(7) {
                                padding: 8px;
                                &::before {
                                    content: '|';
                                }
                            }
                        }
                        .react-datetime-picker__inputGroup__input {
                            min-width: 0.54em;
                            height: calc(100% - 3px);
                            position: relative;
                            padding: 1px;
                            border: 0;
                            background: none;
                            font: inherit;
                            font-size: 16px;
                            font-weight: 500;
                            color: ${({ theme }) => theme.colors.interfaceText500};
                            box-sizing: content-box;
                            -moz-appearance: textfield;
                        }
                        .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
                        .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        .react-datetime-picker__inputGroup__input:invalid {
                            background: rgba(255, 0, 0, 0.1);
                        }
                        .react-datetime-picker__inputGroup__input--hasLeadingZero {
                            margin-left: -0.54em;
                            padding-left: calc(1px +  0.54em);
                        }
                        .react-datetime-picker__inputGroup__amPm {
                            font: inherit;
                            -moz-appearance: menulist;
                        }
                        .react-datetime-picker__button {
                            border: 0;
                            background: transparent;
                            padding: 4px 6px;
                        }
                        .react-datetime-picker__button:enabled {
                            cursor: pointer;
                        }
                        .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
                        .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                            stroke: #0078d7;
                        }
                        .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                            stroke: #6d6d6d;
                        }
                        .react-datetime-picker__button svg {
                            display: inherit;
                        }
                        .react-datetime-picker__calendar,
                        .react-datetime-picker__clock {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            z-index: 1;
                        }
                        .react-datetime-picker__calendar--closed,
                        .react-datetime-picker__clock--closed {
                            display: none;
                        }
                        .react-datetime-picker__calendar {
                            width: 350px;
                            max-width: 100vw;
                        }
                        .react-datetime-picker__calendar .react-calendar {
                            border-width: thin;
                        }
                        .react-datetime-picker__clock {
                            width: 200px;
                            height: 200px;
                            max-width: 100vw;
                            padding: 25px;
                            background-color: white;
                            border: thin solid #a0a096;
                        }                        
                    }

                    .date-error {
                        width: 100%;
                        height: 48px;

                        .react-datetime-picker {
                            width: 100%;
                            height: 48px;
                            display: inline-flex;
                            position: relative;
                        }
                        .react-datetime-picker,
                        .react-datetime-picker *,
                        .react-datetime-picker *:before,
                        .react-datetime-picker *:after {
                            -moz-box-sizing: border-box;
                            -webkit-box-sizing: border-box;
                            box-sizing: border-box;
                        }
                        .react-datetime-picker--disabled {
                            background-color: #f0f0f0;
                            color: #6d6d6d;
                        }
                        .react-datetime-picker__wrapper {
                            display: flex;
                            flex-grow: 1;
                            flex-shrink: 0;
                            border: thin solid #FDB141;
                            border-radius: 8px;
                            padding: 8px 16px;

                        }
                        .react-datetime-picker__inputGroup {
                            min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                            flex-grow: 1;
                            padding: 0 2px;
                        }
                        .react-datetime-picker__inputGroup__divider {
                            padding: 8px 0;
                            white-space: pre;
                            &:nth-child(7) {
                                padding: 8px;
                                &::before {
                                    content: '|';
                                }
                            }
                        }
                        .react-datetime-picker__inputGroup__input {
                            min-width: 0.54em;
                            height: calc(100% - 3px);
                            position: relative;
                            padding: 1px;
                            border: 0;
                            background: none;
                            font: inherit;
                            font-size: 16px;
                            font-weight: 500;
                            color: ${({ theme }) => theme.colors.interfaceText500};
                            box-sizing: content-box;
                            -moz-appearance: textfield;
                        }
                        .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
                        .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }
                        .react-datetime-picker__inputGroup__input:invalid {
                            background: rgba(255, 0, 0, 0.1);
                        }
                        .react-datetime-picker__inputGroup__input--hasLeadingZero {
                            margin-left: -0.54em;
                            padding-left: calc(1px +  0.54em);
                        }
                        .react-datetime-picker__inputGroup__amPm {
                            font: inherit;
                            -moz-appearance: menulist;
                        }
                        .react-datetime-picker__button {
                            border: 0;
                            background: transparent;
                            padding: 4px 6px;
                        }
                        .react-datetime-picker__button:enabled {
                            cursor: pointer;
                        }
                        .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
                        .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                            stroke: #0078d7;
                        }
                        .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                            stroke: #6d6d6d;
                        }
                        .react-datetime-picker__button svg {
                            display: inherit;
                        }
                        .react-datetime-picker__calendar,
                        .react-datetime-picker__clock {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            z-index: 1;
                        }
                        .react-datetime-picker__calendar--closed,
                        .react-datetime-picker__clock--closed {
                            display: none;
                        }
                        .react-datetime-picker__calendar {
                            width: 350px;
                            max-width: 100vw;
                        }
                        .react-datetime-picker__calendar .react-calendar {
                            border-width: thin;
                        }
                        .react-datetime-picker__clock {
                            width: 200px;
                            height: 200px;
                            max-width: 100vw;
                            padding: 25px;
                            background-color: white;
                            border: thin solid #a0a096;
                        }                        
                    }
                }
            }

            .address {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                gap: 16px;
                max-width: 864px;

                .big {
                    max-width: 424px;
                }

                .mid {
                    width: 272px;
                }

                .small {
                    max-width: 136px;
                }

                .num {
                    width: 96px;
                }

                .comp {
                    width: 312px;                    
                }
                
                .pages-title {
                    display: flex;
                    flex-direction: column;

                    .title-error {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        position: relative;

                        span {
                            position: absolute;
                            display: block;
                            top: 20%;
                            right: 5%;
                            color: ${({ theme }) => theme.colors.interfaceDanger};
                        }
                    }
                    
                    p {
                        font-size: 14px;
                        font-weight: 500;
                        width: 100%;
                        color: #5A646E;
                    }
                    
                    .page-input {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        position: relative;

                        .page {
                            width: 116px;                           
                        }
                        
                    }

                }                
            }
        }

        .state {
            display: flex;
            gap: 16px;
            max-width: 864px;
            margin-bottom: 24px;

            .label-state {
                width: 50%;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }
        }

        .fone {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .small {
                width: 32%;
            }

            .big {
                width: 65%;
            }
        }

        .address {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            justify-content: space-between;
            .label {
                width: 24%;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }                
            }

            .small {
                width: 23.5%;
            }
            .big {
                width: 49%;
            }
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }

        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }

`

export const Hr1 = styled.hr`

    width: 100%;
    max-width: 460px;
    height: 1px;
    border: none;
    background: ${({ theme }) => theme.colors.interfaceText200};
    position: absolute;
    top: 25px;
    left: 10px;
    transform: translateY(-50%);
    z-index: 1;
    &.first {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 20%, #C5CED7 20%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 20%, #C5CED7 20%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 20%,#C5CED7 20%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.second {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 50%, #C5CED7 50%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 50%, #C5CED7 50%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 50%,#C5CED7 50%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.third {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 75%, #C5CED7 75%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 75%, #C5CED7 75%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 75%,#C5CED7 75%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
    &.fourth {
        background: linear-gradient(to right,  #F18F00 0%,#F18F00 100%, #C5CED7 100%, #C5CED7 100%);
        background: -moz-linear-gradient(left,  #F18F00 0%, #F18F00 100%, #C5CED7 100%, #C5CED7 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  #F18F00 0%,#F18F00 100%,#C5CED7 100%,#C5CED7 100%); /* Chrome10-25,Safari5.1-6 */
    }
`

export const Footer = styled.div`
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;  
    background: ${({ theme }) => theme.colors.interfacePure0};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    z-index: 0;
    .container {
        width: 1040px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;

        .back {
            width: 160px;
        }
    }
    .buttons {
        display: flex;
        gap: 16px;
        margin-left: auto;
        button {
            width: 160px;
        }
        .continue {
            background: ${({ theme }) => theme.colors.primaryOrange600};
        }
    }

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;

        .progressbar {
            height: 8px;
            width: 170px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};
            position: relative;
            .percent {
                transition: all .2s;
                width: 34px;
                height: 8px;
                position: absolute;
                border-radius: 8px;
                top: 0;
                left: 0;
                background: ${({ theme }) => theme.colors.primaryBlue400};
                &.two {
                    width: 85px;
                }
                &.three {
                    width: 135px;
                }                
            }
        }
    }
`