import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1040px;
    height: auto;
    /* min-height: 100vh; */
    /* background: ${({ theme }) => theme.colors.interfacePure0};  */
    /* padding-bottom: 190px; */
    position: relative;
    margin-top: -90px;
       
    
    .toasted {
        z-index: 99999;        
    }  
    
    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 200px;
        transform: translateX(-50%);
    }
`

export const StatusHeader = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 34px;
`

export const Status = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    width: 600px;
    height: 24px;
    /* padding-bottom: 8px; */

    .type {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        padding-right: 40px;

        svg {
            path {
                fill: ${({ theme }) => theme.colors.secondaryPool600};
            }
        }

        &.delayed {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceDanger300};
                }
            }
        }
    }
`

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    width: 320px;
    
    .save {
        width: fit-content;
        padding: 16px;
        svg {
            width: 18px;
            height: 18px;
            path {
                width: 100%;
                height: 100%;
                fill: ${({ theme }) => theme.colors.interfacePure0};
            }
        }
    }

    .cancel {
        max-width: 105px;
        height: 56px;
    }

    .filter {
        width: 207px;
    }

    .clock {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        height: 56px;
        background-color: ${({ theme }) => theme.colors.interfacePure0};
        border-radius: 8px;
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: scale(1.1);
        }
    }
`

export const DropDownMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 56px;
    height: 56px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    position: relative;
    transition: all .2s;
    &:hover {
        /* transform: scale(1.1); */
        background: ${({ theme }) => theme.colors.interfaceText100};
    }

    .hidden-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 16px;
        width: 272px;
        height: fit-content;
        border-radius: 8px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        cursor: default;
        position: absolute;
        top: 64px;
        right: 0;
        z-index: 99;
        filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
        &.no-show {
            display: none;
        }

        .button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            width: 100%;
            height: 40px;
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.interfaceText400};
            cursor: pointer;
            font-weight: 500;
            font-size: 16px;
            transition: all .2s;
            padding: 11px;
            &:hover {
                background: ${({ theme }) => theme.colors.interfacePure100};
            }
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryOrange700};
                }
            }

            &.money {
                svg {
                    path {
                        fill: ${({ theme }) => theme.colors.primaryBlue300};
                    }
                }
            }
        }
    }

    .hidden-history {
        display: flex;
        flex-direction: column;
        /* justify-content: space-around; */
        /* gap: 32px; */
        width: 512px;
        height: 502px;
        border-radius: 8px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        filter: drop-shadow(3px 4px 7px rgba(20, 20, 23, 0.3));
        cursor: default;
        position: absolute;
        top: 64px;
        right: 0;
        z-index: 99;
        &.no-show {
            display: none;
        }        
    }


`

export const DemandDetails = styled.div`
    display: flex;
    gap: 16px;
`

export const DemandLeft = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 688px;
`

export const DemandAdress = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    .title-service {
        font-size: 36px;
        font-weight: 300;
        color: ${({ theme }) => theme.colors.primaryBlue500};        
    }

    .countie-process {
        display: grid;
        grid-template-columns: 327px 1fr;
        
        .title-infos {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            gap: 8px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;

            span {
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.primaryBlue500};
            }

            .info {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 8px;
                background: ${({ theme }) => theme.colors.background};
                width: fit-content;
                color: ${({ theme }) => theme.colors.interfaceText500};
                font-weight: 400;
                text-transform: capitalize;
            }
        }
    }

    .address {
        display: flex;
        flex-direction: column;
        gap: 8px;

        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .address-info {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;

            .info {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px;
                border-radius: 8px;
                background: ${({ theme }) => theme.colors.background};
                width: fit-content;
                color: ${({ theme }) => theme.colors.interfaceText500};
                font-weight: 400;
            }
        }

    }

    .virtual-address {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;

        .title-infos {
            display: flex;
            flex-direction: column;
            align-self: flex-start;
            gap: 8px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;
            width: 100%;

            span {
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.primaryBlue500};
            }

            .info {
                height: fit-content;
                word-wrap: break-word;
                padding: 8px;
                border-radius: 8px;
                background: ${({ theme }) => theme.colors.background};
                color: ${({ theme }) => theme.colors.interfaceText500};
                font-weight: 400;                
            }
        }    
    }

    .line {
        display: flex;
        gap: 16px;

        .label-state {
            width: 100%;
            p {
                margin-bottom: 8px;
                font-size: 14px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText400};
            }  
            .error {
                position: absolute;
                display: block;
                top: 5%;
                right: 5%;
                color: ${({ theme }) => theme.colors.interfaceDanger};
            }

            .no-data {
                margin-top: 20px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.interfaceText400};
            }

            .css-1s3jnk5-singleValue {
                text-transform: capitalize;
            }
        }

        .mid {
            width: 100%;
            &.highlight {
                input {
                    border: 1px solid ${({ theme }) => theme.colors.secondaryPool500};
                }
            }
        }

        .big {
            width: 100%;
        }

        .date {
            width: 100%;
            height: 48px;

            .react-datetime-picker {
                width: 100%;
                height: 48px;
                display: inline-flex;
                position: relative;
            }
            .react-datetime-picker,
            .react-datetime-picker *,
            .react-datetime-picker *:before,
            .react-datetime-picker *:after {
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
            }
            .react-datetime-picker--disabled {
                background-color: #f0f0f0;
                color: #6d6d6d;
            }
            .react-datetime-picker__wrapper {
                display: flex;
                flex-grow: 1;
                flex-shrink: 0;
                border: thin solid #EAEEF3;
                border-radius: 8px;
                padding: 8px 16px;

            }
            .react-datetime-picker__inputGroup {
                min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                flex-grow: 1;
                padding: 0 2px;
            }
            .react-datetime-picker__inputGroup__divider {
                padding: 8px 0;
                white-space: pre;
                &:nth-child(7) {
                    padding: 8px;
                    &::before {
                        content: '|';
                    }
                }
            }
            .react-datetime-picker__inputGroup__input {
                min-width: 0.54em;
                height: calc(100% - 3px);
                position: relative;
                padding: 1px;
                border: 0;
                background: none;
                font: inherit;
                font-size: 16px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.interfaceText500};
                box-sizing: content-box;
                appearance: none;
                -moz-appearance: textfield;
            }
            .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
            .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            .react-datetime-picker__inputGroup__input:invalid {
                background: rgba(255, 0, 0, 0.1);
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero {
                margin-left: -0.54em;
                padding-left: calc(1px +  0.54em);
            }
            .react-datetime-picker__inputGroup__leadingZero {
                font-weight: 400;
                margin-left: 2px;
            }
            .react-datetime-picker__inputGroup__amPm {
                font: inherit;
                appearance: none;
                -moz-appearance: menulist;
            }
            .react-datetime-picker__button {
                border: 0;
                background: transparent;
                padding: 4px 6px;
            }
            .react-datetime-picker__button:enabled {
                cursor: pointer;
            }
            .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
            .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                stroke: #0078d7;
            }
            .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                stroke: #6d6d6d;
            }
            .react-datetime-picker__button svg {
                display: inherit;
            }
            .react-datetime-picker__calendar,
            .react-datetime-picker__clock {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1;
            }
            .react-datetime-picker__calendar--closed,
            .react-datetime-picker__clock--closed {
                display: none;
            }
            .react-datetime-picker__calendar {
                width: 350px;
                max-width: 100vw;
            }
            .react-datetime-picker__calendar .react-calendar {
                border-width: thin;
            }
            .react-datetime-picker__clock {
                width: 200px;
                height: 200px;
                max-width: 100vw;
                padding: 25px;
                background-color: white;
                border: thin solid #a0a096;
            }                        
        }

        label {
            input {
                text-transform: capitalize;
            }
        }
    }
`

export const ServiceTags = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        .tag {
            display: flex;
            align-items: center;
            gap: 2px;
            width: fit-content;
            height: 32px;
            background: ${({ theme }) => theme.colors.background};
            border-radius: 8px;
            padding: 4px 8px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;

            &.highlight {
                background: ${({ theme }) => theme.colors.secondaryPool100};
            }

            .close {
                cursor: pointer;
            }
        }
    }

    .add {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 500;
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }
    }
`

export const Orientations = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;
   
    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }
    
    .info {
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;
        line-height: 24px;
        /* text-transform: capitalize; */
        white-space: pre-wrap;
    }

    .details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;

        .flex {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        .status {
            display: flex;
            align-items: center;
            gap: 10px;
            width: auto;
            height: 100%;
            padding: 5px 8px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.interfaceText400};
            svg {
                width: 12px;
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText400};
                }
            }

            &.accept {
                background: ${({ theme }) => theme.colors.interfaceSuccess100};
            }

            &.waiting {
                background: ${({ theme }) => theme.colors.primaryOrange50};
            }
        }

        .time {
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText300};
        }

        .arrow {
            cursor: pointer;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue500};
                }
            }
        }
    }


    .text {
        width: 100%;
        height: 300px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        padding: 16px;

        .text-area {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 8px;
            resize: none;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;
            outline: none;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            line-height: 24px;    
            
            &.highlight {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
            }
        }
    }

    .new {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 500;
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }
    }

`

export const DemandInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 336px;
    
`

export const InfoCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 136px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .value-profit {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        height: 56px;
        background: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        padding: 8px;
        
        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: ${({ theme }) => theme.colors.interfacePure0};
            border-radius: 8px;

            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText200};
                }
            }
        }

        .value {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText500}; 
            text-transform: capitalize;
        }

        .profit {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue400};  
            margin-left: auto;

            &.negative {
                color: ${({ theme }) => theme.colors.interfaceDanger500};
            }
        }

        .code {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText300};
        }

        .button {
            display: flex;
            align-items: center;
            height: 100%;
            margin-left: auto;
            margin-right: 10px;
            cursor: pointer;
        }
    }


    .status {
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;
        height: 56px;
        border-radius: 8px;
        padding: 16px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-size: 14px;
        font-weight: 500;

        &.interrupted {
            background-color: ${({ theme }) => theme.colors.interfaceDanger100};
            svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceDanger400};
                }
            }
        }

        &.pending {
            background-color: ${({ theme }) => theme.colors.primaryOrange50};
        }

        &.delayed {
           background-color: ${({ theme }) => theme.colors.interfaceDanger100}; 
           svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceDanger300};
                }
            }
        }

        &.progress {
           background-color: ${({ theme }) => theme.colors.secondaryPool100};
           svg {
                circle {
                    fill: ${({ theme }) => theme.colors.secondaryPool500};
                }
            }
        }

        &.finished {

           &.accepted {
                background-color: ${({ theme }) => theme.colors.interfaceSuccess100};
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess500};
                    }
                }

            }

            &.rejected {
                background-color: ${({ theme }) => theme.colors.interfacePure100};
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }

            }
        }

        &.accepted {
           background-color: ${({ theme }) => theme.colors.secondaryGreen050}; 
           svg {
                circle {
                    fill: ${({ theme }) => theme.colors.secondaryGreen500};
                }
            }
        }

        &.recused {
           background-color: ${({ theme }) => theme.colors.interfaceDanger100};
           svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceDanger400};
                }
            }
        }

        &.canceled {
           background-color: ${({ theme }) => theme.colors.interfacePure100};
           svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfacePure400};
                }
            }
        }
    }
`

export const DeadlineInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    .deadline {
        display: flex;
        flex-direction: column;
        gap: 16px;

        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .date-hour {
            display: flex;
            gap: 8px;

            .info {
                background: ${({ theme }) => theme.colors.background};
                border-radius: 8px;
                padding: 8px;
                font-size: 16px;
                font-weight: 400;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
        }
    }
`

export const HistoryTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: ${({ theme }) => theme.colors.primaryBlue400};
    margin-bottom: 16px;

    svg {
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: scale(1.3);
        }
        path {
            fill: ${({ theme }) => theme.colors.interfacePure200};
        }
    }
`

export const HistorySearch = styled.div`
    width: 100%;
    margin-bottom: 24px;

    .search {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
        height: 56px;
        padding: 16px;
        background: ${({ theme }) => theme.colors.interfacePure0};
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        input {
            width: 100%;
            border: none;
            outline: none;
        }

        .buscar {
            cursor: pointer;
        }

        .clean-search {
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText300};
                }
                &:hover {
                    cursor: pointer;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText500};
                    }
                }
            }
        }
    }
`

export const HistoryTimeLine = styled.div`
    display: flex;
    flex-direction: column;
    height: 400px;
    gap: 16px;
    overflow-y: auto;

    .line-info {
        display: flex;
        align-items: center;
        gap: 32px;

        &:last-child {
            .bullet {
                &::after {
                    content: '';
                    border-left: none;
                    height: 0;
                    /* position: absolute; */
                    top: 0;
                    left: 0;
                }
            }
        }


        .date {
            width: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 40px;
            font-size: 14px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

        .bullet {
            display: flex;
            align-items: center;
            position: relative;

            svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceText400};
                }
            }

            &::after {
                content: '';
                border-left: 2px solid ${({ theme }) => theme.colors.interfaceText400};
                height: 50px;
                position: absolute;
                top: 7px;
                left: 3px;                
            }

            

            &.start {
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }

            &.end {
                svg {
                    circle {
                        fill: ${({ theme }) => theme.colors.interfaceDanger400};
                    }
                }

                &::after {
                    content: '';
                    border-left: none;
                    height: 50px;
                    position: absolute;
                    top: 7px;
                    left: 3px;
                }
            }
        }

        .bullet-start {
            display: flex;
            align-items: center;
            svg {
                circle {
                    fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                }
            }
        }

        .event {
            display: flex;
            align-items: center;
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }
    }
`

export const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;
    padding: 8px;

    .justify {
        height: 56px;
        &.cancel {
            height: 80px;
        }

        p {
            font-weight: 600;
            color: ${({ theme }) => theme.colors.interfacePure500};
            margin-bottom: 16px;
        }
        span {
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfacePure500};
        }
    }

    .accept {
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400}; 

        p {
            margin-bottom: 24px;
        }

        span {
            font-weight: 400;
        }

        div {
            width: 375px;
            margin-bottom: 24px;
        }
    }

    .refuse {
        height: fit-content;

        p {
            font-weight: 400;
        }
    }
`

export const ModalTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 24px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};

    .points {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.interfaceText400};
    }

    .close {
        cursor: pointer;
        transition: all .2s;
        &:hover {
            transform: scale(1.3);
        }
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
    }
`

export const ModalJustification = styled.div`
    width: 100%;
    height: 120px;
    border: 1px solid #EAEEF3;
    border-radius: 8px;
    padding: 16px;

    .text-area {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 8px;
        resize: none;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;
        outline: none;

        &.bold {
            color: ${({ theme }) => theme.colors.interfacePure500};
            font-weight: 500;
        }
    }
`

export const ModalButtons = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;
    width: 100%;

    .continue {
        background: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .danger {
        background: ${({ theme }) => theme.colors.interfaceDanger600};
    }
`

export const Pendencies = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: fit-content;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;
    padding: 24px;

    span {
        font-size: 14px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.primaryBlue500};
    }

    .number-cards {
        display: flex;
        align-items: center;
        gap: 16px;

        .card {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 104px;
            height: 69px;
            border-radius: 8px;
            padding: 8px;

            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 24px;
                font-weight: 400;
            }

            span {
                font-size: 14px;
                font-weight: 600;
                color: ${({ theme }) => theme.colors.interfaceText400};
            }

            &.resolved {
                background-color: ${({ theme }) => theme.colors.interfaceSuccess100};
                .top {
                    color: ${({ theme }) => theme.colors.interfaceSuccess400};
                    svg {
                        width: 12px;
                        path {
                            fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                        }
                    }
                }
            }
            &.accept {
                background-color: ${({ theme }) => theme.colors.secondaryGreen050};
                .top {
                    color: ${({ theme }) => theme.colors.secondaryGreen600};
                    svg {
                        width: 12px;
                        path {
                            fill: ${({ theme }) => theme.colors.secondaryGreen600};
                        }
                    }
                }
            }
            &.refused {
                background-color: ${({ theme }) => theme.colors.interfaceDanger100};
                .top {
                    color: ${({ theme }) => theme.colors.interfaceDanger400};
                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.interfaceDanger400};
                        }
                    }
                }
            }
            &.waiting {
                background-color: ${({ theme }) => theme.colors.primaryOrange50};
                .top {
                    color: ${({ theme }) => theme.colors.primaryOrange700};
                    svg {
                        width: 12px;
                        path {
                            fill: ${({ theme }) => theme.colors.primaryOrange700};
                        }
                    }
                }
            }
            &.response {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
                .top {
                    color: ${({ theme }) => theme.colors.primaryBlue100};
                    svg {
                        path {
                            fill: ${({ theme }) => theme.colors.primaryBlue100};
                        }
                    }
                }

                span {
                    color: ${({ theme }) => theme.colors.primaryBlue500};
                }
            }
        }
        
        .arrow {
            cursor: pointer;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue500};
                }
            }
        }
    }

    .documentation {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        padding: 16px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon-title {
                display: flex;
                align-items: center;
                gap: 10px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                font-size: 14px;
                font-weight: 500;
            }

            .status {
                display: flex;
                align-items: center;
                gap: 10px;
                width: fit-content;
                height: 32px;
                padding: 5px 8px;
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-size: 14px;
                font-weight: 600;

                &.success {
                    background: ${({ theme }) => theme.colors.interfaceSuccess100};
                }

                &.refuse {
                    background: ${({ theme }) => theme.colors.interfaceDanger100};
                }

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText400};
                    }
                }
            }
        }

        .list {
            
            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                
                &::before {
                    content: "•";
                    color: ${({ theme }) => theme.colors.interfaceText400};
                    font-weight: bold;
                    display: inline-block; 
                    width: 1em;
                    margin-left: 0;
                }
            }

        }
    }

    .instruction {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        padding: 16px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .icon-title {
                display: flex;
                align-items: center;
                gap: 10px;
                color: ${({ theme }) => theme.colors.primaryBlue500};
                font-size: 14px;
                font-weight: 500;
            }

            .status {
                display: flex;
                align-items: center;
                gap: 10px;
                width: fit-content;
                height: 32px;
                padding: 5px 8px;
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-size: 14px;
                font-weight: 600;

                &.success {
                    background: ${({ theme }) => theme.colors.interfaceSuccess100};
                }

                &.refuse {
                    background: ${({ theme }) => theme.colors.interfaceDanger100};
                }

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText400};
                    }
                }
            }
        }

        p {
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-size: 14px;
            font-weight: 400;
        }
    }

    .add {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 500;
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }
    }

    .empty {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }
`

export const Pendency = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding: 16px;
    border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
    border-radius: 8px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-title {
            display: flex;
            align-items: center;
            gap: 10px;
            color: ${({ theme }) => theme.colors.primaryBlue500};
            font-size: 14px;
            font-weight: 500;
        }

        .date-status {
            display: flex;
            align-items: center;
            gap: 16px;

            .date {
               font-size: 14px;
               font-weight: 400;
               color: ${({ theme }) => theme.colors.interfaceText300};
            }
        }

        .status {
            display: flex;
            align-items: center;
            gap: 10px;
            width: fit-content;
            height: 32px;
            padding: 5px 8px;
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.interfaceText400};
            font-size: 14px;
            font-weight: 600;

            &.success {
                background: ${({ theme }) => theme.colors.interfaceSuccess100};
            }

            &.refuse {
                background: ${({ theme }) => theme.colors.interfaceDanger100};

                svg {
                    width: 16px;
                    height: 16px;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceText400};
                    }
                }
            }

            &.resolve {
                background: ${({ theme }) => theme.colors.primaryBlue500};
                color: ${({ theme }) => theme.colors.interfacePure0};
                cursor: pointer;
            }

            &.waiting {
                background: ${({ theme }) => theme.colors.primaryOrange50};
                color: ${({ theme }) => theme.colors.interfacetext400};                
            }

            svg {
                width: 12px;
                height: 12px;
                path {
                    fill: ${({ theme }) => theme.colors.interfaceText400};
                }
            }
        }

        .status-link {
            display: flex;
            align-items: center;
            gap: 16px;

            .button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                background-color: ${({ theme }) => theme.colors.background};
                border-radius: 8px;
                cursor: pointer;
            }
        }
        
    }

    .money {
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-size: 14px;
        font-weight: 500;

        span {
            color: ${({ theme }) => theme.colors.interfaceText300};
            font-size: 14px;
            font-weight: 500;
            margin-left: 16px;
        }
    }

    .info {
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 14px;
        font-weight: 400;

    }  
    
    .list {
        display: flex;
        flex-direction: column;
        gap: 24px;        

        p {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

        .answer {
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding-left: 30px;

            .title {
                color: ${({ theme }) => theme.colors.primaryBlue500};
                font-size: 14px;
                font-weight: 500;
            }
        }

    }
`

export const ModalPendenciesType = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-top: 16px;

    .type {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        height: 72px;
        border: 1px solid ${({ theme }) => theme.colors.interfacePure100};
        border-radius: 8px;   
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 20px;
        font-weight: 500;
        padding: 24px;
        cursor: pointer;
        transition: all .2s;
        &:hover {
            transform: scale(1.05);
            background-color: ${({ theme }) => theme.colors.interfacePure100};
        }
    }

    .show-type {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;
        height: 72px;
        border: 1px solid ${({ theme }) => theme.colors.secondaryPool100};
        border-radius: 8px;   
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 20px;
        font-weight: 500;
        padding: 24px;
    }

    .description {
        margin-top: 8px;

        span {
           color: ${({ theme }) => theme.colors.interfaceText400};
           font-weight: 400;
        }

        .text {
            width: 100%;
            height: 160px;
            border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
            border-radius: 8px;
            margin-top: 8px;
            padding: 16px;

            &.money {
                height: 80px;
            }

            .text-area {
                width: 100%;
                height: 100%;
                border: none;
                border-radius: 8px;
                resize: none;
                color: ${({ theme }) => theme.colors.interfacePure500};
                font-weight: 500;
                outline: none;
                font-family: Montserrat, sans-serif;
                font-size: 16px;
                line-height: 24px;    
                
                &.highlight {
                    background-color: ${({ theme }) => theme.colors.secondaryPool100};
                }
            }
        }
    }

    .value {
        span {
           color: ${({ theme }) => theme.colors.interfaceText400};
           font-weight: 400;
        }

        .price {
            display: flex;
            align-items: center;
            gap: 24px;
            margin-top: 8px;
        }

        .radio {
            display: flex;
            align-items: center;
            gap: 20px;

            input {
                cursor: pointer;
            }
        }
    }
`

export const ModalAnswerType = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    margin-top: 24px;

    .title {
        color: ${({ theme }) => theme.colors.interfacePure500};
        font-weight: 400;
        margin-top: 8px;

        span {
            font-weight: 600;
        }

    }

    .value {
        display: flex;
        align-items: center;   
        gap: 20px;
        width: 212px;
        height: 56px;
        background-color: ${({ theme }) => theme.colors.background};
        border-radius: 8px;
        padding: 8px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;        

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background-color: ${({ theme }) => theme.colors.interfacePure0};
            border-radius: 8px;                      
        }
    }

    .justification {
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;  
    }

    .profit {
        color: ${({ theme }) => theme.colors.interfacePure500};
        font-weight: 400;  

        span {
            font-weight: 600;
        }
    }

    .values {
        display: flex;
        align-items: center;
        justify-content: space-between;


    }

    .bold {
        color: ${({ theme }) => theme.colors.interfacePure500};
        font-weight: 600;  
    }

    .old-price {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 212px;
            height: 56px;
            border-radius: 8px;
            background-color: ${({ theme }) => theme.colors.background};
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;

            p {
                color: ${({ theme }) => theme.colors.interfaceText300};
            }

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                border-radius: 8px;
                background-color: ${({ theme }) => theme.colors.interfacePure0};
            }
    }

    .new-price {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 212px;
        height: 56px;
        border-radius: 8px;
        background-color: ${({ theme }) => theme.colors.secondaryPool100};
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;
        
        p {
            color: ${({ theme }) => theme.colors.interfaceText300};
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 8px;
            background-color: ${({ theme }) => theme.colors.interfacePure0};
        
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.secondaryPool200};
                }
            }
        }

    }

    .request {
        color: ${({ theme }) => theme.colors.interfacePure500};
        font-weight: 500;
    }

    .file {
        display: flex;
        gap: 8px;
        width: 100%;
        height: 56px;
        color: ${({ theme }) => theme.colors.interfaceText500};
        font-weight: 400;

        .file-details{
            display: flex;
            justify-content: space-between;
            width: 90%;
            border: 1px solid ${({ theme }) => theme.colors.secondaryPool300};
            border-radius: 8px;
            padding: 16px;

            .icon-title {
                display: flex;
                align-items: center;
                gap: 18px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
        }

        .icon-trash {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            cursor: pointer;
            svg {
                width: 24px;
                height: 24px;
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue500};
                }
            }
        }

        .download {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 56px;
            height: 56px;
            border-radius: 8px;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                background-color: ${({ theme }) => theme.colors.interfaceText100};
            }
        }
    }

    .add {
        display: flex;
        align-items: center;
        gap: 20px;
        height: 56px;
        padding: 16px;
        border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryBlue500};
        font-weight: 500;
        cursor: pointer;
        position: relative;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.primaryBlue500};
            }
        }

        .file-input {
            width: 100%;
            height: 100%;
            color: transparent;
            transition: all .5s;
            border: none;
            padding: 0;
            background: transparent;
            border-radius: 8px;
            color: ${({ theme }) => theme.colors.primaryBlue500};
            position: absolute;
            top: 0;
            left: 0;
        }
        .file-input::-webkit-file-upload-button {
            visibility: hidden;
        }
        .file-input::before {
            content: 'Anexar arquivos';
            color: ${({ theme }) => theme.colors.primaryBlue500};
            display: flex;
            align-items: center;
            justify-content: flex-start;
            border: none;
            padding: 0;
            width: 100%;
            height: 50px;
            outline: none;
            white-space: nowrap;
            -webkit-user-select: none;
            user-select: none;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            padding-left: 60px;
        }
        .file-input:active {
            outline: 0;
        }
    }

    .text {
        width: 100%;
        height: 160px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        margin-top: 8px;
        padding: 16px;

        &.money {
            height: 80px;
        }

        .text-area {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 8px;
            resize: none;
            color: ${({ theme }) => theme.colors.interfacePure500};
            font-weight: 500;
            outline: none;
            font-family: Montserrat, sans-serif;
            font-size: 16px;
            line-height: 24px;    
            
            &.highlight {
                background-color: ${({ theme }) => theme.colors.secondaryPool100};
            }
        }
    }

    .info-description {
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText400};
        margin-bottom: -16px;
    }
`

export const ModalResult = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .button {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 120px;
        padding: 24px;
        border: 1px solid ${({ theme }) => theme.colors.interfacePure100};
        border-radius: 8px;
        cursor: pointer;

        .title {
            display: flex;
            align-items: center;
            gap: 28px;
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};

            &.accept {
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }

            &.reject {
                svg {
                    width: 28px;
                    height: 28px; 
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceDanger500};
                    }
                }
            }

        }

        .subtitle {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }
    }

    .accept-card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 120px;
        padding: 24px;
        border: 2px solid ${({ theme }) => theme.colors.interfaceSuccess200};
        border-radius: 8px;
        cursor: pointer;

        .title {
            display: flex;
            align-items: center;
            gap: 28px;
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};

            &.accept {
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: ${({ theme }) => theme.colors.interfaceSuccess400};
                    }
                }
            }

           

        }

        .subtitle {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

    }

    .upload {
        display: flex;
        flex-direction: column;

        .title {
            margin-bottom: 10px;
        }

        .download {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            border-radius: 8px;
            cursor: pointer;
            padding: 24px;
            transition: all .3s;
            &:hover {
                background-color: ${({ theme }) => theme.colors.interfaceText100};
            }
            color: ${({ theme }) => theme.colors.interfaceText400};
        }


        .add {
            display: flex;
            align-items: center;
            gap: 20px;
            height: 56px;
            padding: 16px;
            border: 2px dotted ${({ theme }) => theme.colors.primaryBlue500};
            color: ${({ theme }) => theme.colors.primaryBlue500};
            font-weight: 500;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.primaryBlue500};
                }
            }

            .file-input {
                width: 100%;
                height: 100%;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input::before {
                content: 'Anexar arquivo de conclusão preenchido';
                color: ${({ theme }) => theme.colors.primaryBlue500};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input:active {
                outline: 0;
            }
            
            .file-input-two {
                width: 100%;
                height: 100%;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input-two::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input-two::before {
                content: 'Anexar Ata do processo';
                color: ${({ theme }) => theme.colors.primaryBlue500};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input-two:active {
                outline: 0;
            }

            .file-input-three {
                width: 100%;
                height: 100%;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input-three::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input-three::before {
                content: 'Anexar arquivo';
                color: ${({ theme }) => theme.colors.primaryBlue500};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input-three:active {
                outline: 0;
            }
        }

        .files {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: 100%;
            height: fit-content;
            margin-bottom: 12px;

            .flex {
                display: flex;                    
                width: 100%;
                height: auto;
            }

            .name-file {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 40px;
                border: 1px solid ${({ theme }) => theme.colors.interfaceText200};
                border-radius: 8px;
                color: ${({ theme }) => theme.colors.interfaceText400};
            }

            .info-title {
                font-size: 18px;
                color: red;
            }
        }

        &.conclusion {
            gap: 12px;
        }
    }

    .text-title {
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText400};
        &.hand {
            color: ${({ theme }) => theme.colors.interfacePure500};
        }
    }

    .radio-input {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        height: 48px;

        input { 
            cursor: pointer;
        }

        span {
            font-weight: 400;
            font-size: 16px;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

    }
    
    .reject-card {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 120px;
        padding: 24px;
        border: 2px solid ${({ theme }) => theme.colors.interfaceDanger200};
        border-radius: 8px;
        cursor: pointer;

        .title {
            display: flex;
            align-items: center;
            gap: 28px;
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};

            &.reject {
                svg {
                    width: 26px;
                    height: 26px;                    
                }
            }

           

        }

        .subtitle {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }


    }

    .final {
        font-size: 16px;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.interfaceText400};
        margin-bottom: -8px;
    }

    .disclaimer {
        width: 394px;        

        .bold {
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfacePure500};

            span {
                font-weight: 600;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfacePure500};
        }
    }
`

export const OrientationsCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: fit-content;
    border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
    border-radius: 8px;
    padding: 16px;

    .info {
        display: flex;
        flex-direction: column;        
        gap: 8px;
        height: fit-content;

        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .info-details {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText500};
        }
    }

    .lawyer-info {
        display: flex;
        height: fit-content;

        .lawyer {
            display: flex;
            flex-direction: column;  
            justify-content: space-between;
            gap: 8px;
            width: 450px;
            height: fit-content;
        }

        .number {
            flex-direction: column;        
            gap: 8px;
        }

        .info-details {
            font-size: 16px;
            font-weight: 400;
            color: ${({ theme }) => theme.colors.interfaceText500};
        }
    }

    .text-info {
        display: flex;
        flex-direction: column;
        gap: 8px; 
        height: fit-content;
        position: relative;

        span {
            font-size: 14px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.primaryBlue500};
        }

        .info {
            height: fit-content;
            white-space: pre-wrap;

            .list {
                white-space: pre-wrap;
            }
        }

        .bullet {
            padding-left: 20px;
            ul {
                li {
                    list-style: disc;
                    font-size: 16px;
                    font-weight: 400;
                    color: ${({ theme }) => theme.colors.interfaceText500};
                }
            }
        }
    }
`

export const OrientationsFiles = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;

    height: fit-content;
    
    border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
    border-radius: 8px;
` 