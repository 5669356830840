import { Container } from "./styles";

interface PropTabs {
    selected?: any,
    setSelected?: any,
    active?: any,
    inactive?: any,
}

export default function OperatorsTab({ selected, setSelected, active, inactive }: PropTabs) {
    return (
        <Container>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '1' ? 'selected tab' : 'tab'}
                id='1'
            >
                Ativos 
                <div 
                    className={selected === '1' ? 'selected notification' : 'notification'}
                >
                    {active}
                </div>
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '2' ? 'selected tab' : 'tab'}
                id='2'
            >
                Inativos
                <div
                    className={selected === '2' ? 'selected notification' : 'notification'}
                >
                    {inactive}
                </div>
            </div>                         
        </Container>
    )
}