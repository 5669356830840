import { ButtonCard, Container } from "./styles";

interface ButtonProps {
    selected?: any,
    setSelected?: any,    
}

export default function ProposalButtons({selected, setSelected}:ButtonProps) {

    return(
        <Container>            
            <ButtonCard 
                className={selected === 'Audiência' ? 'selected' : ''}
                onClick={() => setSelected('Audiência')}
            >
                Audiência
            </ButtonCard>         
            <ButtonCard 
                className={selected === 'Diligência' ? 'selected' : ''}
                onClick={() => setSelected('Diligência')}
            >
                Diligência
            </ButtonCard>         
            <ButtonCard 
                className={selected === 'Protocolo' ? 'selected' : ''}
                onClick={() => setSelected('Protocolo')}
            >
                Protocolo
            </ButtonCard>         
            <ButtonCard 
                className={selected === 'Outros' ? 'selected' : ''}
                onClick={() => setSelected('Outros')}
            >
                Outros
            </ButtonCard>         
        </Container>
    )
}