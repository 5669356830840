import { Container } from "./styles";

interface PropTabs {
    selected?: any,
    setSelected?: any,
}

export default function AccountTabs({ selected, setSelected }: PropTabs) {

    return (
        <Container>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)} 
                className={selected === '1' ? 'selected tab' : 'tab'}
                id='1'
            >
                Visão geral
            </div>
            <div 
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '2' ? 'selected tab' : 'tab'}
                id='2'
            >
                Dados bancários
            </div>
            <div
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '4' ? 'selected tab' : 'tab'}
                id='4'
            >
                Serviços
            </div>
            <div 
                onClick={(value) => setSelected(value.currentTarget.id)}
                className={selected === '3' ? 'selected tab' : 'tab'}
                id='3'
            >
                Comarcas                
            </div>
           
        </Container>
    )
} 