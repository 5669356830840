/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// React //
import { useState } from "react";
import { useHistory } from "react-router-dom";

// Components //
import ButtonDefault from "../../components/form/ButtonDefault";
import Checkbox from "../../components/form/InputCheckbox";
import InputDefault from "../../components/form/InputDefault";

// Icons //
import { 
    IconArrowLeft, 
    IconClosedEye, 
    IconEyeOpen, 
    IconFacebook, 
    IconInsta, 
    IconLinkedin, 
    IconLogoJuridico, 
    IconQuestion 
} from "../../components/icons";

// Services //
import api from "../../services/api";
import toast, { Toaster } from 'react-hot-toast';
import { useAuth } from "../../contexts/UserContext";

// Styles //
import { Container, Footer, Header, MainLeft, MainRight, MainWrapper } from "./styles";

// Assets //
import LoginImage from "../../assets/images/LoginImage.svg"

interface SignInProps {
    Email: string;
    Senha: any;
}

export default function Login() {
    const history = useHistory();
    const { signIn } = useAuth();

    const [loading, setLoading] = useState<boolean>(false)
    const [checked, setChecked] = useState<boolean>(false);
    const [login, setLogin] = useState<SignInProps>({
        Email: "",
        Senha: ""
    });
    const [ forgotPass, setForgotPass ] = useState<any>()
    const [ changeVisibility, setChangeVisibility ] = useState<boolean>(true);
    const [ forgotPassword, setForgotPassword ] = useState<boolean>(false);
    const [ emptyField, setEmptyField ] = useState<any>();
    const [ errorMessage, setErrorMessage ] = useState<any>();
    // const [remember, setRemember] = useState<boolean>(false)

    const onChangeLogin = (key: any, value: string) => {
        if (key === "Email") login.Email = value
        if (key === "Senha") login.Senha = value
        setLogin({ ...login })
    }

    // useEffect(() => {

    //     getRemember()

    // }, [])

    // function getRemember() {

    //     const dataRemember = window.localStorage.getItem('@Jj:remember')

    //     if (dataRemember) {

    //         const dataRememberJSON = JSON.parse(dataRemember)

    //         setLogin({
    //             Email: dataRememberJSON.Email,
    //             Senha: dataRememberJSON.Senha
    //         })
    //         setChecked(true)

    //         console.log('log confirmando que o user manteve logado', checked)
            
    //         history.push('/painel')

    //     } else {
    //         console.log('Decidiu não manter conectado')
    //     }
    // }

    async function handleSubmit(e: any) {
        e.preventDefault();

        try {            
            setLoading(true);
            if (!login.Email) {
                setEmptyField(true);
                setErrorMessage('Campo obrigatório')
                throw new Error ('Email é obrigatório');
            }
            if (!login.Senha) {
                setEmptyField(true)
                setErrorMessage('Campo obrigatório')
                throw new Error ('Senha é obrigatória')
            }

            await signIn({
                email: login.Email,
                senha: login.Senha
            })
            
            setLoading(false);            

        } catch (error: any) {

            setLoading(false)
            
            toast.error(error.message);

        }
    }

    async function submitForgotPassword(e: any) {
        e.preventDefault();

        try {
            setLoading(true);
            
            if (!forgotPass) {
                setEmptyField(true);
                setErrorMessage('Campo obrigatório');
                throw new Error('E-mail é obrigatório');
            }

            const response = await api.get('usuarios/senha/recuperar/' + forgotPass);

            if (response.data.status !== 'success') throw (response.data.message);

            setLoading(false);
            toast.success('Email enviado com sucesso.', {
                style: {
                    padding: '16px',
                    color: '#FA9604',
                },
                iconTheme: {
                    primary: '#02E8BE',
                    secondary: '#FFFAEE',
                },
            });
            
            setTimeout(() => {
                setForgotPassword(false);
            }, 1500)
            

        } catch (error: any) {
            setLoading(false) 
            // console.log('log do erro', error)
            toast.error(error.toString(), {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }

    return (
        <Container>
            
            <MainWrapper>
                <div className="header">
                    <div className="back"
                        onClick={() => history.push('/')}                        
                    >
                        <span><IconArrowLeft /></span> Voltar para o site
                    </div>
                    <div className="icons">
                        <div className="card">
                            <a href="https://www.facebook.com/profile.php?id=100090488119958" target={'_blank'} rel="noreferrer">
                                <IconFacebook />
                            </a>
                        </div>
                        <div className="card">
                            <a href="https://www.instagram.com/123.jus/" target={'_blank'} rel="noreferrer">
                                <IconInsta />
                            </a>
                        </div>
                        <div className="card">
                            <a href="https://www.linkedin.com/company/43238170" target={'_blank'} rel="noreferrer">
                                <IconLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
                {
                    forgotPassword 
                    ?
                        <MainLeft>

                            <Header style={{marginBottom: '40px', height: 'auto'}}>
                                <h1 className="forgot">Esqueci a senha</h1>
                                <p className="forgot-text">Informe seu e-mail e enviaremos um link para redefinir sua senha.</p>
                            </Header>

                            <form onSubmit={submitForgotPassword} className="forgot-form">
                                <InputDefault 
                                    title="E-mail"
                                    value={forgotPass}
                                    onChangeText={(value) => setForgotPass(value)}
                                    className={errorMessage && !forgotPass ? 'empty' : ''}
                                    error={errorMessage && !forgotPass ? errorMessage : ''}
                                />

                                <div className="buttons">
                                    <button
                                        onClick={() => setForgotPassword(false)}
                                    >
                                        Voltar ao login
                                    </button>

                                    <ButtonDefault>
                                        Enviar link
                                    </ButtonDefault>
                                </div>
                            </form>

                        </MainLeft>
                        
                    :
                        <MainLeft>

                            <Header>
                                <IconLogoJuridico />

                                <p>Participe da revolução na <span className="blueTitle">logística jurídica</span> <span className="orangeTitle">em 123!</span> </p>
                            </Header>

                            <form onSubmit={handleSubmit}>

                                <InputDefault
                                    title="E-mail:"
                                    value={login.Email}
                                    onChangeText={(value) => onChangeLogin('Email', value)}
                                    className={emptyField && !login.Email ? 'empty' : ''}
                                    error={errorMessage && !login.Email ? errorMessage : ''}
                                />

                                <div className="password">
                                    <InputDefault
                                        title="Senha:"
                                        type={changeVisibility ? 'password' : 'text'}
                                        value={login.Senha}
                                        onChangeText={(value) => onChangeLogin('Senha', value)}
                                        className={emptyField && !login.Senha ? 'empty' : ''}
                                        error={errorMessage && !login.Senha ? errorMessage : ''}
                                    />
                                    <div 
                                        className="icon"
                                        onClick={() => setChangeVisibility(!changeVisibility)}
                                    >
                                        {changeVisibility ? <IconEyeOpen /> : <IconClosedEye /> }
                                    </div>
                                </div>

                                {/* Talvez será removido essa função */}
                                <div className="checkbox">
                                    <Checkbox
                                        label="Manter conectado"
                                        name="Manter conectado"
                                        value={checked}
                                        onClick={() => setChecked(!checked)}
                                    />
                                </div>
                                {/* Talvez será removido essa função */}

                                <div className="field">
                                    <ButtonDefault loading={loading}>Acessar</ButtonDefault>
                                </div>

                            </form>

                            <Footer>
                                <button
                                    onClick={() => {setForgotPassword(true); setForgotPass(login.Email)}}
                                >
                                   <IconQuestion />  Esqueceu a senha?
                                </button>                                
                            </Footer>

                        </MainLeft>
                }

                <MainRight>                   
                   
                    <img src={LoginImage} alt="Imagem da tela de login" />                   

                </MainRight>

            </MainWrapper>
            <Toaster />
        </Container>
    )
}