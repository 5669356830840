import styled from "styled-components";

export const Container = styled.div`
    max-width: 1040px;
`

export const ProposalTop = styled.div`
    display: flex;
    gap: 16px;
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 2px dashed ${({ theme }) => theme.colors.interfaceText200};
`

export const ProposalBottom = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

export const ServicesOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 512px;
    height: 88px;
    padding: 24px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors.interfaceText400};
    font-size: 16px;
    font-weight: 700;

    .select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 40px;
        border: 1px solid ${({ theme }) => theme.colors.primaryOrange600};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryOrange500};
        cursor: pointer;
        transition: all .3s;
        &:hover {
            transform: scale(1.1);
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: ${({ theme }) => theme.colors.primaryOrange500};
        }
    }
`