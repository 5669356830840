import styled from 'styled-components';

export const Container = styled.div`
    /* display: grid;
    grid-template-columns: 250px 1fr;
    height: 100%; */

    display: flex;
    width: 100%;
    height: 100%;

    .warning {
        display: flex;
        gap: 16px;        
    }
`;

export const Main = styled.div`
    width: 100%;
    overflow: auto;
    max-height: 100vh;
    padding: 32px;
    padding-bottom: 64px;
    background-color: #F6F8FA;
`;