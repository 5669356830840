import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { Container } from './styles';

interface InputProps extends React.HTMLAttributes<HTMLInputElement> {
    placeholder?: string;
    value: string | any;
    name?: string;
    type?: 'text' | 'password' | 'email' | 'tel' | 'number';
    mask?: any;
    onChangeText: (value: string | any) => void;
    search?: boolean;
    className?: any;
    title?: string;
    disabled?: boolean;
    error?: string;
}

export const maskToCurrency = ({ nextState }: any) => {
    const { value } = nextState || {}

    let amountFormatted = value?.replace?.(/\D/g, '')
    amountFormatted = amountFormatted?.replace?.(/^0+/g, '')

    if (amountFormatted?.length === 2) {
        return {
            ...nextState,
            value: `R$ ${amountFormatted}`,
            selection: {
                start: amountFormatted.length + 3,
                end: amountFormatted.length + 3
            }
        }
    }

    const amountFormattedWithComma = amountFormatted?.replace?.(
        /(?=\d{2})(\d{2})$/,
        ',$1'
    )
    const amountFormattedWithDot = amountFormattedWithComma?.replace?.(
        /(\d)(?=(\d{3})+(?!\d))/g,
        '$1.'
    )

    if (amountFormattedWithDot) {
        return {
            ...nextState,
            value: `R$ ${amountFormattedWithDot}`,
            selection: {
                start: amountFormattedWithDot.length + 3,
                end: amountFormattedWithDot.length + 3
            }
        }
    }

    return nextState
}

const InputMoney: React.FC<InputProps> = ({
    placeholder,
    value,
    name,
    mask = '',
    onChangeText,
    type,
    search = false,
    title = null,
    className = '',
    disabled = false,
    error = '',
    ...rest
}) => {
    const [valueInput, setValueInput] = useState<string>(value);

    useEffect(() => {
        setValueInput(value);
    }, [value]);

    function handleOnChange(e: React.FormEvent<HTMLInputElement>) {
        setValueInput(e.currentTarget.value);
        onChangeText(e.currentTarget.value);
    }

    return (
        <Container className={`default-input ${className}`}>
            {title && <p className="title">{title}</p>}
            <InputMask
                type={type}
                value={valueInput}
                name={name}
                onChange={handleOnChange}
                placeholder={placeholder}
                disabled={disabled}
                mask={mask}
                beforeMaskedStateChange={maskToCurrency}
                {...rest}
            />
            <span>{error}</span>
        </Container>
    );
};

export default InputMoney;