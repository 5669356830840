/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// React //
import { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom';

// Services //
import { useAuth } from '../../contexts/UserContext';

// Icons //
import { 
  IconButtonArrow, 
  IconButtonArrowDown, 
  IconButtonDots, 
  IconLogoBig,
  IconUser 
} from '../icons';
import ProfileMenu from '../Ui/ProfileMenu';

// import DropdownItem from '../Ui/DropdownItem';

// Images
import LogoSmallGreen from '../../assets/images/LogoSmallGreen.svg';
import LogoSmallOrange from '../../assets/images/LogoSmallOrange.svg';

// Styles //
import { 
    Container,
    Content,
    Header,
    Ul,
    Li,
    SectionFooter,
    ProfileFooter,
    DescriptionProfileFooter,
} from './styles';

export default function Sidebar({ list }: any) {

    const location = useLocation();
    const { user, pageName } = useAuth();
    const openRef = useRef<any>();
    const [ menuSidebar, setMenuSidebar ] = useState(false);
    const [ selected, setSelected ] = useState<any>();
    const [ openMenu, setOpenMenu ] = useState<any>();
    const [ profile, setProfile ] = useState<boolean>();

    function handleOnClickSide(item: any) {

      setSelected(item);
      setMenuSidebar(false);     
      
    }

    useEffect(() => {
      if (user?.profile === 'comum') {
        const userWithLetter = `D${user.id}`       

        if (userWithLetter === pageName.split(' ')[1]) {

          setProfile(true);

        } else {

          setProfile(false);

        }
      }

      if (user?.profile === 'operador') {
        const userWithLetter = `P${user.id}`
        
        if (userWithLetter === pageName.split(' ')[1]) {

          setProfile(true);

        } else {

          setProfile(false);

        }
      }

      if (user?.profile === 'admin') {
        const userWithLetter = `J${user.id}`
        
        if (userWithLetter === pageName.split(' ')[1]) {

          setProfile(true);

        } else {

          setProfile(false);

        }
      }

    }, [pageName]) 
    
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            
            if (openMenu && openRef.current && !openRef.current.contains(e.target)) {
                setOpenMenu(false);
            }

        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openMenu]);

  return (
    <Container
        isAdmin={user?.profile !== 'comum' ? true : false}
        menuSidebar={menuSidebar}        
    >
      <Content>
        <Header menuSidebar={menuSidebar}>
          {
            !menuSidebar &&
            <IconLogoBig color='#23367C' />
          }

          {
            menuSidebar &&
            user?.profile === 'comum' &&
            <div>
              <img src={LogoSmallGreen} alt="Logo pequeno da 123JUS" />
            </div>
          }

          {
            menuSidebar &&
            user?.profile !== 'comum' &&
            <div>
              <img src={LogoSmallOrange} alt="Logo pequeno da 123JUS" />
            </div>
          }

          <button 
            type="button" 
            className="sideButtonBackToPage"
            onClick={() => setMenuSidebar(!menuSidebar)}
          >
            <IconButtonArrow color={user?.profile !== 'comum' ? "#FFCB7F" : '#B8FFF2'} />
          </button>
        </Header>
      </Content>
      
        <Ul>
          { list &&
          list.map((row: any) => (
            <Li
              isAdmin={user?.profile !== 'comum' ? true : false}
              menuSidebar={menuSidebar}
              key={row.id}
              hasNotification={row.notification}
              hasSelected={location.pathname.includes(row.name.toLocaleLowerCase('pt-BR'))}
            >
              <Link to={`${row.to}`} onClick={() => handleOnClickSide(row.name)}>
                <div className="sideLiLeft">
                  {row.icon}
                  <h1 className='sideTitleLi'>
                    {row.name}
                  </h1>
                </div>

                <div className="sideLiRight">
                  <div
                    className="sideButtonLi"
                    onClick={() => console.log("click")}
                  >
                    <IconButtonArrowDown />
                  </div>
                </div>
              </Link>
            </Li>
          ))}

          {/* {list.map((row: any) => (
            <DropdownItem
              data={row}
              handleonClick={(to, name) => handleOnActiveButton(to, name)}
              selected={row.name === selected}
            />
          ))} */}
        </Ul>            

      <SectionFooter 
        menuSidebar={menuSidebar}
        isAdmin={user?.profile !== 'comum' ? true : false}
        inProfile={profile}
      >
        <ProfileFooter
          menuSidebar={menuSidebar}
          isAdmin={user?.profile !== 'comum' ? true : false} 
          inProfile={profile} 
        >
          <div className="avatarUser">
            {
              profile
                ? <IconUser color={user?.profile !== 'comum' ? "#F18F00" : "#00D3AD"} />
                : <IconUser color={user?.profile !== 'comum' ? "#FFDEAE" : "#B8FFF2"} />
            }            
          </div>
          <div 
            className="sideButtonFooter"
            ref={openRef}
            onClick={() => setOpenMenu(!openMenu)}
          > 
            {
              profile
                ? <IconButtonDots color={user?.profile !== 'comum' ? "#F18F00" : "#00D3AD"} />
                : <IconButtonDots />
            }
            
            {
              openMenu &&
              <ProfileMenu />
            }
          </div>
        </ProfileFooter>

        <DescriptionProfileFooter
          isAdmin={user?.profile !== 'comum' ? true : false}
          menuSidebar={menuSidebar}
          inProfile={profile} 
        >
          <h2>{user?.nome.split(' ')[0]}</h2>
          {
            user?.profile === 'comum' &&
            <span>D{user?.id}</span>
          }
          {
            user?.profile === 'operador' &&
            <span>P{user?.id}</span>
          }
          {
            user?.profile === 'admin' &&
            <span>J{user?.id}</span>
          }

          {
            menuSidebar &&
            <button
              className="sideButtonFooter"
              onClick={() => setOpenMenu(!openMenu)}
            >
              {
                profile
                  ? <IconButtonDots color={user?.profile !== 'comum' ? "#F18F00" : "#00D3AD"} />
                  : <IconButtonDots />
              }
              {
                openMenu &&
                <ProfileMenu 
                  menuSidebar={menuSidebar}
                />
              }
            </button>
          }
        </DescriptionProfileFooter>


      </SectionFooter>
    </Container>
  );
}