import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    padding-bottom: 2rem;

    @media (max-width: 768px) {
        max-width: 100%;
    }
`

export const HeaderCookies = styled.div`
    width: 100%;
    height: 100px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 65%;
    padding-top: 40px;
    position: relative;
`

export const MainInfoCookies = styled.div`
    /* padding-left: 10%; */
    max-width: 65%;
    text-align: justify;

    p {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: ${({theme}) => theme.colors.interfaceText500};
        margin-bottom: 10px;

        &.space {
            margin: 24px 0;
        }

        &.mid-bold {
            font-weight: 500;
            margin-top: 24px;
        }
        &.bold {
            font-weight: 600;
        }

        span {
            text-decoration: underline;

            a {
                text-decoration: none;
                color: ${({theme}) => theme.colors.interfaceText500};
            }
        }

        &.center {
            text-align: center;
        }

        &.two {
            margin-bottom: 35px;
        }

        &.left-pad {
            padding-left: 32px;
        }

        &.top {
            margin-top: 24px;
        }
    }

    .link {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: ${({theme}) => theme.colors.interfaceText500};
        margin-bottom: 4px;

        span {
            text-decoration: underline;
        }
    }

    .list {
        font-size: 1rem;
        font-weight: 400;
        line-height: 24px;
        color: ${({theme}) => theme.colors.interfaceText500};
        padding-left: 44px;
        margin-bottom: 12px;
        
        .list-info {
            list-style: disc;
        }
    }
`