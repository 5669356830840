import styled from "styled-components";

export const WrapperCards = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 100px;

    @media (max-width: 800px) {
        flex-direction: column;
    }
`

