import styled from "styled-components";

export const ServiceWrapper = styled.div`
    width: 1040px;
    height: 100%;
    margin: 0px auto;
    min-height: 100vh;
    padding-bottom: 130px;

    overflow: auto;

    .toasted {
        width: fit-content;
    }

    @media (max-width: 800px) {
        max-width: 100%;
        padding-bottom: 160px;
    }    
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 90px;
    padding-top: 66px;   

    .progress {
        display: flex;
        align-items: center;
        gap: 10px;

        .progressbar {
            height: 8px;
            width: 172px;
            border-radius: 8px;
            background: ${({ theme }) => theme.colors.interfacePure100};
            position: relative;
            
            .percent {
                transition: all .2s;
                width: 44px;
                height: 8px;
                position: absolute;
                border-radius: 8px;
                top: 0;
                left: 0;
                background: ${({ theme }) => theme.colors.primaryBlue100};
                &.two {
                    width: 75px;
                }
                &.three {
                    width: 118px;
                }
                &.four {
                    width: 148px;
                }
                &.five {
                    width: 172px;
                }
            }
        }
    }

    @media (max-width: 800px) {
        padding-left: 10%;
        .progress {
            display: none;
        }
    }
`

export const Main = styled.div`
    
    .top {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 32px;

        .one {
            .register {
                display: flex;
                flex-direction: column;
                gap: 32px;
                
                h3 {
                    color: ${({ theme }) => theme.colors.primaryBlue500};
                    font-weight: 600;
                }

                &.underline {
                    p {
                        color: ${({ theme }) => theme.colors.interfaceText500};
                        font-size: 18px;
                        font-weight: 400;
                        line-height: 150%;
                        span {
                            text-decoration: underline;
                            text-underline-offset: 2px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        .three {
            .register {
                display: flex;
                flex-direction: column;
                gap: 32px;

                h3 {
                    color: ${({ theme }) => theme.colors.primaryBlue500};
                    font-weight: 600;
                }

                .subtitle {
                    color: ${({ theme }) => theme.colors.interfaceText500};
                    font-size: 18px;
                    font-weight: 400;
                }
            }
        }

        .four {
            .register {
                display: flex;

                h3 {
                    color: ${({ theme }) => theme.colors.primaryBlue500};
                    font-weight: 500;
                }
            }
        }

        .five {
            .register {
                margin-bottom: 25px;

                .terms {
                    span {
                        cursor: pointer;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                    }
                }
            }
        }

        .register {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;
            height: fit-content;
            max-width: 680px;
            h3 {
                font-size: 24px;
                font-weight: 500;
                line-height: 36px;
                span {
                    font-size: 24px;
                    line-height: 36px;
                    font-weight: 700;
                    color: ${({ theme }) => theme.colors.secondaryGreen600};
                }
            }
            
            &.services {
                .title {
                    margin-bottom: -10px;
                }
                p {
                    span {
                        font-weight: 500;
                    }
                }
            }
        }

        .subtitle, p {
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            color: ${({ theme }) => theme.colors.interfaceText400};
                span {
                    font-weight: 500;
                }
        }

        .attention {
            border: 1px solid ${({ theme }) => theme.colors.primaryOrange300};
            width: 333px;
            height: 88px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10%;
            border-radius: 8px;
            position: relative;

            .star {
                position: absolute;
                top: 19%;
                left: 4%;
            }

            span {
                margin-top: 5px;
                color: ${({ theme }) => theme.colors.primaryOrange600};
                font-weight: 700;
                font-size: 14px;
                line-height: 21px;
            }

            p {
                color: ${({ theme }) => theme.colors.interfaceText400};
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
            }

        }
    }
    
    @media (max-width: 800px) {
        .top {
            .one {
                max-width: 90%;
                margin: 0 auto;
            }

            .three {
                max-width: 90%;
                margin: 0 auto;
            }
        }
    }
`

export const FormMain = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    .radio {
        display: flex;
        align-items: center;
        gap: 36px;
        
        .radio-input {
            display: flex;
            gap: 12px;
        }
        
    }
    
    .name,
    .mail,
    .social {
        display: flex;
        gap: 16px;
        margin-top: 8px;

        .big {
            width: 424px;
        }
    }

    .state {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;
        margin-bottom: 25px;

        .label {
            width: 100%;
            max-width: 424px;
            position: relative;
            font-size: 14px;
            p {
                margin-bottom: 8px;
            }
            .error {
                position: absolute;
                display: block;
                top: 5%;
                right: 2%;
                color: ${({ theme }) => theme.colors.interfaceDanger};
            }
        }

        .big {
            width: 65%;
        }

        .mid {
            width: 45%;
        }

        .small {
            width: 33%;
        }

        .select {
            height: 48px;
            width: 100%;
            border-radius: 8px;
            padding: 10px;

        }
    }
    
    .process {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 24px 16px;
        margin-bottom: 24px;
        max-width: 864px;
        .big {
            width: 100%;
            max-width: 424px;
            /* &:hover {
                border-color: red;
            } */
        }           
    }

    .bottom {
        max-width: 864px;
        margin-top: 70px;
        margin-bottom: 24px;

        .subtitle {
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 32px;

            span {
                text-decoration: underline;
                text-underline-offset: 2px;
            }
        }

        .subtype, 
        .professional {
            max-width: 864px;
            display: flex;
            gap: 16px;
            margin-bottom: 24px;
            .label-state {
                width: 424px;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }

                .date {
                    width: 100%;
                    height: 48px;

                    .react-datetime-picker {
                        width: 100%;
                        height: 48px;
                        display: inline-flex;
                        position: relative;
                    }
                    .react-datetime-picker,
                    .react-datetime-picker *,
                    .react-datetime-picker *:before,
                    .react-datetime-picker *:after {
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                    }
                    .react-datetime-picker--disabled {
                        background-color: #f0f0f0;
                        color: #6d6d6d;
                    }
                    .react-datetime-picker__wrapper {
                        display: flex;
                        flex-grow: 1;
                        flex-shrink: 0;
                        border: thin solid #EAEEF3;
                        border-radius: 8px;
                        padding: 8px 16px;

                    }
                    .react-datetime-picker__inputGroup {
                        min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                        flex-grow: 1;
                        padding: 0 2px;
                    }
                    .react-datetime-picker__inputGroup__divider {
                        padding: 8px 0;
                        white-space: pre;
                        &:nth-child(7) {
                            padding: 8px;
                            &::before {
                                content: '|';
                            }
                        }
                    }
                    .react-datetime-picker__inputGroup__input {
                        min-width: 0.54em;
                        height: calc(100% - 3px);
                        position: relative;
                        padding: 1px;
                        border: 0;
                        background: none;
                        font: inherit;
                        font-size: 16px;
                        font-weight: 500;
                        color: ${({ theme }) => theme.colors.interfaceText500};
                        box-sizing: content-box;
                        -moz-appearance: textfield;
                    }
                    .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
                    .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    .react-datetime-picker__inputGroup__input:invalid {
                        background: rgba(255, 0, 0, 0.1);
                    }
                    .react-datetime-picker__inputGroup__input--hasLeadingZero {
                        margin-left: -0.54em;
                        padding-left: calc(1px +  0.54em);
                    }
                    .react-datetime-picker__inputGroup__amPm {
                        font: inherit;
                        -moz-appearance: menulist;
                    }
                    .react-datetime-picker__button {
                        border: 0;
                        background: transparent;
                        padding: 4px 6px;
                    }
                    .react-datetime-picker__button:enabled {
                        cursor: pointer;
                    }
                    .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
                    .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                        stroke: #0078d7;
                    }
                    .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                        stroke: #6d6d6d;
                    }
                    .react-datetime-picker__button svg {
                        display: inherit;
                    }
                    .react-datetime-picker__calendar,
                    .react-datetime-picker__clock {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: 1;
                    }
                    .react-datetime-picker__calendar--closed,
                    .react-datetime-picker__clock--closed {
                        display: none;
                    }
                    .react-datetime-picker__calendar {
                        width: 350px;
                        max-width: 100vw;
                    }
                    .react-datetime-picker__calendar .react-calendar {
                        border-width: thin;
                    }
                    .react-datetime-picker__clock {
                        width: 200px;
                        height: 200px;
                        max-width: 100vw;
                        padding: 25px;
                        background-color: white;
                        border: thin solid #a0a096;
                    }                        
                }

                .date-error {
                    width: 100%;
                    height: 48px;

                    .react-datetime-picker {
                        width: 100%;
                        height: 48px;
                        display: inline-flex;
                        position: relative;
                    }
                    .react-datetime-picker,
                    .react-datetime-picker *,
                    .react-datetime-picker *:before,
                    .react-datetime-picker *:after {
                        -moz-box-sizing: border-box;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                    }
                    .react-datetime-picker--disabled {
                        background-color: #f0f0f0;
                        color: #6d6d6d;
                    }
                    .react-datetime-picker__wrapper {
                        display: flex;
                        flex-grow: 1;
                        flex-shrink: 0;
                        border: thin solid #FDB141;
                        border-radius: 8px;
                        padding: 8px 16px;

                    }
                    .react-datetime-picker__inputGroup {
                        min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
                        flex-grow: 1;
                        padding: 0 2px;
                    }
                    .react-datetime-picker__inputGroup__divider {
                        padding: 8px 0;
                        white-space: pre;
                        &:nth-child(7) {
                            padding: 8px;
                            &::before {
                                content: '|';
                            }
                        }
                    }
                    .react-datetime-picker__inputGroup__input {
                        min-width: 0.54em;
                        height: calc(100% - 3px);
                        position: relative;
                        padding: 1px;
                        border: 0;
                        background: none;
                        font: inherit;
                        font-size: 16px;
                        font-weight: 500;
                        color: ${({ theme }) => theme.colors.interfaceText500};
                        box-sizing: content-box;
                        -moz-appearance: textfield;
                    }
                    .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
                    .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    .react-datetime-picker__inputGroup__input:invalid {
                        background: rgba(255, 0, 0, 0.1);
                    }
                    .react-datetime-picker__inputGroup__input--hasLeadingZero {
                        margin-left: -0.54em;
                        padding-left: calc(1px +  0.54em);
                    }
                    .react-datetime-picker__inputGroup__amPm {
                        font: inherit;
                        -moz-appearance: menulist;
                    }
                    .react-datetime-picker__button {
                        border: 0;
                        background: transparent;
                        padding: 4px 6px;
                    }
                    .react-datetime-picker__button:enabled {
                        cursor: pointer;
                    }
                    .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
                    .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
                        stroke: #0078d7;
                    }
                    .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
                        stroke: #6d6d6d;
                    }
                    .react-datetime-picker__button svg {
                        display: inherit;
                    }
                    .react-datetime-picker__calendar,
                    .react-datetime-picker__clock {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: 1;
                    }
                    .react-datetime-picker__calendar--closed,
                    .react-datetime-picker__clock--closed {
                        display: none;
                    }
                    .react-datetime-picker__calendar {
                        width: 350px;
                        max-width: 100vw;
                    }
                    .react-datetime-picker__calendar .react-calendar {
                        border-width: thin;
                    }
                    .react-datetime-picker__clock {
                        width: 200px;
                        height: 200px;
                        max-width: 100vw;
                        padding: 25px;
                        background-color: white;
                        border: thin solid #a0a096;
                    }                        
                }
            }
        }

        .address {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 16px;
            max-width: 864px;

            .big {
                width: 424px;
            }

            .mid {
                width: 272px;
            }

            .small {
                max-width: 136px;
            }

            .num {
                width: 96px;
            }

            .comp {
                width: 312px;                    
            }
            
            .pages-title {
                display: flex;
                flex-direction: column;
                span {
                    font-size: 14px;
                    font-weight: 500;
                    width: 100%;
                    color: #5A646E;
                }

                .page-input {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    .page {
                        width: 80px;
                    }
                }

            }

            .label-state {
                width: 424px;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }
            }

        }
    }

    .divCheckbox {
        margin-top: 8px;
        .checkbox {        
            .square {
                border-color: ${({ theme }) => theme.colors.interfaceText200};
            }
        }
    }

    @media (max-width: 800px) {
        max-width: 90%;
        margin: 0 auto;

        .radio {
            justify-content: center;
        }

        .name,
        .mail,
        .social {
            flex-direction: column;

            .big {
                width: 100%;
            }
        }

        .state {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            
            .label {
                width: 100%;
            }
        }

        .process {
            align-items: center;
            justify-content: center;
            .big {
                width: 100%;
            }
        }

        .bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-top: 0;
            max-width: 100%;

            .subtitle {
                max-width: 90%;
            }

            .subtype, 
            .professional {
                flex-direction: column;
                width: 100%;
                .label-state {
                    width: 100%;
                }
            }

            .address {
                .big,
                .mid,
                .small,
                .num,
                .comp {
                    width: 100%;
                }

                .small {
                    max-width: 100%;
                }
            }
        }
    }
    
`

export const ServicesCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`

export const CardWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        align-items: center;
        justify-items: center;
        width: 100%;
    }
`

export const ButtonCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center; 
    width: 248px;
    height: 112px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 16px;
    border: 4px solid #FFF;
    color: ${({ theme }) => theme.colors.interfaceText400};
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07);

    &.selected {
        border: 4px solid #FFA41F;
    }   
    
    @media (max-width: 800px) {
        width: 90%;
    }
`

export const Divider = styled.div`
    border-bottom: 2px dashed ${({ theme }) => theme.colors.interfaceText200};
`

export const ServiceSelector = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`

export const ServiceOptionCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 512px;
    height: 88px;
    padding: 24px;
    background: ${({ theme }) => theme.colors.interfacePure0};
    border: 2px solid ${({ theme }) => theme.colors.interfaceText100};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors.interfaceText400};
    font-size: 16px;
    font-weight: 700;
    transition: all .3s;

    &:hover {
        border: 2px solid ${({ theme }) => theme.colors.primaryOrange400};
    }

    .select {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 40px;
        border: 1px solid ${({ theme }) => theme.colors.primaryOrange600};
        border-radius: 8px;
        color: ${({ theme }) => theme.colors.primaryOrange500};
        cursor: pointer;
        transition: all .3s;

        &:hover {
            width: 135px;
            background-color: ${({ theme }) => theme.colors.primaryOrange50};
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            color: ${({ theme }) => theme.colors.primaryOrange500};
        }
    }
    
    @media (max-width: 800px) {
        width: 90%;
    }
`

export const ConclusionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1040px;
    margin: 0px auto;
    background: ${({ theme }) => theme.colors.interfacePure0}; 

    .header {
        display: flex;
        gap: 16px;
        margin-bottom: 32px;
    }

    .bottom {
        display: flex;
        gap: 16px;
    }

    @media (max-width: 800px) {
        max-width: 90%;

        .header {
            flex-direction: column;
        }

        .bottom {
            flex-direction: column;
        }
    }
`

export const DeadlineConclusion = styled.div`
    width: 50%;
    font-size: 14px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.interfaceText400};

    .calendar-time {
        display: flex;
        align-items: center;
        gap: 16px;
        width: 510px;
        margin-top: 8px;
    }

    .calendar {
        width: 335px;

        .react-datetime-picker {
            width: 100%;
            height: 48px;
            display: inline-flex;
            position: relative;
            }
            .react-datetime-picker,
            .react-datetime-picker *,
            .react-datetime-picker *:before,
            .react-datetime-picker *:after {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            }
            .react-datetime-picker--disabled {
            background-color: #f0f0f0;
            color: #6d6d6d;
            }
            .react-datetime-picker__wrapper {
            display: flex;
            flex-grow: 1;
            flex-shrink: 0;
            border: thin solid gray;
            border-radius: 8px;
            padding: 8px 16px;

            }
            .react-datetime-picker__inputGroup {
            min-width: calc(4px + (4px * 3) +  0.54em * 6  +  0.217em * 2);
            flex-grow: 1;
            padding: 0 2px;
            }
            .react-datetime-picker__inputGroup__divider {
                padding: 8px 0;
                white-space: pre;
                    &:nth-child(7) {
                        padding: 8px;
                        &::before {
                            content: '|';
                        }
                    }
            }
            .react-datetime-picker__inputGroup__input {
            min-width: 0.54em;
            height: calc(100% - 3px);
            position: relative;
            padding: 1px;
            border: 0;
            background: none;
            font: inherit;
            font-size: 16px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText500};
            box-sizing: content-box;
            -moz-appearance: textfield;
            }
            .react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
            .react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
            .react-datetime-picker__inputGroup__input:invalid {
            background: rgba(255, 0, 0, 0.1);
            }
            .react-datetime-picker__inputGroup__input--hasLeadingZero {
            margin-left: -0.54em;
            padding-left: calc(1px +  0.54em);
            }
            .react-datetime-picker__inputGroup__leadingZero {
                margin-left: -0.44em;
                padding-left: calc(1px +  0.54em);
                font: inherit;
                font-size: 16px;
                font-weight: 500;
                color: ${({ theme }) => theme.colors.interfaceText500};
            }
            .react-datetime-picker__inputGroup__amPm {
            font: inherit;
            -moz-appearance: menulist;
            }
            .react-datetime-picker__button {
            border: 0;
            background: transparent;
            padding: 4px 6px;
            }
            .react-datetime-picker__button:enabled {
            cursor: pointer;
            }
            .react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
            .react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
            stroke: #0078d7;
            }
            .react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
            stroke: #6d6d6d;
            }
            .react-datetime-picker__button svg {
            display: inherit;
            }
            .react-datetime-picker__calendar,
            .react-datetime-picker__clock {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            }
            .react-datetime-picker__calendar--closed,
            .react-datetime-picker__clock--closed {
            display: none;
            }
            .react-datetime-picker__calendar {
            width: 350px;
            max-width: 100vw;
            }
            .react-datetime-picker__calendar .react-calendar {
            border-width: thin;
            }
            .react-datetime-picker__clock {
            width: 200px;
            height: 200px;
            max-width: 100vw;
            padding: 25px;
            background-color: white;
            border: thin solid #a0a096;
        }
    }

    .time {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 18px;
        width: 160px;
        height: 48px;
        border-radius: 8px;
        background: ${({ theme }) => theme.colors.primaryOrange50};
        font-size: 18px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText500};
    }

    @media (max-width: 800px) {
        .calendar-time {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;
        }

        .time {
            width: fit-content;
            padding: 0 15px;
        }
    }
`

export const Orientations = styled.div`
    width: 512px;

    .text {
        width: 100%;
        height: 300px;
        border: 1px solid ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        padding: 16px;
        margin-top: 8px;

        .text-area {
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 8px;
            resize: none;
            padding: 16px;
            color: ${({ theme }) => theme.colors.interfaceText500};
            font-weight: 400;
            outline: none;
        }
    }

    @media (max-width: 800px) {
        max-width: 100%;
    }
        
`

export const Upload = styled.div`
    width: 512px;

    .archives {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        height: 300px;
        border: 1px dashed ${({ theme }) => theme.colors.interfaceText100};
        border-radius: 8px;
        padding: 16px;
        margin-top: 8px;
        overflow-y: scroll;

        .file-uploaded {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 56px;
            border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
            border-radius: 8px;
            padding: 16px;

            .icon-title {
                display: flex;
                align-items: center;
                gap: 16px;

            }

            .icon-trash {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                cursor: pointer;
                svg {
                    width: 100%;
                    height: 100%;
                    path {
                        fill: ${({ theme }) => theme.colors.primaryOrange700};
                    }
                }
            }
        }


        .button {
            display: flex;
            align-items: center;
            gap: 18px;
            width: 100%;
            height: 56px;
            border: 2px dashed ${({ theme }) => theme.colors.primaryOrange600};
            border-radius: 8px;
            padding: 16px;
            color: ${({ theme }) => theme.colors.primaryOrange600};
            cursor: pointer;            
            position: relative;

            .file-input {
                width: 100%;
                height: 56px;
                color: transparent;
                transition: all .5s;
                border: none;
                padding: 0;
                background: transparent;
                border-radius: 8px;
                position: absolute;
                top: 0;
                left: 0;
            }
            .file-input::-webkit-file-upload-button {
                visibility: hidden;
            }
            .file-input::before {
                content: 'Adicionar arquivos';
                color: ${({ theme }) => theme.colors.primaryOrange600};
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border: none;
                padding: 0;
                width: 100%;
                height: 50px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                user-select: none;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                padding-left: 60px;
            }
            .file-input:active {
                outline: 0;
            }
            
        }
    }

    @media (max-width: 800px) {
        max-width: 100%;
    }
       
`  

export const Footer = styled.div`
    width: 100%;
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: 0;
    bottom: 0;  
    background: ${({ theme }) => theme.colors.interfacePure0};
    box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.05);
    z-index: 0;

    .container {
        width: 1040px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        max-width: 90%;
        
        .cancel {
            max-width: 160px;
        }


        .buttons {
            display: flex;
            gap: 16px;

            .cancel {
                border-color: ${({ theme }) => theme.colors.interfaceText200};
            }

            button {
                width: 160px;
            }
        }
    }   

    @media (max-width: 800px) {
        height: fit-content;
        padding: 10px 0;

        .container {
            max-width: 90%;
            flex-direction: column;
            justify-content: space-evenly;

            .buttons {
                justify-content: space-between;
                margin-top: 20px;
            }
        }
    }
`