import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import api from '../services/api';

interface User {
    id: string;
    nome: string;
    photo: string;
    email: string;
    profile: string;
}

interface AuthState {
    token: string;
    user: User;
}

interface SignInCredentials {
    email: string;
    senha: string;
}

interface AuthContextData {
    user: User;
    signIn(credentials: SignInCredentials): Promise<void>
    signOut(): void;
    updateUser(user: User): void;
    pageName: string;
    setPageName(page: any): void;
    pageType: any,
    setPageType(type: any): void,
}

interface TransactionsProviderProps {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

function AuthProvider({ children }: TransactionsProviderProps) {
    const [ pageName, setPageName ] = useState<string>('');
    const [ pageType, setPageType ] = useState<any>();
    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem('@Jj:token');
        const user = localStorage.getItem('@Jj:user');

        if (token && user) {
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            return { token, user: JSON.parse(user) }
        }

        return {} as AuthState;
    })

    const signIn = useCallback(async ({ email, senha }: {email: any; senha: any}) => {
        const response = await api.post('login/geral', {
            email,
            senha,
        });

        if(response.data.status === 'error') {
            throw new Error(response.data.message);
        }

        const { user, token } = response.data.result;

        localStorage.setItem('@Jj:token', token);
        localStorage.setItem('@Jj:user', JSON.stringify(user));
        api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        setData({
            user,
            token
        })
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem('@Jj:token');
        localStorage.removeItem('@Jj:user');

        setData({} as AuthState)
    }, [])

    const updateUser = useCallback(
        (user: User) => {
            localStorage.setItem("@Jj:user", JSON.stringify(user));

            setData({
                token: data.token,
                user,
            });
        },
        [setData, data.token]
    )    

    return (
        <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateUser, pageName, setPageName, pageType, setPageType }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context
}

export { AuthProvider, useAuth }