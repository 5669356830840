import { Link } from "react-router-dom";
import { IconLogoJuridico } from "../../../components/icons";
import { Container, HeaderCookies, MainInfoCookies } from "./styles";


export default function Cookies() {
    const SafariCookie: string = 'https://support.apple.com/pt-br/guide/safari/sfri11471/mac#:~:text=Voc%C3%AA%20pode%20ver%20todos%20os,remover%20alguns%20deles%20ou%20todos.&text=No%20app%20Safari%20do%20Mac,em%20Remover%20ou%20Remover%20Tudo.';
    const ChromeCookie: string = 'https://support.google.com/chrome/answer/95647?hl=pt-BR&co=GENIE.Platform%3DDesktop';
    const FirefoxCookie: string = 'https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox';
    const ExplorerCookie: string = 'https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d';
    const EdgeCookie: string = 'https://support.microsoft.com/pt-br/microsoft-edge/excluir-cookies-no-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09#:~:text=No%20Edge%2C%20selecione%20Configura%C3%A7%C3%B5es%20e%20mais%20%3E%20Configura%C3%A7%C3%B5es%20%3E%20Privacidade%2C,e%20outros%20dados%20do%20site.';
    const OperaCookie: string = 'https://www.opera.com/pt-br/features/bookmarks#see-more';


    return (
        <Container>
            <HeaderCookies>
                <div><IconLogoJuridico /></div>
            </HeaderCookies>

            <MainInfoCookies>
                <p className="bold two">POLÍTICAS DE COOKIES E PRIVACIDADE</p>

                <p className="bold">POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.</p>

                <p className="two">
                    Os Termos, Condições gerais de uso e política de cookies e privacidade de acordo com a Lei nº 13.709/2018 - Lei de Proteção de Dados, apresentados se aplicam aos serviços prestados pela plataforma 123JUS, doravante denominada EMPRESA.
                </p>

                <p className="bold">
                    DISPOSIÇÕES GERAIS
                </p>

                <p className="mid-bold">
                    CONSIDERANDO QUE:
                </p>

                <p className="left-pad">
                    (I) - A empresa 123JUS é uma empresa de tecnologia que criou sistema para intermediar a contratação de profissionais jurídicos e auxiliar na execução dos serviços dos mesmos;
                </p>

                <p className="left-pad">
                    (II) - que a plataforma será integrada com chat para comunicação, sistema web e outras ferramentas de tecnologia;
                </p>

                <p className="left-pad">
                    (III) - que a intermediação de serviços se dará mediante planos de assinatura, conforme livre escolha do usuário no momento de cadastro;
                </p>

                <p className="left-pad">
                    (IV) - que gateway de pagamento irá "distribuir" valores recebidos pelos usuários solicitantes de serviços entre o usuário executor do serviço e a plataforma 123JUS em contraprestação à intermediação da contratação e pagamento do serviço prestado;
                </p>

                <p className="two left-pad">
                    (V) - que a plataforma contará com os seguintes tipos de usuários: usuários solicitantes de serviço (clientes), usuários prestadores de serviço (profissional jurídico), usuários auditores (jurídico interno de empresas) e usuários representantes regionais (prestam serviço aos profissionais jurídicos intermediando seus serviços), podendo serem criados novos tipos de usuários em que este termo precise ser alterado, valendo desde já para todos que utilizarem os serviços da empresa 123JUS.
                </p>

                <p className="bold">OBSERVÂNCIA À LEI DE PROTEÇÃO DE DADOS</p>

                <p>
                    Estamos empenhados em salvaguardar a sua privacidade ao estabelecer esta relação conosco. Este termo tem a finalidade de deixar o mais claro possível a nossa política de coleta e compartilhamento de dados, informando sobre os dados coletados e como os utilizamos.
                </p>

                <p>
                    Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários à prestação de nossos serviços.
                </p>

                <p>
                    Pelo presente as partes declaram que tanto o aplicativo quanto o site quanto do uso do sistema observarão as disposições da à Lei 13.709/18 que regulamenta a proteção de dados pessoais e da Lei nº 12.965/14 que regulamenta o Marco Civil da Internet. Desde já você autoriza o colhimento e uso de dados nos seguintes teremos:
                </p>

                <p className="left-pad">
                    (I) - Algumas informações pessoais são obtidas quando você se registra, preenchendo um formulário de contato ou cadastro. Durante o cadastro a EMPRESA solicita alguns dados como nome, endereço de e-mail e outras informações pessoais. 
                </p>

                <p className="left-pad">
                    (II) - Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
                </p>

                <p className="left-pad">
                    (III) - Ao submeter seus dados para pagamento, podemos coletar informações sobre a compra ou transação. Isso abrange suas informações de pagamento, como os dados do seu cartão de crédito ou débito, informações de conta e autenticação, além dos dados de faturamento, tais como endereço completo, CPF e CNPJ.
                </p>

                <p className="left-pad">
                    (IV) - As informações pessoais transmitidas não são reveladas sem a sua autorização prévia. Portanto, não serão vendidas, compartilhadas sem ser para os propósitos aqui expostos ou alugadas. Suas informações servem exclusivamente para criar um perfil dos usuários e permitem melhorar a qualidade dos serviços, ao mesmo tempo em que possibilitam direcionamento de conteúdos com maior eficácia.
                </p>

                <p className="left-pad">
                    (V) - As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial. Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação.
                </p>

                <p className="left-pad">
                    (VI) - Desde já fica permitido o uso de cookie, que é um pequeno arquivo que seu navegador salva no seu disco rígido. Os cookies não conterão nenhuma informação pessoal identificável, mas permitirão oferecer determinadas funcionalidades que facilitam a navegação.
                </p>

                <p className="left-pad">
                    (VII) - Ao navegar na Internet, algumas informações serão transmitidas automaticamente entre computadores e serão coletadas como parte das exigências tecnológicas inerentes à navegação, para fins estatísticos. São elas: nome do domínio, IP, data e hora de navegação no site da EMRPESA, páginas visitadas dentro do site da EMPRESA, tipo de navegador e outras correlacionadas.
                </p>

                <p>
                    Compartilhamos os seus dados com a empresa responsável pelo processamento de pagamentos, para fins de viabilizar pagamentos e o processamento de dados, bem como para tornar o produto mais relevante e eficiente à sua finalidade.
                </p>

                <p>
                    Estes dados são armazenados em servidores seguros nossos ou de fornecedores contratados, acessados e utilizados de acordo com nossas políticas e padrões de segurança. Todo o tráfego entre nossos servidores ou entre o seu computador e nossos servidores é encriptado através do protocolo seguro SSL ou semelhante.
                </p>

                <p>
                    Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que os dados que coletamos sejam processados de acordo com segurança onde quer que os dados estejam localizados.
                </p>

                <p>
                    Nós manteremos as informações que coletamos de você até que ocorra a solicitação de exclusão definitiva por sua parte. Neste caso, nós cessaremos imediatamente a utilização dos seus dados para fins comerciais, porém armazenaremos os seus dados enquanto tenhamos obrigações legais, tributárias ou judiciais a cumprir com tais dados.
                </p>

                <p>
                    Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas informações para as seguintes FINALIDADES:
                </p>

                <ul className="list">
                    <li className="list-info">
                        Fornecer, melhorar e desenvolver os Serviços disponíveis. Usamos os seus dados para melhorar a qualidade dos nossos serviços, através da personalização do serviço prestado.
                    </li>
                    <li className="list-info">
                        Obrigações legais. Compartilhamos seus dados com terceiros para cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus dados pessoais a terceiros, a um tribunal, reguladores ou agências governamentais.
                    </li>
                </ul>

                <p className="two">
                    A não observância de qualquer disposição das referidas leis implicarão em responsabilidade exclusiva do infrator.
                </p>

                <p className="bold">POLÍTICAS DE COOKIES</p>

                <p>
                    Cookies são pequenos arquivos de texto ou fragmentos de informação que são baixados em seu computador, smartphone ou qualquer outro dispositivo com acesso à internet quando você visita nossa aplicação.
                </p>

                <p>
                    Eles contêm informações sobre a sua navegação em nossas páginas e retêm apenas informações relacionadas a suas preferências.
                </p>

                <p>
                    Assim, essa página consegue armazenar e recuperar os dados sobre os seus hábitos de navegação, de forma a melhorar a experiência de uso, por exemplo. É importante frisar que eles não contêm informações pessoais específicas, como dados sensíveis ou bancários.
                </p>

                <p>
                    O seu navegador armazena os cookies no disco rígido, mas ocupam um espaço de memória mínimo, que não afetam o desempenho do seu computador. A maioria das informações são apagadas logo ao encerrar a sessão, como você verá no próximo tópico.
                </p>

                <p className="bold">
                    Os cookies, quanto a seus proprietários, podem ser:
                </p>

                <p>
                    Cookies proprietários: são cookies definidos por nós ou por terceiros em nosso nome
                </p>

                <p>
                    Cookies de terceiros: são cookies definidos por terceiros confiáveis em nossa aplicação
                </p>

                <p className="bold">
                    Os cookies, quanto ao seu tempo de vida, podem ser:
                </p>

                <p>
                    Cookies de sessão ou temporários: são cookies que expiram assim que você fecha o seu navegador, encerrando a sessão.
                </p>

                <p>
                    Cookies persistentes ou permanentes: são cookies que permanecem no seu dispositivo durante um período determinado ou até que você os exclua.
                </p>

                <p className="bold">
                    Os cookies, quanto a sua finalidade, podem ser:
                </p>

                <p>
                    Cookies necessários: são cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos; se estes, nossos serviços podem apresentar mal desempenho ou não funcionar.
                </p>

                <p>
                    Cookies de desempenho: são cookies que otimizam a forma que nossas aplicações funcionam, coletando informações anônimas sobre as páginas acessadas.
                </p>

                <p>
                    Cookies de funcionalidade: são cookies que memorizam suas preferências e escolhas (como seu nome de usuário)
                </p>

                <p>
                    Cookies de publicidade: são cookies que direcionam anúncios em função dos seus interesses e limitam a quantidade de vezes que o anúncio aparece.
                </p>

                <p className="bold">
                    Porque usamos cookies:
                </p>

                <p>
                    A EMPRESA utiliza Cookies para fornecer a melhor experiência de uso, tornando nossas aplicações mais fáceis e personalizadas, tendo por base suas escolhas e comportamento de navegação.
                </p>

                <p>
                    Assim, buscamos entender como você utiliza nossas aplicações e ajustar o conteúdo para torná-lo mais relevante para você, além de lembrar de suas preferências.
                </p>

                <p>
                    Os Cookies participam deste processo porquanto armazenam, leem e executam os dados necessários para cumprir com o nosso objetivo.
                </p>

                <p className="bold">Que tipo de cookies utilizamos:</p>

                <p>
                    Vários são os tipos de cookies que podem ser utilizados pela EMPRESA. Dentre eles, não se restringindo a cookies necessários, de publicidade, desempenho, funcionalidade e outros que a EMPRESA julgar necessário. É importante lembrar que você pode gerenciar a permissão concedida para cada Cookie em seu navegador. Além disso, uma vez que os Cookies capturam dados sobre você, aconselhamos a leitura de nossa Política de Privacidade, disponível neste documento.
                </p>

                <p className="bold">Gerenciamento de cookies:</p>

                <p>
                    A instalação dos cookies está sujeita ao seu consentimento. Apesar da maioria dos navegadores estar inicialmente configurada para aceitar cookies de forma automática, você pode rever suas permissões a qualquer tempo, de forma a bloqueá-los, aceitá-los ou ativar notificações para quando alguns cookies forem enviados ao seu dispositivo.
                </p>

                <p>
                    Atualmente, na primeira vez que você acessa nossas aplicações, será requerida a sua concordância com a instalação destes. Apenas após a sua aceitação eles serão ativados.
                </p>

                <p>
                    Como já dito, você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Você também pode configurá-los caso a caso. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                </p>

                <p>
                    Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
                </p>

                <p>
                    Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
                </p>

                <Link 
                    to={{ pathname: ExplorerCookie }} target="_blank"
                >
                    <div className="link">Se você usa o <span>Internet Explorer</span>.</div>
                </Link>

                <Link 
                    to={{ pathname: FirefoxCookie }} target="_blank"
                >
                    <div className="link">Se você usa o <span>Firefox</span>.</div>
                </Link>

                <Link 
                    to={{ pathname: SafariCookie }} target="_blank"
                >
                    <div className="link">Se você usa o <span>Safari</span>.</div>
                </Link>

                <Link 
                    to={{ pathname: ChromeCookie }} target="_blank"
                >
                    <div className="link">Se você usa o <span>Google Chrome</span>.</div>
                </Link>

                <Link 
                    to={{ pathname: EdgeCookie }} target="_blank"
                >
                    <div className="link">Se você usa o <span>Microsoft Edge</span>.</div>
                </Link>
                
                <Link 
                    to={{ pathname: OperaCookie }} target="_blank"
                >
                    <div className="link">Se você usa o <span>Opera</span>.</div>
                </Link>

                <p className="bold top">Disposições finais sobre a política de cookies:</p>

                <p>
                    Para a (nome empresarial), a privacidade e confiança são fundamentais para a nossa relação com você. Estamos sempre nos atualizando para manter os mais altos padrões de segurança 
                </p>

                <p>
                    Assim, reservamo-nos o direito de alterar esta Política de Cookies a qualquer tempo. As mudanças entrarão em vigor logo após a publicação, e você será avisado
                </p>

                <p>
                    Ao continuar a navegação nas nossas aplicações após essa mudança se tornar eficaz, você concorda com elas. Aconselhamos que você sempre verifique esta Política, bem como a nossa Política de Privacidade.
                </p>

                <p className="two">
                    Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco pelo email contato@123jus.com.br.
                </p>

                <p className="center">
                    Belo Horizonte, 19 de fevereiro de 2023.
                </p>
            </MainInfoCookies>

        </Container>
    )
}