import styled from "styled-components";

interface CardProps {
    selected?: any,
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1040px;

    .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        margin-top: 300px;
        transform: translateX(-50%);
    }

    .profile {
        .register-form {
            padding-bottom: 80px;

        .name {
            display: flex;
            flex-wrap: wrap;
            gap: 25px 16px;
            margin-bottom: 56px;

            .big {
                width: 65%;

                input {
                    text-transform: capitalize;
                }
            }

            .mid {
                width: 45%;
            }

            .small {
                width: 33%;
            }

            .oab {
                width: 250px;
            }

            .label-oab {
                width: 35%;
                max-width: 335px;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }

                .css-319lph-ValueContainer {
                    text-transform: capitalize;
                }
            }

            .label-state {
                width: 100%;
                max-width: 423px;
                position: relative;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }  
                .error {
                    position: absolute;
                    display: block;
                    top: 5%;
                    right: 5%;
                    color: ${({ theme }) => theme.colors.interfaceDanger};
                }
            }

            .label {
                width: 31.5%;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }                
            }
        }

        .fone {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            .small {
                width: 32%;
            }

            .big {
                width: 65%;
            }
        }

        .address {
            height: 168px;
            margin-bottom: 56px;
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            .label {
                width: 24%;
                p {
                    margin-bottom: 8px;
                    font-size: 14px;
                    font-weight: 500;
                    color: ${({ theme }) => theme.colors.interfaceText400};
                }
            }

            .small {
                width: 23.5%;
            }
            .big {
                width: 49%;
            }
        }

        .css-tj5bde-Svg {
            svg {
                fill: orange;
            }
        }


        .css-tlfecz-indicatorContainer,
        .css-1gtu0rj-indicatorContainer {
            svg {
                fill: orange;
            }
        }
    }
    }

    .plan {
        h3 {
            font-size: 24px;
            font-weight: 500;
            margin-bottom: 16px;
        }

        p {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 32px;
        }
    }

    .toasted {
        z-index: 99999;
    }
    .warning {
        display: flex;
        gap: 16px;
        width: 550px;
    }

    @media (max-width: 1000px) {
        min-width: 1200px;        
    }
`;

export const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    /* max-width: 1040px; */
`

export const ModalMain = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background: ${({ theme }) => theme.colors.interfacePure0};
    padding: 8px;

    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 20px;
        font-weight: 700;
    }

    .close {
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
        /* &:hover {
            transform: scale(1.2);
        } */
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .cancel,
        .danger {
            font-weight: 600;
        }
        .cancel {
           border: 2px solid ${({ theme }) => theme.colors.interfacePure200};
        }
    }
`

export const ModalPlanMain = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 448px;
    /* height: 140px; */
    background: ${({ theme }) => theme.colors.interfacePure0};

    .title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 20px;
        font-weight: 700;
        padding-top: 8px;
        padding-bottom: 24px;
        border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
    }

    .close {
        cursor: pointer;
        svg {
            path {
                fill: ${({ theme }) => theme.colors.interfacePure400};
            }
        }
        /* &:hover {
            transform: scale(1.2);
        } */
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .cancel,
        .save {
            font-weight: 600;
        }
        .cancel {
           border: 2px solid ${({ theme }) => theme.colors.interfacePure200};
        }
        .save {
            color: ${({ theme }) => theme.colors.interfacePure0};
            background: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }
`

export const FreeCardPlan = styled.div<CardProps>`
    display: flex;
    flex-direction: column;
    width: 448px;
    border: ${({ selected }) => (selected === 1 ? '2px solid #23ACFA' : '2px solid #F2F2F2')};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    cursor: pointer;

    .free-plan {
        display: flex;
        align-items: center;
        gap: 26px;
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.interfaceText400};


    }

    .free-price {
        display: flex;
        align-items: center;
        gap: 30px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 16px;
        font-weight: 400;

        span {
            color: ${({ theme }) => theme.colors.secondaryPool600};
            font-weight: 600;
        }

        .price {
            width: 120px;
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }
    }    
`

export const ProCardPlan = styled.div<CardProps>`
    display: flex;
    flex-direction: column;
    width: 448px;
    border: ${({ selected }) => (selected === 2 ? '2px solid #00D3AD' : '2px solid #F2F2F2')};
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 24px;
    cursor: pointer;

    .pro-plan {
        display: flex;
        align-items: center;
        gap: 26px;
        margin-bottom: 32px;
        font-size: 24px;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.secondaryGreen600};
    }

    .pro-price {
        display: flex;
        align-items: center;
        gap: 30px;
        color: ${({ theme }) => theme.colors.interfaceText400};
        font-size: 16px;
        font-weight: 400;

        span {
            color: ${({ theme }) => theme.colors.secondaryGreen600};
            font-weight: 600;
        }

        .price {
            width: 130px;
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.secondaryGreen600};
        }
    }
`

export const ActivityModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 8px;
    gap: 24px;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.interfaceText400};
        padding-bottom: 24px;
        border-bottom: 2px solid ${({ theme }) => theme.colors.interfacePure100};
        
        .points {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        .close {
            cursor: pointer;
            svg {
                path {
                    fill: ${({ theme }) => theme.colors.interfacePure400};
                }
            }
        }
    }

    .user-status {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 128px;
        border-radius: 8px;
        border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
        padding: 24px;
        cursor: pointer;
        .user {
            display: flex;
            align-items: center;
            gap: 26px;
            margin-bottom: 16px;
            font-size: 24px;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }

        .description-active {
            color: ${({ theme }) => theme.colors.interfaceText400};
            span {
               color: ${({ theme }) => theme.colors.interfaceSuccess400};
               font-weight: 600;
            }
        }
        .description-inactive {
            color: ${({ theme }) => theme.colors.interfaceText400};
            span {
               color: ${({ theme }) => theme.colors.interfaceDanger400};
               font-weight: 600;
            }
        }

        &.active {
            border: 2px solid ${({ theme }) => theme.colors.interfaceSuccess400};
        }

        &.inactive {
           border: 2px solid ${({ theme }) => theme.colors.interfaceDanger400};
        }
    }

    .text-area {
        .subtitle {
            margin-bottom: 24px;
            color: ${({ theme }) => theme.colors.interfaceText400};
        }
        .justification {
            width: 100%;
            height: 120px;
            padding: 16px;
            border: 2px solid ${({ theme }) => theme.colors.interfacePure100};
            border-radius: 8px;
            resize: none;
            color: ${({ theme }) => theme.colors.interfaceText500};
            &:focus-visible {
                outline: none;
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .cancel,
        .continue {
            font-weight: 600;
        }
        .cancel {
           border: 2px solid ${({ theme }) => theme.colors.interfacePure200};
        }
        .continue {
            background: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }
`
