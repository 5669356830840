// React
import { useState } from 'react';

// Libraries
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

interface Props {
  info?: any,
}

export default function GraficCard({info}:Props) {
  const [ activeIndex, setActiveIndex ] = useState(2);

  const data = [
    {
      name: info[0]?.mes,
      total: info[0]?.qtd,
    },
    {
      name: info[1]?.mes,
      total: info[1]?.qtd,
    },
    {
      name: info[2]?.mes,
      total: info[2]?.qtd,
    },
  ];

  function handleOnClick(data: any, index: any) {
    setActiveIndex(index)
  }

  return (
    // <Container>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          // width={150}
          // height={40}
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: -5,
          }}
          barSize={90}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="total" onClick={handleOnClick} radius={[8, 8, 0, 0]}>
            {data.map((entry, index) => (
              <Cell cursor="pointer" fill={index === activeIndex ? '#293F93' : '#cccccc'} key={`cell-${index}`} />
            ))}
          </Bar>
          <XAxis dataKey="name" scale="point" padding={{ left: 55, right: 55 }} />
          <YAxis />
          <Tooltip />
        </BarChart>
      </ResponsiveContainer>
    // </Container>
  )
}
