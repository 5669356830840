import styled from "styled-components";

interface Props {
    menuOpen: boolean;
    profileStatus?: any,
}

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;   
    box-shadow: 35px 45px 73px rgba(32, 32, 35, 0.07); 
`

export const ProfileWrapper = styled.div<Props>`
    display: flex;
    flex-direction: column;    
    width: 100%;
    height: ${({ menuOpen }) => (menuOpen ? "160px" : "80px")};    
    background: ${({ theme }) => theme.colors.interfacePure0};
    border-radius: 8px;     
    
    .title {
        color: ${({ theme }) => theme.colors.interText400};
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 4px;
    }

    .subtitle {
        color: ${({ theme }) => theme.colors.interText400};
        font-size: 14px;
        font-weight: 500;
    }
`

export const ProfileTop = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding-left: 24px;
    padding-right: 16px;
`

export const ProfileBottom = styled.div<Props>`
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    align-items: center;
    justify-content: space-between;
    /* gap: 80px; */    
    height: 80px;
    padding-left: 24px;
    padding-right: 16px;
    /* padding-right: 17%; */

    /* @media (max-width: 1400px) {
        padding-left: 7.5%;
        padding-right: 14%;
    } */
`

export const DivIcon = styled.div`
    width: 40px;
    svg {
        path {
            fill: ${({ theme }) => theme.colors.primaryBlue500};
        }
    }
`

export const DivCode = styled.div`
    width: 130px;       
    
`

export const DivName = styled.div`
    width: 260px;
    overflow-x: hidden;
    text-transform: capitalize;
`

export const DivIsland = styled.div`
    width: 170px;
`

export const DivSector = styled.div`
    width: 250px;
    height: 48px;
`

export const DivProfile = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: ${({ theme }) => theme.colors.primaryBlue500};
    border-radius: 8px;
    cursor: pointer;
    &.fake {
        background: none;
        cursor: auto;
    }
`

export const DivArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;    
    cursor: pointer;

    &.fake {
        cursor: auto;
    }
`

export const DivCity = styled.div`
    width: 170px;
`

export const DivMail = styled.div`
    width: 260px;
`

export const DivWhats = styled.div`
    width: 130px;
`