import { ContainerOverlay, Container } from './styles'

interface ModalProps {
    id: string
    children: any
    onClose: () => void
    openModal: boolean
    width?: string
    height?: string
    handleOnFinish?(): void
}

export default function ModalDefault({ id, onClose, width, height, openModal, children, handleOnFinish = () => { } }: ModalProps) {
    const handleCloseModal = (e: any) => {
        if (e.target.id === id) {
            onClose()
        }
    }

    return (
        <ContainerOverlay id={id} openModal={openModal} onClick={handleCloseModal}>
            <Container
                width={width}
                heigth={height}
                openModal={openModal}
            >
                {children}
            </Container>
        </ContainerOverlay>
    )
}