
//Image //
import { useState } from 'react';
import { Link } from 'react-router-dom';
import WellcomeImg from '../../../assets/images/ImageWellcome.png';
import { useAuth } from '../../../contexts/UserContext';

// Icons //
import { IconDuplicate, IconEmail, IconPhone, IconQuestionLine, IconVideo, IconWhatsapp, IconWrong, } from '../../icons';

// Components
import ModalDefault from '../../ModalDefault';

// Libraries
import toast, { Toaster } from 'react-hot-toast';

import { 
  Container,
  ImageWellcome,
  SectionMessage,
  SectionCards,
  Card,
  WrapperModal,
  ModalTitle,
  ModalContacts,
  Divider,
} from './styles';
import ButtonDefault from '../../form/ButtonDefault';
import InputDefault from '../../form/InputDefault';
import api from '../../../services/api';

export interface InviteProps {
  nome?: string,
  email?: string,
}

export default function CardWellcome() {
  const { user } = useAuth();
  const [ helpModal, setHelpModal ] = useState<any>();
  const [ inviteProfessional, setInviteProfessional ] = useState<any>();
  const [ DTOInvite, setDTOInvite ] = useState<InviteProps>({
    nome: '',
    email: '',
  });

  const fullName = user.nome;
  const firstName = fullName?.split(' ')[0];

  function copyText() {
      toast(<div className="warning"><span><IconDuplicate color='#23ACFA' /></span>O e-mail suporte@123jus.com.br foi copiado!</div>, {
          duration: 3000,
          style: {
            padding: '16px',
            minWidth: '520px',
            color: '#1A2858',
            background: '#E4F5FF',
            border: '2px solid #88D3FE',
          },
      }); 
      
      setHelpModal(false);
  }

  function handleOnChange(name: any, value: any) {

    const newDTO: any = DTOInvite
    newDTO[name] = value
    setDTOInvite({ ...newDTO })

  }

  async function inviteAProfessional() {
    
    try {

      const response = await api.post('site/contato/convidar', DTOInvite);

      if (response.data.status !== 'success') {

        if (response.data.result[0] === undefined) {
          throw new Error(response.data.message);

        } else {
          throw new Error(response.data.result[0].message);
        }

      } else {
        setInviteProfessional(false);

        toast(<div className="warning"><span><IconEmail color='#23ACFA' /></span>Convite enviado com sucesso!</div>,
          {
            duration: 3000,
            style: {
              padding: '16px',
              minWidth: '320px',
              color: '#1A2858',
              background: '#E4F5FF',
              border: '2px solid #88D3FE',
            },
          });           
      }

    } catch (error: any) {
      
      console.log('log error invite professional', error)

    }

  }

  return (
    <Container>
      {
        user.profile === 'comum' &&
        <ImageWellcome>
          <img src={WellcomeImg} alt="Julia virtual assistent" />
        </ImageWellcome>
      }

     
      <SectionMessage>
        <h1 className='wellcomeTitle'>Bem vindo, <span>{firstName}</span>!</h1>
        <p className='wellcomeParagraph'>Sou Júlia, sou sua atendente virtual.<br /> Como posso te ajudar hoje?</p>
      </SectionMessage>
     

      <SectionCards>
        {/* <Card>
          <IconVideo />
          <span className='wellcomeCardTitle'>Tutorial</span>
        </Card> */}

        <Card
          className='help'
          onClick={() => setHelpModal(!helpModal)}
        >
          <IconQuestionLine color='#CCCCCC' />
          <span className='wellcomeCardTitle'>Ajuda</span>
        </Card>

        <Card
          onClick={() => setInviteProfessional(!inviteProfessional)}
          className='invite'
        >
          <IconEmail />
          <span className='wellcomeCardTitle'>Convidar profissional</span>
        </Card>
      </SectionCards>

      {/* Modal contato */}
      <ModalDefault
        id={'overlayModal'}
        onClose={() => setHelpModal((helpModal: any) => !helpModal)}
        openModal={helpModal}
        width="560px"
      >
        <WrapperModal>

          <ModalTitle>
            Entre em contato com a equipe!
            <div 
              onClick={() => setHelpModal(false)}
            >
              <IconWrong />
            </div>
          </ModalTitle>

          <Divider />

          <ModalContacts>
            <div className='first-line'>
              <Link
                to={{ pathname: 'https://api.whatsapp.com/send?phone=5531983474387&text=Ol%C3%A1!%20Tudo%20bem%3F' }}
                target={'_blank'}
                rel={'noreferrer'}
              >
                  <div className='card zap'>
                    <IconWhatsapp />
                    Whatsapp
                  </div>
              </Link>

              <div 
                className='card mail'
                onClick={() => {navigator.clipboard.writeText('suporte@123jus.com.br'); copyText()}}
              >
                <IconEmail />
                E-mail
              </div>
            </div>
            <div className="second-line">
              <div className='card phone'>
                <IconPhone />
                Ligar: <span>(31) 3565-7918</span>
              </div>

              <Link to={{ pathname: "https://123jus.notion.site/Profissionais-a22d99931bd0421291cc812f0479886c" }} target="_blank">
                <div className="card tutorial">
                    <IconVideo />
                    Tutorial
                </div>
              </Link>
            </div>
          </ModalContacts>
        </WrapperModal>

      </ModalDefault>

      {/* Modal convite profissional */}
      <ModalDefault
        id={'overlayModal'}
        onClose={() => setInviteProfessional((inviteProfessional: any) => !inviteProfessional)}
        openModal={inviteProfessional}
        width="520px"
      >
        <WrapperModal>

          <ModalTitle>
            Convidar profissional
            <div 
              onClick={() => setInviteProfessional(false)}
            >
              <IconWrong />
            </div>
          </ModalTitle>

          <Divider />

          <ModalContacts>
            <div className='name'>
              <span>Nome do profissional:</span>
              <InputDefault
                title=""
                name="nome_profissional"
                value={DTOInvite.nome}
                onChangeText={(value: any) => handleOnChange('nome', value)}
                className='name'
              />
            </div>

            <div className='mail'>
              <span>E-mail para contato:</span>
              <InputDefault
                title=""
                name="email_profissional"
                value={DTOInvite.email}
                onChangeText={(value: any) => handleOnChange('email', value)}
                className='name'
              />
            </div>

            <div className='buttons'>

              <ButtonDefault
                className="cancel"
                onClick={() => setInviteProfessional(false)}
              >
                Cancelar
              </ButtonDefault>
              <ButtonDefault
                className="continue"                
                onClick={() => inviteAProfessional()}                
              >
                Enviar convite
              </ButtonDefault>

            </div>
          </ModalContacts>
        </WrapperModal>

      </ModalDefault>

      <div onClick={() => toast.remove()}>
        <Toaster />
      </div>
    </Container>
  )
}
